import React, { useEffect, useState } from "react";
import { Input, LoadingButton } from "components/form";
import PhoneInput from "react-phone-input-2";
import { toastMessage } from "utils/toastMessage";
import { loginApi } from "services/auth";
import { regex } from "utils/formValidator";
import commonMessages from "utils/commonMessages";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const LoginModal = ({ sendOtp, moveSignUp, showSignUp }) => {
  // const location = useLocation();
  // console.log(location, "current location");
  const [loginSwitch, setLoginSwitch] = useState("login-email");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [userAuth, setUserAuth] = useState({});
  const { email } = regex;
  const { VALID_EMAIL } = commonMessages;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = () => {
    let status = true;
    const errors = {};
    if (loginSwitch === "login-email") {
      if (!userAuth?.email) {
        errors["email"] = "Email is mandatory";
        status = false;
      } else if (!userAuth.email.match(email)) {
        errors["email"] = VALID_EMAIL;
        errors["emailErr"] = true;
        status = false;
      }
    }

    if (loginSwitch === "login-phone" && !userAuth?.phone) {
      errors["phone"] = "Phone is Mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const handlePhoneChange = (phone, country) => {
    const phoneWithoutCountryCode = phone.replace(country.dialCode, "");
    setUserAuth({
      ...userAuth,
      phone: phoneWithoutCountryCode,
      countryCode: `+${country?.dialCode || "+91"}`,
      completeMobile: `+${
        country?.dialCode || "+91"
      }${phoneWithoutCountryCode}`,
    });
  };

  // const sendOtpHandler = async (e) => {
  //   e.preventDefault();
  //   if (validation()) {
  //     try {
  //       setLoading(true);
  //       const reqData = {};
  //       if (loginSwitch === "login-email") {
  //         reqData.email = userAuth.email;
  //       }
  //       if (loginSwitch === "login-phone" && userAuth.phone.length>5) {

  //         reqData.phone = userAuth.phone;
  //         reqData.countryCode = userAuth.countryCode;
  //       }
  //       else{
  //         // toastMessage("Invalid phone number","error","phoneLength")
  //         return;
  //       }
  //       reqData.type = 1;

  //       const { data } = await loginApi(reqData);
  //       if (data?.code === 200) {
  //         toastMessage(data?.message,"success","otpSender");
  //         sendOtp({...data?.data,authType:loginSwitch} || {});
  //       } else {
  //         toastMessage(data.message, "error", "otpSender");
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  const sendOtpHandler = async (e) => {
    e.preventDefault();
    if (!validation()) return;

    try {
      setLoading(true);

      const reqData = { type: 1 };
      if (loginSwitch === "login-email") {
        reqData.email = userAuth.email;
      } else if (loginSwitch === "login-phone") {
        if (userAuth.phone.length <= 5) {
          toastMessage("Invalid phone number", "error", "phoneLength");
          return;
        }
        reqData.phone = userAuth.phone;
        reqData.countryCode = userAuth.countryCode;
      }

      const { data } = await loginApi(reqData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "otpSender");
        sendOtp({ ...data?.data, authType: loginSwitch, ...reqData });
      } else if (!showSignUp) {
        navigate("/");
        toastMessage(data?.message, "error", "otpSender");
      } else {
        toastMessage(data.message, "error", "otpSender");
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserAuth({});
    setError({});
  }, [loginSwitch]);

  return (
    <>
      <div className="auth_content login">
        <h3 className="title">Login to your account</h3>
        <p>Please enter your registered email address to continue</p>

        <form>
          {loginSwitch === "login-email" && (
            <div className="form_field">
              <label className="label has_child">
                Email Address{" "}
                <span
                  className="usePhone"
                  onClick={() => setLoginSwitch("login-phone")}
                >
                  Use phone number instead
                </span>
              </label>
              <Input
                type="email"
                name="email"
                value={userAuth?.email}
                className="form-control input"
                placeholder="Email Address"
                error={error.email}
                extraError={error.emailErr}
                onChange={(e) =>
                  setUserAuth({
                    ...userAuth,
                    email: e.target.value.toLowerCase(),
                  })
                }
              />
            </div>
          )}

          {loginSwitch === "login-phone" && (
            <div className="form_field">
              <label className="label has_child">
                Mobile Number
                <span
                  className="usePhone"
                  onClick={() => setLoginSwitch("login-email")}
                >
                  Use email address instead
                </span>
              </label>
              <PhoneInput
                className={"country_picker"}
                country={"in"}
                value={userAuth.completeMobile}
                enableSearch={true}
                placeholder={"Enter Phone Number"}
                countryCodeEditable={false}
                onChange={handlePhoneChange}
              />
              {error.phone && (
                <span className="invalid-feedback d-block">{error.phone}</span>
              )}
            </div>
          )}

          <div className="button_wrap button_center">
            <LoadingButton loading={loading} onClick={sendOtpHandler}>
              Send OTP
            </LoadingButton>
          </div>

          {showSignUp && (
            <>
              <p className="signUpText">
                Don't have an account ?{" "}
                <button type="button" onClick={moveSignUp}>
                  Sign up
                </button>
              </p>
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default LoginModal;
