import CommonPagination from "common/CommonPagination";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { getFaqList, submitQueryApi } from "services/master";
import { faqList } from "staticData";
import { toastMessage } from "utils/toastMessage";

const FAQs = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  const [faqList, setFaqList] = useState([]);
  const [submitQuery, setSubmitQuery] = useState({});
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  const [loading, setLoading] = useState(false);

  const fetchFaqList = async () => {
    try {
      const sendData = {};
      sendData.page = page - 1;
      sendData.pageSize = 10;
      onShow();
      const { data } = await getFaqList(sendData);
      if (data?.code === 200) {
        setFaqList(data?.data || []);
        setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "faqList");
        setFaqList([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Submit Query Handler
  const submitQueryHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await submitQueryApi(submitQuery);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "submitQuery");
        setSubmitQuery("");
      } else {
        toastMessage(data?.message, "error", "submitQuery");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle Page Change
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  useEffect(() => {
    fetchFaqList();
  }, []);
  return (
    <>
      <div className="exploreExperts">
        <div className="container">
          <div className="page_title text-center faq_title">
            <span>FAQs</span>
            <h3>Frequently Asked Questions</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p>
          </div>
          <div className="faq spacing">
            <Accordion defaultActiveKey="0">
              {faqList &&
                faqList.length > 0 &&
                faqList.map((item) => {
                  return (
                    <Accordion.Item eventKey={item._id} key={item?._id}>
                      <Accordion.Header>
                        {item?.question || "---"}
                      </Accordion.Header>
                      <Accordion.Body>{item?.answer || "---"}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
            <CommonPagination
              activePage={page}
              total={total}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
