import React, { useEffect, useState } from "react";
import "./Services.scss";
import { banner1, service1 } from "assets/images";
// import VideoPlayer from "components/video-player/VideoPlayer";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button } from "components/form";
import ConfirmPayModal from "components/modals/ConfirmPayModal";
import CustomModal from "components/modals/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import {
  bookOneToOneApi,
  getExpertServiceDetailApi,
  getExpertServiceDetailSlotApi,
} from "services/userServices/servicesDetail&Booking";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import VideoPlayer from "common/YouTubeVideoPlayer";
import {
  addDateDuration,
  convertTo12HourFormat,
  dateFormat,
  dateFormatYear,
  dateFormatYearConsultation,
  getUpcomingWeekDates,
  gettingDay,
  mergeTimeDate,
  setDynamicTime12Hour,
} from "utils/dateFormat";
import UpcomingConsultation from "expertDashboard/dashboard/bookings/Consultation/section/UpcomingConsultation";
import Placeholder from "components/placeholder/Placeholder";
import { useSelector } from "react-redux";
import AuthLogin from "auth/login/Login";
import SignUp from "auth/signUp/SignUp";
import SuccessPayment from "components/SuccessfulPaymentModal/SuccessPayment";

const Consultation = () => {
  const { expertId, serviceId } = useParams();
  const { personalInfo } = useSelector((state) => state.config);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const [selectedTime, setSelectedTime] = useState(null);
  const [payoutModal, setPayoutModal] = useState(false);
  const [slots, setSlots] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [loader, onShow, onHide] = FullPageLoader();
  const [loading, setLoading] = useState(false);
  const [slotTime, setSlotTime] = useState({});
  const [bookingDate, setBookingDate] = useState("");
  const [availableWeek, setAvailableWeek] = useState([]);
  const navigate = useNavigate();

  // Expert Services Slot
  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        setExpertServicesDetail(data?.data);
        setBestTestimonials(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // Expert Service Service Slot
  const fetchingExpertServicesDetailSlot = async (sendData) => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailSlotApi(
        expertId,
        serviceId,
        {
          date: sendData?.isoDate,
          day: sendData?.WeekInNumber,
        }
      );
      if (data?.code === 200) {
        setSlots(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // BookOneToOne
  const BookOneToOneHandler = async () => {
    if (personalInfo?.token) {
      // console.log(slotTime,"SlotTime");
      // return false;
      try {
        setLoading(true);
        const { data } = await bookOneToOneApi({
          _id: serviceId,
          bookedFor: mergeTimeDate(bookingDate, slotTime?.startTime),
          amount: expertServicesDetail?.pricing?.amount,
          totalAmount: expertServicesDetail?.pricing?.totalAmount,
          transactionFeePercentage:
            expertServicesDetail?.pricing?.transactionFeePercentage,
          slot: slotTime,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "BookOneToOne");
          setPayoutModal(false);
          setPaymentStatusModal(true);
          // navigate(`/expert-details/${expertId}?serviceType=oneToOne`);
        } else {
          toastMessage(data?.message, "error", "BookOneToOne");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    } else {
      setLoginModal(true);
      setPayoutModal(false);
    }
  };

  const a = setDynamicTime12Hour(
    convertTo12HourFormat(slotTime?.startTime),
    selectedDate
  );

  // console.log(a, "Time Duration");

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);

  useEffect(() => {
    const weekAvailability = getUpcomingWeekDates().filter((data) =>
      expertServicesDetail?.consultationAvailability?.some(
        (d) => d?.day === Number(data?.WeekInNumber) && d?.status === "true"
      )
    );

    // Filter out unavailable dates
    const filteredWeekAvailability = weekAvailability.filter(
      (data) =>
        !expertServicesDetail?.consultationUnavailability.some(
          (d) => dateFormatYear(d) === data?.isoDate
        )
    );
    setAvailableWeek(filteredWeekAvailability);
  }, [expertServicesDetail]);

  return (
    <>
      <div className="services_page">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img src={expertServicesDetail?.image || service1} alt="" />
                <div className="content">
                  <h4>{expertServicesDetail?.title || "---"} </h4>
                  <p className="duration">
                    <span>Duration</span>{" "}
                    <b> {`(${expertServicesDetail?.duration} mins)`}</b>
                  </p>
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail?.pricing?.slashAmount)}
                        </del>
                        <b>₹ {Number(expertServicesDetail?.pricing?.amount)}</b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>
              <div className="banner_tag">
                <span className="name_tag">1 : 1 Consultation</span>
              </div>
            </div>
            <div className="service_content">
              <div className="content_left">
                <h5 className="_title">Overview</h5>
                <div className="content">
                  {expertServicesDetail?.description ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expertServicesDetail.description,
                      }}
                    ></p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </div>

              {availableWeek && availableWeek.length > 0 ? (
                <div className="content_right">
                  <h5 className="_title">When can we arrange to meet?</h5>
                  <div className="content">
                    <div className="date_listing">
                      <h4>Select Date</h4>
                      <ul>
                        {availableWeek &&
                          availableWeek.length > 0 &&
                          availableWeek.map((data) => (
                            <li>
                              <button
                                type="button"
                                className={
                                  selectedDate === data?.isoDate ? "active" : ""
                                }
                                onClick={() => {
                                  setBookingDate(data?.isoDate);
                                  setSelectedDate(data?.isoDate);
                                  fetchingExpertServicesDetailSlot(data);
                                }}
                              >
                                {data?.formattedDate}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="time_listing">
                      <h4>Select Time Schedule</h4>
                      <ul>
                        {selectedDate ? (
                          slots && slots.length ? (
                            slots.map((data) => (
                              <li>
                                <button
                                  type="button"
                                  className={`${
                                    selectedTime === data?.startTime ||
                                    !data?.isAvailable
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (data?.isAvailable) {
                                      setSlotTime({
                                        startTime: data?.startTime,
                                        endTime: data?.endTime,
                                        duration:
                                          expertServicesDetail?.duration,
                                      });
                                      setSelectedTime(data?.startTime);
                                    } else {
                                      toastMessage(
                                        "Slot is already booked",
                                        "error",
                                        "slotBooked"
                                      );
                                    }
                                  }}
                                >
                                  {`${convertTo12HourFormat(
                                    data?.startTime
                                  )}-${convertTo12HourFormat(data?.endTime)}`}
                                </button>
                              </li>
                            ))
                          ) : (
                            <div className="text-center">
                              No Slot Found on this date
                            </div>
                          )
                        ) : (
                          <>Select Date for booking slot</>
                        )}
                      </ul>
                    </div>

                    {/* Button Only Show TO User Only  */}

                    {(personalInfo?.currentType === 1 || !personalInfo?.token) && (
                      <div className="button_wrap">
                        <Button
                          onClick={() => {
                            !slotTime?.startTime &&
                            !slotTime?.endTime &&
                            !slotTime?.duration
                              ? toastMessage(
                                  "Please select slot",
                                  "error",
                                  "slotBooking"
                                )
                              : setPayoutModal(true);
                          }}
                        >
                          Book Now
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="content_right">
                  {" "}
                  <Placeholder className="w-100"
                    text={"This expert is unavailable for 1:1 Consultations"}
                  />
                </div>
              )}
            </div>
            {/* <VideoPlayer /> */}
            <VideoPlayer src={expertServicesDetail?.video || "---"} />

            {bestTestimonials && bestTestimonials?.length ? (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {bestTestimonials && bestTestimonials.length ? (
                      <TestimonialCard
                        bestTestimonials={bestTestimonials}
                        setBestTestimonials={setBestTestimonials}
                        display={false}
                      />
                    ) : (
                      <Placeholder text="No testimonial available for this service" />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        serviceTag={"1 : 1 Consultation"}
        serviceTittle={expertServicesDetail?.title || "---"}
        duration={`${expertServicesDetail?.duration || "---"}(mins)`}
        price={expertServicesDetail?.pricing?.totalAmount || "---"}
        date={dateFormat(bookingDate) || "---"}
        time={(slotTime && convertTo12HourFormat(slotTime?.startTime)) || "---"}
        buttonText={"Process to Pay"}
        // onClick={BookOneToOneHandler}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        authModal={authModal}
        setAuthModal={setAuthModal}
        loading={loading}
        serviceId={serviceId}
        expertId={expertId}
        expertServicesDetail={expertServicesDetail}
        token={personalInfo?.token}
        setPayoutModal={setPayoutModal}
        payoutModal={payoutModal}
        setPaymentStatusModal={setPaymentStatusModal}
        bookingEndDate={addDateDuration(
          setDynamicTime12Hour(slotTime?.startTime, bookingDate),
          expertServicesDetail?.duration
        )}
        slotTime={slotTime}
        bookingDate={bookingDate}
      />

      <CustomModal
        show={paymentStatusModal}
        handleClose={() => setPaymentStatusModal(false)}
      >
        <div className="text-center">
          <h3>Payment successfully</h3>
          <p>
            Thank you for your payment. Your booking has been successfully
            processed.
          </p>
          <div className="button_wrap button_center">
            <Button onClick={() => setPaymentStatusModal(false)}>OK</Button>
          </div>
        </div>
      </CustomModal>
      <SuccessPayment
        setPaymentStatusModal={setPaymentStatusModal}
        paymentStatusModal={paymentStatusModal}
        expertId={expertId}
        serviceType="oneToOne"
      />
    </>
  );
};

export default Consultation;
