// import FullPageLoader from "components/fullPageLoader/FullPageLoader";
// import React, { useEffect, useState } from "react";
// import { getExpertDetailBySlug } from "services/master";
// import { toastMessage } from "utils/toastMessage";

import {
  callIcon,
  genderIcon,
  locationIcon,
  mailIcon,
  userIcon2,
} from "assets/icons";
import { imagePlaceholder, profileBg } from "assets/images";
import VideoPlayer from "common/YouTubeVideoPlayer";
import { Button } from "components/form";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { useEffect, useState } from "react";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { LiaFilePdf } from "react-icons/lia";
import { RiEdit2Line } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { getExpertDetailBySlug } from "services/master";
import { yearFormat } from "utils/dateFormat";
import { handleMediaDownload } from "utils/mediaDataSender";
import { socialIcons } from "utils/staticDropdown";
import { toastMessage } from "utils/toastMessage";

const PublishExpertDetail = () => {
  const [tabButton, setTabButton] = useState("consultation");
  const [expertServiceDetail, setExpertServiceDetail] = useState([]);
  const [expertDetail, setExpertDetail] = useState({});
  const [expertSocialLinks, setExpertSocialLinks] = useState([]);
  const [loader, onShow, onHide] = FullPageLoader();

  const fetchingPublishExpertDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertDetailBySlug();
      if (data?.code === 200) {
        setExpertDetail(data?.data);
        setExpertSocialLinks(
          data?.data?.socialLinks.reduce((acc, data) => {
            if (data?.name in socialIcons) {
              acc.push({ ...data, logo: socialIcons[data.name] });
            }
            return acc;
          }, [])
        );
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingPublishExpertDetail();
  }, []);
  return (
    <>
      {loader}
      <div className="expert_profile">
        <img src={profileBg} alt="" className="profileBg" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>Profile</h3>
          </div>
          <div className="my_profile">
            <div className="profile_image">
              <div className="image_wrap">
                {expertDetail?.image ? (
                  <img src={expertDetail?.image || imagePlaceholder} alt="" />
                ) : (
                  <Skeleton />
                )}
                <div className="content">
                  <h5>{`${expertDetail?.firstName || "---"}${" "}${
                    expertDetail?.lastName || "---"
                  }`}</h5>
                  <span>{expertDetail?.expertise?.name}</span>
                </div>
              </div>
              <div className="service">
                <h4>
                  {" "}
                  {expertDetail?.expertProfessionalDetails &&
                  expertDetail?.expertProfessionalDetails?.skills.length
                    ? expertDetail?.expertProfessionalDetails?.skills.map(
                        (data) => data?.name
                      )
                    : ""}
                </h4>
                <span>
                  Experience :{" "}
                  {`${
                    expertDetail?.expertProfessionalDetails?.yoe?.years || "---"
                  }${"."}${
                    expertDetail?.expertProfessionalDetails?.yoe?.months || ""
                  }`}{" "}
                </span>
              </div>
              <div className="button_wrap">
                <Button className={"has_icon light"}>
                  <FaRegShareFromSquare /> Share Profile
                </Button>
                <Link to="/dashboard/edit-profile" className="has_icon button">
                  <RiEdit2Line /> Edit Profile
                </Link>
              </div>
            </div>
            <div className="profile_details">
              <div className="content_left table_radius">
                <div className="_sub_title">
                  <h4>Personal Information</h4>
                </div>
                <ul>
                  <li>
                    <img src={userIcon2} alt="" />
                    <p>
                      <span>Name</span>
                      <strong>{`${expertDetail?.firstName || "---"}${" "}${
                        expertDetail?.lastName || "---"
                      }`}</strong>
                    </p>
                  </li>
                  <li>
                    <img src={genderIcon} alt="" />
                    <p>
                      <span>Gender</span>
                      <strong>{expertDetail?.gender || "---"}</strong>
                    </p>
                  </li>
                  <li>
                    <img src={mailIcon} alt="" />
                    <p>
                      <span>Email ID</span>
                      <strong>{expertDetail?.email || "---"}</strong>
                    </p>
                  </li>
                  <li>
                    <img src={callIcon} alt="" />
                    <p>
                      <span>Phone Number</span>
                      <strong>{`${expertDetail?.countryCode || "---"}${" "}${
                        expertDetail?.phone || "---"
                      }`}</strong>
                    </p>
                  </li>
                  <li>
                    <img src={locationIcon} alt="" />
                    <p>
                      <span>Address</span>
                      <strong>{expertDetail?.address || "---"}</strong>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="content_right table_radius">
                <div className="_sub_title">
                  <h4>Professional Details</h4>
                </div>
                <h5>Education</h5>
                {expertDetail?.expertProfessionalDetails &&
                  expertDetail?.expertProfessionalDetails?.education &&
                  expertDetail?.expertProfessionalDetails?.education.length &&
                  expertDetail?.expertProfessionalDetails?.education.map(
                    (data) => (
                      <ul className="education">
                        <li>
                          <small>Education</small>{" "}
                          <b>{data?.education || "---"}</b>
                        </li>
                        <li>
                          <small>Institute</small>{" "}
                          <b>{data?.institute || "---"}</b>
                        </li>
                        <li>
                          <small>Address</small>{" "}
                          <b>{expertDetail?.address || "---"}</b>
                        </li>
                        <li>
                          <small>Country</small>{" "}
                          <b>{data?.country?.name || "---"}</b>
                        </li>
                        <li>
                          <small>Year of Passing</small>{" "}
                          <b>{yearFormat(data?.yearOfPassing) || "---"}</b>
                        </li>
                        <li>
                          <small>Certificate</small>{" "}
                          <b>
                            <LiaFilePdf
                              className="pdfIcon"
                              onClick={() =>
                                handleMediaDownload(data?.certificate)
                              }
                            />
                          </b>
                        </li>
                      </ul>
                    )
                  )}

                <h5>Certifications</h5>
                {expertDetail?.expertProfessionalDetails &&
                  expertDetail?.expertProfessionalDetails?.certifications &&
                  expertDetail?.expertProfessionalDetails?.certifications
                    .length &&
                  expertDetail?.expertProfessionalDetails?.certifications.map(
                    (data) => (
                      <ul className="education">
                        <li>
                          <small>Education</small>{" "}
                          <b>{data?.certification || "---"}</b>
                        </li>
                        <li>
                          <small>Institute</small>{" "}
                          <b>{data?.institute || "---"}</b>
                        </li>
                        <li>
                          <small>Address</small>{" "}
                          <b>{expertDetail?.address || "---"}</b>
                        </li>
                        <li>
                          <small>Country</small>{" "}
                          <b>{data?.country?.name || "---"}</b>
                        </li>
                        <li>
                          <small>Year of Passing</small>{" "}
                          <b>{yearFormat(data?.yearOfPassing) || "---"}</b>
                        </li>
                        <li>
                          <small>Certificate</small>{" "}
                          <b>
                            <LiaFilePdf
                              className="pdfIcon"
                              onClick={() =>
                                handleMediaDownload(data?.certificate)
                              }
                            />
                          </b>
                        </li>
                      </ul>
                    )
                  )}
              </div>
            </div>

            <div className="bankAccount table_radius">
              <div className="_sub_title">
                <h4>Bank Account Details</h4>
              </div>
              <ul>
                <li>
                  <small>Bank Name</small>{" "}
                  <b>{expertDetail?.bank?.bankName || "---"}</b>
                </li>
                <li>
                  <small>Account Holder Name</small>{" "}
                  <b>{expertDetail?.bank?.accountHolderName || "---"}</b>
                </li>
                <li>
                  <small>Account Number</small>{" "}
                  <b>{expertDetail?.bank?.accountNumber || "---"}</b>
                </li>
                <li>
                  <small>IFSC Code</small>{" "}
                  <b>{expertDetail?.bank?.ifsc || "---"}</b>
                </li>
                <li>
                  <small>Branch Address</small>{" "}
                  <b>{expertDetail?.bank?.address || "---"}</b>
                </li>
              </ul>
            </div>

            <div className="additional_details table_radius">
              <div className="_sub_title">
                <h4>Additional Details</h4>
                <div className="socialIcons">
                  {/* <ul>
                    <li>
                      <Link to="" target="_blank">
                        <FaTelegramPlane />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <FaXTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <FaYoutube />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="" target="_blank">
                        <FaLinkedinIn />
                      </Link>
                    </li>
                  </ul> */}
                  <ul>
                    {expertSocialLinks && expertSocialLinks.length ? (
                      expertSocialLinks.map((data, index) => (
                        <li key={index}>
                          <Link to={data?.url}>{data?.logo}</Link>
                        </li>
                      ))
                    ) : (
                      <Skeleton
                        circle={true}
                        height={30}
                        width={30}
                        count={6}
                      />
                    )}
                  </ul>
                </div>
              </div>

              <p className="aboutExpert">{expertDetail?.bio || "---"}</p>
              <p className="video_url">
                <span>Introduction Video Link</span>
                <VideoPlayer
                  src={expertDetail?.introductionVideoLink || "---"}
                />
              </p>
            </div>

            <div className="Testimonials table_radius">
              <div className="_sub_title">
                <h4>Testimonials</h4>
                <ul>
                  <li
                    className={tabButton === "consultation" ? "active" : ""}
                    onClick={() => setTabButton("consultation")}
                  >
                    1 : 1 Consultation
                  </li>
                  <li
                    className={tabButton === "Webinars" ? "active" : ""}
                    onClick={() => setTabButton("Webinars")}
                  >
                    Webinars
                  </li>
                  <li
                    className={tabButton === "Subscription" ? "active" : ""}
                    onClick={() => setTabButton("Subscription")}
                  >
                    Subscription
                  </li>
                </ul>
              </div>

              <div className="tab_content">
                {tabButton === "consultation" && (
                  <>
                    <div className="item">
                      <div className="tab_card">
                        <div className="_title">
                          <h5>
                            Rahul Kumar <small>20 May 2024</small>
                          </h5>
                          <p>
                            Pulled Hamstring causes, Treatment and recovery{" "}
                          </p>
                        </div>
                        <p>
                          Dr vivek sharma has done his MDS in prosthodontics and
                          crown and bridge and has a vast experience of working
                          in government and private dental setups. He is member
                          of indian dental association, indian prosthodontc
                          society and academy of.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="tab_card">
                        <div className="_title">
                          <h5>
                            Rahul Kumar <small>20 May 2024</small>
                          </h5>
                          <p>
                            Pulled Hamstring causes, Treatment and recovery{" "}
                          </p>
                        </div>
                        <p>
                          Dr vivek sharma has done his MDS in prosthodontics and
                          crown and bridge and has a vast experience of working
                          in government and private dental setups. He is member
                          of indian dental association, indian prosthodontc
                          society and academy of.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="tab_card">
                        <div className="_title">
                          <h5>
                            Rahul Kumar <small>20 May 2024</small>
                          </h5>
                          <p>
                            Pulled Hamstring causes, Treatment and recovery{" "}
                          </p>
                        </div>
                        <p>
                          Dr vivek sharma has done his MDS in prosthodontics and
                          crown and bridge and has a vast experience of working
                          in government and private dental setups. He is member
                          of indian dental association, indian prosthodontc
                          society and academy of.
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {tabButton === "Webinars" && (
                  <>
                    <div className="item">
                      <div className="tab_card">
                        <div className="_title">
                          <h5>
                            Rahul Kumar <small>20 May 2024</small>
                          </h5>
                          <p>
                            Pulled Hamstring causes, Treatment and recovery{" "}
                          </p>
                        </div>
                        <p>
                          Dr vivek sharma has done his MDS in prosthodontics and
                          crown and bridge and has a vast experience of working
                          in government and private dental setups. He is member
                          of indian dental association, indian prosthodontc
                          society and academy of.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="tab_card">
                        <div className="_title">
                          <h5>
                            Rahul Kumar <small>20 May 2024</small>
                          </h5>
                          <p>
                            Pulled Hamstring causes, Treatment and recovery{" "}
                          </p>
                        </div>
                        <p>
                          Dr vivek sharma has done his MDS in prosthodontics and
                          crown and bridge and has a vast experience of working
                          in government and private dental setups. He is member
                          of indian dental association, indian prosthodontc
                          society and academy of.
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="tab_card">
                        <div className="_title">
                          <h5>
                            Rahul Kumar <small>20 May 2024</small>
                          </h5>
                          <p>
                            Pulled Hamstring causes, Treatment and recovery{" "}
                          </p>
                        </div>
                        <p>
                          Dr vivek sharma has done his MDS in prosthodontics and
                          crown and bridge and has a vast experience of working
                          in government and private dental setups. He is member
                          of indian dental association, indian prosthodontc
                          society and academy of.
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {tabButton === "Subscription" && (
                  <div className="tab_card"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishExpertDetail;
