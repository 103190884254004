import React from "react";
import "./Testimonial.scss";
import { FaTimes } from "react-icons/fa";
import { dateFormat } from "utils/dateFormat";

const TestimonialCard = ({
  bestTestimonials,
  setBestTestimonials,
  ...rest
}) => {

  const deleteTestimonialsHandler = (testimonialId) => {
    const tempTestimonial = [...bestTestimonials];
    setBestTestimonials(
      tempTestimonial.filter((data) => data?._id !== testimonialId)
    );
  };

  return (
    <>
      {bestTestimonials && bestTestimonials?.length ?
        bestTestimonials.map((data) => (
          <div className="inner_testimonial_card">
            <div className="item">
              <div className="head">
                {rest?.display && (
                  <span className="delete_button">
                    <FaTimes
                      onClick={() => deleteTestimonialsHandler(data?._id)}
                    />
                  </span>
                )}

                <h5>{`${data?.user?.firstName || "---"}${" "}${
                  data?.user?.lastName || "---"
                }`}</h5>
                <p>{dateFormat(data?.createdAt) || "---"}</p>
              </div>
              <p>{data?.message || "---"}</p>
            </div>
          </div>
        )):"No Testimonials Available"}
    </>
  );
};

export default TestimonialCard;
