import React, { useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
import { testimonial } from "staticData";
import CustomModal from "components/modals/CustomModal";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "components/cards/servicesCard/ServicesCard.scss";
import { RiEdit2Line } from "react-icons/ri";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMdAdd } from "react-icons/io";
const EditDigitalContentsDetails = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [active, setActive] = useState("yes");
  const [modal, setModal] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(file);
    e.target.value = null;
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };

  return (
    <>
      <div className="edit_digital_content">
        <div className="dashboard_title">
          <h3>Edit Digital Contents</h3>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input label={"Title"} placeholder={"Enter Title"} />
                </div>
                <div className="form_field">
                  <TextArea
                    label={"Description"}
                    placeholder={"Enter description"}
                  />
                </div>
                <div className="form_field">
                  <Input
                    label={"Overview Video Link"}
                    placeholder={"Enter url"}
                  />
                </div>
              </div>
              <div className="content_right">
                <div className="form_field">
                  <Select label={"Duration (mins)"}>
                    <option>15 mins</option>
                    <option>15 mins</option>
                  </Select>
                </div>

                <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="fileUpload">
                    <input
                      type="file"
                      id="fileUpload"
                      onChange={handleFileChange}
                    />
                    {imagePreview ? (
                      <>
                        <img src={URL.createObjectURL(imagePreview)} alt="" />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="fileUpload">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </form>
            <div className="pricing">
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input type={"number"} placeholder="Enter Amount" />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="Yes"
                      onClick={() => setActive("yes")}
                      className={`radio_button input ${
                        active === "yes" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlash"
                        id="Yes"
                        value="yes"
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      htmlFor="No"
                      onClick={() => setActive("no")}
                      className={`radio_button input ${
                        active === "no" ? "active" : ""
                      }`}
                    >
                      <input type="radio" name="showSlash" id="No" value="no" />
                      <span>No</span>
                    </label>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input type={"number"} placeholder="Enter Amount" />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input type={"number"} placeholder="Enter Amount" />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                    <del>
                      <BsCurrencyRupee /> 600
                    </del>
                    <span>
                      <BsCurrencyRupee /> 500
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="add_chapters spacing">
              <h5>Add Chapters</h5>
              <div className="button_wrap button_right">
                <Button className={"light dashboard_button"}>
                  <IoMdAdd /> Add Topic
                </Button>
              </div>
              <div className="form_field">
                <Input
                  type="text"
                  label="Chapters Name"
                  placeholder="Content management"
                />
              </div>
              <div className="form_field_wrap">
                <div className="form_field">
                  <Input
                    type="text"
                    label="Topic Name"
                    placeholder="Design Review"
                  />
                </div>
                <div className="form_field">
                  <Select label="Upload Option">
                    <option>Video</option>
                  </Select>
                </div>
              </div>

              <div className="form_field_wrap">
                <div className="form_field">
                  <Input
                    type="url"
                    label="Video Link"
                    placeholder="Enter url"
                  />
                </div>
                <div className="form_field">
                  <div className="button_wrap button_right">
                    <Button className={"light dashboard_button red"}>
                      Delete Topic
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="chapter_list spacing">
              <h5>Chapters List</h5>
              <ul>
                <li>
                  <p>
                    <b>Chapter 1 : Content management</b>
                    <span>Total Topic : 2</span>
                  </p>
                  <div className="action">
                    <button type="button">
                      <RiEdit2Line />
                    </button>
                    <button type="button">
                      <LiaTimesSolid />
                    </button>
                  </div>
                </li>
                <li>
                  <p>
                    <b>Chapter 1 : Content management</b>
                    <span>Total Topic : 2</span>
                  </p>
                  <div className="action">
                    <button type="button">
                      <RiEdit2Line />
                    </button>
                    <button type="button">
                      <LiaTimesSolid />
                    </button>
                  </div>
                </li>
                <li>
                  <p>
                    <b>Chapter 1 : Content management</b>
                    <span>Total Topic : 2</span>
                  </p>
                  <div className="action">
                    <button type="button">
                      <RiEdit2Line />
                    </button>
                    <button type="button">
                      <LiaTimesSolid />
                    </button>
                  </div>
                </li>
              </ul>
            </div>

            <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial &&
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          key={item.id}
                          title={item.title}
                          date={item.date}
                          description={item.description}
                          deleteButton={true}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="button_wrap button_right">
              <LoadingButton>Save</LoadingButton>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        className={"xl select_testimonials"}
        show={modal}
        handleClose={() => setModal(false)}
      >
        <h3>Display Testimonials for this service</h3>
        <p>
          Select the best testimonials that you want to display for this service
        </p>

        <div className="card_wrap">
          {testimonial &&
            testimonial.map((item) => {
              return (
                <>
                  <div className="inner_testimonial_card" key={item.id}>
                    <label className="item" htmlFor={item.id}>
                      <input type="checkbox" className="check" id={item.id} />
                      <div className="head">
                        <h5>{item.title}</h5>
                        <p>{item.date}</p>
                      </div>
                      <p>{item.description}</p>
                    </label>
                  </div>
                </>
              );
            })}
        </div>
        <div className="button_wrap button_right mt-3">
          <LoadingButton>Select Testimonials</LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default EditDigitalContentsDetails;
