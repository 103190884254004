import { profileImg } from "assets/images";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { HiOutlineBell } from "react-icons/hi2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { switchAccountApi } from "services/auth";
import { toastMessage } from "utils/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setPersonalInfo, setSteps } from "store/slices/configSlice";
import { FaUser, FaUserCog } from "react-icons/fa";
import { MdDashboardCustomize } from "react-icons/md";
import { profilePlaceholder } from "assets/icons";
import { authRouteHandler } from "utils/common";
import {
  getNotificationApi,
  markAllAsReadApi,
  markAsReadApi,
  newNotificationAlert,
} from "services/notification";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { dateFormatWithTime } from "utils/dateFormat";
import Placeholder from "components/placeholder/Placeholder";

const ExpertAvatar = () => {
  const { personalInfo } = useSelector((state) => state.config);
  const location = useLocation();
  const fitzureInfo = JSON.parse(localStorage.getItem("fitzure_info"));
  const [loader, onShow, onHide] = FullPageLoader();
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const switchAccountHandler = async () => {
    try {
      const { data } = await switchAccountApi({ currentType: 1 });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "switchAccount");
        const currentTypeData = { ...personalInfo, currentType: 1 };
        dispatch(setPersonalInfo({ personalInfo: currentTypeData }));
        dispatch(
          setConfig({
            userType: data?.data?.accountType,
          })
        );
        dispatch(
          setSteps({
            steps: fitzureInfo.moduleSteps,
          })
        );
        localStorage.setItem("fitzure_info", JSON.stringify(currentTypeData));
        // navigate({
        //   pathname: "/navigation",
        //   search: `?${new URLSearchParams({
        //     currentType: "1",
        //   }).toString()}`,
        // });
        authRouteHandler({
          currentType: currentTypeData?.currentType,
          navigate,
          personalInfo: currentTypeData,
        });
        // navigate("/");
      } else {
        toastMessage(data?.message, "error", "switchAccount");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Get Notification Handler
  const getNotificationHandler = async () => {
    try {
      onShow();
      const { data } = await getNotificationApi();
      if (data?.code === 200) {
        setNotificationList(data?.data);
      } else {
        toastMessage(data?.message, "error", "notificationList");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Mark All As Read Handler
  const getMarkAsAllReadHandler = async () => {
    try {
      setLoading(true);
      const { data } = await markAllAsReadApi();
      if (data?.code === 200) {
        getNotificationHandler();
        newNotificationAlertHandler();

      } else {
        toastMessage(data?.message, "error", "getMarkAsAllReadHandler");
        getNotificationHandler();
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Mark All As Read Handler
  const markAsReadHandler = async (e,_id) => {
    try {
      const { data } = await markAsReadApi({ _id: _id });
      if (data?.code === 200) {
        setNotificationList((prev) =>
          prev.map((data) => {
            if (data?._id === _id) {
              return {
                ...data,
                isRead: !data?.isRead,
              };
            } else {
              return data;
            }
          })
        );
        setNotificationNumber((prev) => prev - 1);
      } else {
        toastMessage(data?.message, "error", "markAsReadHandler");
        getNotificationHandler();
      }
    } catch (e) {
      console.log(e.message);
    } finally {
    }
  };

  // New Notification Alert
  const newNotificationAlertHandler = async () => {
    try {
      const { data } = await newNotificationAlert();
      if (data?.code === 200) {
        setNotificationNumber(data?.data);
        getNotificationHandler();
      } else {
        toastMessage(data?.message, "error", "newNotificationAlertHandler");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getNotificationHandler();
    newNotificationAlertHandler();
  }, [location]);

  return (
    <>
      <div className="avatar_content expertAvatar">
        <div className="notification_dropdown">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <HiOutlineBell className="icon" />
              {notificationNumber>0 ? <span className="blinker"></span>:""}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <h5>
                Notifications
                <button onClick={getMarkAsAllReadHandler}>
                  {notificationList &&
                    notificationList.length > 0 &&
                    notificationList.filter((data) => !data?.isRead).length >
                      0 &&
                    "Mark all as read"}
                </button>
              </h5>
              <ul>
                {notificationList && notificationList.length > 0 ? (
                  notificationList.map((data) => (
                    <li
                      key={data?._id}
                      className={`${data?.isRead ? "" : "read"}`}
                    >
                      <b>{data?.title || "---"}</b>
                      <p>{data?.message || "----"}</p>
                      <span className="time">
                        {dateFormatWithTime(data?.createdAt)}
                      </span>
                      {data?.isRead ? (
                        ""
                      ) : (
                        <span
                          className="time"
                          onClick={(e) => {
                            markAsReadHandler(e, data?._id);
                          }}
                        >
                          Mark As Read
                        </span>
                      )}
                    </li>
                  ))
                ) : (
                  <Placeholder text={"No Notification Available"} />
                )}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="profile_dropdown">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <img src={fitzureInfo?.image || profilePlaceholder} alt="name" />{" "}
              <span>{`${fitzureInfo?.firstName} ${fitzureInfo?.lastName} `}</span>{" "}
              <IoIosArrowDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={"span"}>
                <Link to="/dashboard/profile">
                  <FaUserCog />
                  View Profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <Link to="/dashboard/home">
                  <MdDashboardCustomize />
                  Dashboard
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <Link to="#" onClick={switchAccountHandler}>
                  <FaUser />
                  Switch to User
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default ExpertAvatar;
