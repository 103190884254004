import CommonPagination from "common/CommonPagination";
import { LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import React from "react";
import { LuIndianRupee } from "react-icons/lu";

const GetServices = ({
  packageServicesList,
  setSelectServiceModal,
  selectServiceModal,
  setPackageServicesList,
  selectedServices,
  setSelectedServices,
  total,
  activePage,
  handlePageChange,
}) => {
  const serviceData = [
    {
      name: "1 : 1 Consultation",
      value: "oneToOne",
    },
    {
      name: "Webinar",
      value: "webinar",
    },
    {
      name: "Subscription",
      value: "subscription",
    },
    {
      name: "Priority DM",
      value: "prioritydm",
    },
    {
      name: "Digital",
      value: "digital",
    },
  ];

  const selectedServicesHandler = (data) => {
    const objectIndex = selectedServices.findIndex(
      (services) => services?._id === data?._id
    );
    const tempServices = [...selectedServices];

    if (objectIndex !== -1) {
      tempServices.splice(objectIndex, 1);
    } else {
      tempServices.push(data);
    }

    setSelectedServices(tempServices);
  };

  // console.log(selectedServices, "SelectedServices");
  // console.log(packageServicesList, "PackageServiceList");

  return (
    <CustomModal
      className={"xl select_services"}
      show={selectServiceModal}
      handleClose={() => setSelectServiceModal(false)}
    >
      <h3>Display services for this service</h3>
      <p>Select the best services that you want to display for this service</p>

      <div className="card_wrap">
        {packageServicesList && packageServicesList.length > 0 ? (
          packageServicesList.map((item) => {
            return (
              <>
                <div className="inner_services_card service" key={item.id}>
                  <input
                    type="checkbox"
                    className="check"
                    checked={selectedServices.find(
                      (test) => test?._id === item._id
                    )}
                    onChange={() => selectedServicesHandler(item)}
                    id={item._id}
                  />
                  <label className="item" htmlFor={item._id}>
                    <h5>{item.title}</h5>
                    <p className="price">
                      {item?.pricing?.showSlashedAmount && (
                        <del>
                          <LuIndianRupee /> {item?.pricing?.slashAmount}
                        </del>
                      )}

                      <span>
                        <LuIndianRupee />
                        {
                        // item?.pricing?.showSlashedAmount
                        //   ? Number(
                        //       Number(item?.pricing?.amount) -
                        //         Number(item?.pricing?.slashAmount)
                        //     )
                        //   :
                           Number(item?.pricing?.amount)}
                      </span>
                    </p>
                    <div className="bottom_text">
                      <span>
                        {serviceData.find(
                          (data) => data?.value === item?.serviceType
                        )?.name || "---"}
                      </span>
                    </div>
                  </label>
                </div>
              </>
            );
          })
        ) : (
          <Placeholder text={"No services found to select"} />
        )}
      </div>

      <CommonPagination
        handlePageChange={handlePageChange}
        activePage={activePage}
        total={total}
      />

      <div className="button_wrap button_right mt-3">
        <LoadingButton onClick={() => setSelectServiceModal(false)}>
          Select
        </LoadingButton>
      </div>
    </CustomModal>
  );
};

export default GetServices;
