import React from "react";
import "./Placeholder.scss";

const Placeholder = ({ image, text, className }) => {
  return (
    <>
      <div className={`_placeholder ${className}`}>
        <div className="content">
          <img src={image} alt="" />
          <h5>{text}</h5>
        </div>
      </div>
    </>
  );
};

export default Placeholder;
