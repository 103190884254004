import React, { useEffect, useState } from "react";
import "./HomePage.scss";
import { IoMdStar } from "react-icons/io";
import demoImage from "../../assets/images/expert-3.png";

import {
  bannerBg,
  bannerReviewImg,
  expert1Img,
  expertPlaceholderImg,
  expertiseImg,
  personalADImg,
  webinarImg,
} from "assets/images";
import { Button, LoadingButton, RatingStar } from "components/form";
import {
  chatIcon,
  classRoomIcon,
  contentScreenIcon,
  crownIcon,
  on2onIcon,
} from "assets/icons";
import Marquee from "react-fast-marquee";
import { Accordion } from "react-bootstrap";
import { experts_card, faqList } from "staticData";
import ExpertCard from "components/expertsCard";
import { Link, useSearchParams } from "react-router-dom";
import { expertListApi, expertiseListApi, getFaqList, getTestimonialsApi } from "services/master";
import { toastMessage } from "utils/toastMessage";
import Placeholder from "components/placeholder/Placeholder";

const HomePage = () => {
  const [expertList, setExpertList] = useState([]);
  const [faqList,setFaqList]=useState([]);
  const [testimonialsList,setTestimonialsList]=useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  let expertised = searchParam.get("expertise") || "";

  const [expertise, setExpertise] = useState([]);

  const fetchExpertListing = async () => {
    const reqData = {};
    reqData.pageSize = 10;
    reqData.page = 0;
    reqData.search = "";
    if (expertised) {
      reqData.expertise = expertised;
    }

    try {
      const { data } = await expertListApi(reqData);
      if (data?.code === 200) {
        setExpertList(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "expertList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchExpertiseListing = async () => {
    try {
      const { data } = await expertiseListApi();
      if (data?.code === 200) {
        setExpertise(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "expertList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };


  const fetchFaqList = async () => {
    try {
      const sendData = {};
      sendData.pageSize = 5;
      const { data } = await getFaqList(sendData);
      if (data?.code === 200) {
        setFaqList(data?.data || []);
        // setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "faqList");
        setFaqList([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      // onHide();
    }
  };

  const fetchTestimonialsList = async () => {
    try {
      const sendData = {};
      sendData.pageSize = 5;
      const { data } = await getTestimonialsApi(sendData);
      if (data?.code === 200) {
        setTestimonialsList(data?.data || []);
        // setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "setTestimonialsList");
        setTestimonialsList([]);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      // onHide();
    }
  };

  useEffect(() => {
    fetchExpertListing();
    // fetchExpertiseListing();
  }, [searchParam]);

  useEffect(() => {
    fetchExpertiseListing();
    fetchFaqList();
    fetchTestimonialsList();
  }, []);

  const expertiseHandler = (name) => {
    if (!name) {
      searchParam.delete("expertise");
    } else {
      searchParam.set("expertise", name);
    }
    setSearchParam(searchParam);
  };

// console.log(expertList,"Expert List");

  return (
    <>
      <div className="home_page">
        <div className="home_banner">
          <div className="container">
            <img src={bannerBg} alt="" className="bgImage" />
            <div className="content">
              <span className="tag">
                Find your <b>fitness</b> companion
              </span>
              <h1>Empower Your Fitness Journey: Achieve, Transform, Thrive</h1>
              <p>
                Lorem Ipsum is simply dummy text of the <br />
                printing and typesetting industry. Lorem
              </p>
              <div className="reviewImg">
                <img src={bannerReviewImg} alt="customer review" />
              </div>
            </div>
          </div>
        </div>

        {/* Top Experts */}

        <section className="top_experts spacing">
          <div className="container">
            <div className="page_title">
              <span>Explore Expertise</span>
              <h3>For people making Impact</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
              </p>
            </div>

            <div className="servicesList">
              <ul>
                <li
                  onClick={() => expertiseHandler("")}
                  className={`${
                    !expertised || expertised == "" ? "active" : ""
                  }`}
                >
                  All Experts
                </li>
                {expertise &&
                  expertise.length > 0 &&
                  expertise.map((data) => {
                    return (
                      <li
                        className={`${data?._id == expertised ? "active" : ""}`}
                        onClick={() => expertiseHandler(data?._id)}
                      >
                        {data?.name}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="experts_card_wrap">
              {expertList && expertList.length > 0 ? (
                expertList.map((item) => {
                  return (
                    <ExpertCard
                      key={item?.slug}
                      profileImage={item.image || demoImage}
                      expertName={item.firstName}
                      serviceName={item.expertise[0]}
                      rating={Number(item?.rating?.avg) || 0}
                      users={item?.rating?.total || 0}
                      // url={`/expert-details/${item?.slug}`}
                      url={`/${item?.slug}`}

                    />
                  );
                })
              ) : (
                <Placeholder
                  image={expertPlaceholderImg}
                  text={"No Expert Available in this category"}
                />
              )}

              {expertList.length > 0 && (
                <div className="button_wrap button_center">
                  <Link to="/explore-experts" className="button">
                    View All Experts
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="expert_webinars spacing">
          <div className="container">
            <div className="content">
              <div className="page_title">
                <span className="_tag">Advance Features</span>
                <h3>Master Your Skills with Expert Webinars</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </p>
                <ul>
                  <li>Lorem Ipsum is simply dummy text of </li>
                  <li>the printing and typesetting industry. </li>
                  <li>Lorem Ipsum has been industry's</li>
                </ul>
              </div>
            </div>
            <div className="image_wrap">
              <img src={webinarImg} alt="" />
            </div>
          </div>
        </section>

        <section className="expert_webinars odd spacing">
          <div className="container">
            <div className="content">
              <div className="page_title">
                <span className="_tag">Advance Features</span>
                <h3>Get a personal advice on 1-to-1 video call with Sarah</h3>
                <p>
                  Tone your body and improve your health without starving
                  yourself or cutting out carbs Lorem Ipsum is simply dummy text
                  of the printing and the printing.
                </p>
                <ul>
                  <li>Perfect for begineers </li>
                  <li>Great for adults </li>
                  <li>For both men and women</li>
                </ul>
              </div>
            </div>
            <div className="image_wrap">
              <img src={personalADImg} alt="" />
            </div>
          </div>
        </section>

        <section className="share_expertise spacing">
          <div className="container">
            <div className="image_section">
              <div className="image_wrap">
                <img src={expertiseImg} alt="Expertise" />
              </div>
              <div className="content">
                <h4>Join Us: Share Expertise, Amplify Impact!</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </p>
              </div>
            </div>

            <div className="content_section">
              <div className="page_title">
                <span className="_tag">Benefits</span>
                <h3>Join Us: Share Expertise, Amplify</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has.1
                </p>
              </div>

              <div className="expertise_card">
                <img src={on2onIcon} alt="one to one consultation" />
                <h6>Provide personalized 1 : 1 consultation</h6>
                <p>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Suspendisse varius enim in eros
                  elementum tristique.
                </p>
              </div>
              <div className="expertise_card">
                <img src={classRoomIcon} alt="Master classes" />
                <h6>Host Seminars and Masterclasses</h6>
                <p>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Suspendisse varius enim in eros
                  elementum tristique.
                </p>
              </div>
              <div className="expertise_card">
                <img src={contentScreenIcon} alt="Digital Content" />
                <h6>Market Digital Content</h6>
                <p>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Suspendisse varius enim in eros
                  elementum tristique.
                </p>
              </div>
              <div className="expertise_card">
                <img src={chatIcon} alt="DMS" />
                <h6>Manage Priority DM</h6>
                <p>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Suspendisse varius enim in eros
                  elementum tristique.
                </p>
              </div>
              <div className="expertise_card">
                <img src={crownIcon} alt="DMS" />
                <h6>Offer Packages/Subscriptions</h6>
                <p>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Suspendisse varius enim in eros
                  elementum tristique.
                </p>
                <LoadingButton>Sign up as Expert</LoadingButton>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ */}
        <section className="faq_section spacing">
          <div className="container">
            <div className="page_title">
              <span className="_tag">FAQ’s</span>
              <h3>People often ask: Frequently Asked Questions</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
              </p>
            </div>
            <div className="faq">
              <Accordion defaultActiveKey="0">
                {faqList && faqList.length>0 && 
                  faqList.map((item) => {
                    return (
                      <Accordion.Item eventKey={item._id}>
                        <Accordion.Header>{item?.question || "---"}</Accordion.Header>
                        <Accordion.Body>{item?.answer || "---"}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </section>
        {/* Clients Feedback */}
        <div className="clients_feedback spacing">
          <div className="container">
            <div className="page_title text-center">
              <span className="_tag">Testimonials for users</span>
              <h3>Join over 10,000 happy clients</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
              </p>
            </div>
          </div>

          <div className="feedback_slider">
            <ul>
              <Marquee gradient={true}>
                {testimonialsList && testimonialsList.length>0 && testimonialsList.map((data)=> <li>
                  <div className="item">
                    <div className="star">
                      {/* <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar /> */}
                       {Array.from({ length: data?.rating }, (v, i) => (
          <IoMdStar key={i} className={i < data?.rating ? "filled" : "empty"} />
        ))}
                    </div>
                    <p>
                    {data?.message || "---"}
                    </p>
                    <b>{`${data && data?.name}`}</b>
                  </div>
                </li>)}

              </Marquee>
            </ul>

            {/* <ul>
              <Marquee gradient={true} direction="right">
                <li>
                  <div className="item">
                    <div className="star">
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type.
                    </p>
                    <b>Dr. Vivek Sharma</b>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="star">
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type.
                    </p>
                    <b>Dr. Vivek Sharma</b>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="star">
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type.
                    </p>
                    <b>Dr. Vivek Sharma</b>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="star">
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                      <IoMdStar />
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type.
                    </p>
                    <b>Dr. Vivek Sharma</b>
                  </div>
                </li>
              </Marquee>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
