import { CommonSelect, Input, LoadingButton, Select } from "components/form";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import React, { useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  accountDetailApi,
  accountUpdateApi,
  personDetailApi,
} from "services/details";
import { cityListApi, countryListApi, stateListApi } from "services/master";
import { setStepsInfo } from "store/loginInfoSlice";
import { setSteps } from "store/slices/configSlice";
import { proceedNextStep } from "utils/common";
import commonMessages from "utils/commonMessages";
import { allowNumber, regex, replaceLabelWithName } from "utils/formValidator";
import createMediaData from "utils/mediaDataSender";
import { toastMessage } from "utils/toastMessage";

const AccountDetails = ({ signUpForm = true }) => {
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [accountDetail, setAccountDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const { email, ifscRegex, accountNumberRegex, name } = regex;
  const {
    VALID_EMAIL,
    VALID_NAME,
    // VALID_ACCOUNT_NUMBER,
    VALID_IFSC,
  } = commonMessages;

  // console.log('selectedCity', selectedCity)

  //   Country List
  const fetchCountryList = async () => {
    try {
      const res = await countryListApi();
      if (res?.data?.code === 200) {
        setCountryList(
          res.data.data.map((country) => ({
            label: country.name,
            value: country.id,
          })) || []
        );
      } else {
        toastMessage(res.data.message, "error", "countryList");
        setCountryList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // State List
  const fetchStateList = async () => {
    try {
      if (selectedCountry.value) {
        const res = await stateListApi(selectedCountry.value);

        if (res?.data?.code === 200) {
          // setStateList(res?.data?.data || []);
          setStateList(
            res?.data?.data.map((state) => ({
              label: state.name,
              value: state.id,
            })) || []
          );
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch state list",
            "error",
            "stateList"
          );
          setStateList([]);
        }
      } else {
        setStateList([]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // City List
  const fetchCityList = async () => {
    try {
      if (selectedCountry.value && selectedState.value) {
        const res = await cityListApi(selectedState.value);
        if (res?.data?.code === 200) {
          setCityList(
            res.data.data.map((city) => ({
              label: city.name,
              value: city.id,
            })) || []
          );
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch city list",
            "error",
            "cityList"
          );
          setCityList([]);
        }
      } else {
        setCityList([]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Fetching Account Detail
  const fetchingAccountDetail = async (screen = 2) => {
    try {
      onShow();
      const response = await personDetailApi(screen);
      if (response && response?.data?.code === 200) {
        // toastMessage(response?.data?.message, "success", "personDetail");
        setAccountDetail({
          ...response?.data?.data?.bank,
          confirmAccountNumber: response?.data?.data?.bank?.accountNumber,
        });
        if (response?.data?.data?.bank?.city?.id) {
          setSelectedCity({
            label: response?.data?.data?.bank?.city?.name,
            value: response?.data?.data?.bank?.city?.id,
          });
        }
        if (response?.data?.data?.bank?.country?.id) {
          setSelectedCountry({
            label: response?.data?.data?.bank?.country?.name,
            value: response?.data?.data?.bank?.country?.id,
          });
        }
        if (response?.data?.data?.bank?.state?.id) {
          setSelectedState({
            label: response?.data?.data?.bank?.state?.name,
            value: response?.data?.data?.bank?.state?.id,
          });
        }
      } else {
        toastMessage(response?.data?.message, "error", "personDetail");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Account Detail Handler
  const accountDetailHandler = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      setAccountDetail({
        ...accountDetail,
        [name]: { id: value, name: name },
      });
    }
    if (name === "state") {
      setAccountDetail({
        ...accountDetail,
        [name]: { id: value, name: name },
      });
    }
    if (name === "pincode") {
      setAccountDetail({ ...accountDetail, [name]: allowNumber(value) });
    } else {
      setAccountDetail({ ...accountDetail, [name]: value });
    }
  };

  // Input field validation
  const validation = () => {
    let status = true;
    const errors = {};
    if (!accountDetail?.bankName) {
      errors["bankName"] = "Bank name is mandatory";
      status = false;
    }
    if (!accountDetail?.accountHolderName) {
      errors["accountHolderName"] = "Holder name is mandatory";
      status = false;
    } else if (!accountDetail?.accountHolderName.match(name)) {
      errors["accountHolderName"] = VALID_NAME;
      errors["accountHolderNameErr"] = true;
      status = false;
    }
    if (!accountDetail?.accountNumber) {
      errors["accountNumber"] = "Account number is mandatory";
      status = false;
    } 
    // else if (!accountDetail?.accountNumber.match(accountNumberRegex)) {
    //   errors["accountNumber"] = VALID_ACCOUNT_NUMBER;
    //   errors["accountNumberErr"] = true;
    //   status = false;
    // }
    if (!selectedCountry?.value) {
      errors["country"] = "Country is mandatory";
      status = false;
    }
    if (!selectedState?.value) {
      errors["state"] = "State is mandatory";
      status = false;
    }
    if (!selectedCity?.value) {
      errors["city"] = "City is mandatory";
      status = false;
    }
    if (!accountDetail?.confirmAccountNumber) {
      errors["confirmAccountNumber"] = "Confirm account number is mandatory";
      status = false;
    }
    if (!accountDetail?.address) {
      errors["address"] = "Address is mandatory";
      status = false;
    }
    if (!accountDetail?.ifsc) {
      errors["ifsc"] = "IFSC is mandatory";
      status = false;
    } else if (!accountDetail?.ifsc.match(ifscRegex)) {
      errors["ifsc"] = VALID_IFSC;
      errors["ifscErr"] = true;
      status = false;
    }
    if (!accountDetail?.pincode) {
      errors["pincode"] = "Pincode is mandatory";
      status = false;
    } else if (accountDetail?.pincode.length !== 6) {
      errors["pincode"] = "Pincode must be of 6 digit";
      errors["pincodeErr"] = true;
      status = false;
    }
    if (accountDetail.accountNumber && accountDetail.confirmAccountNumber) {
      if (accountDetail.accountNumber !== accountDetail.confirmAccountNumber) {
        errors["confirmAccountNumber"] =
          "Account number & confirm account number must be same";
        errors["confirmAccountNumberErr"] = true;
        status = false;
      }
    }
    setError(errors);
    return status;
  };

  // Update Account Detail
  const updateAccountDetail = async () => {
    if (validation()) {
      try {
        const formData = {
          ...accountDetail,
          city: replaceLabelWithName(selectedCity),
          country: replaceLabelWithName(selectedCountry),
          state: replaceLabelWithName(selectedState),
        };
        delete formData.confirmAccountNumber;
        delete formData._id;
        delete formData.accountId;
        delete formData.createdAt;
        delete formData.updatedAt;

        const response = await accountUpdateApi(formData);
        if (response?.data?.code === 200) {
          fetchingAccountDetail();
          toastMessage(
            response?.data?.message,
            "success",
            "updateAccountDetail"
          );
          const localData = JSON.parse(localStorage.getItem("fitzure_info"));
          localData.moduleSteps = response?.data.data?.expertProfileStatus;
          localStorage.setItem("fitzure_info", JSON.stringify(localData));

          dispatch(setStepsInfo(response?.data.data?.expertProfileStatus));
          dispatch(
            setSteps({ steps: response?.data.data?.expertProfileStatus })
          );
          if(signUpForm){

            navigate("/profile-setup/additional-details");
          }else{
            navigate("/dashboard/profile");
          }
        } else {
          toastMessage(response?.data?.message, "error", "updateAccountDetail");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchStateList();
  }, [selectedCountry.value]);

  useEffect(() => {
    fetchCityList();
  }, [selectedCountry.value && selectedState.value]);

  useEffect(() => {
    fetchCountryList();
    fetchingAccountDetail(2);
  }, []);

  // console.log(error,"Erros");

  return (
    <>
      {loader}
      <div className="personal_detail profile-setup">
        <h3 className="title">Bank Account Details</h3>

        <div className="form_field_wrap multiple">
          <div className="form_field">
            <Input
              type="text"
              className="form-control input"
              placeholder="Enter Bank Name"
              label="Bank Name"
              name="bankName"
              error={error?.bankName}
              value={accountDetail.bankName || ""}
              onChange={accountDetailHandler}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              className="form-control input"
              placeholder="Enter Account Holder Name"
              label="Account Holder Name"
              name="accountHolderName"
              error={error?.accountHolderName}
              extraError={error?.accountHolderNameErr}
              value={accountDetail.accountHolderName || ""}
              onChange={accountDetailHandler}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              className="form-control input"
              placeholder="Enter Account Number"
              label="Account Number"
              name="accountNumber"
              value={accountDetail.accountNumber || ""}
              // extraError={error?.accountNumberErr}
              error={error?.accountNumber}
              onChange={accountDetailHandler}
            />
          </div>

          <div className="form_field">
            <Input
              type="text"
              className="form-control input"
              placeholder="Confirm Account Number"
              label="Confirm Account Number"
              name="confirmAccountNumber"
              error={error?.confirmAccountNumber}
              extraError={error?.confirmAccountNumberErr}
              value={accountDetail.confirmAccountNumber || ""}
              onChange={accountDetailHandler}
            />
          </div>

          <div className="form_field">
            <Input
              type="text"
              className="form-control input"
              placeholder="Enter IFSC"
              label="IFSC"
              error={error?.ifsc}
              extraError={error?.ifscErr}
              name="ifsc"
              value={accountDetail.ifsc || ""}
              onChange={accountDetailHandler}
            />
          </div>

          <div className="form_field"></div>
        </div>

        <div className="form_field_wrap multiple">
          <div className="form_field address">
            <Input
              type="text"
              className="form-control input"
              placeholder="Branch Address"
              label="Branch Address"
              name="address"
              value={accountDetail.address || ""}
              onChange={accountDetailHandler}
              error={error?.address}
            />
          </div>

          <div className="form_field">
            <CommonSelect
              className="form-control input"
              label={"Select Country"}
              options={countryList}
              onChange={(selectedData) => {
                setSelectedCountry(selectedData);
                setAccountDetail({ ...accountDetail, pincode: "" });
                setSelectedState("");
                setSelectedCity("");
              }}
              placeholder={"Select country"}
              value={selectedCountry}
              isMulti={false}
              isSearchable={true}
              maxOptions={3}
              error={!selectedCountry.value && error.country}
            />
          </div>

          <div className="form_field">
            <CommonSelect
              className="form-control input"
              isDisabled={!selectedCountry.value}
              label={"Select State"}
              options={stateList}
              onChange={(selectedData) => {
                setSelectedState(selectedData);
                setSelectedCity("");
                setAccountDetail({ ...accountDetail, pincode: "" });
              }}
              placeholder={"Select State"}
              value={selectedState}
              isMulti={false}
              isSearchable={true}
              maxOptions={3}
              error={!selectedState.value && error.state}
            />
          </div>

          <div className="form_field">
            <CommonSelect
              className="form-control input"
              isDisabled={!selectedCountry.value || !selectedState.value}
              label={"Select City"}
              options={cityList}
              onChange={(selectedData) => {
                setSelectedCity(selectedData);
                setAccountDetail({ ...accountDetail, pincode: "" });
              }}
              placeholder={"Select City"}
              value={selectedCity}
              isMulti={false}
              isSearchable={true}
              maxOptions={3}
              error={!accountDetail.value && error.city}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              className="form-control input"
              placeholder="Pincode"
              label="Pincode"
              name="pincode"
              maxlength="6"
              value={accountDetail.pincode || ""}
              error={error?.pincode}
              extraError={error?.pincodeErr}
              onChange={accountDetailHandler}
            />
          </div>
        </div>

        <div className="button_wrap dashboard">
          <p className="information">
            <AiFillInfoCircle />
            To set up your profile, please provide the following details.
          </p>
          <LoadingButton
            type="button"
            loading={loading}
            onClick={updateAccountDetail}
          >
            Save Profile
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
