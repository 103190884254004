import React, { useState } from "react";
import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
  ShimmerCircularImage,
  ShimmerThumbnail,
  ShimmerBadge,
} from "react-shimmer-effects";

export const Shimmer = ({
  type = "title",
  size,
  height,
  width,
  className,
  rounded,
  line,
  variant,
  showShimmer,
}) => {
   

  if (!showShimmer) {
    return null;
  }

  switch (type) {
    case "button":
      return <ShimmerButton size={size} />;
    case "badge":
      return <ShimmerBadge width={width} size="sm" />;
    case "title":
      return <ShimmerTitle line={1} variant={variant} />;
    case "text":
      return <ShimmerText line={3} />;
    case "circularImage":
      return <ShimmerCircularImage size={size} />;
    case "thumbnail":
      return (
        <ShimmerThumbnail
          height={height}
          width={width}
          className={className}
        //   fitOnFrame={true}
          rounded={true}
        />
      );
    default:
      return null;
  }
};
