import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toastMessage } from "../utils/toastMessage";
import { useSelector, useDispatch } from "react-redux";
import { setPersonalInfo } from "store/slices/configSlice";
import { sidebar } from "common/Sidenav";

// this code is created an instance with base url for admin
export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const getToken = () => {
  const fitzureInfo = JSON.parse(localStorage.getItem("fitzure_info"));
  return fitzureInfo ? fitzureInfo?.token : null;
};

export const setJwtToken = () => {
  const token = getToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

export const setMultiPartHeader = () => {
  const token = getToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };
};

// this interceptors checks user's token is valid or not
export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const token = localStorage.getItem("fitzure_info");
  const { personalInfo } = useSelector((state) => state.config);

  const removeToken = () => {
    localStorage.removeItem("fitzure_info");
    localStorage.removeItem("expertProfileStatus");
  };

  useEffect(() => {
    const resInterceptor = (response) => {
      if (response?.data?.code === 401) {
        removeToken();
        navigate("/");
      }
      return response;
    };

    const errInterceptor = (error) => {
      if (error?.code === "ERR_NETWORK") {
        toastMessage(error?.message, "error", "forbidden");
        return error;
      }
      if (error?.response?.status === 403) {
        removeToken();
        navigate("/");
        toastMessage("Please Login!", "error", "forbidden");
        return error;
      }

      if (error?.response?.data?.code === 403) {
        removeToken();
        navigate("/");
        return error;
      }
      if (error.response && error.response?.data?.code === 400) {
        toastMessage(error.response?.data?.message, "error", "errorMeesage");
      }
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);

    return () => instance.interceptors.response.eject(interceptor);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!token && personalInfo?.token) {
      dispatch(setPersonalInfo({ personalInfo: null }));
    }
  }, [token]);
  return isSet && children;
};
