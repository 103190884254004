import { createSlice } from '@reduxjs/toolkit';

// Define the loginInfo slice
const loginInfoSlice = createSlice({
  name: 'login',
  initialState: {
    loginInfo: {},
  },
  reducers: {
    setLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
    },
  },
});

// Define the stepsInfo slice
const stepsInfoSlice = createSlice({
  name: 'steps',
  initialState: {
    stepsInfo: "",
  },
  reducers: {
    setStepsInfo: (state, action) => {
      state.stepsInfo = action.payload;
    },
  },
});

// Define the image slice
// const imageSlice = createSlice({
//   name: 'images',
//   initialState: {
//     imageInfo: "",
//   },
//   reducers: {
//     setImageInfo: (state, action) => {
//       state.imageInfo = action.payload;
//     },
//   },
// });



// Export actions from both slices
export const { setLoginInfo } = loginInfoSlice.actions;
export const { setStepsInfo } = stepsInfoSlice.actions;
// export const { setImageInfo } = imageSlice.actions;

// Export reducers from both slices
export const loginReducer = loginInfoSlice.reducer;
export const stepsReducer = stepsInfoSlice.reducer;
// export const imagesReducer = imageSlice.reducer;
