import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { createArrayFromTo, createArrayUpTo } from "utils/formValidator";
import { useSearchParams } from "react-router-dom";
import { dashboardYearlyGraphApi } from "services/dashboard";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EarningLineChart = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const year = +searchParam.get("year") || 2024;
  const yearData = createArrayFromTo(2020, new Date().getFullYear());
  const [barData, setBarData] = useState([]);
  const [loader, onShow, onHide] = FullPageLoader();


    // Fetching Data on Yearly Basis
    const fetchingYearlyData = async () => {
      try {
        onShow();
        const { data } = await dashboardYearlyGraphApi({ year: year });
        if (data?.code === 200) {
          setBarData(data?.data);
        } else {
          toastMessage(data?.message, "error", "yeardatafetching");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        onHide();
      }
    };

    useEffect(()=>{
      fetchingYearlyData();
    },[year])

  const monthMapping = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const monthArray = Object.keys(monthMapping).map((month) => ({
    name: month,
    value: monthMapping[month] + 1,
  }));

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Earnings and Revenue per Month",
      },
    },
  };

  // Labels for months
  const labels = monthArray && monthArray.map((data) => data?.name);

  // Chart data
  const data = {
    labels,
    datasets: [
      {
        label: "Earnings",
        data:
          barData &&
          barData.length > 0 &&
          barData.map((data) => data?.totalEarning || []), //  earnings data
        borderColor: "#259D8F",
        backgroundColor: "#259D8F",
      },
      {
        label: "Revenue",
        data:
          barData &&
          barData.length > 0 &&
          barData.map((data) => data?.totalRevenue || []), // revenue data
        borderColor: "#303D39",
        backgroundColor: "#303D39",
      },
    ],
  };

  return (
    <>
      <div className="filters">
        <b>Earnings by Category</b>
        <div className="selects">
          <select
            value={year || ""}
            onChange={(e) => {
              searchParam.set("year", e.target.value);
              setSearchParam(searchParam);
            }}
          >
            <option value="">Select Year</option>
            {yearData &&
              yearData.length > 0 &&
              yearData.map((data) => (
                <option key={data} value={data}>
                  {data}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="chart_wrap">
        <Bar options={options} data={data} />
      </div>
    </>
  );
};

export default EarningLineChart;
