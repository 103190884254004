import React, { useState } from "react";
import "../auth.scss";

import RegisterInfo from "./section/RegisterInfo";
import OTPScreen from "auth/login/section/OTPScreen";

const SignUp = ({ moveLogin ,setLoginModal, showSignUp }) => {
  const [loginStep, setLoginStep] = useState("1");
  const [loginData, setLoginData] = useState({});

  const sendOtpNextHandler = (data = {}) => {
    setLoginData(data);
    setLoginStep("2");
  };

  const closeModal=(data)=>{
    setLoginModal(data)
  }


  return (
    <>
      {loginStep === "1" && (
        <RegisterInfo sendOtp={sendOtpNextHandler} moveLogin={moveLogin} showSignUp={showSignUp} />
      )}

      {loginStep === "2" && <OTPScreen loginData={loginData} showSignUp={showSignUp}

      // verifiedType={"auth"}
       closeModal={closeModal}  />}
    </>
  );
};

export default SignUp;
