import { profileImg, serviceImg1 } from "assets/images";
import { Button } from "components/form";
import React, { useEffect, useState } from "react";
import "./TransactionHistory.scss";
import { Link, useSearchParams } from "react-router-dom";
import { getTransactionListApi } from "services/userServices/transaction";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { dateFormatWithTime } from "utils/dateFormat";
import CommonPagination from "common/CommonPagination";
import Placeholder from "components/placeholder/Placeholder";

const TransactionHistory = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;

  const fetchingTransactionListHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.page = activePage - 1;
      const { data } = await getTransactionListApi(sendData);
      if (data?.code === 200) {
        setTransactionList(data?.data || []);
        setTotal(data?.totalItems || 0);
      } else {
        toastMessage(data?.message, "error", "transactionList");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  useEffect(() => {
    fetchingTransactionListHandler();
  }, [searchParam]);

  // console.log(transactionList,"Transaction List");

  return (
    <>
      {loader}
      <div className="exploreExperts">
        <div className="container">
          <div className="page_title text-center">
            <span>Transaction History</span>
            <h3>For people making Impact</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p>
          </div>
          <div className="transaction_history">
            <div className="table_wrap">
              <div className="table_inner">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Transaction ID</th>
                      <th>Booking ID</th>
                      <th>Date & Time</th>
                      <th>Service</th>
                      <th>Expert Name</th>
                      <th>Total Amount</th>
                      <th>Cancellation</th>
                      <th>Refund</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList &&
                      transactionList.length > 0 ?
                      transactionList.map((data) => {
                        return (
                          <tr>
                            <td>{data?.paymentId || "---"}</td>
                            <td>{data?.bookingId || "---"}</td>
                            <td className="nowrap">{dateFormatWithTime(data?.createdAt)}</td>
                            <td>{data?.service || "---"}</td>
                            <td>
                              {data?.expert
                                ? `${data.expert.firstName || "---"} ${
                                    data.expert.lastName || "---"
                                  }`
                                : "---"}
                            </td>

                            <td>{`INR ${data?.amount || "---"}`}</td>
                            <td>{`INR ${data?.cancellationCharge || 0}`}</td>
                            <td className="text-center">{`INR ${data?.refundedAmount || "0"}`}</td>
                          </tr>
                        );
                      }):<tr><td colSpan={8}><Placeholder text={"No Transaction Done By User"}/></td></tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommonPagination
        total={total}
        activePage={activePage}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default TransactionHistory;
