import React, { useEffect, useState } from "react";
import ConsultationListUpcoming from "./ConsultationListUpcoming";
import ConsultationListCancelled from "./ConsultationListCancelled";
import ConsultationListCompleted from "./ConsultationListCompleted";
import { toastMessage } from "utils/toastMessage";
import { getConsultantListApi } from "services/userServices/servicesListing";
import { useSearchParams } from "react-router-dom";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import CommonPagination from "common/CommonPagination";
import Placeholder from "components/placeholder/Placeholder";

const ConsultationList = ({ serviceType }) => {
  const [loader, onShow, onHide] = FullPageLoader();
  // const [note,setNote]=useState({});
  const [consultantList, setConsultantList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [total, setTotal] = useState(null);
  let page = +searchParam.get("page") || 1;
  let type = searchParam.get("type") || serviceType;
  let bookingStatus = searchParam.get("bookingStatus") || "upcoming";

  const booking = [
    {
      name: "Upcoming",
      value: "upcoming",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Cancelled",
      value: "cancelled",
    },
  ];

  const bookingStatusHandler = (service) => {
    searchParam.set("bookingStatus", service);
    setSearchParam(searchParam);
  };

  // Handle Page Change
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };


  const fetchingConsultantListHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.bookingStatus = bookingStatus;
      sendData.pageSize = 10;
      sendData.page = page - 1;
      const { data } = await getConsultantListApi(sendData);
      if (data?.code === 200) {
        setConsultantList(data?.data || []);
        setTotal(data?.totalItems || null);
        // setNote()
      } else {
        toastMessage(data?.message, "error", "BookingServicesList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingConsultantListHandler();
  }, [searchParam]);

  // console.log(consultantList, "Consultant List");

  return (
    <div className="card_section consultation">
      <div className="sideBar">
        <ul>
          {booking &&
            booking.length &&
            booking.map((data) => (
              <li
                key={data?.value}
                className={`${bookingStatus === data?.value ? "active" : ""}`}
                onClick={() => bookingStatusHandler(data?.value)}
              >
                {data?.name}
              </li>
            ))}
        </ul>
      </div>
      {/* {bookingStatus === "upcoming" && ( */}
        <ConsultationListUpcoming
          consultantList={consultantList}
          fetchingConsultantListHandler={fetchingConsultantListHandler}
          serviceType={serviceType}
          bookingStatus={bookingStatus}
        />
     {/* )} */}
      {/* {bookingStatus === "completed" && (
        <ConsultationListCompleted
          consultantList={consultantList}
          serviceType={serviceType}
        />
      )}
      {bookingStatus === "cancelled" && (
        <ConsultationListCancelled
          consultantList={consultantList}
          serviceType={serviceType}
        />
      )} */}

      {/* <CommonPagination
        activePage={page}
        total={total}
        handlePageChange={handlePageChange}
      /> */}
    </div>
  );
};

export default ConsultationList;
