import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa";
import { forwardRef } from "react";
import { LuCalendarDays } from "react-icons/lu";
import ReactDatePicker from "react-datepicker";
import MultiSelect from "react-select";
import { components } from "react-select";
import { FaRegFilePdf } from "react-icons/fa6";
import { AiOutlineFileJpg } from "react-icons/ai";

export const RatingStar = ({ user, rating, ...rest }) => {
  return (
    <>
      <div className="rating">
        <span className="_rating">{rating || 0}</span>
        <Rating
          className="rating_star"
          emptySymbol={<FaRegStar />}
          fullSymbol={<FaStar />}
          {...rest}
        />
        <span className="user">{user}</span>
      </div>
    </>
  );
};

export const Button = ({ type, className, isDisabled, children, ...rest }) => {
  return (
    <>
      <button
        type={type ? type : "button"}
        disabled={isDisabled}
        className={`button ${className}`}
        {...rest}
      >
        {children}
      </button>
    </>
  );
};

export const LoadingButton = ({
  children,
  loading,
  className = "",
  isDisabled,
  ...rest
}) => {
  return (
    <button
      className={`button has_loader ${className}`}
      {...rest}
      disabled={isDisabled}
    >
      {loading ? (
        <>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24px"
            height="30px"
            viewBox="0 0 24 30"
            // style="enable-background:new 0 0 50 50;"
          >
            <rect x="0" y="13" width="4" height="5" fill="#fff">
              <animate
                attributeName="height"
                attributeType="XML"
                values="5;21;5"
                begin="0s"
                dur="0.6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                attributeType="XML"
                values="13; 5; 13"
                begin="0s"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </rect>
            <rect x="10" y="13" width="4" height="5" fill="#fff">
              <animate
                attributeName="height"
                attributeType="XML"
                values="5;21;5"
                begin="0.15s"
                dur="0.6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                attributeType="XML"
                values="13; 5; 13"
                begin="0.15s"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </rect>
            <rect x="20" y="13" width="4" height="5" fill="#fff">
              <animate
                attributeName="height"
                attributeType="XML"
                values="5;21;5"
                begin="0.3s"
                dur="0.6s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                attributeType="XML"
                values="13; 5; 13"
                begin="0.3s"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export const Input = forwardRef(
  (
    {
      value = "",
      onChange,
      className = "",
      label,
      error,
      required,
      extraError = false,
      infoErr = false,
      optional = false,
      subText,
      ...rest
    },
    ref
  ) => {
    const classGenerator = () => {
      if (infoErr && extraError) {
        return "is-invalid";
      }
      if (infoErr) {
        return "info";
      }
      return ((!value && error) || extraError) && "is-invalid";
    };
    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
            {subText && <span className="subText">{subText}</span>}
          </label>
        )}
        <input
          value={value}
          onChange={onChange}
          className={`form-control input ${className} ${classGenerator()}`}
          ref={ref}
          {...rest}
        />
        {error && (
          <span className={`${infoErr ? "info-feedback" : "invalid-feedback"}`}>
            {error}
          </span>
        )}
      </>
    );
  }
);

export const Select = forwardRef(
  (
    {
      label,
      onChange,
      value,
      error,
      className = "",
      children,
      required,
      optional,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
          </label>
        )}
        <select
          onChange={onChange}
          value={value}
          className={`form-control input ${className} ${
            !value && error && "is-invalid"
          }`}
          ref={ref}
          {...rest}
        >
          {children}
        </select>
        {error && <span className="invalid-feedback">{error}</span>}
      </>
    );
  }
);

export const TextArea = forwardRef(
  ({ value, onChange, label, className = "", error, ...rest }, ref) => {
    return (
      <>
        {label && <label className="my-1 label">{label}</label>}
        <textarea
          value={value}
          onChange={onChange}
          className={`form-control input ${className} ${
            !value && error && "is-invalid"
          }`}
          ref={ref}
          {...rest}
        />
        {error && <span className="invalid-feedback">{error}</span>}
      </>
    );
  }
);

export const DatePicker = forwardRef(
  (
    {
      minDate,
      value,
      onChange,
      className = "",
      label,
      error,
      required,
      extraError = false,
      infoErr = false,
      optional = false,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const classGenerator = () => {
      if (infoErr && extraError) {
        return "is-invalid";
      }
      if (infoErr) {
        return "info";
      }
      return ((!value && error) || extraError) && "is-invalid";
    };

    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
          </label>
        )}
        <ReactDatePicker
          ref={ref}
          className={`form-control input date_picker ${className} ${classGenerator()}`}
          showIcon
          icon={<LuCalendarDays />}
          minDate={minDate}
          selected={value}
          onChange={onChange}
          dateFormat={"dd-MMM-yyyy"}
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={300}
          onChangeRaw={(e) => e.preventDefault()}
          {...rest}
          placeholderText={placeholder}
        />
        {!value && error && (
          <span
            className={`${
              infoErr ? "info-feedback" : "invalid-feedback"
            } d-block`}
          >
            {error}
          </span>
        )}
      </>
    );
  }
);

export const YearPicker = forwardRef(
  (
    {
      value,
      onChange,
      className = "",
      label,
      error,
      required,
      extraError = false,
      infoErr = false,
      optional = false,
      ...rest
    },
    ref
  ) => {
    const classGenerator = () => {
      if (infoErr && extraError) {
        return "is-invalid";
      }
      if (infoErr) {
        return "info";
      }
      return ((!value && error) || extraError) && "is-invalid";
    };

    return (
      <>
        {label && (
          <label className="label">
            {label}
            {required && <span className="required mx-1"> *</span>}
            {optional && <em className="optional">{"(optional)"}</em>}
          </label>
        )}
        <ReactDatePicker
          ref={ref}
          className={`form-control input ${className} ${classGenerator()}`}
          showIcon
          icon={<LuCalendarDays />}
          selected={value}
          onChange={onChange}
          dateFormat="yyyy"
          // useShortMonthInDropdown
          // showYearDropdown
          // scrollableYearDropdown
          showYearPicker
          onChangeRaw={(e) => e.preventDefault()}
          {...rest}
          yearDropdownItemNumber={300}
        />
        {!value && error && (
          <span
            className={`${
              infoErr ? "info-feedback" : "invalid-feedback"
            } d-block`}
          >
            {error}
          </span>
        )}
      </>
    );
  }
);

export const CommonSelect = ({
  label,
  onChange,
  value,
  error,
  className = "",
  options = [],
  isMulti = false,
  isSearchable = false,
  noOptionsMessage = () => "No options found",
  required = false,
  loading = false,
  isDisabled = false,
  placeholder = "Select",

  ...rest
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected
        ? "linear-gradient(180deg, #01b4a7 0%, #00aace 100%)"
        : "#fff", // Change background color of selected option
      color: state.isSelected
        ? "linear-gradient(180deg, #01b4a7 0%, #00aace 100%)"
        : "black", // Change text color of selected option
      "&:hover": {
        background: "linear-gradient(180deg, #01b4a7 0%, #00aace 100%)", // Change background color of option on hover
        color: "#fff", // Change text color of option on hover
      },
    }),
    loadingIndicator: (base, state) => ({
      ...base,
      color: "#FF8989", // Set the color to red
    }),
  };

  const LoadingIndicator = (props) => {
    return (
      <components.LoadingIndicator {...props}>
        {/* <ClipLoader size={20} color="#999" loading={true} />
         */}
        <p>Loading...</p>
      </components.LoadingIndicator>
    );
  };

  const handleChange = (selectedOptions) => {
    onChange(selectedOptions);
  };
  return (
    <div>
      {label && <label className="label">{label}</label>}
      {required && <span className="text-danger"> *</span>}

      <MultiSelect
        onChange={handleChange}
        value={value}
        className={`form-control multi-select ${className} ${
          !value && error && "is-invalid"
        }`}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        isLoading={loading}
        styles={customStyles}
        isDisabled={isDisabled}
        classNamePrefix="react_select"
        // placeholderText={placeholder}
        components={{ LoadingIndicator }}
        {...rest}
      />
      {/* {leftIcon} */}
      {error && <div className="invalid-feedback d-flex">{error}</div>}
    </div>
  );
};

// Switch Button //

export const SwitchButton = ({
  onChange,
  className = "",
  status,
  disabled,
  ...rest
}) => {
  return (
    <>
      <label className={`switch ${className} ${disabled ? "disabled" : ""}`}>
        <input
          type="checkbox"
          checked={status}
          disabled={disabled}
          {...rest}
          onChange={onChange}
        />
        <span className="slider round"></span>
      </label>
    </>
  );
};

export const FileUpload = ({ id, onChange, preview, fileName }) => {
  return (
    <>
      <div className="file_upload_wrap">
        <label className="file_upload input" htmlFor={id}>
          <input type="file" id={id} onChange={onChange} />
          <span className="fileName">{fileName}</span>
          <span className="browse">Browse</span>
        </label>

        {preview && (
          <div className="preview">
            <AiOutlineFileJpg className="image" />
          </div>
        )}
      </div>
    </>
  );
};
