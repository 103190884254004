import React, { useEffect, useState } from "react";

import "./MyBookings.scss";
import { crownIcon2, on2onIcon2, webinarIcon } from "assets/icons";
import ConsultationList from "./consultation/ConsultationList";
import WebinarsList from "./webinar/WebinarList";
import { useSearchParams } from "react-router-dom";
import { getWebinarListApi } from "services/userServices/servicesListing";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { FaDiamond } from "react-icons/fa6";
import SubscriptionList from "./subscription/SubscriptionList";

const MyBookings = () => {
  const [loader, onShow, onHide] = FullPageLoader();
  const [searchParam, setSearchParam] = useSearchParams();
  let type = searchParam.get("type") || "subscription";
  let subscriptionType = searchParam.get("subscriptionType") || "oneTime";

  const servicesType = [
    {
      name: "Training",
      value: "subscription",
    },
    {
      name: "1:1 Consultation",
      value: "oneToOne",
    },
    {
      name: "Webinar",
      value: "webinar",
    },
  ];

  const subscriptionTypeData = [
    {
      name: "Batch Training",
      value: "oneTime",
    },
    {
      name: "Recurring Training",
      value: "recurring",
    },
  ];

  // Set Active Class Handler
  const activeClassHandler = (value) => {
    if (value !== "subscription") {
      searchParam.delete("subscriptionType");
      // setSearchParam(searchParam);
    }

      searchParam.delete("page");
      searchParam.delete("bookingStatus");
      searchParam.delete("pageSize");
      searchParam.set("type", value);
      setSearchParam(searchParam);
    
  };

  const activeSubscriptionClassHandler = (value) => {
    searchParam.delete("page");
    searchParam.delete("bookingStatus");
    searchParam.delete("pageSize");

    searchParam.set("subscriptionType", value);
    setSearchParam(searchParam);
  };

  // console.log(type,"Type");

  return (
    <>
      <div className="exploreExperts">
        <div className="container">
          <div className="page_title text-center">
            <span>My Bookings</span>
            <h3>For people making Impact</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p>
          </div>

          <div className="my_bookings">
            <div className="tab_button_wrap">
              {servicesType &&
                servicesType.length &&
                servicesType.map((data) => (
                  <button
                    type="button"
                    className={` button ${
                      type === data?.value ? "active" : ""
                    }`}
                    onClick={() => activeClassHandler(data?.value)}
                  >
                    {data?.name}
                    <FaDiamond />
                  </button>
                ))}
            </div>

            <div className="tab_button_wrap">
              {type === "subscription" &&
                subscriptionTypeData &&
                subscriptionTypeData.length &&
                subscriptionTypeData.map((data) => (
                  <button
                    type="button"
                    className={` button ${
                      subscriptionType === data?.value ? "active" : ""
                    }`}
                    onClick={() => activeSubscriptionClassHandler(data?.value)}
                  >
                    {data?.name}
                    <FaDiamond />
                  </button>
                ))}
            </div>

            {type === "oneToOne" && <ConsultationList serviceType={type} />}
            {type === "webinar" && <WebinarsList serviceType={type} />}
            {type === "subscription" && (
              <SubscriptionList
                serviceType={type}
                subscriptionType={subscriptionType}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBookings;
