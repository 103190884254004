import { Modal } from "react-bootstrap";
import "../components/modals/Modal.scss";
import { charLimit } from "utils/mediaDataSender";
import { IoClose } from "react-icons/io5";

const DescriptionModal = ({
  show,
  handleClose,
  description,
  className,
  closeButton = true,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`custom_modal ${className}`}
    >
      <Modal.Body>
        {closeButton && (
          <span className="close_button" onClick={handleClose}>
            <IoClose />
          </span>
        )}
        <div className="modal_content">
          {" "}
          <p>{description}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DescriptionModal;
