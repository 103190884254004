import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Get One To One List API
export const getOneToOneApi = async (data) => {
  const endPoint =
    `expert/one-to-one-consultation/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Start One To One
export const startOneToOneApi = async (data) => {
  const endPoint = `expert/one-to-one-consultation/start`;
  return await axios.put(endPoint, data, setJwtToken());
};


// Cancel One To One
export const cancelOneToOne = async (data) => {
  const endPoint = `expert/service/cancel`;
  return await axios.patch(endPoint, data, setJwtToken());
};

// Add Note API
export const addNoteApi = async (data) => {
  const endPoint = `expert/one-to-one-consultation/add-note`;
  return await axios.put(endPoint, data, setMultiPartHeader());
};
