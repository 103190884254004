import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../../config/axiosInstance";

// Get Transaction List API
export const getTransactionListApi = async (data) => {
  const endPoint =
    `user/transaction/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};
