import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../../config/axiosInstance";

// Start Consultation
export const startConsultationApi = async (data) => {
  const endPoint = `user/one-to-one-consultation/start`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Start One Time Subscription
export const startOneTimeSubscription = async (data) => {
  const endPoint = `user/subscription/start-one-time-subcription`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Cancel Consultation
export const cancelConsultationApi = async (data) => {
  const endPoint = `user/one-to-one-consultation/cancel`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Rate Consultation
export const rateConsultationApi = async (data) => {
  const endPoint = `user/one-to-one-consultation/rate`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Start Webinar
export const startWebinarApi = async (data) => {
  const endPoint = `user/webinar/start`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Rate Webinar
export const rateWebinarApi = async (data) => {
  const endPoint = `user/services/rate`;
  return await axios.put(endPoint, data, setJwtToken());
};

// Text Priority DM
export const textPriorityDmApi = async (data) => {
  const endPoint = `user/priority-dm/text`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};

// Cancel Services
export const cancelServicesApi = async (data) => {
  const endPoint = `user/services/cancel`;
  return await axios.patch(endPoint, data, setJwtToken());
};
