import { banner2, imagePlaceholder, serviceImg1 } from "assets/images";
import React from "react";
import { LuIndianRupee } from "react-icons/lu";
import { RiEdit2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "../manageServices/ManageServices.scss";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
const DigitalContentsDetails = () => {
  return (
    <>
      <div className="consultation_details has_banner _priority_dm">
        <img src={banner2} alt="" className="banner_img" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>Digital Product</h3>
          </div>

          <div className="page_banner spacing">
            <div className="banner_content">
              <img src={imagePlaceholder} alt="" />
              <div className="content">
                <h5>Pulled Hamstring causes, Treatment and recovery </h5>
                <p className="duration">
                  Promised response <strong>(1 Day)</strong>
                </p>
                <p className="price">
                  <del>
                    <LuIndianRupee /> 600
                  </del>
                  <span>
                    <LuIndianRupee /> 500
                  </span>
                </p>
              </div>
            </div>
            <div className="button_wrap button_right">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <HiDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={"span"}>
                    <Link to="/dashboard/digital-contents/edit">
                      <RiEdit2Line /> Edit Service
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as={"span"}>
                    <HiOutlineTrash />
                    Delete Service
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </div>
          </div>
          <div className="page_content spacing">
            <div className="overView table_radius">
              <div className="_sub_title">
                <h4>Overview</h4>
              </div>
              <div className="content">
                <h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat
                </h4>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                  nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur?
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                  nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur?
                </p>
              </div>
            </div>
          </div>
          <div className="chapters_link spacing">
            <h5>Chapters List</h5>
            <ul>
              <li>
                <h6>Chapters 1 : Content management</h6>
                <span>Effective strategies for organizing, storing, and distributing digital content.</span>
              </li>
              <li>
                <h6>Chapters 2 : Content management</h6>
                <span>Effective strategies for organizing, storing, and distributing digital content.</span>
              </li>
            </ul>
          </div>
          <div className="introduction_url">
            <b>Introduction Video Link</b>
            <p>https://youtu.be/YIC_Ml_g_jc?si=7DmqyVOMgnpN9JvN</p>
          </div>
          <div className="table_radius">
            <div className="_sub_title">
              <h4>Testimonial</h4>
            </div>
            <div className="content">
              <div className="card_wrap">
                {testimonial &&
                  testimonial.map((item) => {
                    return (
                      <TestimonialCard
                        key={item.id}
                        title={item.title}
                        date={item.date}
                        description={item.description}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalContentsDetails;
