import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  CommonSelect,
  Input,
  LoadingButton,
  Select,
  YearPicker,
} from "components/form";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RiUploadCloud2Line } from "react-icons/ri";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  personDetailApi,
  personalDetailApi,
  professionalDetailUpdateApi,
} from "services/details";
import {
  cityListApi,
  countryListApi,
  expertiseListApi,
  skillsListApi,
  stateListApi,
} from "services/master";
import { toastMessage } from "utils/toastMessage";
import { yearFormat } from "utils/dateFormat";
import {
  createArrayUpTo,
  regex,
  replaceLabelWithName,
  replaceNameWithLabel,
  replaceNameWithLabelSkill,
} from "utils/formValidator";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { setSteps } from "store/slices/configSlice";
import { useDispatch } from "react-redux";
import { setStepsInfo } from "store/loginInfoSlice";
import commonMessages from "utils/commonMessages";
import { showImageHandler } from "utils/mediaDataSender";

const PersonaDetail = ({ signUpForm = true }) => {
  const educationRefs = useRef([React.createRef()]);
  const certificateRefs = useRef([React.createRef()]);
  const { imageFileRegex, fileRegex } = regex;
  const { FILE_FORMAT } = commonMessages;

  const [professionalError, setProfessionalError] = useState({});
  const [certificateError, setCertificateError] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const yoe = createArrayUpTo(69);
  const yom = createArrayUpTo(11);
  const navigate = useNavigate();
  const [educationCountryList, setEducationCountryList] = useState([]);
  const [certificationCountryList, setCertificationCountryList] = useState([]);
  const [error, setError] = useState({});
  const [deleteEducation, setDeleteEducation] = useState([]);
  const [deleteCertificate, setDeleteCertificate] = useState([]);
  const [educationDetail, setEducationDetail] = useState([
    {
      education: "",
      specialisation: "",
      institute: "",
      country: "",
      state: "",
      city: "",
      completed: false,
      yearOfPassing: "",
    },
  ]);

  const [certificationDetail, setCertificationDetail] = useState([
    {
      certification: "",
      institute: "",
      country: "",
      state: "",
      city: "",
      completed: "",
      yearOfPassing: "",
    },
  ]);

  const [skillsDetail, setSkillsDetail] = useState({
    expertise: "",
    yoe: {
      years: "",
      months: "",
    },
  });
  const [expertiseList, setExpertiseList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skillsValue, setSkillsValue] = useState([]);
  const dispatch = useDispatch();

  // Fetching Personal Detail;
  const fetchingProfessionalDetail = async (screen = 1) => {
    onShow();
    try {
      const response = await personDetailApi(screen);
      if (response?.data?.code === 200) {
        const data = response?.data?.data?.expertProfessionalDetails?.skills.map(
          (data) => replaceNameWithLabelSkill(data)
        );
        setSkillsValue(data);
        setSkillsDetail({
          yoe: response?.data?.data?.expertProfessionalDetails?.yoe,
          expertise: response?.data?.data?.expertise?._id,
        });
        if (
          response?.data.data?.expertProfessionalDetails?.education &&
          response?.data.data?.expertProfessionalDetails?.education.length > 0
        ) {
          setEducationDetail(
            response?.data.data?.expertProfessionalDetails?.education.map(
              (edu, index) => {
                // Create the education detail object
                const educationDetail = {
                  education: edu.education,
                  specialisation: edu.specialisation,
                  institute: edu.institute,
                  country: edu?.country.id
                    ? replaceNameWithLabel(edu?.country)
                    : "",
                  state: replaceNameWithLabel(edu.state),
                  city: replaceNameWithLabel(edu.city),
                  completed: edu.completed,
                  yearOfPassing: edu.yearOfPassing || "",
                  certificate: edu.certificate,
                  _id: edu._id,
                };

                // Add a new reference if it's not the first item
                if (index !== 0) {
                  educationRefs.current.push(React.createRef());
                }

                return educationDetail;
              }
            )
          );
        }
        if (
          response?.data.data?.expertProfessionalDetails?.certifications &&
          response?.data.data?.expertProfessionalDetails?.certifications
            .length > 0
        ) {
          setCertificationDetail(
            response?.data.data?.expertProfessionalDetails?.certifications.map(
              (certificate, index) => {
                // Create the education detail object
                const certificationDetail = {
                  certification: certificate.certification,
                  // specialisation: certificate.specialisation,
                  institute: certificate.institute,
                  country: replaceNameWithLabel(certificate?.country),
                  state: replaceNameWithLabel(certificate.state),
                  city: replaceNameWithLabel(certificate.city),
                  completed: certificate.completed,
                  yearOfPassing: certificate.yearOfPassing,
                  certifications: certificate.certificate,
                  _id: certificate._id,
                };

                // Add a new reference if it's not the first item
                if (index !== 0) {
                  certificateRefs.current.push(React.createRef());
                }

                return certificationDetail;
              }
            )
          );
        }
      } else {
        toastMessage(response?.data?.message, "error", "personDetail");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  const fetchingExpertiseList = async () => {
    try {
      const response = await expertiseListApi();
      if (response?.data?.code === 200) {
        setExpertiseList(response?.data?.data);
      } else {
        toastMessage(response?.data?.message, "error", "expertiseList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchingSkillsList = async () => {
    try {
      const response = await skillsListApi();
      if (response?.data?.code === 200) {
        const data = response?.data?.data.map((data) =>
          replaceNameWithLabelSkill(data)
        );
        setSkills(data);
      } else {
        toastMessage(response?.data?.message, "error", "skillsList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //   Country List
  const fetchEducationCountryList = async () => {
    try {
      const res = await countryListApi();
      if (res?.data?.code === 200) {
        setEducationCountryList(
          res.data.data.map((country) => ({
            label: country.name,
            value: country.id,
          })) || []
        );
      } else {
        toastMessage(res.data.message, "error", "countryList");
        setEducationCountryList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  const fetchCertificationCountryList = async () => {
    try {
      const res = await countryListApi();
      if (res?.data?.code === 200) {
        setCertificationCountryList(
          res.data.data.map((country) => ({
            label: country.name,
            value: country.id,
          })) || []
        );
      } else {
        toastMessage(res.data.message, "error", "countryList");
        setCertificationCountryList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // State List
  const fetchEducationStateList = async (index, countryId) => {
    try {
      if (countryId) {
        const res = await stateListApi(countryId);
        if (res?.data?.code === 200) {
          const newEducationDetail = [...educationDetail];
          newEducationDetail[index].stateList =
            res.data.data.map((state) => ({
              label: state.name,
              value: state.id,
            })) || [];
          setEducationDetail(newEducationDetail);
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch state list",
            "error",
            "stateList"
          );
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const fetchCertificationStateList = async (index, countryId) => {
    try {
      if (countryId) {
        const res = await stateListApi(countryId);
        if (res?.data?.code === 200) {
          const newCertificationDetail = [...certificationDetail];
          newCertificationDetail[index].stateList =
            res.data.data.map((state) => ({
              label: state.name,
              value: state.id,
            })) || [];
          setCertificationDetail(newCertificationDetail);
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch state list",
            "error",
            "stateList"
          );
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const fetchCertificationCityList = async (index, stateId) => {
    try {
      if (stateId) {
        const res = await cityListApi(stateId);
        if (res?.data?.code === 200) {
          const newCertificationDetail = [...certificationDetail];
          newCertificationDetail[index].cityList =
            res.data.data.map((city) => ({
              label: city.name,
              value: city.id,
            })) || [];
          setCertificationDetail(newCertificationDetail);
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch city list",
            "error",
            "cityList"
          );
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // City List
  const fetchEducationCityList = async (index, stateId) => {
    try {
      if (stateId) {
        const res = await cityListApi(stateId);
        if (res?.data?.code === 200) {
          const newEducationDetail = [...educationDetail];
          newEducationDetail[index].cityList =
            res.data.data.map((city) => ({
              label: city.name,
              value: city.id,
            })) || [];
          setEducationDetail(newEducationDetail);
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch city list",
            "error",
            "cityList"
          );
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchEducationCountryList();
    fetchingSkillsList();
    fetchCertificationCountryList();
    fetchingExpertiseList();
    fetchingProfessionalDetail();
  }, []);

  /***************************Handle Validation****************************/
  function isAnyFieldFilled(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return false;
    }

    return data.some((obj) => {
      return Object.values(obj).some((value) => {
        if (typeof value === "string") {
          return value.trim() !== "";
        }
        if (typeof value === "object" && value !== null) {
          return Object.keys(value).length > 0 || value instanceof File;
        }
        return value !== null && value !== undefined;
      });
    });
  }

  function checkFieldFilled(data) {
    if (!Array.isArray(data)) {
      return Object.values(data).some((value) => {
        if (typeof value === "string") {
          return value.trim() !== "";
        }
        if (typeof value === "object" && value !== null) {
          return Object.keys(value).length > 0 || value instanceof File;
        }
        return value !== null && value !== undefined;
      });
    }
  }

  function isAllFieldsFilled(data) {
    if (!Array.isArray(data) || data.length === 0) {
      return false;
    }

    return data.every((obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "string") {
          return value.trim() !== "";
        }
        if (typeof value === "object" && value !== null) {
          console.log(
            Object.keys(value).length > 0 || value instanceof File,
            "Return check for object"
          );
          return Object.keys(value).length > 0 || value instanceof File; // Check if the object has keys or is an instance of File
        }
        console.log(
          value !== null && value !== undefined,
          "Return check for other types"
        );
        return value !== null && value !== undefined; // Check for other types (numbers, booleans, etc.)
      });
    });
  }

  // Certification Validation On Submiting
  const certificationSubmitValidation = () => {
    let status = true;
    const errors = {};

    certificationDetail.forEach((certificate, index) => {
      if (checkFieldFilled(certificate)) {
        // if its return true means some data has been filled so validation called for this
        if (!certificate.certification) {
          errors[`certification${index}`] = "certification is mandatory";
          status = false;
        }
        if (!certificate.institute) {
          errors[`institute${index}`] = "Institute is mandatory";
          status = false;
        }
        if (!certificate.country.value) {
          errors[`country${index}`] = "Country is mandatory";
          status = false;
        }
        if (!certificate.state.value) {
          errors[`state${index}`] = "State is mandatory";
          status = false;
        }
        if (!certificate.city.value) {
          errors[`city${index}`] = "City is mandatory";
          status = false;
        }
        if (
          certificate.completed.toString() === "true" &&
          !certificate.yearOfPassing
        ) {
          errors[`yearOfPassing${index}`] = "Year of Passing is mandatory";
          status = false;
        }
        if (certificate.completed === "") {
          errors[`completed${index}`] = "Certificate completed is mandatory";
          status = false;
        }
        if (!certificate.certifications) {
          errors[`certifications${index}`] = "Certificate is mandatory";
          status = false;
        }
        setCertificateError(errors);
      }
    });
    return status;
  };

  const validation = () => {
    let status = true;
    const error = {};
    if (!skillsDetail.expertise) {
      error["expertise"] = "Please select expertise";
      status = false;
    }
    if (!skillsValue || skillsValue.length === 0) {
      error["skills"] = "Skills is mandatory";
      status = false;
    }
    // if (!skillsDetail?.yoe?.years) {
    //   error["months"] = "please select both years & months of experience";
    //   status = false;
    // }
    setProfessionalError(error);
    return status;
  };

  const certificationValidation = () => {
    let status = true;
    const errors = {};

    certificationDetail.forEach((certificate, index) => {
      const isCertificateFilled = isAnyFieldFilled(certificate);
      if (!isCertificateFilled) {
        if (!certificate.certification) {
          errors[`certification${index}`] = "certification is mandatory";
          status = false;
        }
        if (!certificate.institute) {
          errors[`institute${index}`] = "Institute is mandatory";
          status = false;
        }
        if (!certificate.country.value) {
          errors[`country${index}`] = "Country is mandatory";
          status = false;
        }
        if (!certificate.state.value) {
          errors[`state${index}`] = "State is mandatory";
          status = false;
        }
        if (!certificate.city.value) {
          errors[`city${index}`] = "City is mandatory";
          status = false;
        }
        if (
          certificate.completed.toString() === "true" &&
          !certificate.yearOfPassing
        ) {
          errors[`yearOfPassing${index}`] = "Year of Passing is mandatory";
          status = false;
        }
        if (certificate.completed === "") {
          errors[`completed${index}`] = "Certificate completed is mandatory";
          status = false;
        }
        if (!certificate.certifications) {
          errors[`certifications${index}`] = "Certificate is mandatory";
          status = false;
        }
        setCertificateError(errors);
      }
    });
    return status;
  };

  const educationValidation = () => {
    let status = true;
    const errors = {};

    educationDetail.forEach((edu, index) => {
      if (!edu.education) {
        errors[`education${index}`] = "Education is mandatory";
        status = false;
      }
      if (!edu.specialisation) {
        errors[`specialisation${index}`] = "Specialisation is mandatory";
        status = false;
      }
      if (!edu.institute) {
        errors[`institute${index}`] = "Institute is mandatory";
        status = false;
      }
      if (!edu.country.value) {
        errors[`country${index}`] = "Country is mandatory";
        status = false;
      }
      if (!edu.state.value) {
        errors[`state${index}`] = "State is mandatory";
        status = false;
      }
      if (!edu.city.value) {
        errors[`city${index}`] = "City is mandatory";
        status = false;
      }
      if (
        edu?.certificate &&
        (edu?.certificate).toString() === "true" &&
        !edu.yearOfPassing
      ) {
        errors[`yearOfPassing${index}`] = "Year of Passing is mandatory";
        status = false;
      }
      if (!edu.certificate) {
        errors[`certificate${index}`] = "Certificate is mandatory";
        status = false;
      }
      setError(errors);
    });

    return status;
  };

  /*****************************************************************************/

  const handleInputChange = (index, field, value) => {
    const newEducationDetail = [...educationDetail];
    if (field === "yearOfPassing") {
      newEducationDetail[index].yearOfPassing = yearFormat(value);
    }
    if (field === "country") {
      newEducationDetail[index].country = value;
      newEducationDetail[index].state = "";
      newEducationDetail[index].city = "";
    }
    if (field === "state") {
      newEducationDetail[index].state = value;
      newEducationDetail[index].city = "";
    }
    if (field === "completed") {
      newEducationDetail[index].completed = value;
      newEducationDetail[index].yearOfPassing = "";
    } else {
      newEducationDetail[index][field] = value;
    }
    setEducationDetail(newEducationDetail);
  };

  const handleCertificationInputChange = (index, field, value) => {
    const newCertificationDetail = [...certificationDetail];
    if (field === "yearOfPassing") {
      newCertificationDetail[index].yearOfPassing = yearFormat(value);
    }

    if (field === "country") {
      newCertificationDetail[index].country = value;
      newCertificationDetail[index].state = "";
      newCertificationDetail[index].city = "";
      if (field === "state") {
        newCertificationDetail[index].state = value;
        newCertificationDetail[index].city = "";
      }
      if (field === "completed") {
        newCertificationDetail[index].completed = value;
        newCertificationDetail[index].yearOfPassing = "";
      }
    } else {
      newCertificationDetail[index][field] = value;
    }
    setCertificationDetail(newCertificationDetail);
  };

  const addEducationField = () => {
    if (educationValidation()) {
      setEducationDetail([
        ...educationDetail,
        {
          education: "",
          specialisation: "",
          institute: "",
          country: "",
          state: "",
          city: "",
          completed: false,
          yearOfPassing: "",
          certificate: null,
        },
      ]);
      educationRefs.current.push(React.createRef());
    }
  };

  const addCertificationField = () => {
    if (certificationValidation()) {
      setCertificationDetail([
        ...certificationDetail,
        {
          certification: "",
          institute: "",
          country: "",
          state: "",
          city: "",
          completed: "",
          yearOfPassing: "",
          certifications: null,
        },
      ]);
      certificateRefs.current.push(React.createRef());
    }
  };

  const removeEducationField = (index) => {
    const newEducationDetail = [...educationDetail];
    const item = newEducationDetail[index];

    if (item && item._id) {
      setDeleteEducation((prevDeleteIds) => {
        const idExists = prevDeleteIds.some(({ _id }) => _id === item._id);
        if (!idExists) {
          return [...prevDeleteIds, { _id: item._id }];
        }
        return prevDeleteIds;
      });
    }
    newEducationDetail.splice(index, 1);
    setEducationDetail(newEducationDetail);
  };

  const removeCertificationField = (index) => {
    if (certificationDetail.length > 1) {
      setCertificationDetail((prevCertificationDetail) => {
        const newCertificationDetail = [...prevCertificationDetail];
        const item = newCertificationDetail[index];

        if (item && item._id) {
          setDeleteCertificate((prevDeleteIds) => {
            const idExists = prevDeleteIds.some(({ _id }) => _id === item._id);
            if (!idExists) {
              return [...prevDeleteIds, { _id: item._id }];
            }
            return prevDeleteIds;
          });
        }
        newCertificationDetail.splice(index, 1);
        return newCertificationDetail;
      });
    }
  };

  const handleFileChange = async (index, event, id) => {
    event.preventDefault();

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // if (imageFileRegex.test(file.name)) {
      if (fileRegex.test(file.name)) {
        const newEducationDetail = [...educationDetail];
        newEducationDetail[index].certificate = file;
        setEducationDetail(newEducationDetail);
      } else {
        event.target.value = "";
        const newEducationDetail = [...educationDetail];
        newEducationDetail[index].certificate = "";
        setEducationDetail(newEducationDetail);
        toastMessage(FILE_FORMAT, "error", "imageFormat");
      }
    } else {
      event.target.value = "";
      const newEducationDetail = [...educationDetail];
      newEducationDetail[index].certificate = "";
      setEducationDetail(newEducationDetail);
    }
  };

  const handleCertificationFileChange = async (index, event) => {
    event.preventDefault();

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // if (imageFileRegex.test(file.name)) {
      if (fileRegex.test(file.name)) {
        const newCertificationDetail = [...certificationDetail];
        newCertificationDetail[index].certifications = file;
        setCertificationDetail(newCertificationDetail);
      } else {
        event.target.value = "";
        const newCertificationDetail = [...certificationDetail];
        newCertificationDetail[index].certifications = "";
        setCertificationDetail(newCertificationDetail);
        toastMessage(FILE_FORMAT, "error", "imageFormat");
      }
    } else {
      event.target.value = "";
      const newCertificationDetail = [...certificationDetail];
      newCertificationDetail[index].certifications = "";
      setEducationDetail(newCertificationDetail);
    }
  };

  // For Removing keys from education Detail
  const removeKeys = (obj, keys) => {
    let newObj = { ...obj };
    keys.forEach((key) => {
      delete newObj[key];
    });
    return newObj;
  };

  const professionalDetailHandler = async () => {
    let status = validation();
    let educationStatus = educationValidation();
    let certificationStatus = certificationSubmitValidation();

    if (status && educationStatus && certificationStatus) {
      const keysToRemove = ["stateList", "cityList", "certificate"];

      const keysToRemoveFromCertification = [
        "stateList",
        "cityList",
        "certifications",
      ];
      const cleanedEducationDetail = educationDetail.map((detail) => {
        const { country, state, city, ...rest } = removeKeys(
          detail,
          keysToRemove
        );
        return {
          ...rest,
          country: replaceLabelWithName(country),
          state: replaceLabelWithName(state),
          city: replaceLabelWithName(city),
        };
      });

      const cleanedCertificateDetail = certificationDetail.map((detail) => {
        const { country, state, city, ...rest } = removeKeys(
          detail,
          keysToRemoveFromCertification
        );
        return {
          ...rest,
          country: replaceLabelWithName(country),
          state: replaceLabelWithName(state),
          city: replaceLabelWithName(city),
        };
      });
      try {
        setLoading(true);
        const updatedEducationFileIndexes = [];
        const updatedCertificationsFileIndexes = [];
        const formData = new FormData();

        educationDetail.length > 0 &&
          educationDetail.forEach((certificate, index) => {
            if (
              certificate.certificate &&
              certificate.certificate.name &&
              certificate._id
            ) {
              updatedEducationFileIndexes.push(index);
            }
            if (certificate.certificate && certificate.certificate.name) {
              formData.append("education", certificate.certificate);
            }
          });

        if (
          updatedEducationFileIndexes &&
          updatedEducationFileIndexes.length > 0
        ) {
          formData.append(
            "updatedEducationFileIndexes",
            JSON.stringify(updatedEducationFileIndexes)
          );
        }

        certificationDetail.length > 0 &&
          certificationDetail.forEach((certificate, index) => {
            if (
              certificate.certifications &&
              certificate.certifications.name &&
              certificate._id
            ) {
              updatedCertificationsFileIndexes.push(index);
            }
            if (
              certificate.certifications &&
              certificate.certifications?.name
            ) {
              formData.append("certifications", certificate?.certifications);
            }
          });

        if (
          updatedCertificationsFileIndexes &&
          updatedCertificationsFileIndexes.length
        ) {
          formData.append(
            "updatedCertificationsFileIndexes",
            JSON.stringify(updatedCertificationsFileIndexes)
          );
        }

        if (deleteCertificate.length > 0) {
          formData.append(
            "certificationsToRemove",
            JSON.stringify(deleteCertificate)
          );
        }

        if (deleteEducation.length > 0) {
          formData.append("educationToRemove", JSON.stringify(deleteEducation));
        }

        const cleanedData = cleanedCertificateDetail.filter((item) => {
          return Object.entries(item).every(([key, value]) => {
            if (key === "yearOfPassing" && item.completed === false) {
              return true;
            }
            if (key === "yearOfPassing" && item.completed === true) {
              return value !== null && value !== undefined && value !== "";
            }
            return value !== null && value !== undefined && value !== "";
          });
        });

        if (cleanedData.length > 0) {
          formData.append("certifications", JSON.stringify(cleanedData));
        }

        formData.append(
          "education",
          JSON.stringify(
            cleanedEducationDetail.map(({ certificate, ...rest }) => {
              return {
                ...rest,
              };
            })
          )
        );
        if (Object.keys(skillsDetail?.yoe).length) {
          formData.append("yoe", JSON.stringify(skillsDetail?.yoe));
        } else {
          formData.append("yoe", undefined);
        }
        formData.append("expertise", skillsDetail.expertise);
        if (skillsValue && skillsValue.length > 0) {
          formData.append(
            "skills",
            JSON.stringify(skillsValue.map((data) => ({ _id: data.value })))
          );
        }
        const { data } = await professionalDetailUpdateApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "updateProfessionalDetail");

          const localData = JSON.parse(localStorage.getItem("fitzure_info"));
          localData.moduleSteps = data.data?.expertProfileStatus;
          localStorage.setItem("fitzure_info", JSON.stringify(localData));

          dispatch(setStepsInfo(data.data?.expertProfileStatus));
          dispatch(setSteps({ steps: data.data?.expertProfileStatus }));
          if (signUpForm) {
            navigate("/profile-setup/account-details");
          } else {
            navigate("/dashboard/profile");
          }
        } else {
          toastMessage(data?.message, "error", "updateProfessionalDetail");
        }
      } catch {
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="personal_detail">
        <h3 className="title">Skills</h3>
        {loader}
        <div className="form_field_wrap multiple">
          <div className="form_field">
            <label className="label">Area of Expertise</label>
            <select
              className="form-control input"
              placeholder=""
              value={skillsDetail?.expertise}
              onChange={(e) =>
                setSkillsDetail({ ...skillsDetail, expertise: e.target.value })
              }
            >
              <option value="">Select Expertise</option>
              {expertiseList &&
                expertiseList.length > 0 &&
                expertiseList.map((data) => (
                  <option key={data?._id} value={data?._id}>
                    {data?.name}
                  </option>
                ))}
            </select>
            {professionalError.expertise && (
              <span className="invalid-feedback d-block">
                {professionalError.expertise}
              </span>
            )}
          </div>

          <div className="form_field">
            <CommonSelect
              className="form-control input"
              label={"Skills"}
              options={skills}
              value={skillsValue}
              onChange={setSkillsValue}
              placeholder={"Select Skill"}
              isMulti={true}
              isSearchable={true}
              error={professionalError.skills}
            />
          </div>

          <div className="form_field">
            <label className="label">Years of Experience</label>
            <div className="has_child">
              <select
                className="form-control input"
                placeholder=""
                value={skillsDetail?.yoe?.years ?? ""}
                name="years"
                onChange={(e) =>
                  setSkillsDetail({
                    ...skillsDetail,
                    yoe: { ...skillsDetail.yoe, years: e.target.value || "" },
                  })
                }
              >
                <option value="">Select Year</option>
                {yoe &&
                  yoe.length > 0 &&
                  yoe.map((data) => <option value={data}>{data}</option>)}
              </select>
              <select
                className="form-control input"
                placeholder=""
                value={skillsDetail?.yoe?.months ?? ""}
                onChange={(e) =>
                  setSkillsDetail({
                    ...skillsDetail,
                    yoe: { ...skillsDetail.yoe, months: e.target.value || "" },
                  })
                }
              >
                <option value="">Select Month</option>
                {yom &&
                  yom.length > 0 &&
                  yom.map((data) => <option value={data}>{data}</option>)}
              </select>
            </div>
            {professionalError.months && (
              <span className="invalid-feedback d-block">
                {professionalError.months}
              </span>
            )}
          </div>
        </div>

        <div className="title_wrap">
          <h3 className="title">Education</h3>
        </div>

        {educationDetail.map((edu, index) => (
          <>
            <div className="form_field_wrap multiple" key={index}>
              <div className="form_field">
                <Select
                  className="form-control input"
                  value={edu.education}
                  onChange={(e) =>
                    handleInputChange(index, "education", e.target.value)
                  }
                  label={"Education"}
                  error={error[`education${index}`]}
                >
                  <option value="">Select Education</option>
                  <option value="Diploma">Diploma</option>
                  <option value="10">10</option>
                  <option value="12">12</option>
                  <option value="Bachelor of Science in Exercise Science">
                    Bachelor of Science in Exercise Science
                  </option>
                </Select>
              </div>

              <div className="form_field">
                <Input
                  type="text"
                  className="form-control input"
                  maxlength="50"
                  placeholder="Specialisation"
                  label="Specialisation"
                  value={edu.specialisation}
                  onChange={(e) =>
                    handleInputChange(index, "specialisation", e.target.value)
                  }
                  error={error[`specialisation${index}`]}
                />
              </div>

              <div className="form_field">
                <Input
                  type="text"
                  className="form-control input"
                  placeholder="Enter Institute"
                  label="Institute"
                  maxlength="100"
                  value={edu.institute}
                  onChange={(e) =>
                    handleInputChange(index, "institute", e.target.value)
                  }
                  error={error[`institute${index}`]}
                />
              </div>

              {/* education country list */}
              <div className="form_field">
                <CommonSelect
                  className="form-control input"
                  label={"Select Country"}
                  options={educationCountryList}
                  onChange={(selectedData) => {
                    handleInputChange(index, "country", selectedData);

                    fetchEducationStateList(index, selectedData.value);
                  }}
                  placeholder={"Select country"}
                  value={edu.country}
                  isMulti={false}
                  isSearchable={true}
                  maxOptions={3}
                  error={error[`country${index}`]}
                />
              </div>

              {/* education state list */}
              <div className="form_field">
                <CommonSelect
                  isDisabled={!edu.country.label && !edu.country.value}
                  className="form-control input"
                  label={"Select State"}
                  options={edu.stateList || []}
                  onChange={(selectedData) => {
                    handleInputChange(index, "state", selectedData);
                    fetchEducationCityList(index, selectedData.value);
                  }}
                  placeholder={"Select state"}
                  value={edu.state}
                  isMulti={false}
                  isSearchable={true}
                  maxOptions={3}
                  error={error[`state${index}`]}
                />
              </div>

              <div className="form_field">
                <CommonSelect
                  isDisabled={!edu.state.label && !edu.state.value}
                  className="form-control input"
                  label={"Select City"}
                  options={edu.cityList || edu.stateList}
                  onChange={(selectedData) => {
                    handleInputChange(index, "city", selectedData);
                  }}
                  placeholder={"Select city"}
                  value={edu.city}
                  isMulti={false}
                  isSearchable={true}
                  maxOptions={3}
                  error={error[`city${index}`]}
                />
              </div>

              <div className="form_field">
                <label className="label">Completed?</label>
                <div className="radio_button_wrap hasTwo">
                  <label
                    htmlFor={`completed${index}`}
                    className={`radio_button input ${
                      edu.completed ? "active" : ""
                    }`}
                    onClick={() => handleInputChange(index, "completed", true)}
                  >
                    <input
                      type="radio"
                      name={`completed${index}`}
                      id={`completed${index}`}
                      checked={edu.completed}
                    />

                    <span>Yes, I did</span>
                  </label>

                  <label
                    htmlFor={`pursuing${index}`}
                    className={`radio_button input ${
                      !edu.completed ? "active" : ""
                    }`}
                    onClick={() => handleInputChange(index, "completed", false)}
                  >
                    <input
                      type="radio"
                      name={`completed${index}`}
                      id={`pursuing${index}`}
                      checked={!edu.completed}
                    />
                    <span>I am pursuing</span>
                  </label>
                </div>
                {error[`yearOfPassing${index}`] && (
                  <span className="invalid-feedback d-block">
                    {error[`completed${index}`]}
                  </span>
                )}
              </div>

              <div className="form_field">
                <YearPicker
                  label={"Year of Passing"}
                  disabled={edu?.completed == false}
                  className="form-control input"
                  placeholder="Year of passing"
                  maxDate={Date.now()}
                  showOnlyYear={true}
                  selected={edu.yearOfPassing}
                  onChange={(date) =>
                    handleInputChange(index, "yearOfPassing", date)
                  }
                  error={error[`yearOfPassing${index}`]}
                />
              </div>

              <div className="form_field">
                <label className="label">
                  Upload Certificate or Any Related Certificate
                </label>
                <Button
                  type={"file"}
                  className={"has_icon light rounded w-100"}
                  onClick={() => {
                    educationRefs.current[index].current?.click();
                  }}
                >
                  <RiUploadCloud2Line />
                  Browse Files
                </Button>
                <input
                  type="file"
                  hidden
                  ref={educationRefs.current[index]}
                  onChange={(e) => handleFileChange(index, e, edu._id)}
                />
                {error[`certificate${index}`] && (
                  <span className="invalid-feedback d-block">
                    {error[`certificate${index}`]}
                  </span>
                )}
              </div>

              {edu.certificate && (
                <div className="uploaded_preview">
                  <div className="imagePreview">
                    {/* <img
                      src={
                        edu.certificate?.name
                          ? URL.createObjectURL(edu.certificate)
                          : edu?.certificate
                      }
                      alt=""
                    /> */}
                    {showImageHandler(edu?.certificate)}
                  </div>
                </div>
              )}

              {index === educationDetail.length - 1 ? (
                <div className="button_wrap button_right">
                  <Button
                    className={"has_icon light"}
                    onClick={addEducationField}
                  >
                    <FaPlus /> Add Education
                  </Button>
                </div>
              ) : (
                <div className="button_wrap button_right mt-4">
                  <Button
                    className={"has_icon light"}
                    onClick={() => removeEducationField(index)}
                  >
                    <FaMinus /> Remove Education
                  </Button>
                </div>
              )}
            </div>
          </>
        ))}

        <div className="title_wrap">
          <h3 className="title">Certifications (Optional)</h3>
          <Button
            className={"has_icon light"}
            onClick={addCertificationField}
            isDisabled={!isAnyFieldFilled(certificationDetail)}
          >
            <FaPlus /> Add More Certification
          </Button>
        </div>

        {certificationDetail &&
          certificationDetail.length > 0 &&
          certificationDetail.map((certificate, index) => {
            return (
              <>
                <div className="form_field_wrap multiple" key={index}>
                  <div className="form_field">
                    <label className="label">Education</label>
                    <Select
                      className="form-control input"
                      value={certificate?.certification}
                      error={certificateError[`certification${index}`]}
                      onChange={(e) =>
                        handleCertificationInputChange(
                          index,
                          "certification",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Education</option>
                      <option value="Diploma">Diploma</option>
                      <option value="10">10</option>
                      <option value="12">12</option>
                      <option value="Bachelor of Science in Exercise">
                        Bachelor of Science in Exercise
                      </option>
                    </Select>
                  </div>

                  <div className="form_field">
                    <Input
                      type="text"
                      className="form-control input"
                      placeholder="Enter Institute"
                      label="Institute"
                      maxlength="100"
                      value={certificate.institute}
                      error={certificateError[`institute${index}`]}
                      onChange={(e) =>
                        handleCertificationInputChange(
                          index,
                          "institute",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div className="form_field"></div>

                  <div className="form_field">
                    <CommonSelect
                      className="form-control input"
                      error={certificateError[`country${index}`]}
                      label={"Select Country"}
                      options={certificationCountryList}
                      onChange={(selectedData) => {
                        handleCertificationInputChange(
                          index,
                          "country",
                          selectedData
                        );
                        fetchCertificationStateList(index, selectedData.value);
                      }}
                      placeholder={"Select country"}
                      value={certificate.country}
                      isMulti={false}
                      isSearchable={true}
                      maxOptions={3}
                    />
                  </div>
                  <div className="form_field">
                    <CommonSelect
                      isDisabled={
                        !certificate.country.label && !certificate.country.value
                      }
                      className="form-control input"
                      error={certificateError[`state${index}`]}
                      label={"Select State"}
                      options={certificate.stateList || []}
                      onChange={(selectedData) => {
                        handleCertificationInputChange(
                          index,
                          "state",
                          selectedData
                        );
                        fetchCertificationCityList(index, selectedData.value);
                      }}
                      placeholder={"Select state"}
                      value={certificate.state}
                      isMulti={false}
                      isSearchable={true}
                      maxOptions={3}
                      // error={error[`state${index}`]}
                    />
                  </div>
                  <div className="form_field">
                    <CommonSelect
                      className="form-control input"
                      error={certificateError[`city${index}`]}
                      isDisabled={
                        !certificate.state.label && !certificate.state.value
                      }
                      label={"Select City"}
                      options={certificate.cityList || []}
                      onChange={(selectedData) => {
                        handleCertificationInputChange(
                          index,
                          "city",
                          selectedData
                        );
                      }}
                      placeholder={"Select city"}
                      value={certificate.city}
                      isMulti={false}
                      isSearchable={true}
                      maxOptions={3}
                      // error={error[`city${index}`]}
                    />
                  </div>

                  <div className="form_field">
                    <label className="label">Completed?</label>
                    <div className="radio_button_wrap hasTwo">
                      <label
                        htmlFor={`certificateCompleted${index}`}
                        className={`radio_button input ${
                          certificate.completed === true ? "active" : ""
                        }`}
                        onClick={() =>
                          handleCertificationInputChange(
                            index,
                            "completed",
                            true
                          )
                        }
                      >
                        <input
                          type="radio"
                          name={`completed${index}`}
                          id={`certificateCompleted${index}`}
                          checked={certificate.completed === true}
                        />

                        <span>Yes, I did</span>
                      </label>

                      <label
                        htmlFor={`certificatePursuing${index}`}
                        className={`radio_button input ${
                          certificate.completed === false ? "active" : ""
                        }`}
                        onClick={() =>
                          handleCertificationInputChange(
                            index,
                            "completed",
                            false
                          )
                        }
                      >
                        <input
                          type="radio"
                          name={`completed${index}`}
                          id={`certificatePursuing${index}`}
                          checked={certificate.completed === false}
                        />
                        <span>I am pursuing</span>
                      </label>
                    </div>
                    {certificateError[`completed${index}`] && (
                      <span className="invalid-feedback d-block">
                        {certificateError[`completed${index}`]}
                      </span>
                    )}
                  </div>

                  <div className="form_field">
                    <YearPicker
                      label={"Year of Passing"}
                      disabled={certificate?.completed == false}
                      className={"form-control input"}
                      maxDate={Date.now()}
                      showOnlyYear={true}
                      error={certificateError[`yearOfPassing${index}`]}
                      selected={
                        certificate?.completed ? certificate.yearOfPassing : ""
                      }
                      onChange={(date) =>
                        handleCertificationInputChange(
                          index,
                          "yearOfPassing",
                          date
                        )
                      }
                    />
                  </div>
                  <div className="form_field">
                    <label className="label">
                      Upload Certificate or Any Related Certificate
                    </label>
                    <Button
                      type={"file"}
                      className={"has_icon light rounded w-100"}
                      onClick={() => {
                        certificateRefs.current[index].current.click();
                      }}
                    >
                      <RiUploadCloud2Line />
                      Browse Files
                    </Button>
                    <input
                      type="file"
                      hidden
                      ref={certificateRefs.current[index]}
                      onChange={(e) => handleCertificationFileChange(index, e)}
                    />
                    {certificateError[`certifications${index}`] && (
                      <span className="invalid-feedback d-block">
                        {certificateError[`certifications${index}`]}
                      </span>
                    )}
                  </div>
                  {certificate.certifications && (
                    <div className="uploaded_preview">
                      <div className="imagePreview">
                        <img
                          src={
                            certificate.certifications?.name
                              ? URL.createObjectURL(certificate.certifications)
                              : certificate?.certifications
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}

                  {certificationDetail.length > 1 && (
                    <div className="button_wrap button_right">
                      <Button
                        className={"has_icon light"}
                        onClick={() => removeCertificationField(index)}
                      >
                        <FaMinus /> Remove Certificate
                      </Button>
                    </div>
                  )}

                  {/* )} */}
                </div>
              </>
            );
          })}

        <div className="button_wrap dashboard">
          <p className="information">
            <AiFillInfoCircle />
            To set up your profile, please provide the following details.
          </p>
          <LoadingButton
            type="button"
            loading={loading}
            onClick={professionalDetailHandler}
          >
            Save and Next
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default PersonaDetail;
