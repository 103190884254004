import DashboardHome from "expertDashboard/dashboard/DashboardHome";
import {
  PriorityDMsIcon,
  PrivacyPolicyIcon,
  bookingsIcon,
  contentIcon,
  crownIcon2,
  dashboardIcon,
  faqIcon,
  logOutIcon,
  on2onIcon2,
  packageIcon,
  smServicesIcon,
  termsIcon,
  webinarIcon,
} from "../assets/icons";
import EditExpertProfile from "expertDashboard/dashboard/editProfile/EditExpertProfile";
import ExpertProfile from "expertDashboard/dashboard/profile/ExpertProfile";
import PriorityDMS from "expertDashboard/dashboard/priorityDMS/PriorityDMS";
import ManageServices from "expertDashboard/dashboard/manageServices/ManageServices";
import AddServices from "expertDashboard/dashboard/manageServices/addServices/AddServices";
import ConsultationDetail from "expertDashboard/dashboard/manageServices/consultation/ConsultationDetails";
import EditConsultationDetails from "expertDashboard/dashboard/manageServices/consultation/EditConsultationDetails";
import WebinarsDetails from "expertDashboard/dashboard/manageServices/webinars/WebinarsDetails";
import EditWebinarsDetails from "expertDashboard/dashboard/manageServices/webinars/EditWebinarsDetails";
import EditSubscriptionDetails from "expertDashboard/dashboard/manageServices/subscription/EditSubscriptionDetails";
import PriorityDmsDetails from "expertDashboard/dashboard/manageServices/priorityDMS/PriorityDmsDetails";
import EditPriorityDmsDetails from "expertDashboard/dashboard/manageServices/priorityDMS/EditPriorityDmsDetails";
import DigitalContents from "expertDashboard/dashboard/digitalContents/DigitalContents";
import DigitalContentsDetails from "expertDashboard/dashboard/digitalContents/DigitalContentsDetails";
import EditDigitalContentsDetails from "expertDashboard/dashboard/digitalContents/EditDigitalContentsDetails";
import CreatePackage from "expertDashboard/dashboard/packages/CreatePackage";
import EditCreatePackageDetails from "expertDashboard/dashboard/packages/EditCreatePackageDetails";
import TransitionHistory from "expertDashboard/dashboard/transitionHistory/TransitionHistory";
import SubscriptionDetails from "expertDashboard/dashboard/manageServices/subscription/SubscriptionDetails";
import CreatePackageDetails from "expertDashboard/dashboard/packages/CreatePackageDetails";
import ScheduleSetting from "expertDashboard/dashboard/schedule/ScheduleSetting";
import CMSTerms from "expertDashboard/dashboard/cms/CMSTerms";
import ExpertPrivacyPolicy from "expertDashboard/dashboard/cms/ExpertPrivacyPolicy";
import ExpertFaq from "expertDashboard/dashboard/cms/ExpertFAQ";
import BookingConsultation from "expertDashboard/dashboard/bookings/Consultation/BookingConsultation";
import BookingWebinars from "expertDashboard/dashboard/bookings/Webinars/BookingWebinars";
import BookingSubscription from "expertDashboard/dashboard/bookings/Subscription/BookingSubscription";
import EditSchedule from "expertDashboard/dashboard/schedule/EditSchedule";
import AddDigitalCourse from "expertDashboard/dashboard/digitalContents/AddDigitalCourse";
import AddPackage from "expertDashboard/dashboard/packages/AddPackage";
import EditDigitalCourse from "expertDashboard/dashboard/digitalContents/EditDigitalCourse";
import ViewDigitalCourse from "expertDashboard/dashboard/digitalContents/ViewDigitalCourse";
import AddDigitalProduct from "expertDashboard/dashboard/digitalContents/AddDigitalProduct";
import ViewDigitalProduct from "expertDashboard/dashboard/digitalContents/ViewDigitalProduct";
import EditDigitalProduct from "expertDashboard/dashboard/digitalContents/EditDigitalProduct";
import EditSubscription from "expertDashboard/dashboard/manageServices/subscription/EditSubscription";
import EditRecurringSubscription from "expertDashboard/dashboard/manageServices/subscription/EditRecurringSubscription";
import AddConsultations from "expertDashboard/dashboard/manageServices/consultation/AddConsultations";
import AddWebinar from "expertDashboard/dashboard/manageServices/webinars/AddWebinars";
import AddSubscriptions from "expertDashboard/dashboard/manageServices/subscription/AddSubscriptions";
import AddRecurringSubscription from "expertDashboard/dashboard/manageServices/subscription/AddRecurringSubscription";
import AddPriorityDms from "expertDashboard/dashboard/manageServices/priorityDMS/AddPriorityDms";
import ExploreExperts from "pages/exploreExperts/ExploreExperts";
import ExpertDetail from "pages/exploreExperts/expertDetail/expertsDetail";
import Consultation from "pages/services/Consultation";
import Webinars from "pages/services/Webinars";
import PriorityDMService from "pages/services/PriorityDM";
import UserSubscription from "pages/services/UserSubscription";
import UserDigitalContent from "pages/services/UserDigitalContent";
import PackageDetail from "pages/packagesList/PackageDetail";

export const commonSidebar = (currentType, moduleSteps) => {
  const allSidebar = [
    {
      id: 1,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Dashboard",
      subtitle: false,
      path: "/dashboard/home",
      icon: dashboardIcon,
      isChild: null,
    },
    {
      id: 2,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Priority DM",
      subtitle: false,
      path: "/dashboard/prioritydms",
      icon: PriorityDMsIcon,
      isChild: null,
    },
    {
      id: 3,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Manage Services",
      subtitle: false,
      path: "/dashboard/manage-services",
      icon: smServicesIcon,
      isChild: null,
    },
    {
      id: 4,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Schedule",
      subtitle: false,
      path: "/dashboard/services-schedule",
      icon: bookingsIcon,
      isChild: null,
    },

    {
      id: 5,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Digital Contents",
      subtitle: false,
      path: "/dashboard/digital-contents",
      icon: contentIcon,
      isChild: null,
    },
    {
      id: 6,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Package",
      subtitle: false,
      path: "/dashboard/package",
      icon: packageIcon,
      isChild: null,
    },
    {
      id: 7,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Transaction History",
      subtitle: false,
      path: "/dashboard/transition-history",
      icon: termsIcon,
      isChild: null,
    },
    {
      id: 8,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "Bookings",
      subtitle: [
        {
          id: 1,
          enabled: currentType === 0 && moduleSteps === 4,
          // enabled: true,
          type: "expert",
          name: "1 : 1 Consultations",
          subtitle: false,
          path: "/dashboard/expert/bookings/consultation",
          icon: on2onIcon2,
          isChild: null,
        },
        {
          id: 2,
          enabled: currentType === 0 && moduleSteps === 4,
          // enabled: true,
          type: "expert",
          name: "Webinars",
          subtitle: false,
          path: "/dashboard/expert/bookings/webinars",
          icon: webinarIcon,
          isChild: null,
        },
        {
          id: 3,
          enabled: currentType === 0 && moduleSteps === 4,
          // enabled: true,
          type: "expert",
          name: "Trainings",
          subtitle: false,
          path: "/dashboard/expert/bookings/subscription",
          icon: crownIcon2,
          isChild: null,
        },
      ],
    },
    {
      id: 9,
      enabled: currentType === 0 && moduleSteps === 4,
      // enabled: true,
      type: "expert",
      name: "CMS",
      subtitle: [
        {
          id: 1,
          enabled: currentType === 0 && moduleSteps === 4,
          // enabled: true,
          type: "expert",
          name: "Privacy Policy ",
          subtitle: false,
          path: "/dashboard/expert/privacyPolicy",
          icon: PrivacyPolicyIcon,
          isChild: null,
        },
        {
          id: 2,
          enabled: currentType === 0 && moduleSteps === 4,
          // enabled: true,
          type: "expert",
          name: "Terms & Conditions",
          subtitle: false,
          path: "/dashboard/expert/terms_conditions",
          icon: termsIcon,
          isChild: null,
        },
        {
          id: 3,
          enabled: currentType === 0 && moduleSteps === 4,
          // enabled: true,
          type: "expert",
          name: "FAQs / Contact Us",
          subtitle: false,
          path: "/dashboard/expert/faqs",
          icon: faqIcon,
          isChild: null,
        },
      ],
    },
  ].filter((data) => data.enabled);
  return allSidebar;
};

export const commonRoutes = (currentType, moduleSteps) => {
  // console.log(currentType,"Current Type", moduleSteps,"Module Steps");
  const allRoutes = [
    // {
    //   id: 1,
    //   enabled: currentType===0 && moduleSteps===4,
    //   // enabled: true,
    //   element: <ExpertDashboard />,
    //   path: "/dashboard",
    //   active: true,
    // },
    {
      id: 2,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <DashboardHome />,
      path: "/dashboard/home",
      active: true,
    },
    {
      id: 3,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditExpertProfile />,
      path: "/dashboard/edit-profile",
      active: true,
    },
    {
      id: 4,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ExpertProfile />,
      path: "/dashboard/profile",
      active: true,
    },
    {
      id: 5,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <PriorityDMS />,
      path: "/dashboard/prioritydms",
      active: true,
    },
    {
      id: 6,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ManageServices />,
      path: "/dashboard/manage-services",
      active: true,
    },
    {
      id: 7,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddServices />,
      path: "/dashboard/manage-services/add-services",
      active: true,
    },
    {
      id: 8,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ConsultationDetail />,
      path: `/dashboard/manage-services/consultation/detail/:serviceType/:serviceId`,
      active: true,
    },
    {
      id: 1008,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditSchedule />,
      path: `/dashboard/manage-services/consultation/edit-schedule/:serviceId`,
      active: true,
    },
    {
      id: 9,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditConsultationDetails />,
      path:
        "/dashboard/manage-services/consultation/detail/edit/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 10,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <WebinarsDetails />,
      path: "/dashboard/manage-services/webinar/detail/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 11,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditWebinarsDetails />,
      path:
        "/dashboard/manage-services/webinar/detail/edit/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 12,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <SubscriptionDetails />,
      path:
        "/dashboard/manage-services/subscription/detail/:serviceType/:serviceId/:subscriptionType",
      active: true,
    },
    {
      id: 13,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditRecurringSubscription />,
      path:
        "/dashboard/manage-services/subscription/detail/edit/recurring/:serviceType/:serviceId/:subscriptionType",
      active: true,
    },
    {
      id: 13,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditSubscription />,
      path:
        "/dashboard/manage-services/subscription/detail/edit/:serviceType/:serviceId/:subscriptionType",
      active: true,
    },
    {
      id: 14,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <PriorityDmsDetails />,
      path:
        "/dashboard/manage-services/prioritydms/detail/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 15,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditPriorityDmsDetails />,
      path:
        "/dashboard/manage-services/prioritydms/detail/edit/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 16,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <DigitalContents />,
      path: "/dashboard/digital-contents",
      active: true,
    },
    {
      id: 17,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <DigitalContentsDetails />,
      path: `/dashboard/digital-contents/view/`,
      active: true,
    },
    {
      id: 18,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditDigitalContentsDetails />,
      path: "/dashboard/digital-contents/edit",
      active: true,
    },
    {
      id: 118,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddDigitalCourse />,
      // path: "/dashboard/digital-contents/course/add",
      path: "/dashboard/digital-contents/product/add",
      active: true,
    },
    {
      id: 11008,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditDigitalCourse />,
      path:
      "/dashboard/digital-contents/edit/digitalProduct/:serviceType/:serviceId/:digitalType",
      // path:
      //   "/dashboard/digital-contents/edit/:serviceType/:serviceId/:digitalType",
      active: true,
    },
    {
      id: 1001008,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditDigitalProduct />,
      path:
        "/dashboard/digital-contents/edit/:serviceType/:serviceId/:digitalType",
      // path:
      //   "/dashboard/digital-contents/edit/digitalProduct/:serviceType/:serviceId/:digitalType",
      active: true,
    },
    {
      id: 110008,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ViewDigitalCourse />,
      // path:
      //   "/dashboard/digital-contents/view/:serviceType/:serviceId/:digitalType",
           path:
        "/dashboard/digital-contents/view/digitalProduct/:serviceType/:serviceId/:digitalType",
      active: true,
    },
    {
      id: 1100008,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ViewDigitalProduct />,
      path:"/dashboard/digital-contents/view/:serviceType/:serviceId/:digitalType",
      // path:
        // "/dashboard/digital-contents/view/digitalProduct/:serviceType/:serviceId/:digitalType",
      active: true,

    },
    {
      id: 1018,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddDigitalProduct />,
      // path: "/dashboard/digital-contents/product/add",
      path: "/dashboard/digital-contents/course/add",
      active: true,
    },
    {
      id: 19,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <CreatePackage />,
      path: "/dashboard/package/",
      active: true,
    },
    {
      id: 109,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddPackage />,
      path: "/dashboard/package/add",
      active: true,
    },
    {
      id: 191,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <CreatePackageDetails />,
      path: "/dashboard/package/detail/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 20,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <EditCreatePackageDetails />,
      path: "/dashboard/package/edit/:serviceType/:serviceId",
      active: true,
    },
    {
      id: 21,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <TransitionHistory />,
      path: "/dashboard/transition-history",
      active: true,
    },
    {
      id: 22,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ScheduleSetting />,
      path: "/dashboard/services-schedule",
      active: true,
    },

    {
      id: 24,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <CMSTerms />,
      path: "/dashboard/expert/terms_conditions",
      active: true,
    },
    {
      id: 241,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ExpertPrivacyPolicy />,
      path: "/dashboard/expert/privacyPolicy",
      active: true,
    },
    {
      id: 2421,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <ExpertFaq />,
      path: "/dashboard/expert/faqs",
      active: true,
    },

    {
      id: 2611,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <BookingConsultation />,
      path: "/dashboard/expert/bookings/consultation",
      active: true,
    },
    {
      id: 2622,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <BookingWebinars />,
      path: "/dashboard/expert/bookings/webinars",
      active: true,
    },
    {
      id: 2633,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <BookingSubscription />,
      path: "/dashboard/expert/bookings/subscription",
      active: true,
    },

    {
      id: 20001,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddConsultations />,
      path: "/dashboard/manage-services/add-consultation-services/:serviceType",
      active: true,
    },
    {
      id: 20002,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddConsultations />,
      path: "/dashboard/manage-services/add-consultation-services/:serviceType",
      active: true,
    },
    {
      id: 20003,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddWebinar />,
      path: "/dashboard/manage-services/add-webinar-services/:serviceType",
      active: true,
    },
    {
      id: 20004,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddSubscriptions />,
      path:
        "/dashboard/manage-services/add-subscription-services/:serviceType/:subscriptionType",
      active: true,
    },
    {
      id: 20005,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddRecurringSubscription />,
      path:
        "/dashboard/manage-services/add-subscription-services/recurring/:serviceType/:subscriptionType",
      active: true,
    },
    {
      id: 20006,
      // enabled: true,
      enabled: currentType === 0 && moduleSteps === 4,
      element: <AddPriorityDms />,
      path: "/dashboard/manage-services/add-priority-services/:serviceType",
      active: true,
    },

    // {
    //   id: 61,
    //   enabled: true,
    //   // enabled: (currentType === 1 && moduleSteps > 0) || !token,
    //   // element: <ExploreExperts />,
    //   path: "/explore-experts",
    //   active: true,
    // },
    // {
    //   id: 72,
    //   enabled: true,
    //   // enabled: (currentType === 1 && moduleSteps > 0) || !token,
    //   element: <ExpertDetail />,
    //   path: "/expert-details/:_id",
    //   active: true,
    // },
    // {
    //   id: 92,
    //   enabled: true,
    //   // enabled: (currentType === 1 && moduleSteps > 0) || !token,
    //   element: <Consultation />,
    //   path: "/service/consultation/:expertId/:serviceId",
    //   active: true,
    // },
    // {
    //   id: 93,
    //   enabled: true,
    //   // enabled: (currentType === 1 && moduleSteps > 0) || !token,
    //   element: <Webinars />,
    //   path: "/service/webinar/:expertId/:serviceId",
    //   active: true,
    // },
    // {
    //   id: 112,
    //   enabled: true,
    //   // enabled: (currentType === 1 && moduleSteps > 0) || !token,
    //   element: <PriorityDMService />,
    //   path: "/service/prioritydm/:expertId/:serviceId",
    //   active: true,
    // },
    // {
    //   id: 121,
    //   enabled: true,
    //   // enabled: (currentType === 1 && moduleSteps > 0) || !token,
    //   element: <UserSubscription />,
    //   path: "/service/subscription/:expertId/:serviceId",
    //   active: true,
    // },
    // {
    //   id: 133,
    //   enabled: true,
    //   // enabled:  !token,
    //   element: <UserDigitalContent />,
    //   path: "/service/digital/:expertId/:serviceId",
    //   active: true,
    // },
    // {
    //   id: 155,
    //   enabled: true,
    //   // enabled:  !token,
    //   element: <PackageDetail />,
    //   path: "/packages-list/detail/:expertId/:serviceId",
    //   active: true,
    // },

    //  Bookings //
  ].filter((data) => data.enabled);
  return allRoutes;
};
