import React, { useEffect, useState } from "react";
import "./Services.scss";
import { banner1, service1 } from "assets/images";
// import VideoPlayer from "components/video-player/VideoPlayer";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button } from "components/form";
import ConfirmPayModal from "components/modals/ConfirmPayModal";
import CustomModal from "components/modals/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import {
  bookOneToOneApi,
  getExpertServiceDetailApi,
  getExpertServiceDetailSlotApi,
} from "services/userServices/servicesDetail&Booking";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import VideoPlayer from "common/YouTubeVideoPlayer";
import {
  addDateDuration,
  convertTo12HourFormat,
  dateFormat,
  dateFormatYear,
  getUpcomingWeekDates,
  getWeekDatesInRange,
  gettingDay,
  mergeTimeDate,
} from "utils/dateFormat";
import UpcomingConsultation from "expertDashboard/dashboard/bookings/Consultation/section/UpcomingConsultation";
import { useSelector } from "react-redux";
import AuthLogin from "auth/login/Login";
import SignUp from "auth/signUp/SignUp";
import SuccessPayment from "components/SuccessfulPaymentModal/SuccessPayment";
import Placeholder from "components/placeholder/Placeholder";

const UserSubscription = () => {
  const { expertId, serviceId } = useParams();
  const { personalInfo } = useSelector((state) => state.config);
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [payoutModal, setPayoutModal] = useState(false);
  const [slots, setSlots] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [loader, onShow, onHide] = FullPageLoader();
  const [loading, setLoading] = useState(false);
  const [slotTime, setSlotTime] = useState({});
  const [bookingDate, setBookingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [autoPay, setAutoPay] = useState(true);
  const navigate = useNavigate();
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Expert Services Slot
  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        setExpertServicesDetail(data?.data);
        // setBestTestimonials(data?.data?.testimonials || [])
        setBookingDate(data?.data?.subscriptionAvailability?.startDate);
        setEndingDate(data?.data?.subscriptionAvailability?.endDate);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // Expert Service Service Slot
  const fetchingExpertServicesDetailSlot = async (sendData) => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailSlotApi(
        expertId,
        serviceId,
        {
          date: sendData?.isoDate,
          day: sendData?.WeekInNumber,
        }
      );
      if (data?.code === 200) {
        setSlots(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // BookOneToOne
  const BookOneToOneHandler = async () => {
    if (personalInfo?.token) {
      try {
        setLoading(true);
        let sendData = {
          _id: serviceId,
          // bookedFor: mergeTimeDate(bookingDate, slotTime?.startTime),
          amount: expertServicesDetail?.pricing?.amount,
          totalAmount: expertServicesDetail?.pricing?.totalAmount,
          transactionFeePercentage:
            expertServicesDetail?.pricing?.transactionFeePercentage,
          slot: slotTime,
        };
        if (expertServicesDetail.subscriptionType === "recurring") {
          sendData.isAutoPay = autoPay;
        }
        const { data } = await bookOneToOneApi(sendData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "BookOneToOne");
          // navigate(`/expert-details/${expertId}?serviceType=subscription`);
          navigate(`/${expertId}?serviceType=subscription`);

          setPayoutModal(false);
          setPaymentStatusModal(true);
        } else {
          toastMessage(data?.message, "error", "BookOneToOne");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    } else {
      setLoginModal(true);
      setPayoutModal(false);
    }
  };

  const handleAutoPay = (status) => {
    setAutoPay(!status);
  };

  const formatDate = (date) => {
    date = new Date(date);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);

  // console.log(
  //   expertServicesDetail?.subscriptionAvailability?.days,
  //   "ExpertServices Detail"
  // );

  return (
    <>
      <div className="services_page">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img src={expertServicesDetail?.image || service1} alt="" />
                <div className="content">
                  <h4>{expertServicesDetail?.title || "---"} </h4>
                  <p className="duration">
                    <span>Duration</span>{" "}
                    <b> {`(${expertServicesDetail?.duration} mins)`}</b>
                  </p>
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail?.pricing?.slashAmount)}
                        </del>
                        <b>
                          ₹{" "}
                          {
                            Number(expertServicesDetail?.pricing?.amount)
                            // -
                            //   Number(expertServicesDetail?.pricing?.slashAmount)
                          }
                        </b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>
              <div className="banner_tag">
                <span className="name_tag">
                  {expertServicesDetail?.subscriptionType === "recurring"
                    ? expertServicesDetail?.recurringServiceType === "public"
                      ? "Group Training"
                      : "Personal Training"
                    : "Batch Training"}
                </span>
              </div>
            </div>
            <div className="service_content">
              {/* {expertServicesDetail?.subscriptionType === "recurring" ||
                "oneTime" && ( */}
              {
                // expertServicesDetail?.recurringServiceType === "private" ? (
                //   <div className="highlight table_radius">
                //     <div class="_sub_title">
                //       <h4>Weekly Time</h4>
                //     </div>
                //     <ul className="content">
                //       <p className="text-center">
                //         {!expertServicesDetail?.privateSubscriptionAvailability
                //           ?.availabilities.length && "No Services Found"}
                //       </p>
                //       {expertServicesDetail?.privateSubscriptionAvailability
                //         ?.availabilities &&
                //         expertServicesDetail?.privateSubscriptionAvailability
                //           ?.availabilities.length > 0 &&
                //         expertServicesDetail?.privateSubscriptionAvailability?.availabilities.map(
                //           (data, index) => {
                //             return (
                //               <li key={index}>
                //                 <span className="day">
                //                   {weekDays[data?.day]}
                //                 </span>
                //                 <p className="schedule">
                //                   {data?.range &&
                //                     data.range.length > 0 &&
                //                     data.range.map((rangeData) => {
                //                       return (
                //                         <React.Fragment key={rangeData._id}>
                //                           <span>
                //                             {rangeData?.startTime || "---"}
                //                           </span>{" "}
                //                           -{" "}
                //                           <span>
                //                             {rangeData?.endTime || "---"}
                //                           </span>
                //                         </React.Fragment>
                //                       );
                //                     })}
                //                 </p>
                //               </li>
                //             );
                //           }
                //         )}
                //     </ul>
                //   </div>
                // ) :
                <div className="highlight table_radius availability">
                  <div class="_sub_title">
                    <h4>Availabilities</h4>
                  </div>
                  <ul className="days">
                    <li>
                      <b>Days - </b>
                    </li>
                    <li>
                      {" "}
                      {expertServicesDetail?.subscriptionAvailability?.days.map(
                        (data, index) => (
                          <span className="day">
                            {/* {index === 0 ? "" : " , "} */}
                            {weekDays[data]}
                          </span>
                        )
                      )}
                    </li>
                  </ul>
                  <ul className="content">
                    <li>
                      <p className="text-center">
                        {!expertServicesDetail?.subscriptionAvailability?.range
                          .length && "No Services Found"}
                      </p>
                    </li>

                    <li>
                      <b>Time -</b>
                      <p>
                        {expertServicesDetail?.subscriptionAvailability
                          ?.range &&
                          expertServicesDetail?.subscriptionAvailability?.range
                            .length > 0 &&
                          expertServicesDetail?.subscriptionAvailability?.range.map(
                            (rangeData, index) => {
                              return (
                                <>
                                  {/* <span className="day">{weekDays[data?.day]}</span> */}
                                  <span className="schedule" key={index}>
                                    <React.Fragment key={rangeData._id}>
                                      <span>
                                        {rangeData?.startTime || "---"}
                                      </span>{" "}
                                      -{" "}
                                      <span>{rangeData?.endTime || "---"}</span>
                                    </React.Fragment>
                                  </span>
                                </>
                              );
                            }
                          )}
                      </p>
                    </li>
                  </ul>
                </div>
                //  )
              }
              <div className="content_left has_space">
                <h5 className="_title">Overview</h5>
                <div className="content">
                  {expertServicesDetail?.description ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expertServicesDetail.description,
                      }}
                    ></p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </div>

              {expertServicesDetail?.alreadyBooked &&
              Object.keys(expertServicesDetail?.alreadyBooked).length > 0 ? (
                <Placeholder text={"You already purchase this subscription"} />
              ) : (
                <div className="content_right">
                  <h5 className="_title">When can we arrange to meet?</h5>
                  <div className="content">
                    {expertServicesDetail.subscriptionType !== "recurring" && (
                      // <div className="date_listing">
                      //   <h4>Select Date</h4>
                      //   <ul>
                      //     {getWeekDatesInRange() &&
                      //       getWeekDatesInRange().length &&
                      //       getWeekDatesInRange().map((data) => (
                      //         <li>
                      //           <button
                      //             type="button"
                      //             className={
                      //               selectedDate === data.isoDate ? 'active' : ''
                      //             }
                      //             onClick={() => {
                      //               setBookingDate(data?.isoDate)
                      //               setSelectedDate(data.isoDate)
                      //               fetchingExpertServicesDetailSlot(data)
                      //             }}
                      //           >
                      //             {data?.formattedDate}
                      //           </button>
                      //         </li>
                      //       ))}
                      //   </ul>
                      // </div>
                      <></>
                    )}
                    <div className="time_listing">
                      <h4>Select Time Schedule</h4>
                      {expertServicesDetail?.subscriptionType === "oneTime" ? (
                        <>
                          <h6>
                            Your service will be active from{" "}
                            {formatDate(
                              expertServicesDetail?.subscriptionAvailability
                                ?.startDate
                            )}
                            , until{" "}
                            {formatDate(
                              expertServicesDetail?.subscriptionAvailability
                                ?.endDate
                            )}
                          </h6>
                        </>
                      ) : // : expertServicesDetail?.subscriptionType ===
                      //   "recurring" ? (
                      //   <h6>
                      //     Your service will be active from{" "}
                      //     {formatDate(
                      //       expertServicesDetail?.subscriptionAvailability
                      //         ?.startDate
                      //     )}
                      //   </h6>
                      // )
                      null}
                      <ul>
                        {/* {expertServicesDetail?.recurringServiceType &&
      expertServicesDetail?.recurringServiceType ===
        "private" ? (
        expertServicesDetail.privateSubscriptionAvailability
          ?.availabilities[0] ? (
          expertServicesDetail?.privateSubscriptionAvailability
            ?.availabilities[0]?.range.length > 0 ? (
            expertServicesDetail?.privateSubscriptionAvailability?.availabilities[0]?.range.map(
              (data) =>
                data.isAvailable && (
                  <li key={data.startTime}>
                    <button
                      type="button"
                      className={`${
                        selectedTime === data?.startTime
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        setSlotTime({
                          startTime: data?.startTime,
                          endTime: data?.endTime,
                          duration:
                            expertServicesDetail?.duration,
                          _id: data._id,
                        });
                        setSelectedTime(data?.startTime);
                      }}
                    >
                      {`${data?.startTime}-${data?.endTime}`}
                    </button>
                  </li>
                )
            )
          ) : (
            <div className="text-center">
              No Slot Found on this date
            </div>
          )
        ) : (
          <>Select Date for booking slot</>
        )
      ) :  */}
                        {expertServicesDetail.subscriptionAvailability
                          ?.range ? (
                          expertServicesDetail?.subscriptionAvailability.range
                            .length > 0 ? (
                            expertServicesDetail?.subscriptionAvailability?.range.map(
                              (data) =>
                                data.isAvailable && (
                                  <>
                                    <li key={data.startTime}>
                                      <button
                                        type="button"
                                        className={`${
                                          selectedTime === data?.startTime
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setSlotTime({
                                            startTime: data?.startTime,
                                            endTime: data?.endTime,
                                            duration:
                                              expertServicesDetail?.duration,
                                            _id: data._id,
                                          });
                                          setSelectedTime(data?.startTime);
                                        }}
                                      >
                                        {`${data?.startTime}-${data?.endTime}`}
                                      </button>
                                    </li>
                                  </>
                                )
                            )
                          ) : (
                            <div className="text-center">
                              No Slot Found on this date
                            </div>
                          )
                        ) : (
                          <>Select Date for booking slot</>
                        )}
                      </ul>
                    </div>

                    {/* This Button Show Only To User */}

                    {(personalInfo?.currentType === 1 ||
                      !personalInfo?.token) && (
                      <div className="button_wrap">
                        <Button
                          onClick={() => {
                            !slotTime?.startTime &&
                            !slotTime?.endTime &&
                            !slotTime?.duration
                              ? toastMessage(
                                  "Please select slot",
                                  "error",
                                  "slotBooking"
                                )
                              : setPayoutModal(true);
                          }}
                        >
                          Book Now
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* <VideoPlayer /> */}
            <VideoPlayer src={expertServicesDetail?.video || "---"} />

            {bestTestimonials && bestTestimonials.length > 0 && (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {bestTestimonials &&
                      bestTestimonials.map((item) => {
                        return (
                          <TestimonialCard
                            bestTestimonials={bestTestimonials}
                            setBestTestimonials={setBestTestimonials}
                            display={false}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        // serviceTag={
        //   expertServicesDetail.subscriptionType
        //     ? "Subscription"
        //     : "1:1 Consultation"
        // }
        serviceTag={"Subscription"}
        serviceTittle={expertServicesDetail?.title || "---"}
        duration={`${expertServicesDetail?.duration || "---"}(mins)`}
        price={expertServicesDetail?.pricing?.totalAmount || "---"}
        // date={
        //   dateFormat(bookingDate) ||
        //   `${formatDate(
        //     expertServicesDetail?.subscriptionAvailability?.startDate
        //   )} ${expertServicesDetail.subscriptionType === "oneTime" &&
        //   " - " +
        //   formatDate(
        //     expertServicesDetail?.subscriptionAvailability?.endDate
        //   )
        //   }` ||
        // "---"
        // }
        date="---"
        time={slotTime?.startTime || "---"}
        buttonText={"Process to Pay"}
        onClick={BookOneToOneHandler}
        isSubscriptionModel={
          true && expertServicesDetail.subscriptionType === "recurring"
        }
        handleAutoPay={() => handleAutoPay(autoPay)}
        autoPay={autoPay}
        loading={loading}
      /> */}

      {/*Common Payout Modal Component */}
      <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        // serviceTag={"Subscription"}
        serviceTag= {expertServicesDetail?.subscriptionType === "recurring"
        ? expertServicesDetail?.recurringServiceType === "public"
          ? "Group Training"
          : "Personal Training"
        : "Batch Training"}
        serviceTittle={expertServicesDetail?.title || "---"}
        duration={`${expertServicesDetail?.duration || "---"}(mins)`}
        price={expertServicesDetail?.pricing?.totalAmount || "---"}
        // date={dateFormat(bookingDate) || "---"}
        time={(slotTime && convertTo12HourFormat(slotTime?.startTime)) || "---"}
        buttonText={"Process to Pay"}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        authModal={authModal}
        setAuthModal={setAuthModal}
        loading={loading}
        serviceId={serviceId}
        expertId={expertId}
        expertServicesDetail={expertServicesDetail}
        token={personalInfo?.token}
        setPayoutModal={setPayoutModal}
        payoutModal={payoutModal}
        setPaymentStatusModal={setPaymentStatusModal}
        slotTime={slotTime}
        bookingDate={bookingDate}
        endingDate={endingDate}
        days={expertServicesDetail?.subscriptionAvailability?.days}
        // bookingEndDate={addDateDuration(
        //   expertServicesDetail?.webinarAvailability,
        //   expertServicesDetail?.duration
        // )}
      />

      <SuccessPayment
        paymentStatusModal={paymentStatusModal}
        setPaymentStatusModal={setPaymentStatusModal}
        expertId={expertId}
        serviceType="subscription"
      />
    </>
  );
};

export default UserSubscription;
