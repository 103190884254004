import moment from "moment-timezone";

const getTimezone = () => {
  try {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return timeZone;
  } catch (error) {
    console.error("Error retrieving timezone:", error);
  }
};

export const dateFormat = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("DD MMM YYYY") : "";
};

// Date Format in Month , Date ,Year , Week
export const dateFormatMDYW = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("MMMM ,D,YYYY,dddd") : "";
};

// In year month Date format
export const dateFormatYear = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("YYYY-MM-DD") : "";
};


// Finding Day on that date
export const gettingDay = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("dddd") : "";
};

// Just Year
export const yearFormat = (date) => {
  const timezone = getTimezone();
  return date ? moment(date).tz(timezone).format("YYYY") : "";
};

// in utc

export const dateFormatUtc = (date) => {
  const timezone = getTimezone();
  return date
    ? moment(date).tz(timezone).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : "";
};

export const dateFormatWithTime = (date) => {
  // const timezone = getTimezone();
  // return date ? moment(date).tz(timezone).format("DD MMM YYYY, hh:mm A") : "";
  return date ? moment(date).format("DD MMM YYYY, hh:mm A") : "";
};

export const timeFormat = (time) => {
  const timezone = getTimezone();
  return time ? moment(time).tz(timezone).format("hh:mm A") : "";
};

// convert time into complete time zone
// export const completeTimeFormat = (time) => {
//   const timezone = getTimezone();
//   if (!time) return "";

//   // Parse the given time string as a time today in the user's timezone
//   const today = moment.tz(timezone).startOf("day"); // Get the start of today in the user's timezone
//   const timeInLocal = moment.tz(
//     today.format("YYYY-MM-DD") + " " + time,
//     "YYYY-MM-DD HH:mm",
//     timezone
//   );


export const completeTimeFormat = (time) => {
  const timezone = getTimezone(); // Assume this function returns the user's timezone
  if (!time) return ""; // Return empty string if time is invalid

  // Parse the given time string and set it as today's time in the user's timezone
  const today = moment.tz(timezone).startOf("day"); // Get the start of today in the user's timezone

  // Combine today's date with the given time in "HH:mm" format
  return  moment.tz(`${today.format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD HH:mm", timezone);


  // // Convert to a complete ISO string with the timezone offset
  // const formattedTime = timeInLocal.format();

  // return formattedTime;
};

// Merge Date & Time
export const mergeTimeDate = (date = "", time = "") => {
  const dateStr = date.trim();
  const timeStr = time.trim();
  const dateTimeStr = `${dateStr} ${timeStr}`;
  const dateTime = new Date(dateTimeStr);
  if (isNaN(dateTime.getTime())) {
    throw new Error("Invalid date or time format");
  }
  return dateTime.toISOString();
};

// 24 hour time format
export const timeFormat_24 = (time) => {
  const timezone = getTimezone();
  return time ? moment(time).tz(timezone).format("HH:mm") : "";
};

export function timeFormat24(time=""){
  const time2 = new Date(time).toLocaleString("en-US", {
    timeZone: "UTC",
  });
  const timezone = getTimezone();
  return time2 ? moment(time2).tz(timezone).format("HH:mm") : "";
}

export const convert12To24Hour = (time12h="") => {
  const [time, modifier] = time12h?.split(' ');  // Split the time and AM/PM

  let [hours, minutes] = time.split(':');       // Split hours and minutes

  
  // Convert to 24-hour format
  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  } else if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  return `${hours}:${minutes}`;
};

export function convert24To12Hour(time24h) {
  // Split the time into hours and minutes
  let [hours, minutes] = time24h.split(':');
  hours = parseInt(hours);

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;  // Converts 0 to 12 for midnight and converts 13-23 to 1-11

  // Format hours and minutes as two-digit strings if needed
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

// full time format
export const fullTimeFormat = (time) => {
  const timezone = getTimezone();
  return time ? moment(time).tz(timezone).format("HH:mm:ss") : "";
};

// Get upcoming 7 days using moment
export const getUpcomingWeekDates = () => {
  const timezone = getTimezone();
  const today = moment().tz(timezone);
  return Array.from({ length: 7 }, (_, i) => {
    const date = today.clone().add(i, "days");
    return {
      id: Date.now(),
      formattedDate: date.format("ddd, DD MMM"),
      isoDate: date.format("YYYY-MM-DD"),
      weekDay: date.format("dddd"),
      WeekInNumber: date.format("e"),
    };
  });
};

// get week days between two days function
export const getWeekDatesInRange = (startDate, endDate) => {
  const timezone = getTimezone();
  const start = moment(startDate).tz(timezone);
  const end = moment(endDate).tz(timezone);

  const dates = [];
  let currentDate = start.clone();

  while (currentDate.isSameOrBefore(end, "day")) {
    dates.push({
      id: Date.now(), // or use a more robust unique ID generation method
      formattedDate: currentDate.format("ddd, DD MMM"),
      isoDate: currentDate.format("YYYY-MM-DD"),
      weekDay: currentDate.format("dddd"),
      WeekInNumber: currentDate.format("e"),
    });
    currentDate.add(1, "day");
  }

  return dates;
};

// Get Current Time Formatted
export const getCurrentTimeFormatted = () => {
  const timezone = getTimezone();
  const now = moment().tz(timezone);
  return now.format("HH:mm");
};

// Getting whether webinar start or end function
// export const servicesStartingChecker = (date, minute = null, day = null) => {
//   const timezone = getTimezone();
//   const current = moment().tz(timezone);
//   const startDate = moment(date).tz(timezone);
//   let endDate = moment().tz(timezone);
//   if (minute) {
//     endDate = moment(date).add(minute, "minutes");
//   }
//   if (day) {
//     endDate = startDate.add(day, "days");
//   }

//   console.log(startDate, endDate);

//   if (current.isBetween(startDate, endDate, null, "[]")) {
//     return true;
//   } else {
//     return false;
//   }
// };

export const servicesStartingChecker = (date, minute = null, day = null) => {
  const timezone = getTimezone();
  const current = moment().tz(timezone);
  // console.log(date.toISOString(),"endDate")
  const startDate = moment(date).tz(timezone);
  // console.log(minute)

  let endDate;

  if (minute) {
    endDate = moment(startDate).add(minute, "minutes");
    return current.isBetween(startDate, endDate, null, "[]");
  } else if (day) {
    const daysDifference = startDate.diff(current, "days");
    return daysDifference >= day;
  }

  return false;
};

export const compareDates = (date, minute = null, day = null) => {
  const timezone = getTimezone();
  const current = moment().tz(timezone);
  const startDate = moment(date).tz(timezone);

  let endDate;

  if (minute) {
    endDate = moment(startDate).add(minute, "minutes");
    return current.isBetween(startDate, endDate, null, "[]")
      ? "Start Consultation"
      : "";
  } else if (day) {
    const daysDifference = startDate.diff(current, "days");
    return daysDifference >= day ? "Cancel Consultation" : "";
  } else return "Scheduled";
};

// For just subscription
export const startSubscriptionChecker = (
  startDate,
  endDate,
  checkDate,
  duration
) => {
  const timezone = getTimezone();
  const current = moment(moment().tz(timezone)).startOf("day");
  const start = moment(startDate).tz(timezone).startOf("day");
  const end = moment(endDate).tz(timezone).endOf("day");

  // Check if current date is within the startDate and endDate
  if (current.isBetween(start, end, null, "[]")) {
    const value = servicesStartingChecker(checkDate, duration, null);
    return value;
  } else {
    // console.log("End of Subscription");
    return false; // End of subscription period
  }
};

export function addDurationToTime(startTime, duration) {
  // console.log(startTime,"StartTime",duration,"Duration");
  // Parse the start time string into a moment object
  const startMoment = moment(startTime);

  // Add the duration (in minutes) to the start moment
  const endMoment = startMoment.add(duration, "minutes");

  // Format the end moment to the desired string format
  const endDateString = endMoment.format("ddd MMM DD YYYY HH:mm:ss Z");
  // console.log(endDateString,"EndDateString");

  return endDateString;
}

// export function addDateDuration(startTime,duartion){
//   const time2 = new Date(startTime).toLocaleString("en-US", {
//     timeZone: "UTC",
//   });
//   return moment.add(time2).("minute"),duration
// }

export function addDateDuration(startTime, duration) {
  // Create a moment object from the startTime and keep it in UTC
  const time = moment(startTime).utc();
  return time.add(duration, 'minutes').toISOString(); // Return the result in ISO 8601 format
}

export function convertTo12HourFormat(time) {
  if (time) {
    // Split the time into hours and minutes
    let [hour, minute] = time.split(":");

    // Convert hour from string to number
    hour = parseInt(hour, 10);

    // Determine AM/PM suffix
    const ampm = hour >= 12 ? "PM" : "AM";

    // Convert hour to 12-hour format
    hour = hour % 12 || 12; // Convert '0' hour to '12' for 12-hour format

    // Return formatted time
    return `${hour}:${minute} ${ampm}`;
  }
}

export function setDynamicTime12Hour(time12hr, date) {

  const parsedDate = moment(date, "YYYY-MM-DD");

  const dateTimeWithDynamicTime = moment(time12hr, "h:mm").set({
    year: parsedDate.year(),
    month: parsedDate.month(),
    date: parsedDate.date(),
  });
  const dateTimeInUTC = dateTimeWithDynamicTime.toISOString();
  return dateTimeInUTC;
}

// Merge date and time for subscription
export function mergeSubscriptionTimeDate(date, time) {
  // Parse the date as a UTC moment object
  let momentDate = moment(new Date());
  if (date) {
    momentDate = moment.utc(date);
  }

  // Parse the time in "HH:mm" format
  const [hours, minutes] = time.split(":").map(Number);

  // Set the hours and minutes on the moment object
  momentDate.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

  // Return the merged date-time as a UTC moment object
  return momentDate;
}

// Dashboard Filter For Months
export const dashboardFilter = () => {
  const timezone = getTimezone();

  const endDate = moment().tz(timezone).endOf("day");

  const dateRanges = [
    {
      name: "Today",
      value: "today",
      fromDate: moment().tz(timezone).startOf("day"),
      endDate: endDate,
    },
    {
      name: "Yesterday",
      value: "yesterday",
      fromDate: moment().tz(timezone).subtract(1, "day").startOf("day"),
      endDate: endDate,
    },

    {
      name: "Last 7 Days",
      value: "last7Days",
      fromDate: moment().tz(timezone).subtract(7, "days").startOf("day"),
      endDate: endDate,
    },
    // {
    //   name: "Last Month",
    //   value: "lastMonth",
    //   fromDate: moment().tz(timezone).subtract(1, "month").startOf("month"),
    //   endDate: endDate,
    // },
    {
      name: "Last Month",
      value: "lastMonth",
      fromDate: moment().tz(timezone).subtract(1, "month").startOf("month"),
      endDate: moment().tz(timezone).subtract(1, "month").endOf("month"),
    },
    {
      name: "Current Month",
      value: "currentMonth",
      fromDate: moment().tz(timezone).startOf("month"),
      endDate: endDate,
    },
    {
      name: "Life Time",
      value: "lifetime",
      fromDate: moment.tz("1900-01-01", timezone).startOf("day"),
      endDate: endDate,
    },
    {
      name: "Custom Range",
      value: "customRange",
    },
  ];

  return dateRanges;
};
