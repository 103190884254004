import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "config/axiosInstance";

// Get Expert Transaction List API
export const getExpertTransactionListApi = async (data) => {
  const endPoint =
    `expert/transaction/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};



// Post Authorize Razorpay
export const authorizeRazorpayApi = async () => {
  const endPoint = `expert/transaction/enable-payouts`;

  return await axios.post(endPoint, {}, setJwtToken());
};
