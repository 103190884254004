import React, { useState } from 'react';
import './threeDotLoader.scss';

const FullPageLoader = () => {
  const [loading, setLoading] = useState(false);

  const showLoader = () => setLoading(true); // Show loader
  const hideLoader = () => setLoading(false); // Hide loader

  return [
    loading ? (
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    ) : null,
    showLoader,
    hideLoader,
  ];
};

export default FullPageLoader;
