import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../../config/axiosInstance";

// Get Priority List API
export const getUserPiorityListApi = async (data) => {
  const endPoint =
    `user/priority-dm/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get Webinar List API
export const getWebinarListApi = async (data) => {
  const endPoint =
    `user/webinar/get-list` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get Consultant List API
export const getConsultantListApi = async (data) => {
  const endPoint =
    `user/one-to-one-consultation/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

export const getDigitalListApi = async (data) => {
  const endPoint =
    `user/digital-content/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get Subscription Listing API

export const getSubscriptionListApi = async (data) => {
  const endPoint =
    `user/subscription/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};
