import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Get Notification API
export const getNotificationApi = async (data) => {
  const endPoint =
    `expert/notification/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Mark All As Read
export const markAllAsReadApi = async () => {
  const endPoint = `expert/notification/mark-all-as-read`;
  return await axios.patch(endPoint, {}, setJwtToken());
};

// Mark  As Read
export const markAsReadApi = async (data) => {
  const endPoint = `expert/notification/mark-read`;
  return await axios.patch(endPoint, data, setJwtToken());
};

// New Notification ALert
export const newNotificationAlert = async () => {
    const endPoint = `expert/notification/new-alert`;
    return await axios.get(endPoint, setJwtToken());
  };
