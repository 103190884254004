// import React, { useState } from 'react'
// import { extractVideoId } from 'utils/extractVideoId'

// const YoutubeVideoPlayer = ({ src, height = '315', width = '560' }) => {
//   const [error, setError] = useState(false)

//   const extractedSrc = extractVideoId(src)

//   // console.log('extracted src', extractedSrc)

//   const handleOnError = () => {
//     setError(true)
//   }

//   return (
//     <>
//       {error ? (
//         <p>Sorry, there was an error playing the video.</p>
//       ) : (
//         <iframe
//           width={width}
//           height={height}
//           src={`https://www.youtube.com/embed/${extractedSrc}`}
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//           allowFullScreen
//           onError={handleOnError}
//         ></iframe>
//       )}
//     </>
//   )
// }

// export default YoutubeVideoPlayer

import { Skeleton } from "antd";
import React, { useState } from "react";
import { extractVideoId } from "utils/extractVideoId";

const VideoPlayer = ({ src, height = "315", width = "100%" }) => {


  const [error, setError] = useState(false);

  const extractedSrc = extractVideoId(src);

  const handleOnError = () => {
    setError(true);
  };

  let videoSrc = "";
  if (extractedSrc?.type === "youtube") {
    videoSrc = `https://www.youtube.com/embed/${extractedSrc.id}`;
  } else if (extractedSrc?.type === "vimeo") {
    videoSrc = `https://player.vimeo.com/video/${extractedSrc.id}`;
  }

  return (
    <>
      {error ? (
        <p>Sorry, there was an error playing the video.</p>
      ) : (
        videoSrc && (
          <iframe
            width={width}
            height={height}
            src={videoSrc}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            onError={handleOnError}
            className="video_player"
          ></iframe>
        )
      )}
    </>
  );
};

export default VideoPlayer;
