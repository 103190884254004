import React, { useEffect, useState } from "react";
import {
  banner1,
  expert1Img,
  imagePlaceholder,
  service1,
  serviceImg1,
} from "assets/images";
// import VideoPlayer from "components/video-player/VideoPlayer";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button } from "components/form";
import CustomModal from "components/modals/CustomModal";
// import "./PriorityDM.scss";
import { useParams } from "react-router-dom";
import { toastMessage } from "utils/toastMessage";
import {
  bookOneToOneApi,
  getExpertServiceDetailApi,
} from "services/userServices/servicesDetail&Booking";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import VideoPlayer from "common/YouTubeVideoPlayer";
import Placeholder from "components/placeholder/Placeholder";

const ConsultationDetail = () => {
  const [payoutModal, setPayoutModal] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const { expertId, serviceId } = useParams();
  const [loader, onShow, onHide] = FullPageLoader();
  const [loading, setLoading] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [selectedPackage, setSelectedPackage] = useState({});
  const [bestTestimonials, setBestTestimonials] = useState([]);

  // fetch package detail
  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        setExpertServicesDetail(data?.data);
        setBestTestimonials(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // book Package
  const BookOneToOneHandler = async () => {
    // console.log(slotTime, 'slotTime')
    try {
      setLoading(true);
      const { data } = await bookOneToOneApi({
        _id: serviceId,
        // bookedFor: mergeTimeDate(bookingDate, slotTime?.startTime),
        amount: expertServicesDetail?.pricing?.amount,
        totalAmount: expertServicesDetail?.pricing?.totalAmount,
        transactionFeePercentage:
          expertServicesDetail?.pricing?.transactionFeePercentage,
        // slot: slotTime,
        // isAutoPay: autoPay,
      });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "BookOneToOne");
        setPayoutModal(false);
        // setPaymentStatusModal(true)
      } else {
        toastMessage(data?.message, "error", "BookOneToOne");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);

  return (
    <>
      {loader}
      <div className="services_page priorityDM">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img
                  src={expertServicesDetail?.image || service1}
                  alt={expertServicesDetail?.title}
                />
                <div className="content">
                  <h4>{expertServicesDetail?.title || "---"} </h4>
                  <p className="duration">
                    {/* <span>Promised response</span>{' '} */}
                    <b>
                      {" "}
                      {`${
                        expertServicesDetail?.duration
                          ? `(${expertServicesDetail?.duration} mins)`
                          : ""
                      }`}
                    </b>{" "}
                  </p>
                  {/* <p className="price">
                    <del>₹ 600</del> <b>₹ 500</b>
                  </p> */}
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail?.pricing?.slashAmount)}
                        </del>
                        <b>
                          ₹{" "}
                          {
                            // Number(expertServicesDetail?.pricing?.amount) -
                            Number(expertServicesDetail?.pricing?.amount)
                          }
                        </b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>
              <div className="banner_tag">
                {/* <Button
                  className={'dashboard_button'}
                  onClick={() => setPayoutModal(true)}
                >
                  Buy Now
                </Button> */}
                {/* {expertServicesDetail?.alreadyBooked ? (
                  <p>You Already Booked This Package</p>
                ) : (
                  <Button
                    className={"dashboard_button"}
                    onClick={() => setPayoutModal(true)}
                  >
                    Book Now
                  </Button>
                )} */}
              </div>
            </div>
            <div className="service_content">
              <div className="content_left">
                <h5 className="_title">Overview</h5>
                <div className="content">
                  {/* <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna.
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem. Ut enim
                    ad minima veniam, quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut aliquid ex ea commodi
                    consequatur?
                  </p>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem. Ut enim
                    ad minima veniam, quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut aliquid ex ea commodi
                    consequatur?
                  </p> */}
                  {expertServicesDetail?.description ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expertServicesDetail.description,
                      }}
                    ></p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </div>
            </div>
            <div className="packages">
              <h5>1:1 Consultations</h5>

              <ul>
                {expertServicesDetail?.services &&
                  expertServicesDetail?.services.length > 0 &&
                  expertServicesDetail?.services.map((currentService) => {
                    return (
                      <li>
                        <p>
                          <b>{currentService?.service?.title || "---"}</b>
                          {/* <del>₹600</del> <span>₹500</span>{' '} */}
                          {currentService.service?.pricing
                            ?.showSlashedAmount ? (
                            <>
                              <del>
                                ₹{" "}
                                {Number(
                                  currentService.service?.pricing?.amount
                                )}
                              </del>
                              <b>
                                ₹{" "}
                                {Number(
                                  currentService.service?.pricing?.amount
                                ) -
                                  Number(
                                    currentService.service?.pricing?.slashAmount
                                  )}
                              </b>
                            </>
                          ) : (
                            <>
                              ₹{" "}
                              {Number(currentService.service?.pricing?.amount)}
                            </>
                          )}
                          <span className="tag">1 Used, 1 Remaining</span>
                        </p>
                        {!expertServicesDetail.alreadyBooked && (
                          <Button
                            className={"dashboard_button"}
                            onClick={() => setPayoutModal(true)}
                          >
                            Book Now
                          </Button>
                        )}
                      </li>
                    );
                  })}

                {/* <li>
                  <p>
                    <b>Digital Content Organization</b>
                    <del>₹600</del> <span>₹500</span>{' '}
                  </p>
                  <Button
                    className={'dashboard_button'}
                    onClick={() => setPayoutModal(true)}
                  >
                    Access
                  </Button>
                </li> */}
              </ul>
            </div>
            {/* <VideoPlayer /> */}
            {expertServicesDetail?.video && (
              <VideoPlayer src={expertServicesDetail?.video || ""} />
            )}

            {bestTestimonials && bestTestimonials.length > 0 && (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {/* {testimonial &&
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          key={item.id}
                          title={item.title}
                          date={item.date}
                          description={item.description}
                          deleteButton={false}
                        />
                      );
                    })} */}
                    {bestTestimonials && bestTestimonials.length ? (
                      bestTestimonials.map((item) => {
                        return (
                          <TestimonialCard
                            bestTestimonials={bestTestimonials}
                            setBestTestimonials={setBestTestimonials}
                            display={false}
                          />
                        );
                      })
                    ) : (
                      <Placeholder text="No testimonial available for this service" />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        className={"confirm_pay priority"}
      >
        <h3>Confirm and pay</h3>
        <div className="content_wrap">
          <div className="_card">
            <img src={imagePlaceholder} alt="" />
            <div className="content">
              <span>Priority DM</span>
              <h5>{expertServicesDetail?.title || "---"} </h5>
              <p>
                Promised response <b> (1 Day)</b>
              </p>
              <p>
                <b className="color-green">{`₹${
                  expertServicesDetail?.pricing?.totalAmount || "---"
                }`}</b>{" "}
                (Include taxes)
              </p>
            </div>
          </div>
          <div className="button_wrap">
            <Button
              className={"w-100 dashboard_button"}
              onClick={BookOneToOneHandler}
            >
              Process to Pay
            </Button>
          </div>
        </div>
      </CustomModal>

      {/* <CustomModal
        show={paymentStatusModal}
        handleClose={() => setPaymentStatusModal(false)}
      >
        <div className="text-center">
          <h3>Payment successfully</h3>
          <p>
            Thank you for your payment. Your booking has been successfully
            processed.
          </p>
          <div className="button_wrap button_center">
            <Button onClick={() => setPaymentStatusModal(false)}>OK</Button>
          </div>
        </div>
      </CustomModal> */}
    </>
  );
};

export default ConsultationDetail;
