import { RatingStar } from "components/form";
import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const ExpertCard = ({
  profileImage,
  expertName,
  serviceName,
  rating,
  users,
  key,
  // total,
  url,
}) => {
  const ratings = Number(rating);

  return (
    <>
      <div className="item" key={key || 1}>
        <div className="expertCard">
          <Link to={url}>
            <div className="image_wrap">
              <img src={profileImage} alt={expertName} />
              
            </div>
            <div className="content">
                <h4>{expertName}</h4>
                <RatingStar
                  user={users}
                  rating={ratings}
                  readonly
                  initialRating={ratings}
                />
                <p>{serviceName}</p>
              </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ExpertCard;
