import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Dashboard Booking API
export const dashboardBookingApi = async () => {
  return await axios.get(`expert/dashboard/bookings`, setJwtToken());
};

// Dashboard Grapgh API
export const dashboardYearlyGraphApi = async (data) => {
  const endPoint =
    `expert/dashboard/earnings-graph` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Todays Booking
export const todayBookingApi = async (data) => {
  const endPoint =
    `expert/dashboard/todays-booking` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Pie Chart 
export const pieChartDataApi = async (data) => {
  const endPoint =
    `expert/dashboard/earnings-pie` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Add/Update Pixel Ids
export const addPixelApi = async (data) => {
  const endPoint =
    `expert/update-pixel`
  return await axios.put(endPoint,data, setJwtToken());
};

// fetching Pixel IDs
export const fetchingPixelApi = async (data) => {
  const endPoint =
    `expert/details-by-slug/${data}`
  return await axios.get(endPoint, setJwtToken());
};
