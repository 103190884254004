import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from "../../config/axiosInstance";
  
  // Get Webinar List API
  export const getWebinarApi = async (data) => {
    const endPoint =
      `expert/webinar/get-list` +
      "?" +
      new URLSearchParams({ ...data }).toString();
    return await axios.get(endPoint, setJwtToken());
  };
  
  // Start Webinar
  export const starWebinarApi = async (data) => {
    const endPoint = `expert/webinar/start`;
    return await axios.put(endPoint, data, setJwtToken());
  };
    