import React from "react";
import CustomModal from "./CustomModal";
import { Button } from "components/form";
import "./Modal.scss";
const LogoutModal = ({ show, handleClose,action }) => {
  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        className={"logoutModal"}
      >
      
        <h3>You're About To Be Signed Out</h3>
        <p>
          for security reasons, your connection times out after your’ve been
          inactive for a while. check logout to stay signed in
        </p>

        <div className="button_wrap button_center">
          <Button className={"light"} onClick={handleClose}>Cancel</Button>
          <Button onClick={action}>Logout</Button>
        </div>
      </CustomModal>
    </>
  );
};

export default LogoutModal;
