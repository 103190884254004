export const base64ToFile = (url, name) => {
    let arr = url.split(',')
    // console.log(arr)
    let mime = arr[0].match(/:(.*?);/)[1]
    let data = arr[1]
  
    let dataStr = atob(data)
    let n = dataStr.length
    let dataArr = new Uint8Array(n)
  
    while (n--) {
      dataArr[n] = dataStr.charCodeAt(n)
    }
  
    let file = new File([dataArr], name, { type: mime })
  
    return file
  }
  