import React, { useState } from "react";
import "../auth.scss";
import LoginModal from "./section/LoginModal";
import OTPScreen from "./section/OTPScreen";
import { useDispatch } from "react-redux";
import { setLoginModalVisible } from "store/slices/configSlice";

const AuthLogin = ({ moveSignUp, setLoginModal, showSignUp }) => {
  const [loginStep, setLoginStep] = useState("1");
  const [loginData, setLoginData] = useState({});

  const sendOtpNextHandler = (data = {}) => {
    setLoginData(data);
    setLoginStep("2");
  };

  const closeModal = (data) => {
    setLoginModal(data);
  };

  return (
    <>
      {loginStep === "1" && (
        <LoginModal
          sendOtp={sendOtpNextHandler}
          moveSignUp={moveSignUp}
          showSignUp={showSignUp}
        />
      )}

      {loginStep === "2" && (
        <OTPScreen
          loginData={loginData}
          closeModal={closeModal}
          showSignUp={showSignUp}
        />
      )}
    </>
  );
};

export default AuthLogin;
