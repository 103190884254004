import { isDisabled } from "@testing-library/user-event/dist/utils";
import { pdfIcon } from "assets/icons";
import { profileImg } from "assets/images";
import { Button, LoadingButton, TextArea } from "components/form";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import ConsultationList from "pages/bookings/consultation/ConsultationList";
import React, { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import {
  addNoteApi,
  cancelOneToOne,
  startOneToOneApi,
} from "services/oneToOne";
import { startSubscriptionApi } from "services/subscription";
import {
  compareDates,
  convertTo12HourFormat,
  dateFormat,
  mergeSubscriptionTimeDate,
  // mergeSubscriptionTimeDate,
  mergeTimeDate,
  servicesStartingChecker,
  startSubscriptionChecker,
  timeFormat,
} from "utils/dateFormat";
import { regex } from "utils/formValidator";
import { toastMessage } from "utils/toastMessage";

const UpcomingSubscription = ({
  setSubscriptionList,
  subscriptionList,
  total,
  handlePageChange,
  bookingStatus,
  fetchingSubscriptionList,
  subscriptionType,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useSearchParams();
  const [id, setId] = useState();
  const [startConsultantLoading, setStartConsultantLoading] = useState(false);
  const [cancelOneToOneModal, setCancelOneToOneModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [attached, setAttached] = useState(false);
  const [error, setError] = useState("");
  const { pdfFileRegex } = regex;

  const [addNoteData, setAddNoteData] = useState({});

  // console.log(searchParam.get("bookingStatus"));

  // console.log('bookingStatus', bookingStatus)

  // Cancel oneToOne Handler
  const cancelConsultationHandler = async () => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await cancelOneToOne({
          _id: id,
          serviceType: "oneToOne",
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "cancelConsultation");
          fetchingSubscriptionList();
          setId("");
          setCancelOneToOneModal(false);
        } else {
          toastMessage(data?.message, "error", "cancelConsultation");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Note oneToOne Handler
  const NoteConsultationHandler = async () => {
    if (id) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("text", addNoteData.text);
        formData.append("_id", id);
        if (addNoteData?.attachment?.name) {
          formData.append("attachment", addNoteData.attachment);
        }

        const { data } = await addNoteApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "noteAdd");
          fetchingSubscriptionList();
          setAddNoteData("");
          setId("");
          setNoteModal(false);
        } else {
          toastMessage(data?.message, "error", "noteAdd");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Start subscription Handler
  const startSubscriptionHandler = async (_id) => {
    if (_id) {
      try {
        const { data } = await startSubscriptionApi({
          _id: _id,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "startSubscriptionHandler");
          fetchingSubscriptionList();
        } else {
          toastMessage(data?.message, "error", "startSubscriptionHandler");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setStartConsultantLoading(false);
      }
    }
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setAddNoteData({ ...addNoteData, attachment: e.target.files[0] });
      } else {
        e.target.value = "";
        setAddNoteData({ ...addNoteData, attachment: "" });
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setAddNoteData({ ...addNoteData, attachment: "" });
    }
  };

  // console.log(bookingStatus, "BookingStatus");

  return (
    <React.Fragment key={Date.now()}>
      <div className="card_wrap">
        {subscriptionList && subscriptionList.length ? (
          subscriptionList.map((data, index) => {
            // console.log('subscriptionList', subscriptionList)
            return (
              <React.Fragment key={index}>
                <div key={index} className="consultation_card">
                  <div className="item">
                    <div className="head">
                      <h4>{data?.service?.title || "---"}</h4>
                      <span>{data?.bookingId || "---"}</span>
                    </div>
                    <div className="content_wrap">
                      <div className="content">
                        <img src={data?.user?.image || profileImg} alt="" />

                        <p>
                          <b>{`${
                            (data?.user && data?.user?.firstName) || "---"
                          }${" "}${data?.user && data?.user?.lastName}`}</b>
                          {/* <span>
                            Booking Date :{" "}
                            {dateFormat(data?.bookedFor) || "---"}
                          </span> */}
                        </p>
                      </div>
                      {subscriptionType === "oneTime" && (
                        <p className="time">
                          <span>
                            Start Date :{" "}
                            {dateFormat(
                              data?.service?.subscriptionAvailability?.startDate
                            ) || "---"}
                          </span>
                          <span>
                            End Date :{" "}
                            {dateFormat(
                              data?.service?.subscriptionAvailability?.endDate
                            ) || "---"}
                          </span>
                        </p>
                      )}
                      {subscriptionType === "recurring" && (
                        <p className="time">
                          <span>
                            Start Date :{" "}
                            {dateFormat(
                              data?.subscriptions[
                                data?.subscriptions.length - 1
                              ]?.currentStart
                            ) || "---"}
                          </span>
                          <span>
                            End Date :{" "}
                            {dateFormat(
                              data?.subscriptions[
                                data?.subscriptions.length - 1
                              ]?.currentEnd
                            )}{" "}
                          </span>
                        </p>
                      )}

                      <p className="time">
                        <span>
                          Start Time :{" "}
                          {convertTo12HourFormat(data?.slot?.startTime) ||
                            "---"}
                        </span>
                        <span>Duration : {data?.slot?.duration} min</span>
                      </p>

                      <div className="button_wrap">
                        {bookingStatus === "ongoing" &&
                          (() => {
                            const lastSubscription =
                              data?.subscriptions?.[
                                data.subscriptions.length - 1
                              ];
                            const startDate =
                              subscriptionType === "oneTime"
                                ? data?.service?.subscriptionAvailability
                                    ?.startDate
                                : lastSubscription?.currentStart;
                            const endDate =
                              subscriptionType === "oneTime"
                                ? data?.service?.subscriptionAvailability
                                    ?.endDate
                                : lastSubscription?.currentEnd;
                            const startTime = data?.slot?.startTime;
                            const endTime = data?.slot?.endTime;
                            const duration = data?.slot?.duration;
                            const value = startSubscriptionChecker(
                              mergeSubscriptionTimeDate(startDate, startTime),
                              mergeSubscriptionTimeDate(endDate, endTime),
                              mergeSubscriptionTimeDate(null, startTime),
                              duration
                            );
                            if (value) {
                              return (
                                <Button
                                  className="w-100"
                                  onClick={() => {
                                    startSubscriptionHandler(data?._id);

                                    // Open the expert meeting link if it exists
                                    if (data?.expertMeetingLink) {
                                      window.open(
                                        data.expertMeetingLink,
                                        "_blank"
                                      );
                                    }
                                  }}
                                >
                                  Start Session
                                </Button>
                              );
                            } else {
                              <Button className="w-100" isDisabled={true}>
                                End Of Subscription
                              </Button>;
                            }
                          })()}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <Placeholder
            // image={expertPlaceholderImg}
            text={"No Services Available of this expert"}
          />
        )}
      </div>

      {/* Cancel One TO One Modal */}
      {/* <CustomModal
        show={cancelOneToOneModal}
        handleClose={() => {
          setCancelOneToOneModal(false);
        }}
        className={"logoutModal"}
      >
        <h3>Cancel Service</h3>
        <p>Are you sure you want to cancel this service?</p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setCancelOneToOneModal(false);
            }}
          >
            No
          </Button>
          <Button onClick={cancelConsultationHandler}>Yes</Button>
        </div>
      </CustomModal> */}

      {/* Add Note Modal */}
      {/* <CustomModal
        show={noteModal}
        handleClose={() => {
          setNoteModal(false);
          setId();
          setError("");
          setAddNoteData("");
        }}
      >
        <div className="PriorityDMS_reply">
          <h3 className="title">{`Reply to ${addNoteData?.firstName} ${" "} ${
            addNoteData?.lastName
          }`}</h3>
      

          <div className="form_field">
            <label className="label">
              Enter Message{" "}
              <p className="file_upload">
                {addNoteData?.attachment?.name ? (
                  <>
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="text-decoration-underline">File name</span>
                    <button
                      type="button"
                      className="cancel_button"
                      onClick={() => {
                        setAttached(true);
                        setAddNoteData({ ...addNoteData, attachment: "" });
                      }}
                    >
                      <IoClose />
                    </button>
                  </>
                ) : (
                  <label htmlFor="uploadFile">
                    <input
                      type="file"
                      id="uploadFile"
                      onChange={imageHandler}
                    />
                    <GrAttachment />
                    <span className="text-decoration-underline">
                      Upload Document
                    </span>
                  </label>
                )}
              </p>
            </label>
            <TextArea
              placeholder="Enter Message"
              value={addNoteData?.text}
              onChange={(e) =>
                setAddNoteData({ ...addNoteData, text: e.target.value })
              }
              error={error}
            />
          </div>
          <LoadingButton
            className="w-100"
          
            loading={loading}
            onClick={NoteConsultationHandler}
          >
            Submit
          </LoadingButton>
        </div>
      </CustomModal> */}
    </React.Fragment>
  );
};

export default UpcomingSubscription;
