import AddService from "components/cards/servicesCard/AddService";
import React from "react";
import "../ManageServices.scss";
const AddServices = () => {
  return (
    <>
      <div className="add_services">
        <div className="dashboard_title">
          <h3>Edit Subscription</h3>
        </div>

        <div className="dashboard_content_inner">
          <AddService />
        </div>
      </div>
    </>
  );
};

export default AddServices;
