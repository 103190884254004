import React from "react";
import "./Footer.scss";
import { logoWhiteImg } from "assets/images";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTelegramPlane,
  FaYoutube,
} from "react-icons/fa";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";

import { Link } from "react-router-dom";
const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer_content">
            <div className="logo_section">
              <img src={logoWhiteImg} alt="" />
              <p>
                Dr vivek sharma has done his MDS in prosthodontics and crown and
                bridge and has a vast experience of working in government and
                private dental setups. He is member of indian dental
                association, indian prosthodontc society and academy of oral
                implantology. He has many awards to his credit.
              </p>

              <div className="socialIcons">
                <ul>
                  <li>
                    <Link to="https://web.telegram.org/k/" target="_blank">
                      <FaTelegramPlane />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/fitzure" target="_blank">
                      <FaInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://x.com/fitzure30758" target="_blank">
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@fitzure" target="_blank">
                      <FaYoutube />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/Fitzure" target="_blank">
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/company/fitzure" target="_blank">
                      <FaLinkedinIn />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="quickLinks">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link to="/aboutus">About us</Link>
                </li>
                <li>
                  <Link to="/explore-experts">Experts</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Cancellation & Refund Policy</Link>
                </li>
              </ul>
            </div>

            <div className="connect">
              <h3>Contact</h3>
              <ul>
                <li>
                  <a href="tel:+91 9876543210">
                    <span>Call me on</span>
                    <strong>+91 9876543210</strong>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@fitzure.com">
                    <span>Email me</span>
                    <strong>info@fitzure.com</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            <p>© {year} All rights are reserved - Powered by Fitzure</p>
            <Link to="/terms">Terms and Conditions</Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
