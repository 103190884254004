import React, { useEffect, useState, useRef, useCallback } from "react";
import { LoadingButton } from "components/form";
import OTPInput from "react-otp-input";
import { resendOtp, verifyOtpApi } from "services/auth";
import { toastMessage } from "utils/toastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebar } from "common/Sidenav";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setPersonalInfo, setSteps } from "store/slices/configSlice";
import { setLoginInfo } from "store/loginInfoSlice";
import { authRouteHandler } from "utils/common";

const OTPScreen = ({
  loginData,
  closeModal,
  type,
  tempValueHandler,
  showSignUp,
  custom,
}) => {
  // console.log(showSignUp, "showSignUp ");
  const currentLocation = useLocation();
  // console.log(currentLocation, "current location");

  const [otp, setOtp] = useState("");
  const { userType, steps, personalInfo } = useSelector(
    (state) => state.config
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const minutesRef = useRef(0);
  const secondsRef = useRef(59);
  const intervalRef = useRef(null);
  const [, setDummyState] = useState(0);

  // State to trigger re-renders
  const dispatch = useDispatch();

  const verifyOtpHandler = async () => {
    // Constants for messages and navigation paths
    const INVALID_OTP_MESSAGE = "Invalid OTP";
    const VERIFICATION_ERROR_MESSAGE = "An error occurred during verification.";
    const OTP_LENGTH = 6;

    // Check if OTP is valid
    if (otp.length !== OTP_LENGTH) {
      toastMessage(INVALID_OTP_MESSAGE, "error", "invalidOtp");
      return;
    }

    setLoading(true);

    if (type === "verification") {
      try {
        const { _id, authType } = loginData || {};
        const type = authType === "login-phone" ? 0 : 1;
        const { data } = await verifyOtpApi({ id: _id, otp, type });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "verifyNumber");
          closeModal(false);
          tempValueHandler();
        } else {
          toastMessage(data?.message, "error", "VerifyNumber");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const { _id, authType } = loginData || {};
        const type = authType === "login-phone" ? 0 : 1;
        const { data } = await verifyOtpApi({ id: _id, otp, type });

        // Handle successful response
        if (data?.code === 200) {
          const {
            accessToken,
            email,
            expertProfileStatus,
            accountType,
            firstName,
            lastName,
            _id,
            image,
            currentType,
            slug
          } = data?.data;

          // Store data in localStorage
          const fitzureInfo = {
            token: accessToken,
            email,
            moduleSteps: expertProfileStatus,
            accountType,
            firstName,
            lastName,
            _id,
            image: image || "",
            currentType,
            slug,
          };

          localStorage.setItem("fitzure_info", JSON.stringify(fitzureInfo));
          localStorage.setItem("expertProfileStatus", expertProfileStatus);
          dispatch(
            setConfig({
              userType: accountType,
            })
          );
          dispatch(setSteps({ steps: expertProfileStatus }));
          dispatch(setPersonalInfo({ personalInfo: fitzureInfo }));

          closeModal(false);
          // Show success message
          toastMessage(data?.message, "success", "login");
          if (showSignUp) {
            // const navigateTo = getNavigationPath(
            //   // Number(accountType),
            //   // Number(expertProfileStatus),
            //   // accountType
            //   Number(currentType),Number(moduleSteps)
            // );
            // navigate({
            //   pathname: "/navigation",
            //   search: `?${new URLSearchParams({
            //     currentType: currentType,
            //   }).toString()}`,
            // });

            authRouteHandler({
              currentType,
              navigate,
              personalInfo: fitzureInfo,
            });
          } else {
            navigate(currentLocation?.pathname);
          }
        } else {
          // Show error message from API response
          toastMessage(data?.message, "error", "loginToDashboard");
        }
      } catch (error) {
        console.error("Error:", error.message);
        toastMessage(VERIFICATION_ERROR_MESSAGE, "error", "loginError");
      } finally {
        setLoading(false);
      }
    }
  };

  // Helper function to determine navigation path
  // const getNavigationPath = (currentType,moduleSteps) => {
  //   if (
  //     (currentType === 1 && moduleSteps===0)

  //   ) {
  //     return "/profile-setup/personal-info"; // For User Login
  //   }
  //   if (accountType === 1 && steps === 1) {
  //     return "/"; // For User Login
  //   }
  //   if (steps === 4 && (accountType === 0 || accountType === 2)) {
  //     return "/dashboard/home";
  //   }
  //   const nextStep = sidebar({ userType, steps }).find(
  //     ({ id }) => id === steps
  //   );
  //   return nextStep?.path ?? "/profile-setup/personal-info";
  // };

  // Resend Otp Handler
  const resendOtpHandler = async () => {
    try {
      setResendDisabled(true);
      minutesRef.current = 0;
      secondsRef.current = 59;
      startTimer();
      setDummyState((prev) => prev + 1);
      const reqData = {};
      reqData._id = loginData._id;
      if (loginData?.authType === "login-phone") {
        reqData.phone = loginData.phone;
        reqData.countryCode = loginData.countryCode;
      }
      if (loginData?.authType === "login-email") {
        reqData.email = loginData.email;
      }
      const res = await resendOtp(reqData);
      if (res?.data?.code === 200) {
        toastMessage(res?.data?.message, "success", "OtpSend");
      } else {
        toastMessage(res?.data?.message, "error", "OtpSend");
      }
      // startTimer();
    } catch (e) {
      console.log("error:", e.message);
    }
  };

  // Timer logic
  const startTimer = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setDummyState((prev) => prev + 1); // Trigger re-render to update the UI
      if (secondsRef.current > 0) {
        secondsRef.current -= 1;
      } else {
        if (minutesRef.current > 0) {
          minutesRef.current -= 1;
          secondsRef.current = 59;
        } else {
          clearInterval(intervalRef.current);
          setResendDisabled(false);
        }
      }
    }, 1000);
  }, []);

  useEffect(() => {
    startTimer();
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [startTimer]);

  return (
    <div className="auth_content otp_screen">
      <h3 className="title">
        Verify your{" "}
        {loginData?.authType === "login-phone" ? "phone number" : "email"}
      </h3>
      <p>
        Please enter the OTP sent to{" "}
        <b>
          {loginData?.authType === "login-phone"
            ? `${loginData?.countryCode}${" "}${loginData?.phone}`
            : loginData?.email}
        </b>
      </p>

      <form>
        <div className="form_field">
          <label className="label has_child">Enter OTP</label>

          <div className="opt_wrapper">
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={"opt-input"}
              inputStyle={"input"}
              renderInput={(props) => <input {...props} />}
              inputType={"tel"}
            />
          </div>
        </div>
        <div className="button_wrap button_center">
          <LoadingButton
            loading={loading}
            type="button"
            onClick={verifyOtpHandler}
          >
            Verify
          </LoadingButton>
        </div>

        <p className="signUpText">
          {resendDisabled && "OTP will expire in "}
          {resendDisabled ? (
            <>
              {" "}
              <b>
                {minutesRef.current < 10
                  ? `${minutesRef.current}`
                  : minutesRef.current}
                :
                {secondsRef.current < 10
                  ? `${secondsRef.current}`
                  : secondsRef.current}{" "}
                Sec
              </b>
            </>
          ) : (
            <button type="button" onClick={resendOtpHandler}>
              Resend OTP
            </button>
          )}
        </p>
      </form>
    </div>
  );
};

export default OTPScreen;
