export const getDataFromLocal = (key) => {
    return localStorage.getItem(key)
  }
  
  export function setLocalStorage(parentKey, childKey, value) {
    let localData = localStorage.getItem('fitzure_web')
      ? JSON.parse(localStorage.getItem('fitzure_web'))
      : {}
    localData = {
      ...localData,
      [parentKey]: { ...localData[parentKey], [childKey]: value },
    }
    localStorage.setItem('fitzure_web', JSON.stringify(localData))
  }
  
  export function getLocalStorage(parentKey, childKey) {
    let localData = JSON.parse(localStorage.getItem('fitzure_web')) || {}
    return localData[parentKey]?.[childKey]
  }