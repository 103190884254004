import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Get Scheduled API
export const getScheduledApi = async (data) => {
  const endPoint =
    `expert/service/services-to-schedule` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};



// Add Scheduled API
export const addScheduledApi = async (data) => {
  const endPoint = `expert/service/schedule`;
  return await axios.patch(endPoint, data, setJwtToken());
};
