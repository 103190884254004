import { sidebar } from "common/Sidenav"; // Assuming sidebar is directly exported

export function proceedNextStep(stepValue) {
  // Find the next step object from sidebar
  const nextStep = sidebar().find((item) => item.id === Number(stepValue));

  // Navigate to the path of nextStep if found, otherwise fallback to a default path
  return nextStep?.path || "/profile-setup/personal-info";
}

export function authRouteHandler({
  currentType = null,
  navigate,
  personalInfo = {},
}) {
  if (typeof currentType === "number") {
    if (currentType === 1) {
      if (personalInfo?.moduleSteps === 0) {
        navigate("/profile-setup/personal-info");
      } else {
        navigate("/");
      }
    } else {
      if (currentType === 0) {
        if (personalInfo?.moduleSteps === 0) {
          navigate("/profile-setup/personal-info");
        } else if (personalInfo?.moduleSteps === 1) {
          navigate("/profile-setup/personal-details");
        } else if (personalInfo?.moduleSteps === 2) {
          navigate("/profile-setup/account-details");
        } else if (personalInfo?.moduleSteps === 3) {
          navigate("/profile-setup/additional-details");
        } else {
          navigate("/dashboard/home");
        }
      }
    }
  }
}

// export const resaonData = ["Queries", "Complaints", "Feedbacks", "Others"];

export const reasonData=[
  {
  name:"Query",
  value:"query"
},
{
  name:"Complaint",
  value:"complaints"
},
{
  name:"Feedback",
  value:"feedback"
},
{
  name:"Other",
  value:"others"
},
]
