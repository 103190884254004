import { expertPlaceholderImg3, imagePlaceholder, profileImg, serviceImg1 } from "assets/images";
import { Button, LoadingButton } from "components/form";
import React, { useEffect, useState } from "react";
import "./PackagesList.scss";
import RatingModal from "components/modals/RatingModal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import {
  // getdigitalListApi,
  getDigitalListApi,
} from "services/userServices/servicesListing";
import { charLimit } from "utils/mediaDataSender";
import DescriptionModal from "common/DescriptionModal";
import { LuIndianRupee } from "react-icons/lu";
import { rateWebinarApi } from "services/userServices/startServices";
import Placeholder from "components/placeholder/Placeholder";

const DigitalContentList = () => {
  const [ratingModal, setRatingModal] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [digitalList, setDigitalList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [total, setTotal] = useState(null);
  let page = +searchParam.get("page") || 1;
  let type = searchParam.get("type") || "digital";
  let bookingStatus = searchParam.get("bookingStatus") || "upcoming";
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [ratingField, setRatingField] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchingDigitalContentListHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.pageSize = 10;
      sendData.page = page - 1;
      const { data } = await getDigitalListApi(sendData);
      if (data?.code === 200) {
        setDigitalList(data?.data || []);
        setTotal(data?.totalItems || null);
      } else {
        toastMessage(data?.message, "error", "BookingServicesList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingDigitalContentListHandler();
  }, []);

  const rateServiceHandler = (_id) => {
    setRatingField({ ...ratingField, _id: _id, serviceType: "digital" });
    setRatingModal(true);
  };

  // Validation
  const validation = () => {
    let status = true;
    if (!ratingField?.rating) {
      setError("Please provide rating for this service");
      status = false;
    }
    return status;
  };

  // OnChange Handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setRatingField({ ...ratingField, [name]: value });
  };

  // Submit Review Handler
  const submitReviewHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await rateWebinarApi(ratingField);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "rateWebinar");
          setRatingField({});
          setRatingModal(false);
          fetchingDigitalContentListHandler();
        } else {
          toastMessage(data?.message, "error", "rateWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const navigateHandler = (expertId, serviceId) => {
    navigate(`/digital-content-list/detail/${expertId}/${serviceId}`);
  };

  // console.log(digitalList, "DigitalList");

  return (
    <>
      <div className="exploreExperts packagesList">
        <div className="container">
          <div className="page_title text-center">
            <span>Digital Contents</span>
            <h3>Digital Contents</h3>
            <p>
              Lorem Ipsum&nbsp;is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's
            </p>
          </div>
          <div className="packages_listing_wrap">
            {digitalList && digitalList.length ? (
              digitalList.map((data) => (
                <div
                  className="item"
                  // onClick={() =>
                  //   navigateHandler(data?.expert?._id, data?.service?._id)
                  // }
                >
                  <div className="package_card">
                    <div
                      className="image_wrap cursor-pointer"
                      onClick={() =>
                        navigateHandler(data?.expert?._id, data?.service?._id)
                      }
                    >
                      <img src={data?.service?.image || imagePlaceholder} alt="" />
                      <span className="tag">
                        {/* {`${
                        data?.service?.noOfChapters || ""
                      } Chapters`} */}
                      {data?.digitalContentType==="course" ? "Digital Course":"Digital Product"}
                      </span>
                    </div>
                    <div className="content">
                      <div className="author">
                        <img src={profileImg} alt="" />
                        <span>
                          Created by{" "}
                          {`${data?.expert?.firstName} ${" "} ${
                            data?.expert?.lastName
                          }`}{" "}
                        </span>
                      </div>

                      <h3>{data?.service?.title || "---"} </h3>
                      <p>
                        {data?.service?.description ? (
                          <>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: charLimit(
                                  data?.service?.description,
                                  100
                                ),
                              }}
                            ></p>
                            {data?.service?.description.length > 100 && (
                              <button type="button"
                                onClick={() => {
                                  if (
                                    data?.service?.description &&
                                    data?.service?.description.length > 100
                                  ) {
                                    setDescriptionModal(true);
                                    setDescription(
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data?.service?.description,
                                        }}
                                      ></p>
                                    );
                                  }
                                }}
                              >
                                Read More
                              </button>
                            )}
                          </>
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.service?.description,
                            }}
                          ></p>
                        )}
                      </p>
                      <div className="price">
                        {data?.service?.pricing?.showSlashedAmount && (
                          <del>
                            <LuIndianRupee />
                            {data?.service?.pricing?.slashAmount}
                          </del>
                        )}

                        <b>
                          <LuIndianRupee />
                          {
                          Number
                          (
                            // data?.service?.pricing?.amount -
                              Number(data?.service?.pricing?.amount)
                          ) || "0"}
                        </b>
                      </div>
                      <div className="button_wrap button_center">
                        <LoadingButton
                          isDisabled={data?.isRated}
                          loading={loading}
                          className={"w-100 light"}
                          onClick={() => rateServiceHandler(data?._id)}
                        >
                          Rate Service
                        </LoadingButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Placeholder
                image={expertPlaceholderImg3}
                text="Digital content not available"
              />
            )}
            {/* <div className="item">
              <div className="package_card">
                <Link to="/packages-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button
                      className={"light w-100"}
                      onClick={() => setRatingModal(true)}
                    >
                      Rate Package
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="package_card">
                <Link to="/digital-content-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button
                      className={"light w-100"}
                      onClick={() => setRatingModal(true)}
                    >
                      Rate Package
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="package_card">
                <Link to="/packages-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button className={"light w-100"}>Rate Package</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="package_card">
                <Link to="/packages-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button className={"light w-100"}>Rate Package</Button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Rating Webinar Services Modal */}
      {ratingModal && (
        <RatingModal
          show={ratingModal}
          handleClose={() => {
            setRatingModal(false);
            setRatingField({});
            setError("");
          }}
          ratingField={ratingField}
          setRatingField={setRatingField}
          onChangeHandler={onChangeHandler}
          loading={loading}
          action={submitReviewHandler}
          error={error}
        />
      )}

      {descriptionModal && (
        <DescriptionModal
          show={descriptionModal}
          handleClose={() => {
            setDescriptionModal(false);
          }}
          description={description}
          title={"Description"}
        />
      )}
    </>
  );
};

export default DigitalContentList;
