import { logOutIcon } from "assets/icons";
import { commonSidebar } from "common/CommonSidenav";
import { Button } from "components/form";
import LogoutModal from "components/modals/LogoutModal";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { setPersonalInfo } from "store/slices/configSlice";

const ExpertSideBar = ({ hideNav }) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const { personalInfo } = useSelector((state) => state.config);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogoutHandler = () => {
    localStorage.removeItem("fitzure_info");
    localStorage.removeItem("expertProfileStatus");
    dispatch(setPersonalInfo({ personalInfo: "" }));
    navigate("/");
  };

  // console.log("common Sidebar");

  return (
    <>
      <span className="sub_title">Menu</span>
      <nav className="side_nav">
        <ul 
        onClick={hideNav}
        >
          {commonSidebar(
            personalInfo?.currentType,
            personalInfo?.moduleSteps
          ) &&
            commonSidebar(personalInfo?.currentType, personalInfo?.moduleSteps)
              .length > 0 &&
            commonSidebar(personalInfo?.currentType, personalInfo?.moduleSteps)
              .filter((data) => !data.subtitle)
              .map((data) => (
                <li key={data.path}>
                  <NavLink to={data.path} className="nav-link">
                    <img src={data.icon} alt="" />
                    <span>{data.name}</span>
                  </NavLink>
                </li>
              ))}
        </ul>
      </nav>
      <span className="sub_title">Bookings</span>
      <nav className="side_nav">
        <ul 
        onClick={hideNav}
        >
          {commonSidebar(
            personalInfo?.currentType,
            personalInfo?.moduleSteps
          ) &&
            commonSidebar(personalInfo?.currentType, personalInfo?.moduleSteps)
              .length > 0 &&
            commonSidebar(personalInfo?.currentType, personalInfo?.moduleSteps)
              .filter((data) => data?.subtitle && data?.name === "Bookings")
              .map((data) =>
                data?.subtitle.map((subtitleItem) => (
                  <li key={subtitleItem.path}>
                    <NavLink to={subtitleItem.path} className="nav-link">
                      <img src={subtitleItem.icon} alt="" />
                      <span>{subtitleItem.name}</span>
                    </NavLink>
                  </li>
                ))
              )}
        </ul>
      </nav>

      <span className="sub_title">CMS</span>
      <nav className="side_nav">
        <ul 
        onClick={hideNav}
        >
          {commonSidebar(
            personalInfo?.currentType,
            personalInfo?.moduleSteps
          ) &&
            commonSidebar(personalInfo?.currentType, personalInfo?.moduleSteps)
              .length > 0 &&
            commonSidebar(personalInfo?.currentType, personalInfo?.moduleSteps)
              .filter((data) => data?.subtitle && data?.name === "CMS")
              .map((data) =>
                data?.subtitle.map((subtitleItem) => (
                  <li key={subtitleItem.path}>
                    <NavLink to={subtitleItem.path} className="nav-link">
                      <img src={subtitleItem.icon} alt="" />
                      <span>{subtitleItem.name}</span>
                    </NavLink>
                  </li>
                ))
              )}
        </ul>
      </nav>
      <nav className="side_nav">
        <ul 
        onClick={hideNav}
        >
          <li>
            <button
              type="button"
              className="nav-link _logout"
              onClick={() => setLogoutModal(true)}
            >
              <img src={logOutIcon} alt="" />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </nav>
      <LogoutModal
        show={logoutModal}
        handleClose={() => setLogoutModal(false)}
        action={userLogoutHandler}
      />
    </>
  );
};

export default ExpertSideBar;
