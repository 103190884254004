import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceDetailInfo: {},
};

// Define the  service Detail slice
const serviceDetailSlice = createSlice({
  name: "serviceDetails",
  initialState,
  reducers: {
    setServiceDetailInfo: (state, action) => {
      state.serviceDetailInfo = action.payload.serviceDetailInfo;
    },
  },
});

export const { setServiceDetailInfo } = serviceDetailSlice.actions;

export default serviceDetailSlice.reducer;
