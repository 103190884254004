import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userType: null,
  steps: null,
  personalInfo: {},
  loginModalVisible: {},
};

// Define the config slice
const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.userType = action.payload.userType;
    },
    setSteps: (state, action) => {
      state.steps = action.payload.steps;
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload.personalInfo;
    },
    setLoginModalVisible: (state, action) => {
      state.loginModalVisible = action.payload.loginModalVisible;
    },
  },
});

export const {
  setConfig,
  setSteps,
  setPersonalInfo,
  setLoginModalVisible,
} = configSlice.actions;

export default configSlice.reducer;
