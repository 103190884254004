import React, { useEffect, useState } from "react";
import { Input, LoadingButton } from "components/form";
import PhoneInput from "react-phone-input-2";
import { loginApi, userIdAvailabilityApi } from "services/auth";
import { toastMessage } from "utils/toastMessage";
import commonMessages from "utils/commonMessages";
import { regex } from "utils/formValidator";

const RegisterInfo = ({ sendOtp, moveLogin }) => {
  const [loginSwitch, setLoginSwitch] = useState("login-email");
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({});
  const [error, setError] = useState({});
  const { VALID_EMAIL } = commonMessages;
  const { email, name } = regex;

  // Validation
  const validation = () => {
    let status = true;
    const errors = {};
    if (loginSwitch === "login-email") {
      if (!inputData?.email) {
        errors["email"] = "Email is mandatory";
        status = false;
      } else if (!inputData.email.match(email)) {
        errors["email"] = VALID_EMAIL;
        errors["emailErr"] = true;
        status = false;
      }
    }

    if (loginSwitch === "login-phone" && !inputData?.phone) {
      errors["phone"] = "Phone is Mandatory";
      status = false;
    } else if (loginSwitch === "login-phone" && inputData?.phone.length < 6) {
      errors["phone"] = "Invalid Phone Number";
      status = false;
    }
    if (!inputData?.accountType) {
      errors["accountType"] = "Account Type is Mandatory";
      status = false;
    }
    if (!inputData?.firstName) {
      errors["firstName"] = "First Name  is Mandatory";
      status = false;
    } else if (inputData?.firstName.length < 3) {
      errors["firstName"] = "First Name is too short";
      errors["firstNameErr"] = true;
      status = false;
    }

    // if (!inputData?.slug) {
    //   errors["slug"] = "User Id is Mandatory";
    //   status = false;
    // } else if (inputData?.slug.length < 3) {
    //   errors["slug"] = "User Id is too short";
    //   errors["slugErr"] = true;
    //   status = false;
    // }

    if (!inputData?.lastName) {
      errors["lastName"] = "Last Name  is Mandatory";
      status = false;
    } else if (inputData?.lastName.length < 3) {
      errors["lastName"] = "Last Name is too short";
      errors["lastNameErr"] = true;
      status = false;
    }
    setError(errors);
    return status;
  };

  const handlePhoneChange = (phone, country) => {
    const phoneWithoutCountryCode = phone.replace(country.dialCode, "");
    setInputData({
      ...inputData,
      phone: phoneWithoutCountryCode,
      email: "",
      countryCode: `+${country?.dialCode || "+91"}`,
      completeMobile: `+${
        country?.dialCode || "+91"
      }${phoneWithoutCountryCode}`,
    });
  };

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setInputData({
        ...inputData,
        [name]: value.toLowerCase(),
        phone: "",
        countryCode: "",
      });
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const sendOtpHandler = async (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        setLoading(true);
        delete inputData.completeMobile;
        if (loginSwitch === "login-email") {
          delete inputData.phone;
          delete inputData.countryCode;
        }
        if (loginSwitch === "login-phone") {
          delete inputData.email;
        }
        const { data } = await loginApi({ ...inputData, type: 0 });
        if (data.code === 200) {
          toastMessage(data?.message, "success", "otpSender");
          sendOtp({ ...data?.data, ...inputData, authType: loginSwitch } || {});
        } else {
          toastMessage(data.message, "error", "otpSender");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // const checkUserIdAvailability = async () => {
  //   try {
  //     // setAvailabilityLoader(true);
  //     const { data } = await userIdAvailabilityApi(inputData?.slug);
  //     if (data?.code === 200) {
  //       toastMessage("User Name Available", "success", "availableError");
  //       // setIsAvailable(true);
  //     } else {
  //       toastMessage(data?.message, "error", "availableError");
  //       // setIsAvailable(false);
  //     }
  //   } catch (e) {
  //     console.log(e.message);
  //   } finally {
  //     // setAvailabilityLoader(false);
  //   }
  // };

  // useEffect(() => {
  //   if (inputData?.slug) {
  //     const timeoutId = setTimeout(() => {
  //       checkUserIdAvailability();
  //     }, 4000);
  
  //     // Clear timeout on component unmount or before next effect
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [inputData?.slug]); //

  return (
    <>
      <div className="auth_content login">
        <h3 className="title">Create an Account</h3>
        <p>Create an account to continue</p>{" "}
        {/* Account type 0 for expert || 1 for user  || 2 for both */}
        <form>
          <div className="form_field_wrap">
            <div className="form_field">
              <label
                htmlFor="expert"
                className={`radio_button input ${
                  inputData.accountType == "0" ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  name="accountType"
                  value={"0"}
                  id="expert"
                  checked={inputData.accountType == "0"}
                  onChange={inputFieldHandler}
                />
                <span>Sign up as Expert</span>
              </label>
              {error.accountType && (
                <span className="invalid-feedback d-block">
                  {error.accountType}
                </span>
              )}
            </div>
            <div className="form_field">
              <label
                htmlFor="user"
                className={`radio_button input ${
                  inputData.accountType == "1" ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  name="accountType"
                  value={"1"}
                  id="user"
                  onChange={inputFieldHandler}
                  checked={inputData.accountType == "1"}
                />
                <span>Sign up as User </span>
              </label>
            </div>
          </div>

          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                className="form-control input"
                placeholder="Enter First Name"
                label="First Name"
                name="firstName"
                value={inputData?.firstName || ""}
                onChange={inputFieldHandler}
                error={error.firstName}
                extraError={error?.firstNameErr}
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                className="form-control input"
                placeholder="Enter Last Name"
                label="Last Name"
                name="lastName"
                value={inputData?.lastName || ""}
                onChange={inputFieldHandler}
                error={error.lastName}
                extraError={error?.lastNameErr}
              />
            </div>
          </div>
          {/* <div className="form_field_wrap"> */}
            {/* <div className="form_field">
              <Input
                type="text"
                className="form-control input"
                placeholder="Enter User Id"
                label="User Id"
                name="slug"
                value={inputData?.slug || ""}
                onChange={inputFieldHandler}
                onBlur={checkUserIdAvailability}
                error={error.slug}
                extraError={error?.slugErr}
              />
            </div> */}
            {loginSwitch === "login-email" && (
              <div className="form_field">
                <label className="label has_child">
                  Email Address{" "}
                  <span
                    className="usePhone"
                    onClick={() => setLoginSwitch("login-phone")}
                  >
                    Use phone number instead
                  </span>
                </label>
                <Input
                  type="email"
                  className="form-control input"
                  placeholder="Email"
                  name="email"
                  value={inputData?.email || ""}
                  onChange={inputFieldHandler}
                  error={error.email}
                  extraError={error.emailErr}
                />
              </div>
            )}

            {loginSwitch === "login-phone" && (
              <div className="form_field">
                <label className="label has_child">
                  Mobile Number
                  <span
                    className="usePhone"
                    onClick={() => setLoginSwitch("login-email")}
                  >
                    Use email address instead
                  </span>
                </label>
                <PhoneInput
                  className={"country_picker"}
                  country={"in"}
                  value={inputData.completeMobile}
                  enableSearch={true}
                  placeholder={"Enter Phone Number"}
                  countryCodeEditable={false}
                  onChange={handlePhoneChange}
                />
                {error.phone && (
                  <span className="invalid-feedback d-block">
                    {error.phone}
                  </span>
                )}
              </div>
            )}
          {/* </div> */}
          <div className="button_wrap button_center">
            <LoadingButton onClick={sendOtpHandler} loading={loading}>
              Send OTP
            </LoadingButton>
          </div>

          <p className="signUpText">
            Already have an account?{" "}
            <button type="button" onClick={moveLogin}>
              Login
            </button>
          </p>
        </form>
      </div>
    </>
  );
};

export default RegisterInfo;
