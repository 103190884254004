import React, { useEffect, useState } from "react";
import { banner1, expert1Img, service1, serviceImg1 } from "assets/images";
// import VideoPlayer from "components/video-player/VideoPlayer";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import "./PackagesList.scss";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toastMessage } from "utils/toastMessage";
import {
  bookOneToOneApi,
  bookPackageServices,
  getExpertServiceDetailApi,
} from "services/userServices/servicesDetail&Booking";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import VideoPlayer from "common/YouTubeVideoPlayer";
import Placeholder from "components/placeholder/Placeholder";
import AuthLogin from "auth/login/Login";
import SignUp from "auth/signUp/SignUp";
import { useSelector } from "react-redux";
import { dateFormat, timeFormat, timeFormat_24 } from "utils/dateFormat";
import { IoCalendarOutline } from "react-icons/io5";
import { MdAccessTime } from "react-icons/md";
import { profilePlaceholder } from "assets/icons";
import ConfirmPayModal from "components/modals/ConfirmPayModal";
import SuccessPayment from "components/SuccessfulPaymentModal/SuccessPayment";

const PackageDetail = () => {
  const { personalInfo } = useSelector((state) => state.config);
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const [payoutModal, setPayoutModal] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const { expertId, serviceId } = useParams();
  const [loader, onShow, onHide] = FullPageLoader();
  // const [loading, setLoading] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [selectedPackage, setSelectedPackage] = useState({});
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [loading, setLoading] = useState({
    prioritydm: false,
    oneToOne: false,
    digital: false,
    package: false,
  });
  const navigate = useNavigate();

  // fetch package detail
  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        if (
          data?.data &&
          data?.data?.alreadyBooked &&
          data?.data?.alreadyBooked?.packageBookingServices
        ) {
          const servicesWithUsed = data?.data?.services.map(
            (service, index) => {
              const used =
                data?.data?.alreadyBooked?.packageBookingServices[index]
                  ?.used || null;
              return { ...service, used };
            }
          );

          // console.log(servicesWithUsed, "ServicesWithUsed");

          const expertServicesDetail = {
            ...data?.data,
            services: servicesWithUsed,
          };

          setExpertServicesDetail(expertServicesDetail);
        } else {
          setExpertServicesDetail(data?.data);
        }

        setBestTestimonials(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // Book Package
  const bookPackageServicesHandler = async (currentService = {}) => {
    let reqData = {};
    let data = {};
    if (personalInfo?.token) {
      if (expertServicesDetail?.alreadyBooked?._id) {
        reqData = {
          _id: currentService?.service?._id,
          packageBooking: expertServicesDetail?.alreadyBooked?._id,
        };
        if (currentService?.service?.serviceType === "prioritydm") {
          setLoading({ ...loading, prioritydm: true });
        } else if (currentService?.service?.serviceType === "oneToOne") {
          setLoading({ ...loading, oneToOne: true });
        } else if (currentService?.service?.serviceType === "digital") {
          setLoading({ ...loading, digital: true });
        }
      } else {
        setLoading({ ...loading, package: true });
        reqData = {
          _id: expertServicesDetail?._id,
          amount: expertServicesDetail?.pricing?.amount,
          totalAmount: expertServicesDetail?.pricing?.totalAmount || "",
          transactionFeePercentage:
            expertServicesDetail?.pricing?.transactionFeePercentage || "",
        };
      }

      try {
        // Make the API call
        if (expertServicesDetail?.alreadyBooked?._id) {
          data = await bookPackageServices(reqData);
        } else {
          data = await bookOneToOneApi(reqData);
        }

        // console.log(data,"Data");
        if (data?.data?.code === 200) {
          toastMessage(data?.data?.message, "success", "BookOneToOne");
          setPayoutModal(false);
          if (!expertServicesDetail?.alreadyBooked?._id) {
            setPaymentStatusModal(true);
          }
          fetchingExpertServicesDetail();
        } else {
          toastMessage(data?.message, "error", "BookOneToOne");
        }
      } catch (e) {
        console.error(e.message);
      } finally {
        // Reset loading state
        setLoading({
          prioritydm: false,
          oneToOne: false,
          digital: false,
          package: false,
        });
      }
    } else {
      setLoginModal(true);
      setPayoutModal(false);
    }
  };

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);

  // console.log(expertServicesDetail, "ExpertServicesDetail");

  return (
    <>
      {loader}
      <div className="services_page priorityDM">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img
                  src={expertServicesDetail?.image || profilePlaceholder}
                  alt={expertServicesDetail?.title}
                />
                <div className="content">
                  <h4>{expertServicesDetail?.title || "---"} </h4>
                  {expertServicesDetail?.duration &&
                  <p className="duration">
                    {/* <span>Promised response</span>{' '} */}
                    <b>
                      {" "}
                      {`${
                        expertServicesDetail?.duration
                          ? `(${expertServicesDetail?.duration} mins)`
                          : ""
                      }`}
                    </b>{" "}
                  </p>
                }
                  {/* <p className="price">
                    <del>₹ 600</del> <b>₹ 500</b>
                  </p> */}
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail?.pricing?.slashAmount)}
                        </del>
                        <b>
                          ₹{" "}
                          {
                            // Number(expertServicesDetail?.pricing?.amount)

                            Number(expertServicesDetail?.pricing?.amount)
                          }
                        </b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>

              {/*This Button Show Only To User */}

              {(personalInfo?.currentType === 1 || !personalInfo?.token) && (
                <div className="banner_tag">
                  {/* <Button
     className={'dashboard_button'}
     onClick={() => setPayoutModal(true)}
   >
     Buy Now
     
   </Button> */}
                  <span className="name_tag">Package</span>
                  {expertServicesDetail?.alreadyBooked ? (
                    <p className="bookedTag">Already Booked</p>
                  ) : (
                    <Button
                      className={"dashboard_button"}
                      onClick={() => setPayoutModal(true)}
                    >
                      Buy Package
                    </Button>
                  )}
                </div>
              )}
            </div>
            {expertServicesDetail?.description && (
              <div className="service_content">
                <div className="content_left">
                  <h5 className="_title">Overview</h5>
                  <div className="content">
                    {expertServicesDetail?.description ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: expertServicesDetail.description,
                        }}
                      ></p>
                    ) : (
                      <p>---</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="packages">
              <h5>Package</h5>

              <ul>
                {expertServicesDetail?.services &&
                  expertServicesDetail?.services.length > 0 &&
                  expertServicesDetail?.services.map((currentService) => {
                    return (
                      <li>
                        <p>
                          <b>{`${currentService?.service?.title || "---"} ( ${
                            currentService?.serviceType === "prioritydm"
                              ? "Priority DM"
                              : currentService?.serviceType === "digital"
                              ? "Digital Contents"
                              : "1:1 Consultations"
                          })`}</b>
                          {currentService.service?.pricing
                            ?.showSlashedAmount ? (
                            <>
                              <del>
                                ₹{" "}
                                {Number(
                                  currentService.service?.pricing?.slashAmount
                                )}
                              </del>
                              <b>
                                ₹{" "}
                                {Number(
                                  currentService.service?.pricing?.amount
                                )}
                              </b>
                            </>
                          ) : (
                            <>
                              ₹{" "}
                              {Number(currentService.service?.pricing?.amount)}
                            </>
                          )}
                          {expertServicesDetail?.alreadyBooked && (
                            <span className="tag">
                              {(currentService?.used && currentService?.used) ||
                                0}{" "}
                              Used,{" "}
                              {currentService?.provided -
                                ((currentService?.used &&
                                  currentService?.used) ||
                                  0)}{" "}
                              Remaining
                            </span>
                          )}
                        </p>
                        {expertServicesDetail?.alreadyBooked &&
                          (currentService?.serviceType === "prioritydm" ? (
                            currentService?.used !== currentService?.provided &&
                            currentService?.provided ? (
                              <LoadingButton
                                loading={loading?.prioritydm}
                                onClick={() => {
                                  // setSelectedData(currentService);
                                  // console.log("i got clicked outside");
                                  if (currentService) {
                                    // console.log("i got clicked");
                                    bookPackageServicesHandler(currentService);
                                  }
                                }}
                              >
                                Send DM
                              </LoadingButton>
                            ) : null
                          ) : currentService?.serviceType === "oneToOne" ? (
                            currentService?.used !== currentService?.provided &&
                            currentService?.provided ? (
                              <LoadingButton
                                loading={loading?.oneToOne}
                                onClick={() => {
                                  setPaymentStatusModal(true);
                                  // setSelectedData(currentService);
                                  navigate(
                                    `/package/consultant_booking/${
                                      expertServicesDetail?.alreadyBooked &&
                                      expertServicesDetail?.alreadyBooked?._id
                                    }/${serviceId}/${
                                      currentService?.service?._id
                                    }/${expertId}`
                                  );
                                }}
                              >
                                Book Now
                              </LoadingButton>
                            ) : null
                          ) : currentService?.used !==
                            currentService?.provided ? (
                            <LoadingButton
                              loading={loading?.digital}
                              onClick={() => {
                                setSelectedData(currentService);
                                if (currentService) {
                                  bookPackageServicesHandler(currentService);
                                }
                              }}
                            >
                              Access
                            </LoadingButton>
                          ) : null)}
                      </li>
                    );
                  })}

                {/* <li>
                  <p>
                    <b>Digital Content Organization</b>
                    <del>₹600</del> <span>₹500</span>{' '}
                  </p>
                  <Button
                    className={'dashboard_button'}
                    onClick={() => setPayoutModal(true)}
                  >
                    Access
                  </Button>
                </li> */}
              </ul>
            </div>
            {/* <VideoPlayer /> */}
            {expertServicesDetail?.video && (
              <VideoPlayer src={expertServicesDetail?.video || ""} />
            )}

            {bestTestimonials && bestTestimonials.length > 0 && (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {/* {testimonial &&
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          key={item.id}
                          title={item.title}
                          date={item.date}
                          description={item.description}
                          deleteButton={false}
                        />
                      );
                    })} */}
                    {bestTestimonials && bestTestimonials.length ? (
                      bestTestimonials.map((item) => {
                        return (
                          <TestimonialCard
                            bestTestimonials={bestTestimonials}
                            setBestTestimonials={setBestTestimonials}
                            display={false}
                          />
                        );
                      })
                    ) : (
                      <Placeholder text="No testimonial available for this service" />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        serviceTag={"Package"}
        serviceTittle={expertServicesDetail?.title || "---"}
        duration={`${expertServicesDetail?.duration || "---"}(mins)`}
        price={expertServicesDetail?.pricing?.totalAmount || "---"}
        buttonText={"Process to Pay"}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        authModal={authModal}
        setAuthModal={setAuthModal}
        loading={loading}
        serviceId={serviceId}
        expertId={expertId}
        expertServicesDetail={expertServicesDetail}
        token={personalInfo?.token}
        setPayoutModal={setPayoutModal}
        payoutModal={payoutModal}
        setPaymentStatusModal={setPaymentStatusModal}
      />

      <SuccessPayment
        paymentStatusModal={paymentStatusModal}
        setPaymentStatusModal={setPaymentStatusModal}
        expertId={expertId}
        serviceType="package"
      />
    </>
  );
};

export default PackageDetail;
