import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Get Priority Dms List API
export const getPriorityDmsApi = async (data) => {
  const endPoint =
    `expert/priority-dm/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Send Reply 
export const sendReplyApi = async (data) => {
  const endPoint =
    `expert/priority-dm/reply`
  return await axios.put(endPoint,data, setMultiPartHeader());
};
