import { pdfIcon } from "assets/icons";
import { profileImg } from "assets/images";
import { Button, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import RatingModal from "components/modals/RatingModal";
import Placeholder from "components/placeholder/Placeholder";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  cancelServicesApi,
  rateWebinarApi,
  startConsultationApi,
} from "services/userServices/startServices";
import {
  dateFormat,
  getCurrentTimeFormatted,
  servicesStartingChecker,
  timeFormat,
} from "utils/dateFormat";
import { toastMessage } from "utils/toastMessage";

const ConsultationListUpcoming = ({
  consultantList,
  fetchingConsultantListHandler,
  serviceType,
  bookingStatus,
}) => {
  const [reviewModal, setReviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startConsultantLoading, setStartConsultantLoading] = useState(false);
  const [error, setError] = useState("");
  const [ratingField, setRatingField] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const [cancelOneToOneModal, setCancelOneToOneModal] = useState(false);
  const [note, setNote] = useState({});
  const navigate = useNavigate();

  const rateServiceHandler = (_id) => {
    setRatingField({ ...ratingField, _id: _id, serviceType: serviceType });
    setRatingModal(true);
  };

  // Validation
  const validation = () => {
    let status = true;
    if (!ratingField?.rating) {
      setError("Please provide rating for this service");
      status = false;
    }
    return status;
  };

  // Submit Review Handler
  const submitReviewHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await rateWebinarApi(ratingField);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "rateWebinar");
          setRatingField({});
          setRatingModal(false);

          fetchingConsultantListHandler();
        } else {
          toastMessage(data?.message, "error", "rateWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // OnChange Handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setRatingField({ ...ratingField, [name]: value });
  };

  // Cancel oneToOne Handler
  const cancelConsultationHandler = async () => {
    if (ratingField?._id) {
      try {
        setLoading(true);
        const { data } = await cancelServicesApi({
          _id: ratingField?._id,
          serviceType: serviceType,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "cancelConsultation");
          setRatingField({});
          fetchingConsultantListHandler();
          setCancelOneToOneModal(false);
        } else {
          toastMessage(data?.message, "error", "cancelConsultation");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Start Consultation Handler
  const startConsultationHandler = async (_id, meetingLink) => {
    if (_id) {
      try {
        const { data } = await startConsultationApi({
          _id: _id,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "startConsultation");

          fetchingConsultantListHandler();
        } else {
          toastMessage(data?.message, "error", "startConsultation");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setStartConsultantLoading(false);
      }
    }
  };

  const navigateHandler = (expertId, serviceId) => {
    navigate(`/consultation-detail/${expertId}/${serviceId}`);
  };

  return (
    <>
      <div className="card_wrapper">
        {consultantList && consultantList.length ? (
          consultantList.map((data) => (
            <>
              <div
                className="item"
                // onClick={() =>
                //   navigateHandler(data?.expert?._id, data?.service?._id)
                // }
              >
                {/* <Link
                to={`/consultation-detail/${data?.expert?._id}/${data?.service?._id}`}
              ></Link> */}
                <div className="_card">
                  <div className="head">
                    <h4>{data?.service?.title || "---"}</h4>
                    <p>Booking ID : {data?.bookingId || "---"}</p>
                  </div>
                  <div
                    className="content"
                    onClick={() =>
                      navigateHandler(data?.expert?._id, data?.service?._id)
                    }
                  >
                    <img src={data?.expert?.image || profileImg} alt="" />
                    <p>
                      <b>{`${
                        data?.expert && (data?.expert?.firstName || "---")
                      } ${"  "}${data?.expert?.lastName}`}</b>
                      <span>
                        Booking Date : {dateFormat(data?.bookedFor) || "---"}
                      </span>
                    </p>
                  </div>
                  <p className="time_duration">
                    <span>
                      Start Time : {timeFormat(data?.bookedFor) || "---"}
                    </span>
                    <span>Duration : {data?.slot?.duration || "---"}</span>
                  </p>

                  {bookingStatus === "completed" ? (
                    <p className="time_duration py-0">
                      <span className="text">
                        Note : {data?.note?.text || "---"}
                      </span>
                    </p>
                  ) : null}

                  {/* <div className="button_wrap">
                  <Button isDisabled={true} className="w-100">
                    Start Consultation
                  </Button>
                  <Button className="w-100">
                    Cancel Consultation
                  </Button>
                  
                </div> */}

                  {bookingStatus === "upcoming" ? (
                    <>
                      <div className="button_wrap">
                        <LoadingButton
                          loading={loading}
                          className={"w-100"}
                          onClick={() => {
                            window.open(data?.meetingLink);
                            startConsultationHandler(
                              data?._id,
                              data?.meetingLink
                            );
                          }}
                          isDisabled={
                            !servicesStartingChecker(
                              data?.bookedFor,
                              data?.service?.duration,
                              null
                            )
                          }
                        >
                          Start
                        </LoadingButton>
                        <LoadingButton
                          loading={startConsultantLoading}
                          className={"w-100 light"}
                          isDisabled={
                            !servicesStartingChecker(data?.bookedFor, null, 1)
                          }
                          onClick={() => {
                            setCancelOneToOneModal(true);
                            setRatingField({
                              ...ratingField,
                              _id: data?._id,
                            });
                          }}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </>
                  ) : bookingStatus === "completed" ? (
                    <>
                      <div className="button_wrap">
                        <LoadingButton
                          // isDisabled={data?.isRated}
                          loading={loading}
                          className={"w-100 light"}
                          onClick={() => {
                            // rateServiceHandler(data?._id)
                            setReviewModal(true);
                            setNote({
                              ...data?.note,
                              firstName: data?.expert?.firstName,
                              lastName: data?.expert?.lastName,
                            });
                          }}
                        >
                          View Note
                        </LoadingButton>
                        <LoadingButton
                          isDisabled={data?.isRated}
                          loading={loading}
                          className={"w-100 light"}
                          onClick={() => rateServiceHandler(data?._id)}
                        >
                          Rate Service
                        </LoadingButton>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ))
        ) : (
          <Placeholder text="No Bookings For 1:1 Consultations" />
        )}
        {/* 
        <div className="item">
          <div className="_card">
            <div className="head">
              <h4>Full Body Strech</h4>
              <p>Booking ID : #123DBS4282</p>
            </div>
            <div className="content">
              <img src={profileImg} alt="" />
              <p>
                <b>Vivek Kumar </b>
                <span>Booking Date : 05/23/2024</span>
              </p>
            </div>
            <p className="time_duration">
              <span>Start Time : 03:00PM</span>
              <span>Duration : 30 min</span>
            </p>
            <div className="button_wrap">
              <Button className="w-100" onClick={() => setReviewModal(true)}>
                Start Consultation
              </Button>
            </div>
          </div>
        </div> */}
      </div>

      {/* Rating Consultant Services Modal */}
      {ratingModal && (
        <RatingModal
          show={ratingModal}
          handleClose={() => {
            setRatingModal(false);
            setRatingField({});
            setError("");
          }}
          ratingField={ratingField}
          setRatingField={setRatingField}
          onChangeHandler={onChangeHandler}
          loading={loading}
          action={submitReviewHandler}
          error={error}
        />
      )}

      <CustomModal
        show={cancelOneToOneModal}
        handleClose={() => {
          setCancelOneToOneModal(false);
          setRatingField({});
        }}
        className={"logoutModal"}
      >
        <h3>Cancel Service</h3>
        <p>Are you sure you want to cancel this service?</p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setCancelOneToOneModal(false);
              setRatingField({});
            }}
          >
            No
          </Button>
          <Button onClick={cancelConsultationHandler}>Yes</Button>
        </div>
      </CustomModal>

      <CustomModal show={reviewModal} handleClose={() => setReviewModal(false)}>
        <h3>View Notes</h3>
        <p>
          View notes by expert{" "}
          {`${note?.firstName || "---"}${" "}${note?.lastName || "---"}`}{" "}
        </p>

        {note?.attachment && (
          <div className="_card has-border attachment">
            <img src={pdfIcon} alt="" />
            <span>attachment.pdf</span>
          </div>
        )}

        <div className="_card has-border">
          <p>{note?.text || "---"}</p>
        </div>
        {/* <div className="button_wrap">
          <Button className="w-100">Rate Service</Button>
        </div> */}
      </CustomModal>
    </>
  );
};

export default ConsultationListUpcoming;
