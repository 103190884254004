import {
  banner1,
  banner2,
  imagePlaceholder,
  profileImg,
  serviceImg1,
} from "assets/images";
import { Button } from "components/form";
import React, { useEffect, useState } from "react";
import { LuIndianRupee } from "react-icons/lu";
import { RiEdit2Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "../ManageServices.scss";
import {
  deleteServicesApi,
  getServicesDetailApi,
} from "services/expertServices/manage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import DeleteServices from "../DeleteServices";
import Placeholder from "components/placeholder/Placeholder";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import { dateFormat, timeFormat_24 } from "utils/dateFormat";

const SubscriptionDetails = () => {
  const [loader, onShow, onHide] = FullPageLoader();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [serviceDetail, setServiceDetail] = useState({});
  const { serviceType, serviceId, subscriptionType } = useParams();
  const [testimonial, setTestimonial] = useState([]);
  const navigate = useNavigate();
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setServiceDetail(data?.data);
        setTestimonial(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // -----------Delete Service------------
  const deleteServiceHandler = async () => {
    try {
      setDeleteLoader(true);
      const { data } = await deleteServicesApi({ _id: serviceId });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "DeleteService");
        navigate(`/dashboard/manage-services?serviceType=${serviceType}`);
      } else {
        toastMessage(data?.message, "error", "DeleteService");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);
  return (
    <>
      <div className="consultation_details has_banner">
        <img src={banner2} alt="" className="banner_img" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>
              {/* Trainings{" "} */}
              {`${" "}${
                serviceDetail?.subscriptionType === "recurring"
                  ? "Recurring Trainings"
                  : "Batch Trainings"
              }`}
            </h3>
          </div>

          <div className="page_banner spacing">
            <div className="banner_content">
              <img src={serviceDetail?.image || imagePlaceholder} alt="" />
              <div className="content">
                {/* <h5>Pulled Hamstring causes, Treatment and recovery </h5> */}
                <h5>{`${serviceDetail?.title}${" "}${" "}`} </h5>

                <p className="duration">
                  Duration <strong>{`${serviceDetail?.duration} ${" "} Mins  `}</strong>
                  {subscriptionType !== "recurring" && " , Start Date & End Date "}
                  {subscriptionType !== "recurring" && (
                    <strong>
                      (
                      {dateFormat(
                        serviceDetail?.subscriptionAvailability?.startDate
                      )}
                      ,
                      {dateFormat(
                        serviceDetail?.subscriptionAvailability?.endDate
                      )}{" "}
                      )
                    </strong>
                  )}
                </p>
                <p className="price">
                  {/* <del>
                    <LuIndianRupee /> {serviceDetail?.pricing?.amount}
                  </del> */}
                  {serviceDetail?.pricing?.showSlashedAmount ? (
                    <>
                      <del>
                        <LuIndianRupee />{" "}
                        {Number(serviceDetail?.pricing?.slashAmount) || ""}
                      </del>

                      <span>
                        <LuIndianRupee />{" "}
                      
                         {Number(serviceDetail?.pricing?.amount)}
                      </span>
                    </>
                  ) : (
                    <span>
                      <LuIndianRupee />{" "}
                        {Number(serviceDetail?.pricing?.amount)}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="button_wrap button_right">
              {/* <Link
                to={`/dashboard/manage-services/webinar/detail/edit/${serviceType}/${serviceId}`}
                className={" button has_icon dashboard_button"}
              >
                <RiEdit2Line /> Edit Service
              </Link> */}

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <HiDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={"span"}>
                    <Link
                      to={`${
                        subscriptionType === "recurring"
                          ? `/dashboard/manage-services/subscription/detail/edit/recurring/${serviceType}/${serviceId}/${subscriptionType}`
                          : `/dashboard/manage-services/subscription/detail/edit/${serviceType}/${serviceId}/${subscriptionType}`
                      }`}
                    >
                      <RiEdit2Line /> Edit Service
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={"span"}
                    onClick={() => setDeleteModal(true)}
                  >
                    <HiOutlineTrash />
                    Delete Service
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="page_content spacing">
            {subscriptionType === "recurring" && (
              // (serviceDetail?.recurringServiceType === "private" ? (
              //   <div className="highlight table_radius">
              //     <div class="_sub_title">
              //       <h4>Weekly Time</h4>
              //     </div>
              //     <ul className="content">
              //       <p className="text-center">
              //         {!serviceDetail?.privateSubscriptionAvailability
              //           ?.availabilities.length && "No Services Found"}
              //       </p>
              //       {serviceDetail?.privateSubscriptionAvailability
              //         ?.availabilities &&
              //         serviceDetail?.privateSubscriptionAvailability
              //           ?.availabilities.length > 0 &&
              //         serviceDetail?.privateSubscriptionAvailability?.availabilities.map(
              //           (data, index) => {
              //             return (
              //               <li key={index}>
              //                 <span className="day">{weekDays[data?.day]}</span>
              //                 <p className="schedule">
              //                   {data?.range &&
              //                     data.range.length > 0 &&
              //                     data.range.map((rangeData) => {
              //                       return (
              //                         <React.Fragment key={rangeData._id}>
              //                           <span>
              //                             {rangeData?.startTime || "---"}
              //                           </span>{" "}
              //                           -{" "}
              //                           <span>
              //                             {rangeData?.endTime || "---"}
              //                           </span>
              //                         </React.Fragment>
              //                       );
              //                     })}
              //                 </p>
              //               </li>
              //             );
              //           }
              //         )}
              //     </ul>
              //   </div>
              // ) :

              <div className="highlight table_radius">
                <div class="_sub_title">
                  <h4>
                    Weekly Time (
                    {serviceDetail?.subscriptionAvailability?.days.map(
                      (data, index) => (
                        <span className="day">
                          {index === 0 ? "" : " , "}
                          {weekDays[data]}
                        </span>
                      )
                    )}
                    )
                  </h4>
                </div>
                <ul className="content">
                  <p className="text-center">
                    {!serviceDetail?.subscriptionAvailability?.range.length &&
                      "No Services Found"}
                  </p>
                  {serviceDetail?.subscriptionAvailability?.range &&
                    serviceDetail?.subscriptionAvailability?.range.length > 0 &&
                    serviceDetail?.subscriptionAvailability?.range.map(
                      (rangeData, index) => {
                        return (
                          <li key={index}>
                            {/* <span className="day">{weekDays[data?.day]}</span> */}
                            <p className="schedule">
                              <React.Fragment key={rangeData._id}>
                                <span>{rangeData?.startTime || "---"}</span> -{" "}
                                <span>{rangeData?.endTime || "---"}</span>
                              </React.Fragment>
                            </p>
                          </li>
                        );
                      }
                    )}
                </ul>
              </div>
            )}

            <div className="overView table_radius">
              <div className="_sub_title">
                <h4>Overview</h4>
              </div>
              <div className="content">
                {serviceDetail?.description ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: serviceDetail.description,
                    }}
                  ></p>
                ) : (
                  <p>---</p>
                )}
              </div>
            </div>

            {/* <div className="highlight table_radius">
              <div class="_sub_title">
                <h4>2K+ People join this webinars</h4>
              </div>
              <ul className="content _profiles">
                <li>
                  <img src={serviceDetail?.image || profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li>
                <li>
                  <img src={profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li>
                <li>
                  <img src={profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li>
                <li>
                  <img src={profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li>
              </ul>
            </div> */}
          </div>
          {serviceDetail?.video && (
            <div className="introduction_url">
              <b>Introduction Video Link</b>
              {/* <p>https://youtu.be/YIC_Ml_g_jc?si=7DmqyVOMgnpN9JvN</p> */}
              <p>{serviceDetail?.video || "---"}</p>
            </div>
          )}

          {testimonial && testimonial.length > 0 && (
            <div className="table_radius">
              <div className="_sub_title">
                <h4>Testimonial</h4>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial && testimonial.length > 0 ? (
                    <TestimonialCard
                      bestTestimonials={testimonial}
                      setBestTestimonials={setTestimonial}
                      display={false}
                    />
                  ) : (
                    <Placeholder text="No Testimonials for this webinar services" />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Delete Component */}
          {deleteModal && (
            <DeleteServices
              loading={deleteLoader}
              deleteServiceHandler={deleteServiceHandler}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetails;
