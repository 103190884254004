import { Button } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPayment = ({
  paymentStatusModal,
  setPaymentStatusModal,
  expertId,
  serviceType,
}) => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    setPaymentStatusModal(false);
    // navigate(`/expert-details/${expertId}?serviceType=${serviceType}`);
    navigate(`/${expertId}?serviceType=${serviceType}`);

  };

  return (
    <CustomModal
      show={paymentStatusModal}
      handleClose={() => setPaymentStatusModal(false)}
    >
      <div className="text-center">
        <h3>Payment successfully</h3>
        <p>
          Thank you for your payment. Your booking has been successfully
          processed.
        </p>
        <div className="button_wrap button_center">
          <Button onClick={navigateHandler}>OK</Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SuccessPayment;
