import { Button, LoadingButton } from 'components/form';
import CustomModal from 'components/modals/CustomModal';
import React from 'react'

const DeleteServices = ({deleteModal,setDeleteModal,deleteServiceHandler,loading}) => {
  return (
    <CustomModal
    show={deleteModal}
    handleClose={() => {
        setDeleteModal(false);
    }}
    className={"logoutModal"}
  >
    <h3>Delete Service</h3>
    <p>Are you sure you want to delete this service?</p>

    <div className="button_wrap button_center">
      <Button
        className={"light"}
        onClick={() => {
            setDeleteModal(false);
        }}
      >
        No
      </Button>
      <LoadingButton loading={loading} onClick={deleteServiceHandler}>Yes</LoadingButton>
    </div>
  </CustomModal>
  )
}

export default DeleteServices