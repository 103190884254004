import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Get Subscription List API
export const getSubscriptionListApi = async (data) => {
  const endPoint =
    `expert/subscription/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Start Subscription
export const startSubscriptionApi = async (data) => {
    const endPoint = `expert/subscription/start-one-time-subcription`;
    return await axios.put(endPoint, data, setJwtToken());
  };
