import {
  Button,
  FileUpload,
  Input,
  LoadingButton,
  Select,
} from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import { updateDigitalProductApi } from "services/expertServices/manage";
import { getFileExtension, regex } from "utils/formValidator";
import { toastMessage } from "utils/toastMessage";

const EditChapter = ({
  editModal,
  setEditModal,
  selectedData,
  setSelectedData,
  serviceId,
  fetchingServiceDetailHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const [topicsToRemove, setTopicToRemove] = useState([]);
  const [error, setError] = useState({});
  const { documentFileRegex } = regex;

  const handleChapterChange = (field, e) => {
    const { name, value } = e.target;
    setSelectedData({ ...selectedData, [field]: value });
  };

  const handleChange = (field, e, topicIndex) => {
    const { value } = e.target;
    if (field === "uploadType") {
      setSelectedData((prevChapters) => {
        const newChapters = { ...prevChapters };
        const topic = newChapters?.topics[topicIndex];
        let newTopic = {
          ...topic,
          [field]: value,
          ["topicsFiles"]: "",
          ["topicVIdeoLinks"]: "",
          ["isEdit"]: "yes",
        };
        newChapters.topics[topicIndex] = newTopic;
        return newChapters;
      });
    } else {
      setSelectedData((prevChapters) => {
        const newChapters = { ...prevChapters };
        const topic = newChapters?.topics[topicIndex];
        let newTopic = { ...topic, [field]: value };
        newChapters.topics[topicIndex] = newTopic;
        return newChapters;
      });
    }
  };

  //   Handle Document
  const handleDocument = (field, e, topicIndex) => {
    const { files } = e.target;

    if (files.length === 0) return;

    const file = files[0];
    const isValidFile = documentFileRegex.test(file.name);

    setSelectedData((prevChapters) => {
      const newChapters = { ...prevChapters };
      const topic = { ...newChapters.topics[topicIndex] };

      if (isValidFile) {
        topic[field] = file;
      } else {
        e.target.value = "";
        topic[field] = "";
        toastMessage("Please upload pdf, txt, or doc", "error", "fileFormat");
      }

      newChapters.topics[topicIndex] = topic;
      return newChapters;
    });
  };

  //   Add Topic
  const addTopicHandler = () => {
    setSelectedData((prev) => {
      const updatedChapter = { ...prev };
      updatedChapter.topics = [
        ...updatedChapter.topics,
        {
          isNew: "yes",
          name: "",
          uploadType: "",
          topicsFiles: "",
          topicVIdeoLinks: "",
        },
      ];

      return updatedChapter;
    });
  };

  //   Delete Topic
  const deleteTopicHandler = (topicIndex, topicId) => {
    setSelectedData((prev) => {
      const updatedChapters = { ...prev };
      const updatedTopics = [...updatedChapters.topics];
      updatedTopics.splice(topicIndex, 1);
      updatedChapters.topics = updatedTopics;

      return updatedChapters;
    });
    if (topicId !== null && topicId !== undefined)
      setTopicToRemove((prev) => [...prev, { _id: topicId }]);
  };

  //   For Updating Chapter in update Digital Product
  const editChapterHandler = async () => {
    const topicVIdeoLinks = selectedData?.topics && selectedData?.topics
      .filter((topic) => topic.topicVIdeoLinks)
      .map((topic) => topic.topicVIdeoLinks);

    // For getting indexes of url in chapters
    const topicVideoIndexes = selectedData?.topics && selectedData?.topics.reduce(
      (acc, data, index) => {
        if (data?.topicVIdeoLinks && (data?.isEdit || data?.isNew)) {
          acc.push(index);
        }
        return acc;
      },
      []
    );

    const topicsFiles = selectedData?.topics
      .map((topic) => topic.topicsFiles)
      .filter((file) => file?.name);

    const updatedTopicFileIndexes = selectedData?.topics
      .map((data, index) => {
        if (
          (!data?.isNew && data?.isEdit) ||
          (!data?.isNew && data?.topicsFiles?.name)
        ) {
          return index;
        }
        return null;
      })
      .filter((index) => index !== null);

    const topics = selectedData?.topics.map((data) => {
      if (data?._id) {
        return {
          _id: data?._id,
          name: data?.name,
          uploadType: data?.uploadType,
        };
      } else {
        return {
          name: data?.name,
          uploadType: data?.uploadType,
        };
      }
    });
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("topics", JSON.stringify(topics));
      if (topicVIdeoLinks && topicVIdeoLinks.length) {
        formData.append("topicVIdeoLinks", JSON.stringify(topicVIdeoLinks));
      }
      if (topicVideoIndexes && topicVideoIndexes.length)
        formData.append("topicVideoIndexes", JSON.stringify(topicVideoIndexes));
      if (updatedTopicFileIndexes && updatedTopicFileIndexes.length)
        formData.append(
          "updatedTopicFileIndexes",
          JSON.stringify(updatedTopicFileIndexes)
        );
      if (topicsFiles && topicsFiles.length > 0) {
        topicsFiles.forEach((data, index) => {
          formData.append("topicsFiles", data);
        });
      }
      if (topicsToRemove && topicsToRemove.length)
        formData.append("topicsToRemove", JSON.stringify(topicsToRemove));
      formData.append("chapterName", selectedData?.name || "");
      formData.append("chapterId", selectedData?._id);
      formData.append("serviceId", serviceId || "");
      const { data } = await updateDigitalProductApi(formData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "updateDigitalProduct");
        fetchingServiceDetailHandler();
        setError({});
        setSelectedData({});
        setEditModal(false);
      } else {
        toastMessage(data?.message, "error", "updateDigitalOriduct");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // console.log(selectedData, "SelectedData");

  return (
    <CustomModal
      className="md"
      show={editModal}
      handleClose={() => {
        setEditModal(false);
        setSelectedData({});
      }}
    >
      <h3>Edit Chapter</h3>


      <div className="button_wrap button_right">
        <Button
          className={"light dashboard_button"}
          onClick={() => addTopicHandler(1)}
        >
          <IoMdAdd /> Add Topic
        </Button>
      </div>

      <div className="form_field">
        <Input
          type="text"
          label="Chapters Name"
          name="name"
          placeholder="Content management"
          value={selectedData?.name || ""}
          onChange={(e) => handleChapterChange("name", e)}
        />
      </div>
      {selectedData?.topics && selectedData?.topics.length > 0
        ? selectedData?.topics.map((topic, topicIndex) => {
            return (
              <>
                <div className="form_field_wrap">
                  <div className="form_field">
                    <Input
                      type="text"
                      label="Topic Name"
                      placeholder="Design Review"
                      name="name"
                      value={topic?.name}
                      onChange={(e) => handleChange("name", e, topicIndex)}
                    />
                  </div>
                  <div className="form_field">
                    <Select
                      label="Upload Option"
                      name="uploadType"
                      value={topic?.uploadType}
                      onChange={(e) =>
                        handleChange("uploadType", e, topicIndex)
                      }
                    >
                      <option value="">Select Upload Type</option>
                      <option value="file">Document</option>
                      <option value="video">URL</option>
                    </Select>
                  </div>
                </div>

                <div className="form_field_wrap">
                  {topic?.uploadType && topic?.uploadType === "video" && (
                    <div className="form_field">
                      <Input
                        type="url"
                        label="Video Link"
                        placeholder="Enter url"
                        name="topicVIdeoLinks"
                        value={topic?.topicVIdeoLinks}
                        onChange={(e) =>
                          handleChange("topicVIdeoLinks", e, topicIndex)
                        }
                      />
                    </div>
                  )}{" "}
                  {topic?.uploadType && topic?.uploadType === "file" && (
                    <>
                      <div className="form_field">
                        <label className="label d-block">Upload Document</label>
                        <label
                          className="imagePreview fileUpload"
                          htmlFor={`fileUpload-${1}-${topicIndex}`}
                        >
                          <input
                            type="file"
                            name="topicsFiles"
                            id={`fileUpload-${1}-${topicIndex}`}
                            onChange={(e) =>
                              handleDocument("topicsFiles", e, topicIndex)
                            }
                            //   error={error?.image}
                          />
                          {topic?.topicsFiles ? (
                            <>
                              {topic?.topicsFiles &&
                                getFileExtension(
                                  topic?.topicsFiles.name || topic?.topicsFiles
                                )}
                            </>
                          ) : (
                            <div className="content">
                              <IoCloudUploadOutline />
                              <label
                                className="button"
                                htmlFor={`fileUpload-${1}-${topicIndex}`}
                              >
                                Browse
                              </label>
                              <span>drop a file here</span>
                              <p>*File supported .pdf & .word & .txt</p>
                            </div>
                          )}
                        </label>
                      </div>
                    </>
                  )}
                  <div className="button_wrap button_right">
                    <button
                      type="button"
                      className={"delete_appends"}
                      onClick={() => deleteTopicHandler(topicIndex, topic?._id)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              </>
            );
          })
        : ""}

      {/* <FileUpload fileName={"new file name"} preview={true}/> */}
      <div className="button_wrap button_center">
        <Button
          className={"light"}
          onClick={() => {
            setEditModal(false);
          }}
        >
          No
        </Button>
        <LoadingButton loading={loading} onClick={editChapterHandler}>
          Yes
        </LoadingButton>
      </div>
    </CustomModal>
  );
};

export default EditChapter;
