import React from "react";
import Pagination from "react-js-pagination";

const CommonPagination = ({ activePage, total, handlePageChange, ...rest }) => {
  return (
    <>
      {total > 10 ? (
        <div className="pagination_wrap">
          <Pagination
            activePage={activePage}
            previousLabel={"previous"}
            nextLabel={"next"}
            itemsCountPerPage={10}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            //   hideDisabled={true}
            {...rest}
          />
        </div>
     ) : null} 
    </>
  );
};

export default CommonPagination;
