import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pixelInfo: {},
};

// Define the  service Detail slice
const pixelInfoSlice = createSlice({
  name: "pixelInfo",
  initialState,
  reducers: {
    setPixelInfo: (state, action) => {
      state.pixelInfo = action.payload.pixelInfo;
    },
  },
});

export const { setPixelInfo } = pixelInfoSlice.actions;

export default pixelInfoSlice.reducer;
