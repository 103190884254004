import { Select } from "components/form";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import Placeholder from "components/placeholder/Placeholder";
import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { todayBookingApi } from "services/dashboard";
import { dateFormatYear, timeFormat } from "utils/dateFormat";
import { toastMessage } from "utils/toastMessage";

const TodayBooking = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const serviceType = searchParam.get("serviceType") || "oneToOne";
  const [todayBooking, setTodayBooking] = useState([]);
  const [loader, onShow, onHide] = FullPageLoader();
  const subscriptionType = searchParam.get("subscriptionType") || "oneTime";

  const serviceHandler = (e) => {
    const { value } = e.target;
    if (value !== "subscription") {
      searchParam.delete("subscriptionType");
      searchParam.set("serviceType", value);
    } else {
      searchParam.set("serviceType", value);
    }
    setSearchParam(searchParam);
  };

  // Services Type
  const serviceData = [
    {
      name: "1:1 Consultations",
      value: "oneToOne",
    },
    {
      name: "Webinars",
      value: "webinar",
    },
    {
      name: "Trainings",
      value: "subscription",
    },
  ];

  // Getting Today's Booking
  const fetchingTodayBooking = async () => {
    try {
      let sendData = { serviceType };

      if (serviceType === "subscription") {
        Object.assign(sendData, { subscriptionType });
      } else {
        // Remove subscriptionType if it exists
        Object.assign(sendData, { subscriptionType: undefined });
        delete sendData.subscriptionType;
      }
      onShow();
      const { data } = await todayBookingApi(sendData);
      if (data?.code === 200) {
        setTodayBooking(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "todayBookingFetching");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingTodayBooking();
  }, [serviceType, subscriptionType]);

  return (
    <div className="booking_listing _card">
      <div className="_title">
        <b className="_card_sub_title">Today’s Bookings</b>
        <Select value={serviceType} onChange={serviceHandler}>
          <option value="">Select Services</option>
          {serviceData &&
            serviceData.map((data) => (
              <option key={data?.value} value={data?.value}>
                {data?.name}
              </option>
            ))}
        </Select>
        {serviceType === "subscription" && (
          <Select
            value={subscriptionType}
            onChange={(e) => {
              searchParam.set("subscriptionType", e.target.value);
              setSearchParam(searchParam);
            }}
          >
            <option value="">Training Type</option>
            <option value="oneTime">Batch Trainings</option>
            <option value="recurring">Recurring Trainings</option>
          </Select>
        )}
      </div>

      <div className="table_wrap">
        <div className="table_inner">
          <table className="table">
            <thead>
              <tr>
              <th>S.No</th>
                {serviceType !== "webinar" && <th>user</th>}
                <th>Services</th>
                <th className="nowrap">Start Time</th>
                {/* <th className="nowrap">Booked For</th> */}
              </tr>
            </thead>
            <tbody>
              {todayBooking && todayBooking.length > 0 ? (
                todayBooking.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index+1}</td>
                      {serviceType !== "webinar" && (
                        <td className="nowrap">{data?.user || "---"}</td>
                      )}

                      <td className="nowrap">{data?.service || "---"}</td>
                      <td className="nowrap">
                        {timeFormat(data?.bookedFor) || "---"}
                      </td>
                      {/* <td className="nowrap">
                        {" "}
                        {dateFormatYear(data?.bookedFor) || "---"}
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <Placeholder text={"No Bookings For Today"} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(TodayBooking);
