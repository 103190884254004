import { Button, Input, LoadingButton, Select } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import { getFileExtension } from "utils/formValidator";

const AddChapter = ({
  addChapterModal,
  setAddChapterModal,
  addTopicHandler,
  handleChange,
  loading,
  add,
  setAdd,
  handleDocument,
  handleChapterChange,
  deleteTopicHandler,
  updateChapterHandler,
  
}) => {
  // const [loading,setLoading]=useState(false);
  return (
    <CustomModal
      className="md add_services_from "
      show={addChapterModal}
      handleClose={() => {
        setAddChapterModal(false);
        setAdd({});
      }}
    >
      <h3>Add Chapter</h3>
      {/* <p>Are you sure you want to delete this service?</p> */}

      <div className="button_wrap button_right">
        <Button
          className={"light dashboard_button"}
          onClick={() => addTopicHandler(1)}
        >
          <IoMdAdd /> Add Topic
        </Button>
      </div>

      <div className="form_field">
        <Input
          type="text"
          label="Chapters Name"
          name="name"
          placeholder="Content management"
          value={add?.name || ""}
          onChange={(e) => handleChapterChange("name", e)}
        />
      </div>
      {add?.topics && add?.topics.length > 0
        ? add?.topics.map((topic, topicIndex) => {
            return (
              <>
                <div className="form_field_wrap">
                  <div className="form_field">
                    <Input
                      type="text"
                      label="Topic Name"
                      placeholder="Design Review"
                      name="name"
                      value={topic?.name}
                      onChange={(e) => handleChange("name", e, topicIndex)}
                    />
                  </div>
                  <div className="form_field">
                    <Select
                      label="Upload Option"
                      name="uploadType"
                      value={topic?.uploadType}
                      onChange={(e) =>
                        handleChange("uploadType", e, topicIndex)
                      }
                    >
                      <option value="">Select Upload Type</option>
                      <option value="file">Document</option>
                      <option value="video">URL</option>
                    </Select>
                  </div>
                </div>

                <div className="form_field_wrap">
                  <div className="form_field">
                    {topic?.uploadType && topic?.uploadType === "video" && (
                      <Input
                        type="url"
                        label="Video Link"
                        placeholder="Enter url"
                        name="topicVIdeoLinks"
                        value={topic?.topicVIdeoLinks}
                        onChange={(e) =>
                          handleChange("topicVIdeoLinks", e, topicIndex)
                        }
                      />
                    )}{" "}
                    {topic?.uploadType && topic?.uploadType === "file" && (
                      <>
                        <label className="label d-block">Upload Document</label>
                        <label
                          className="fileUpload"
                          htmlFor={`fileUpload-${1}-${topicIndex}`}
                        >
                          <input
                            type="file"
                            name="topicsFiles"
                            id={`fileUpload-${1}-${topicIndex}`}
                            onChange={(e) =>
                              handleDocument("topicsFiles", e, topicIndex)
                            }
                            //   error={error?.image}
                          />
                          {topic?.topicsFiles ? (
                            <>
                              {topic?.topicsFiles &&
                                topic?.topicsFiles?.name &&
                                getFileExtension(topic?.topicsFiles.name)}
                            </>
                          ) : (
                            <div className="content">
                              <IoCloudUploadOutline />
                              <label
                                className="button"
                                htmlFor={`fileUpload-${1}-${topicIndex}`}
                              >
                                Browse
                              </label>
                              <span>drop a file here</span>
                              <p>*File supported .pdf & .word & .txt</p>
                            </div>
                          )}
                        </label>
                      </>
                    )}
                  </div>
                  <div className="form_field">
                    <div className="button_wrap button_right">
                      {/* {data?.topics.length ===
                                        topicIndex + 1 && (
                                        <Button
                                          className={"dashboard_button"}
                                          onClick={() =>
                                            saveChangesHandler(index)
                                          }
                                        >
                                          Save Changes
                                        </Button>
                                      )} */}

                      <button
                        type="button"
                        className={"delete_trash"}
                        onClick={() =>
                          deleteTopicHandler(topic?._id, topicIndex)
                        }
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : ""}

      <div className="button_wrap button_center">
        <Button
          className={"light"}
          onClick={() => {
            setAddChapterModal(false);
          }}
        >
          No
        </Button>
        <LoadingButton loading={loading} onClick={updateChapterHandler}>
          Yes
        </LoadingButton>
      </div>
    </CustomModal>
  );
};

export default AddChapter;
