import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// Get services for packages API
export const getPackageServicesApi = async (data) => {
  const endPoint =
    `expert/service/available-for-package` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get User package list
export const getUserPackageList = async (data) => {
  const endPoint =
    `/user/package/get-list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};
