import { pdfIcon } from "assets/icons";
import { profileImg, service1 } from "assets/images";
import { Button, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import RatingModal from "components/modals/RatingModal";
import Placeholder from "components/placeholder/Placeholder";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  cancelServicesApi,
  rateWebinarApi,
  startWebinarApi,
} from "services/userServices/startServices";
import {
  dateFormat,
  dateFormatWithTime,
  servicesStartingChecker,
  timeFormat,
} from "utils/dateFormat";
import { toastMessage } from "utils/toastMessage";

const WebinarsListUpcoming = ({
  bookingStatus,
  bookingList,
  fetchingBookingListHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const [startWebinarLoading, setStartWebinarLoading] = useState(false);
  const [error, setError] = useState("");
  const [ratingField, setRatingField] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const [cancelWebinarModal, setCancelWebinarModal] = useState(false);
  const navigate = useNavigate();

  const rateServiceHandler = (_id) => {
    setRatingField({ ...ratingField, _id: _id, serviceType: "webinar" });
    setRatingModal(true);
  };

  // Validation
  const validation = () => {
    let status = true;
    if (!ratingField?.rating) {
      setError("Please provide rating for this service");
      status = false;
    }
    return status;
  };

  // Submit Review Handler
  const submitReviewHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await rateWebinarApi(ratingField);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "rateWebinar");
          setRatingField({});
          setRatingModal(false);
          fetchingBookingListHandler();
        } else {
          toastMessage(data?.message, "error", "rateWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // OnChange Handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setRatingField({ ...ratingField, [name]: value });
  };

  // Cancel Webinar Handler
  const cancelWebinarHandler = async () => {
    if (ratingField?._id) {
      try {
        setLoading(true);
        const { data } = await cancelServicesApi({
          _id: ratingField?._id,
          serviceType: "webinar",
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "cancelWebinar");
          setRatingField({});
          fetchingBookingListHandler();
          setCancelWebinarModal(false);
        } else {
          toastMessage(data?.message, "error", "cancelWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Start Webinar Handler
  const startWebinarHandler = async (_id) => {
    if (_id) {
      try {
        setStartWebinarLoading(true);
        const { data } = await startWebinarApi({
          _id: _id,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "startWebinar");
          fetchingBookingListHandler();
        } else {
          toastMessage(data?.message, "error", "startWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setStartWebinarLoading(false);
      }
    }
  };

  const navigateHandler = (expertId, serviceId) => {
    navigate(`/webinar-detail/${expertId}/${serviceId}`);
  };

  return (
    <>
      <div className="card_wrapper">
        {bookingList && bookingList.length ? (
          bookingList.map((data) => {
            return (
              <div className="item">
                <div
                  className="_card"
                  // onClick={() =>
                  //   navigateHandler(data?.expert, data?.service?._id)
                  // }
                >
                  <div
                    className="image_wrap"
                    onClick={() =>
                      navigateHandler(data?.expert, data?.service?._id)
                    }
                  >
                    <img src={data?.service?.image || service1} alt="" />
                    <span className="tag">
                      {data?.service?.duration || "---"}min
                    </span>
                  </div>
                  <div className="content">
                    <p className="joining_members">
                      {" "}
                      <div className="_avatar">
                        <img src={profileImg} alt="" />
                        <img src={profileImg} alt="" />
                        <img src={profileImg} alt="" />
                        <img src={profileImg} alt="" />
                      </div>{" "}
                      2K+ People join this webinars
                    </p>
                    <p className="timing">
                      Start Date & Time :{" "}
                      {`${
                        dateFormat(data?.service?.webinarAvailability) || "---"
                      }${" "}${timeFormat(
                        data?.service?.webinarAvailability || "---"
                      )}`}
                    </p>
                    <h4>{data?.service?.title || "---"} </h4>
                    {data?.service?.description ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.service?.description,
                        }}
                      ></p>
                    ) : (
                      <p
                      // onClick={() =>
                      //   navigateHandler(data?.expert, data?.service?._id)
                      // }
                      >
                        ---
                      </p>
                    )}
                    {bookingStatus === "upcoming" ? (
                      <>
                        <div className="button_wrap">
                          <LoadingButton
                            loading={loading}
                            className={"w-100"}
                            onClick={() => {
                              startWebinarHandler(data?._id);
                              window.open(data?.meetingLink);
                            }}
                            isDisabled={
                              !servicesStartingChecker(
                                data?.service?.webinarAvailability,
                                data?.service?.duration,
                                null
                              )
                            }
                          >
                            Start Webinars
                          </LoadingButton>
                          <LoadingButton
                            loading={startWebinarLoading}
                            className={"w-100"}
                            onClick={() => {
                              setCancelWebinarModal(true);
                              setRatingField({
                                ...ratingField,
                                _id: data?._id,
                              });
                            }}
                            isDisabled={
                              !servicesStartingChecker(
                                data?.service?.webinarAvailability,
                                null,
                                1
                              )
                            }
                          >
                            Cancel Webinar
                          </LoadingButton>
                        </div>
                      </>
                    ) : bookingStatus === "completed" ? (
                      <>
                        <div className="button_wrap">
                          <LoadingButton
                            isDisabled={data?.isRated}
                            loading={loading}
                            className={"w-100 light"}
                            onClick={() => rateServiceHandler(data?._id)}
                          >
                            Rate Service
                          </LoadingButton>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Placeholder text="No Bookings For Webinar" />
        )}
      </div>

      {/* Rating Webinar Services Modal */}
      {ratingModal && (
        <RatingModal
          show={ratingModal}
          handleClose={() => {
            setRatingModal(false);
            setRatingField({});
            setError("");
          }}
          ratingField={ratingField}
          setRatingField={setRatingField}
          onChangeHandler={onChangeHandler}
          loading={loading}
          action={submitReviewHandler}
          error={error}
        />
      )}

      {/* Cancel Webinar Modal */}
      <CustomModal
        show={cancelWebinarModal}
        handleClose={() => {
          setCancelWebinarModal(false);
          setRatingField({});
        }}
        className={"logoutModal"}
      >
        <h3>Cancel Service</h3>
        <p>Are you sure you want to cancel this service?</p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setCancelWebinarModal(false);
              setRatingField({});
            }}
          >
            No
          </Button>
          <Button onClick={cancelWebinarHandler}>Yes</Button>
        </div>
      </CustomModal>
    </>
  );
};

export default WebinarsListUpcoming;
