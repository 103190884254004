import { pdfIcon } from "assets/icons";
import { imagePlaceholder, profileImg, service1 } from "assets/images";
import { Button, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import RatingModal from "components/modals/RatingModal";
import Placeholder from "components/placeholder/Placeholder";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  cancelServicesApi,
  rateWebinarApi,
  startOneTimeSubscription,
  startWebinarApi,
} from "services/userServices/startServices";
import {
  convertTo12HourFormat,
  dateFormat,
  dateFormatWithTime,
  mergeSubscriptionTimeDate,
  servicesStartingChecker,
  startSubscriptionChecker,
} from "utils/dateFormat";
import { toastMessage } from "utils/toastMessage";

const SubscriptionListUpcoming = ({
  bookingStatus,
  bookingList,
  fetchingBookingListHandler,
  subscriptionType,
}) => {
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [startWebinarLoading, setStartWebinarLoading] = useState(false);
  const [error, setError] = useState("");
  const [ratingField, setRatingField] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const [cancelWebinarModal, setCancelWebinarModal] = useState(false);
  const navigate = useNavigate();

  const rateServiceHandler = (_id) => {
    setRatingField({ ...ratingField, _id: _id, serviceType: "webinar" });
    setRatingModal(true);
  };

  // Validation
  const validation = () => {
    let status = true;
    if (!ratingField?.rating) {
      setError("Please provide rating for this service");
      status = false;
    }
    return status;
  };

  // Submit Review Handler
  const submitReviewHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await rateWebinarApi(ratingField);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "rateWebinar");
          setRatingField({});
          setRatingModal(false);
          fetchingBookingListHandler();
        } else {
          toastMessage(data?.message, "error", "rateWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // OnChange Handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setRatingField({ ...ratingField, [name]: value });
  };

  // Cancel Webinar Handler
  const cancelWebinarHandler = async () => {
    if (ratingField?._id) {
      try {
        setCancelLoading(true);
        const { data } = await cancelServicesApi({
          _id: ratingField?._id,
          serviceType: "subscription",
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "cancelWebinar");
          setRatingField({});
          fetchingBookingListHandler();
          setCancelWebinarModal(false);
        } else {
          toastMessage(data?.message, "error", "cancelWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setCancelLoading(false);
      }
    }
  };

  // Start Webinar Handler
  const startSubscriptionHandler = async (_id) => {
    if (_id) {
      try {
        setStartWebinarLoading(true);
        const { data } = await startOneTimeSubscription({
          _id: _id,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "startSubscriptionHandler");
          fetchingBookingListHandler();
        } else {
          toastMessage(data?.message, "error", "startSubscriptionHandler");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setStartWebinarLoading(false);
      }
    }
  };

  const navigateHandler = (expertId, serviceId) => {
    navigate(`/subscription-detail/${expertId}/${serviceId}`);
  };

  return (
    <>
      <div className="card_wrapper">
        {bookingList && bookingList.length ? (
          bookingList.map((data) => {
            return (
              <div className="item">
                <div
                  className="_card"
                  // onClick={() =>
                  //   navigateHandler(data?.expert, data?.service?._id)
                  // }
                >
                  <div
                    className="image_wrap"
                    onClick={() =>
                      navigateHandler(data?.expert, data?.service?._id)
                    }
                  >
                    <img src={data?.service?.image || imagePlaceholder} alt="" />
                    <span className="tag">
                      {data?.slot?.duration || "---"}min
                    </span>
                  </div>
                  <div className="content">
                    <ul className="slots_detail">
                      <li>
                        {subscriptionType === "recurring" && (
                          <>
                            <p className="timing">
                              <b>Start Date</b>{" "}
                              {dateFormat(
                                data?.subscriptions[
                                  data?.subscriptions.length - 1
                                ]?.currentStart
                              ) || "---"}
                            </p>

                            <p className="timing">
                              <b> End Date</b>{" "}
                              {dateFormat(
                                data?.subscriptions[
                                  data?.subscriptions.length - 1
                                ]?.currentEnd
                              ) || "---"}
                            </p>
                          </>
                        )}
                        {subscriptionType === "oneTime" && (
                          <>
                            <p className="timing">
                              <b>Start Date</b>{" "}
                              {dateFormat(
                                data?.service?.subscriptionAvailability
                                  ?.startDate
                              ) || "---"}
                            </p>

                            <p className="timing">
                              <b>End Date</b>{" "}
                              {dateFormat(
                                data?.service?.subscriptionAvailability?.endDate
                              ) || "---"}
                            </p>
                          </>
                        )}
                      </li>
                      <li>
                        <p className="timing">
                          <b>Start Time</b>{" "}
                          {convertTo12HourFormat(data?.slot?.startTime) ||
                            "---"}
                        </p>
                        <p className="timing">
                          <b>End Time</b>{" "}
                          {convertTo12HourFormat(data?.slot?.endTime) || "---"}
                        </p>
                      </li>
                    </ul>

                    <h4>{data?.service?.title || "---"} </h4>
                    {data?.service?.description ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.service?.description,
                        }}
                      ></p>
                    ) : (
                      <p
                      // onClick={() =>
                      //   navigateHandler(data?.expert, data?.service?._id)
                      // }
                      >
                        ---
                      </p>
                    )}
                    {/* {bookingStatus === "ongoing" ? (
                      <>
                        <div className="button_wrap">
                          <LoadingButton
                            loading={loading}
                            className={"w-100"}
                            onClick={() => {
                              startSubscriptionHandler(data?._id);
                              if (data?.meetingLink) {
                                window.open(data.meetingLink, "_blank");
                              }
                            }}
                            isDisabled={
                              !servicesStartingChecker(
                                data?.slot?.startTime,
                                data?.service?.duration
                              )
                            }
                          >
                            Start
                          </LoadingButton>
                          <LoadingButton
                            loading={startWebinarLoading}
                            className={"w-100"}
                            onClick={() => {
                              setCancelWebinarModal(true);
                              setRatingField({
                                ...ratingField,
                                _id: data?._id,
                              });
                            }}
                          >
                            Cancel
                          </LoadingButton>
                        </div>
                      </>
                    ) : bookingStatus === "completed" ? (
                      <>
                        <div className="button_wrap">
                          <LoadingButton
                            isDisabled={data?.isRated}
                            loading={loading}
                            className={"w-100 light"}
                            onClick={() => rateServiceHandler(data?._id)}
                          >
                            Rate Service
                          </LoadingButton>
                        </div>
                      </>
                    ) : (
                      ""
                    )} */}
                    {(bookingStatus === "ongoing" || bookingStatus==="upcoming") &&
                      (() => {
                        const lastSubscription =
                          data?.subscriptions?.[data.subscriptions.length - 1];
                        const startDate =
                          subscriptionType === "oneTime"
                            ? data?.service?.subscriptionAvailability?.startDate
                            : lastSubscription?.currentStart;
                        const endDate =
                          subscriptionType === "oneTime"
                            ? data?.service?.subscriptionAvailability?.endDate
                            : lastSubscription?.currentEnd;
                        const startTime = data?.slot?.startTime;
                        const endTime = data?.slot?.endTime;
                        const duration = data?.slot?.duration;
                        const value = startSubscriptionChecker(
                          mergeSubscriptionTimeDate(startDate, startTime),
                          mergeSubscriptionTimeDate(endDate, endTime),
                          mergeSubscriptionTimeDate(null, startTime),
                          duration
                        );
                        if (value) {
                          return (
                            <div className="button_wrap">
                              <Button
                                className="w-100"
                                onClick={() => {
                                  startSubscriptionHandler(data?._id);

                                  // Open the expert meeting link if it exists
                                  if (data?.meetingLink) {
                                    window.open(
                                      data.meetingLink,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                Start Session
                              </Button>
                            </div>
                          );
                        } else {
                          <Button className="w-100" isDisabled={true}>
                            End Of Training
                          </Button>;
                        }
                      })()}

                    {subscriptionType === "recurring" && (
                      <div className="button_wrap">
                        <Button
                          className="w-100"
                          onClick={() => {
                            setCancelWebinarModal(true);
                            setRatingField({
                              ...ratingField,
                              _id: data?._id,
                            });
                          }}
                        >
                          Cancel Training
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Placeholder text="No Booking For Training" />
        )}
      </div>

      {/* Rating Webinar Services Modal */}
      {ratingModal && (
        <RatingModal
          show={ratingModal}
          handleClose={() => {
            setRatingModal(false);
            setRatingField({});
            setError("");
          }}
          ratingField={ratingField}
          setRatingField={setRatingField}
          onChangeHandler={onChangeHandler}
          loading={loading}
          action={submitReviewHandler}
          error={error}
        />
      )}

      {/* Cancel Webinar Modal */}
      <CustomModal
        show={cancelWebinarModal}
        handleClose={() => {
          setCancelWebinarModal(false);
          setRatingField({});
        }}
        className={"logoutModal"}
      >
        <h3>Cancel Service</h3>
        <p>Are you sure you want to cancel this service?</p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setCancelWebinarModal(false);
              setRatingField({});
            }}
          >
            No
          </Button>
          <LoadingButton loading={cancelLoading} onClick={cancelWebinarHandler}>
            Yes
          </LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default SubscriptionListUpcoming;
