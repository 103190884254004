import FAQs from "pages/FAQ/FAQs";
import RefundPolicy from "pages/RefundPolicy/RefundPolicy";
import TransactionHistory from "pages/TransactionHistory/TransactionHistory";
import AboutUs from "pages/aboutUs/AboutUs";
import MyBookings from "pages/bookings/MyBookings";
import ConsultationDetail from "pages/bookings/consultation/ConsultationDetail";
import SubscriptionDetail from "pages/bookings/subscription/SubscriptionDetail";
import WebinarDetail from "pages/bookings/webinar/WebinarDetail";
import ContactUs from "pages/contactUs/ContactUs";
import DigitalContentDetail from "pages/digital-content/DigitalContentDetail";
import DigitalContentList from "pages/digital-content/DigitalContentList";
import ExploreExperts from "pages/exploreExperts/ExploreExperts";
import ExpertDetail from "pages/exploreExperts/expertDetail/expertsDetail";
import HomePage from "pages/homePage/HomePage";
import PackageDetail from "pages/packagesList/PackageDetail";
import PackagesList from "pages/packagesList/PackagesList";
import PriorityDM from "pages/priorityDM/PriorityDM";
import PriorityDMDetail from "pages/priorityDM/PriorityDMDetail";
import PrivacyPolicy from "pages/privacy-policy/PrivacyPolicy";
import Consultation from "pages/services/Consultation";
import PackageConsultantDetail from "pages/services/PackageConsultantDetail";
import PriorityDMService from "pages/services/PriorityDM";
import UserDigitalContent from "pages/services/UserDigitalContent";
import UserSubscription from "pages/services/UserSubscription";
import Webinars from "pages/services/Webinars";
import TermsCondition from "pages/terms-condition/TermsCondition";
import EditUserProfile from "userDashboard/UserDetails";

export const userRoutes = (currentType, moduleSteps, token) => {
  const userRoute = [
    {
      id: 10001,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <HomePage />,
      path: "/",
      active: true,
    },

    {
      id: 1,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <AboutUs />,
      path: "/aboutus",
      active: true,
    },
    {
      id: 100001,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <RefundPolicy />,
      path: "/refund-policy",
      active: true,
    },
    {
      id: 100002,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <AboutUs />,
      path: "/aboutus",
      active: true,
    },
    {
      id: 2,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <ContactUs />,
      path: "/contactus",
      active: true,
    },
    {
      id: 3,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <PrivacyPolicy />,
      path: "/privacy-policy",
      active: true,
    },
    {
      id: 4,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <FAQs />,
      path: "/faq",
      active: true,
    },
    {
      id: 5,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <TermsCondition />,
      path: "/terms",
      active: true,
    },
    {
      id: 6,
      // enabled: true,
      enabled: (currentType === 1 && moduleSteps > 0) || !token,
      element: <ExploreExperts />,
      path: "/explore-experts",
      active: true,
    },
    {
      id: 7,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <ExpertDetail />,
      // path: "/expert-details/:_id",
      path: "/:_id",

      active: true,
    },
    {
      id: 8,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <EditUserProfile />,
      path: "/user-detail-edit",
      active: true,
    },
    {
      id: 9,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <Consultation />,
      path: "/service/consultation/:expertId/:serviceId",
      active: true,
    },
    {
      id: 10,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <Webinars />,
      path: "/service/webinar/:expertId/:serviceId",
      active: true,
    },
    {
      id: 11,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <PriorityDMService />,
      path: "/service/prioritydm/:expertId/:serviceId",
      active: true,
    },
    {
      id: 12,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <UserSubscription />,
      path: "/service/subscription/:expertId/:serviceId",
      active: true,
    },
    {
      id: 13,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <UserDigitalContent />,
      path: "/service/digital/:expertId/:serviceId",
      active: true,
    },
    {
      id: 14,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <PackagesList />,
      path: "/packages-list",
      active: true,
    },
    {
      id: 15,
      // enabled: true,
      enabled:
        (currentType === 1 && moduleSteps > 0) || !token || currentType === 0,
      element: <PackageDetail />,
      path: "/packages-list/detail/:expertId/:serviceId",
      active: true,
    },
    {
      id: 16,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <PackageConsultantDetail />,
      path:
        "/package/consultant_booking/:bookingId/:packageId/:serviceId/:expertId",
      active: true,
    },
    {
      id: 17,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <DigitalContentList />,
      path: "/digital-content-list",
      active: true,
    },
    {
      id: 18,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <DigitalContentDetail />,
      path: "/digital-content-list/detail/:expertId/:serviceId",
      active: true,
    },
    {
      id: 19,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <TransactionHistory />,
      path: "/transaction-history",
      active: true,
    },
    {
      id: 20,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <PriorityDM />,
      path: "/priorityDM",
      active: true,
    },
    {
      id: 21,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <PriorityDMDetail />,
      path: "/priorityDM-detail/:expertId/:serviceId",
      active: true,
    },
    {
      id: 22,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <ConsultationDetail />,
      path: "/consultation-detail/:expertId/:serviceId",
      active: true,
    },
    {
      id: 23,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <WebinarDetail />,
      path: "/webinar-detail/:expertId/:serviceId",
      active: true,
    },
    {
      id: 24,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <SubscriptionDetail />,
      path: "/subscription-detail/:expertId/:serviceId",
      active: true,
    },
    {
      id: 25,
      // enabled: true,
      enabled: currentType === 1 && moduleSteps > 0,
      element: <MyBookings />,
      path: "/my-bookings",
      active: true,
    },
  ].filter((data) => data?.enabled);
  return userRoute;
};
