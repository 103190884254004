import ExpertPersonalDetails from "expertDashboard/expertProfileSetup/PersonalInfo";
import React, { useEffect, useState } from "react";
import "../expertDashboard/dashboard/DashboardHome.scss";
import OTPScreen from "auth/login/section/OTPScreen";
import CustomModal from "components/modals/CustomModal";
import { CommonSelect, Input, LoadingButton } from "components/form";
import { AiFillInfoCircle } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import ReactDatePicker from "react-datepicker";
import commonMessages from "utils/commonMessages";
import { allowNumber, regex, replaceLabelWithName } from "utils/formValidator";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ImagePicker from "expertDashboard/expertProfileSetup/ImagePicker";
import { toastMessage } from "utils/toastMessage";
import { resendOtp, userIdAvailabilityApi } from "services/auth";
import { personDetailApi, personalUpdateApi } from "services/details";
import createMediaData from "utils/mediaDataSender";
import { LuCalendarDays } from "react-icons/lu";
import { cityListApi, countryListApi, stateListApi } from "services/master";
import { profilePlaceholder } from "assets/icons";
const EditUserProfile = () => {
  const [tabButton, setTabButton] = useState("personal");
  const [personalDetail, setPersonalDetail] = useState({});
  const [checkPhoneNumber, setCheckPhoneNumber] = useState("");
  const [checkEmail, setCheckEmail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [set]
  const [verifyModal, setVerifyModal] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [cityList, setCityList] = useState([]);
  const [image, setImage] = useState("");
  const [error, setError] = useState({});
  const { VALID_EMAIL, VALID_FIRST_NAME, VALID_LAST_NAME } = commonMessages;
  const { email, url, name } = regex;
  const [mobileVerificationModal, setMobileVerificationModal] = useState(false);
  const [verified, setVerified] = useState({});
  const navigate = useNavigate();
  const [availabilityLoader, setAvailabilityLoader] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);

  // Twelve years before
  const twelveYearsAgo = new Date();
  twelveYearsAgo.setFullYear(twelveYearsAgo.getFullYear() - 12);

  //   Country List
  const fetchCountryList = async () => {
    try {
      const res = await countryListApi();
      if (res?.data?.code === 200) {
        // setCountryList(res.data.data || []);
        setCountryList(
          res.data.data.map((country) => ({
            label: country.name,
            value: country.id,
          })) || []
        );
      } else {
        toastMessage(res.data.message, "error", "countryList");
        setCountryList([]);
      }
    } catch (e) {
      console.log("Error:", e.message);
    }
  };

  // State List
  const fetchStateList = async () => {
    try {
      if (selectedCountry?.value) {
        const res = await stateListApi(selectedCountry?.value);

        if (res?.data?.code === 200) {
          setStateList(
            res.data.data.map((state) => ({
              label: state.name,
              value: state.id,
            })) || []
          );
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch state list",
            "error",
            "stateList"
          );
          setStateList([]);
        }
      } else {
        setStateList([]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // City List
  const fetchCityList = async () => {
    try {
      if (selectedCountry.value && selectedState.value) {
        const res = await cityListApi(selectedState.value);
        // console.log(res, "Response");
        if (res?.data?.code === 200) {
          setCityList(
            res.data.data.map((city) => ({
              label: city.name,
              value: city.id,
            })) || []
          );
        } else {
          toastMessage(
            res?.data?.message || "Failed to fetch city list",
            "error",
            "cityList"
          );
          setCityList([]);
        }
      } else {
        setCityList([]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Fetching Personal Detail;
  const fetchingPersonalInfoDetail = async (screen = 1) => {
    try {
      onShow();
      const response = await personDetailApi(screen);
      if (response?.data?.code === 200) {
        setImage(response?.data?.data?.image || profilePlaceholder);
        setPersonalDetail({
          firstName: response?.data?.data?.firstName,
          lastName: response?.data?.data?.lastName,
          pincode: response?.data?.data?.pincode,
          dob: response?.data?.data?.dob,
          email: response?.data?.data?.email,
          gender: response?.data?.data?.gender,
          countryCode: response?.data?.data?.countryCode,
          phone: response?.data?.data?.phone,
          address: response?.data?.data?.address,
          confirmAccountNumber: response?.data?.data?.bank?.accountNumber,
          slug: response?.data?.data?.slug,
        });
        if (response?.data?.data?.phone && response?.data?.data?.countryCode) {
          setCheckPhoneNumber({
            phone: response?.data?.data?.phone,
            countryCode: response?.data?.data?.countryCode,
            email: response?.data?.data?.email,
          });
        }
        if (response?.data?.data?.email) {
          setCheckEmail(response?.data?.data?.email);
        }
        setVerified({
          isEmailVerified: response?.data?.data?.isEmailVerified,
          isPhoneVerified: response?.data?.data?.isPhoneVerified,
          _id: response?.data?.data?._id,
        });
        if (response?.data?.data?.city?.id) {
          setSelectedCity({
            label: response?.data?.data?.city?.name,
            value: response?.data?.data?.city?.id,
          });
        }
        if (response?.data?.data?.country?.id) {
          setSelectedCountry({
            label: response?.data?.data?.country?.name,
            value: response?.data?.data?.country?.id,
          });
        }
        if (response?.data?.data?.state?.id) {
          setSelectedState({
            label: response?.data?.data?.state?.name,
            value: response?.data?.data?.state?.id,
          });
        }
      } else {
        toastMessage(response?.data?.message, "error", "personDetail");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  const personalDetailHandler = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      setPersonalDetail({ ...personalDetail, [name]: allowNumber(value) });
    }
    if (name === "email") {
      setPersonalDetail({ ...personalDetail, [name]: value.toLowerCase() });
    } else {
      setPersonalDetail({ ...personalDetail, [name]: value });
    }
  };

  const handlePhoneChange = (phone, country) => {
    const phoneWithoutCountryCode = phone.replace(country.dialCode, "");
    setPersonalDetail({
      ...personalDetail,
      phone: phoneWithoutCountryCode,
      countryCode: `+${country?.dialCode || "+91"}`,
      completeMobile: `+${
        country?.dialCode || "+91"
      }${phoneWithoutCountryCode}`,
    });
  };

  //Check Verification Field Handler
  const checkVerificationFieldHandler = () => {
    setCheckPhoneNumber({
      email: personalDetail?.email,
      phone: personalDetail?.phone,
      countryCode: personalDetail.countryCode,
    });
    setVerified({ ...verified, isEmailVerified: true, isPhoneVerified: true });
  };

  // Input field validation
  const validation = () => {
    let status = true;
    const errors = {};
    if (!personalDetail?.firstName) {
      errors["firstName"] = "First name is mandatory";
      status = false;
    } else if (!personalDetail?.firstName.match(name)) {
      errors["firstName"] = VALID_FIRST_NAME;
      errors["firstNameErr"] = true;
      status = false;
    }
    if (!personalDetail?.lastName) {
      errors["lastName"] = "Last name is mandatory";
      status = false;
    } else if (!personalDetail?.lastName.match(name)) {
      errors["lastName"] = VALID_LAST_NAME;
      errors["lastNameErr"] = true;
      status = false;
    }
    if (!personalDetail?.gender) {
      errors["gender"] = "Gender is mandatory";
      status = false;
    }
    if (!selectedCountry?.value) {
      errors["country"] = "Country is mandatory";
      status = false;
    }
    if (!selectedState?.value) {
      errors["state"] = "State is mandatory";
      status = false;
    }
    if (!selectedCity?.value) {
      errors["city"] = "City is mandatory";
      status = false;
    }
    if (!personalDetail?.email) {
      errors["email"] = "Email is mandatory";
      status = false;
    } else if (!personalDetail?.email.match(email)) {
      errors["email"] = VALID_EMAIL;
      errors["emailErr"] = true;
      status = false;
    }
    if (!personalDetail?.phone) {
      errors["phone"] = "Mobile number is mandatory";
      status = false;
    } else if (personalDetail?.phone.length < 6) {
      errors["phone"] = "Invalid phone number";
      status = false;
    }
    if (!personalDetail?.slug) {
      errors["slug"] = "User Id is Mandatory";
      status = false;
    } else if (personalDetail?.slug.length < 3) {
      errors["slug"] = "User Id is too short";
      errors["slugErr"] = true;
      status = false;
    }
    if (!personalDetail?.address) {
      errors["address"] = "Address is mandatory";
      status = false;
    }
    if (!personalDetail?.dob) {
      errors["dob"] = "DOB is mandatory";
      status = false;
    }
    if (!personalDetail?.pincode) {
      errors["pincode"] = "Pincode is mandatory";
      status = false;
    }
    if (!image && !image?.name) {
      errors["image"] = "Image is mandatory";
      status = false;
    }
    setError(errors);
    return status;
  };

  const checkUserIdAvailability = async () => {
    if (personalDetail?.slug) {
      try {
        setAvailabilityLoader(true);
        const { data } = await userIdAvailabilityApi(personalDetail?.slug);
        if (data?.code === 200) {
          toastMessage("User Name Available", "success", "availableError");
          setIsAvailable(true);
        } else {
          toastMessage(data?.message, "error", "availableError");
          setIsAvailable(false);
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setAvailabilityLoader(false);
      }
    }
  };

  const updatePersonalDetail = async () => {
    if (validation()) {
      try {
        setLoading(true);
        delete personalDetail.completeMobile;
        delete personalDetail.confirmAccountNumber;
        const formData = new FormData();
        createMediaData(formData, personalDetail);
        if (image && image.name) {
          formData.append("image", image);
        }
        formData.append(
          "city",
          JSON.stringify(replaceLabelWithName(selectedCity))
        );
        formData.append(
          "state",
          JSON.stringify(replaceLabelWithName(selectedState))
        );
        formData.append(
          "country",
          JSON.stringify(replaceLabelWithName(selectedCountry))
        );

        const response = await personalUpdateApi(formData);
        if (response?.data?.code === 200) {
          toastMessage(
            response?.data?.message,
            "success",
            "profileInfoUpdated"
          );
          navigate("/");
        } else {
          toastMessage(response?.data?.message, "error", "profileInfoUpdated");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Verify Otp Handler
  const resendOtpHandler = async (VerifyData, type, countryCode = "") => {
    try {
      const reqData = {
        _id: verified._id,
        verification: true,
      };

      if (type === "login-email") {
        if (email.test(VerifyData)) {
          reqData.email = VerifyData;
        } else {
          toastMessage("Invalid Email", "error", "Invalid Email");
          return;
        }
      } else if (type === "login-phone") {
        if (VerifyData.length >= 6) {
          reqData.phone = VerifyData;
          reqData.countryCode = countryCode;
        } else {
          toastMessage("Invalid Phone Number", "error", "Invalid Phone Number");
          return;
        }
      } else {
        toastMessage(
          "Invalid Verification Type",
          "error",
          "Invalid Verification Type"
        );
        return;
      }

      const res = await resendOtp(reqData);

      if (res?.data?.code === 200) {
        setMobileVerificationModal(true);
        toastMessage(res?.data?.message, "success", "OtpSend");
      } else {
        toastMessage(res?.data?.message, "error", "OtpSend");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchStateList();
  }, [selectedCountry.value]);

  useEffect(() => {
    fetchCityList();
  }, [selectedCountry.value && selectedState.value]);

  useEffect(() => {
    fetchCountryList();
    fetchingPersonalInfoDetail(1);
  }, []);

  return (
    <>
      <section className="user_edit_profile">
        <div className="container">
          <div className="editProfile">
            <div className="dashboard_title">
              <h4>Edit Profile</h4>
            </div>
            <div className="dashboard_content_inner">
              <div className="tab_section"></div>

              <div className="tab_content">
                <>
                  <div className="profile-setup">
                    <h3 className="title">Personal Information</h3>
                    {loader}
                    <form>
                      <ImagePicker
                        imageSelect={(img) => {
                          setImage(img);
                        }}
                        prevImage={image}
                        error={error.image}
                      />
                      <div className="form_field_wrap multiple">
                        <div className="form_field">
                          <Input
                            type="text"
                            name="firstName"
                            placeholder="Enter First Name"
                            label="First Name"
                            value={personalDetail?.firstName || ""}
                            onChange={personalDetailHandler}
                            error={error?.firstName}
                            extraError={error?.firstNameErr}
                            maxlength="25"
                          />
                        </div>
                        <div className="form_field">
                          <Input
                            type="text"
                            name="lastName"
                            placeholder="Enter Last Name"
                            label="Last Name"
                            value={personalDetail?.lastName || ""}
                            onChange={personalDetailHandler}
                            error={error?.lastName}
                            extraError={error?.lastNameErr}
                            maxlength="25"
                          />
                        </div>
                        <div className="form_field">
                          <Input
                            type="text"
                            name="slug"
                            placeholder="Enter Username"
                            label="Username"
                            value={personalDetail?.slug || ""}
                            onChange={personalDetailHandler}
                            onBlur={checkUserIdAvailability}
                            error={error?.slug}
                            extraError={error?.slugErr}
                            maxlength="35"
                          />
                        </div>
                        <div className="form_field">
                          <label className="label">Gender</label>
                          <div className="radio_button_wrap">
                            <label
                              htmlFor="Male"
                              className={`radio_button input ${
                                personalDetail?.gender === "male"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="gender"
                                id="Male"
                                checked={personalDetail?.gender === "male"}
                                value="male"
                                onChange={personalDetailHandler}
                              />
                              <span>Male</span>
                            </label>
                            <label
                              htmlFor="Female"
                              className={`radio_button input ${
                                personalDetail?.gender === "female"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="gender"
                                id="Female"
                                checked={personalDetail?.gender === "female"}
                                value="female"
                                onChange={personalDetailHandler}
                              />
                              <span>Female</span>
                            </label>
                            <label
                              htmlFor="Others"
                              className={`radio_button input ${
                                personalDetail?.gender === "others"
                                  ? "active"
                                  : ""
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="gender"
                                id="Others"
                                checked={personalDetail?.gender === "others"}
                                value="others"
                                onChange={personalDetailHandler}
                              />
                              <span>Others</span>
                            </label>
                          </div>
                          {error.gender && !personalDetail.gender && (
                            <div className="invalid-feedback d-block">
                              {error.gender}
                            </div>
                          )}
                        </div>
                        <div className="form_field">
                          <label className="label">Date of Birth</label>
                          <ReactDatePicker
                            className="form-control input"
                            showIcon
                            icon={<LuCalendarDays />}
                            selected={personalDetail?.dob}
                            showMonthDropdown
                            useShortMonthInDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={300}
                            placeholderText={"Enter your DOB"}
                            onChange={(date) =>
                              setPersonalDetail({
                                ...personalDetail,
                                dob: date,
                              })
                            }
                            maxDate={twelveYearsAgo}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                          {error.dob && !personalDetail.dob && (
                            <div className="invalid-feedback d-block">
                              {error.dob}
                            </div>
                          )}
                        </div>
                        <div className="form_field has_verify">
                          <Input
                            type="email"
                            name="email"
                            placeholder="Enter Email Address"
                            label="Email Address"
                            value={personalDetail?.email || ""}
                            onChange={personalDetailHandler}
                            error={error?.email}
                            extraError={error?.emailErr}
                          />
                          {/* {(!verified?.isEmailVerified ||
                personalDetail?.email !== checkPhoneNumber?.email) && (
                <span
                  className="verify_button"
                  onClick={() => {
                    setVerified({
                      ...verified,
                      email: personalDetail.email,
                      authType: "login-email",
                    });
                    resendOtpHandler(personalDetail?.email, "login-email");
                  }}
                >
                  Verify
                </span>
              )} */}

                          {(!verified?.isEmailVerified ||
                            (personalDetail?.email &&
                              personalDetail.email !==
                                checkPhoneNumber?.email)) && (
                            <span
                              className="verify_button"
                              onClick={() => {
                                setVerified({
                                  ...verified,
                                  email: personalDetail?.email,
                                  authType: "login-email",
                                });
                                resendOtpHandler(
                                  personalDetail?.email,
                                  "login-email"
                                );
                              }}
                            >
                              Verify
                            </span>
                          )}
                        </div>
                        <div className="form_field has_verify">
                          <label className="label">Phone Number</label>
                          <PhoneInput
                            className={"country_picker"}
                            country={"in"}
                            value={`${personalDetail.countryCode}${personalDetail.phone}`}
                            enableSearch={true}
                            placeholder={"Enter Whatsapp Number"}
                            countryCodeEditable={true}
                            onChange={handlePhoneChange}
                          />
                          {/* {(!verified?.isPhoneVerified || (personalDetail?.phone && personalDetail?.countryCode &&  personalDetail?.phone !== checkPhoneNumber?.phone ||
                personalDetail?.countryCode !==
                  checkPhoneNumber?.countryCode ))
               ) && (
                <span
                  className="verify_button"
                  onClick={() => {
                    setVerified({
                      ...verified,
                      phone: personalDetail?.phone,
                      countryCode: personalDetail.countryCode,
                      authType: "login-phone",
                    });
                    resendOtpHandler(
                      personalDetail?.phone,
                      "login-phone",
                      personalDetail?.countryCode
                    );
                  }}
                >
                  Verify
                </span>
              )} */}

                          {(!verified?.isPhoneVerified ||
                            (personalDetail?.phone &&
                              personalDetail?.countryCode &&
                              (personalDetail.phone !==
                                checkPhoneNumber?.phone ||
                                personalDetail.countryCode !==
                                  checkPhoneNumber?.countryCode))) && (
                            <span
                              className="verify_button"
                              onClick={() => {
                                setVerified({
                                  ...verified,
                                  phone: personalDetail?.phone,
                                  countryCode: personalDetail?.countryCode,
                                  authType: "login-phone",
                                });
                                resendOtpHandler(
                                  personalDetail?.phone,
                                  "login-phone",
                                  personalDetail?.countryCode
                                );
                              }}
                            >
                              Verify
                            </span>
                          )}

                          {error.phone && (
                            <span className="invalid-feedback d-block">
                              {error.phone}
                            </span>
                          )}
                        </div>
                        <div className="form_field address">
                          <Input
                            type="text"
                            name="address"
                            className="form-control input"
                            placeholder="Enter  Address"
                            label="Enter Address"
                            value={personalDetail?.address || ""}
                            onChange={personalDetailHandler}
                            error={error?.address}
                          />
                        </div>
                        <div className="form_field">
                          {/* <Select
                className="form-control input"
                label="Select Country"
                value={personalDetail?.country}
                name="country"
                onChange={personalDetailHandler}
                error={error?.country}
              >
                <option>Select Country</option>
                {countryList &&
                  countryList.length > 0 &&
                  countryList.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    );
                  })}
              </Select> */}

                          <CommonSelect
                            className="form-control input"
                            label={"Select Country"}
                            options={countryList}
                            onChange={(selectedData) => {
                              setSelectedCountry(selectedData);
                              setSelectedState("");
                              setSelectedCity("");
                              setPersonalDetail({
                                ...personalDetail,
                                pincode: "",
                              });
                            }}
                            placeholder={"Select Country"}
                            value={selectedCountry}
                            isMulti={false}
                            isSearchable={true}
                            maxOptions={3}
                            error={!selectedCountry.value && error.country}
                          />
                        </div>

                        {/* <div className="form_field">
              <Select
                className="form-control input"
                label="Select State"
                value={personalDetail?.state}
                name="state"
                onChange={personalDetailHandler}
                error={error?.state}
              >
                <option>Select State</option>
                {stateList &&
                  stateList.length > 0 &&
                  stateList.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    );
                  })}
              </Select>
            </div> */}

                        <div className="form_field">
                          <CommonSelect
                            className="form-control input"
                            isDisabled={!selectedCountry.value}
                            label={"Select State"}
                            options={stateList}
                            onChange={(selectedData) => {
                              setSelectedState(selectedData);
                              setSelectedCity("");
                              setPersonalDetail({
                                ...personalDetail,
                                pincode: "",
                              });
                            }}
                            placeholder={"Select State"}
                            value={selectedState}
                            isMulti={false}
                            isSearchable={true}
                            maxOptions={3}
                            error={!selectedState.value && error.state}
                          />
                        </div>

                        <div className="form_field">
                          <CommonSelect
                            className="form-control input"
                            isDisabled={
                              !selectedCountry.value || !selectedState.value
                            }
                            label={"Select City"}
                            options={cityList}
                            onChange={(selectedData) => {
                              setSelectedCity(selectedData);
                              setPersonalDetail({
                                ...personalDetail,
                                pincode: "",
                              });
                            }}
                            placeholder={"Select City"}
                            value={selectedCity}
                            isMulti={false}
                            isSearchable={true}
                            maxOptions={3}
                            error={!personalDetail.value && error.city}
                          />
                        </div>

                        <div className="form_field">
                          <Input
                            type="text"
                            className="form-control input"
                            placeholder="Enter Pincode"
                            maxlength="6"
                            name="pincode"
                            label="Pincode"
                            value={personalDetail?.pincode || ""}
                            onChange={personalDetailHandler}
                            error={error?.pincode}
                          />
                        </div>
                      </div>

                      <div className="button_wrap dashboard">
                        {/* <p className="information">
              <AiFillInfoCircle />
              To set up your profile, please provide the following details.
            </p> */}
                        <LoadingButton
                          type="button"
                          loading={loading}
                          onClick={updatePersonalDetail}
                        >
                          Save and Next
                        </LoadingButton>
                      </div>
                    </form>
                  </div>

                  <CustomModal
                    show={mobileVerificationModal}
                    handleClose={() => setMobileVerificationModal(false)}
                  >
                    <OTPScreen
                      loginData={verified}
                      closeModal={() => setMobileVerificationModal(false)}
                      type="verification"
                      tempValueHandler={checkVerificationFieldHandler}
                    />
                  </CustomModal>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditUserProfile;
