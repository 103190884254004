import {
  PriorityDMsIcon2,
  crownIcon2,
  on2onIcon2,
  profilePlaceholder,
  webinarIcon,
} from "assets/icons";
import { imagePlaceholder } from "assets/images";
import ServicesCard from "components/cards/servicesCard/ServicesCard";
import { Button } from "components/form";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { Link, useSearchParams } from "react-router-dom";
import { getServicesApi } from "services/expertServices/manage";
import { serviceCardData } from "staticData";
import { charLimit } from "utils/mediaDataSender";
import { toastMessage } from "utils/toastMessage";

const DigitalContents = () => {
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [digitalContentList, setDigitalContentList] = useState([]);
  const [activeClass, setActiveClass] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [total, setTotal] = useState(null);
  const [showSimmer, setShowShimmer] = useState(false);
  let activePage = +searchParam.get("page") || 0;
  const [digitalContent, setDigitalContent] = useState("course");
  const digitalType = searchParam.get("digitalType") || "course";

  const digitalData = [
    {
      image: on2onIcon2,
      name: "Digital Course",
      value: "course",
    },
    {
      image: webinarIcon,
      name: "Digital Product",
      value: "product",
    },
  ];

  const fetchingServicesData = async () => {
    try {
      setShowShimmer(true);
      const sendData = {
        page: `${activePage}`,
        pageSize: "10",
        serviceType: "digital",
        digitalContentType:digitalType
      };

      setDigitalContentList([]);
      const { data } = await getServicesApi(sendData);
      if (data?.code === 200) {
        setDigitalContentList(data?.data || []);
        setTotal(data?.result?.total || 0);
      } else {
        setDigitalContentList([]);
        setTotal(0);
        toastMessage(data.message, "error", "digitalList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setShowShimmer(false);
    }
  };

  const digitalContentHandler = (value) => {
    setDigitalContent(value);
  };

  const digitalContentTypeHandler = (type) => {
    if(type===digitalType){
      searchParam.delete("digitalType");
      setSearchParam(searchParam);
    }
    searchParam.set("digitalType", type);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    fetchingServicesData();
  }, []);

  return (
    <>
      <div className="digital_contents">
        <div className="dashboard_title">
          <h3>Digital Contents</h3>
        </div>
        <div className="dashboard_content_inner">
        <div className="tab_section flex">
          <ul>
            <li>
              <button
                type="button"
                onClick={() => digitalContentTypeHandler("course")}
                className={`tab_button 
                  ${digitalType === "course" ? "active" : ""}
                  `}
              >
                Digital Course
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => digitalContentTypeHandler("product")}
                className={`tab_button ${
                  digitalType === "product" ? "active" : ""
                }`}
              >
              Digital Product
              </button>
            </li>
          </ul>
          {/* <div className="tab_section flex pt-0"> */}
            {/* <h5>Digital Contents</h5> */}
            <div className="button_section">
              <Button
                className="dashboard_button black light"
                onClick={() => setAddServiceModal(true)}
              >
                <IoMdAdd /> Add Content
              </Button>
            </div>
          </div>

          <div className="card_wrapper">
            {/* <div className="card_wrapper"> */}
            {digitalContentList && digitalContentList.length > 0 ? (
              digitalContentList.map((item) => {
                return (
                  <>
                    <ServicesCard
                      key={item?._id}
                      url={
                        item?.digitalContentType === "course"
                          ? `/dashboard/digital-contents/view/${item.serviceType}/${item?._id}/${item?.digitalContentType}`
                          : `/dashboard/digital-contents/view/digitalProduct/${item.serviceType}/${item?._id}/${item?.digitalContentType}`
                      }
                      isScheduled={item?.isScheduled}
                      image={item?.image || imagePlaceholder}
                      duration={item?.duration}
                      title={item?.title}
                      isHidden={item?.isHidden}
                      description={item?.description}
                      pricing={item?.pricing}
                      serviceType={item?.serviceType}
                      webinarAvailability={item?.webinarAvailability}
                      fetchingServicesData={fetchingServicesData}
                    />
                  </>
                );
              })
            ) : showSimmer ? (
              Array.from({ length: 6 }).map((_, i) => (
                <ServicesCard key={i} showSimmer={showSimmer} />
              ))
            ) : (
              <Placeholder text={"Digital Content services not available"} />
            )}
          </div>
        </div>
        {/* </div> */}
      </div>

      <CustomModal
        show={addServiceModal}
        handleClose={() => setAddServiceModal(false)}
        className={"addServiceModal"}
      >
        <h3>Select Service</h3>
        <p>Please select the category in which you like to add this service</p>
        <ul>
          {digitalData && digitalData.length > 0
            ? digitalData.map((data) => {
                return (
                  <li
                    className={`${
                      digitalContent === data?.value ? "active" : ""
                    }`}
                    onClick={(e) => {
                      digitalContentHandler(data?.value);
                      e.preventDefault();
                    }}
                  >
                    <img src={data?.image} alt="" />
                    <span>{data?.name}</span>
                  </li>
                );
              })
            : ""}
        </ul>
        <div className="button_wrap button_center">
          <Link
            to={`/dashboard/digital-contents/${digitalContent}/add`}
            className="button w-100"
          >
            Next
          </Link>
        </div>
      </CustomModal>
    </>
  );
};

export default DigitalContents;
