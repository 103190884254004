import {
    instance as axios,
    setJwtToken,
    setMultiPartHeader,
  } from "../../../config/axiosInstance";
  
  // Get Notification API
  export const getNotificationUserApi = async (data) => {
    const endPoint =
      `user/notification/get-list` +
      "?" +
      new URLSearchParams({ ...data }).toString();
    return await axios.get(endPoint, setJwtToken());
  };
  
  // Mark All As Read
  export const markAllAsReadUserApi = async () => {
    const endPoint = `user/notification/mark-all-as-read`;
    return await axios.patch(endPoint, {}, setJwtToken());
  };
  
  // Mark  As Read
  export const markAsReadUserApi = async (data) => {
    const endPoint = `user/notification/mark-read`;
    return await axios.patch(endPoint, data, setJwtToken());
  };
  
  // New Notification ALert
  export const newNotificationUserAlert = async () => {
      const endPoint = `user/notification/new-alert`;
      return await axios.get(endPoint, setJwtToken());
    };
  