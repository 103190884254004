import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../../config/axiosInstance";

// Get Expert Service Detail API
export const getExpertServiceDetailApi = async (expertId, serviceId) => {
  const endPoint = `user/services/details/${serviceId}/${expertId}`;
  return await axios.get(endPoint, setJwtToken());
};

//   Get service Detail Slots
export const getExpertServiceDetailSlotApi = async (
  expertId,
  serviceId,
  data
) => {
  const endPoint =
    `user/services/details-slots/${serviceId}/${expertId}` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Booking Priority Dms
export const bookPriorityDmAPi = async (data) => {
  // const endPoint = `user/services/book-priority-dm`;
  const endPoint = `user/services/book1`;
  return await axios.post(endPoint, data, setJwtToken());
};

// Booking 1to1 Consultation
export const bookOneToOneApi = async (data) => {
  // const endPoint = `user/services/book`;
  const endPoint = `user/services/book1`;
  return await axios.post(endPoint, data, setJwtToken());
};

// Book Packages Services
export const bookPackageServices = async (data) => {
  const endPoint = `user/package/book-service`;
  return await axios.post(endPoint, data, setJwtToken());
};

// Verify Signature
export const verifySignatureApi = async (data) => {
  const endPoint = `admin/transaction/verify-signature`;
  return await axios.post(endPoint, data, setJwtToken());
};
