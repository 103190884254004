import { instance as axios, setJwtToken } from '../../config/axiosInstance'

// ---------------------login----------------------------------
export const loginApi = async (data) => {
  return await axios.post('account/auth', data)
}

// ---------------------Verify Otp----------------------------------
export const verifyOtpApi = async (data) => {

    return await axios.patch("account/verify-otp", data);
  };

  // ---------------------Resend Otp----------------------------------
export const resendOtp = async (data) => {
    return await axios.patch("account/resend-otp", data);
  };

    // ---------------------Switch Account----------------------------------
export const switchAccountApi = async (data) => {
  return await axios.patch("account/switch-profile",data, setJwtToken());
};

// -------------------------Check UserId Availability------------
export const userIdAvailabilityApi = async (data) => {
  return await axios.get(`account/check-slug-availability?slug=${data}`, setJwtToken());
};