// import React, { useEffect, useState } from "react";
// // import WebinarsListCompleted from "./WebinarsListCompleted";
// // import WebinarsListCancelled from "./WebinarsListCancelled";
// // import WebinarsListUpcoming from "./WebinarsListUpcoming";
// import { useSearchParams } from "react-router-dom";
// import { toastMessage } from "utils/toastMessage";
// import FullPageLoader from "components/fullPageLoader/FullPageLoader";
// import {
//   getSubscriptionListApi,
//   getWebinarListApi,
// } from "services/userServices/servicesListing";
// import SubscriptionListUpcoming from "./SubscriptionListUpcoming";

// const SubscriptionList = ({ serviceType, subscriptionType }) => {
//   const [loader, onShow, onHide] = FullPageLoader();
//   const [bookingList, setBookingList] = useState([]);
//   const [searchParam, setSearchParam] = useSearchParams();
//   let page = +searchParam.get("page") || 1;
//   let type = searchParam.get("type") || serviceType;
//   let bookingStatus = searchParam.get("bookingStatus") || "upcoming";

//   const booking = [
//     { name: "Ongoing", value: "ongoing" },
//     { name: "Upcoming", value: "upcoming" },
//     { name: "Completed", value: "completed" },
//     { name: "Cancelled", value: "cancelled" },
//   ];

//   let filteredBooking = booking;

//   if (subscriptionType === "oneTime") {
//     // Remove "Cancelled" status for one-time subscriptions
//     filteredBooking = booking.filter((item) => item.value !== "cancelled");
//   }

//   if (subscriptionType === "recurring") {
//     // Only keep "Ongoing" and "Cancelled" statuses for recurring subscriptions
//     filteredBooking = booking.filter(
//       (item) => item.value === "ongoing" || item.value === "cancelled"
//     );
//   }
//   const bookingStatusHandler = (service) => {
//     searchParam.set("bookingStatus", service);
//     setSearchParam(searchParam);
//   };

//   const fetchingBookingListHandler = async () => {
//     try {
//       onShow();
//       const sendData = {};
//       sendData.bookingStatus = bookingStatus;
//       sendData.pageSize = 10;
//       sendData.page = page - 1;
//       sendData.subscriptionType = subscriptionType;
//       const { data } = await getSubscriptionListApi(sendData);
//       if (data?.code === 200) {
//         setBookingList(data?.data);
//       } else {
//         toastMessage(data?.message, "error", "BookingServicesList");
//       }
//     } catch (e) {
//       console.log(e.message || "something went wrong");
//     } finally {
//       onHide();
//     }
//   };

//   useEffect(() => {
//     // if (page === 0) {
//     //   searchParam.delete("page");
//     //   setSearchParam(searchParam);
//     // }
//     fetchingBookingListHandler();
//   }, [searchParam]);

//   return (
//     <div className="card_section webinar">
//       <div className="sideBar">
//         <ul>
//           {booking &&
//             booking.length &&
//             booking.map((data) => (
//               <li
//                 key={data?.value}
//                 className={`${bookingStatus === data?.value ? "active" : ""}`}
//                 onClick={() => bookingStatusHandler(data?.value)}
//               >
//                 {data?.name}
//               </li>
//             ))}
//         </ul>
//       </div>
//       {
//         <SubscriptionListUpcoming
//           bookingStatus={bookingStatus}
//           setBookingList={setBookingList}
//           bookingList={bookingList}
//           fetchingBookingListHandler={fetchingBookingListHandler}
//         />
//       }
//     </div>
//   );
// };

// export default SubscriptionList;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { getSubscriptionListApi } from "services/userServices/servicesListing";
import SubscriptionListUpcoming from "./SubscriptionListUpcoming";

const SubscriptionList = ({ serviceType, subscriptionType }) => {
  const [loader, onShow, onHide] = FullPageLoader();
  const [bookingList, setBookingList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  let type = searchParam.get("type") || serviceType;
  let bookingStatus = searchParam.get("bookingStatus") || "ongoing";

  const booking = [
    { name: "Ongoing", value: "ongoing" },
    { name: "Upcoming", value: "upcoming" },
    { name: "Completed", value: "completed" },
    { name: "Cancelled", value: "cancelled" },
  ];

  let filteredBooking = booking;

  // Adjust booking list based on subscriptionType
  if (subscriptionType === "oneTime") {
    // Remove "Cancelled" status for one-time subscriptions
    filteredBooking = booking.filter((item) => item.value !== "cancelled");
  } else if (subscriptionType === "recurring") {
    // Remove "Completed" and "Upcoming" statuses for recurring subscriptions
    filteredBooking = booking.filter(
      (item) => item.value !== "completed" && item.value !== "upcoming"
    );
  }

  const bookingStatusHandler = (service) => {
    searchParam.set("bookingStatus", service);
    setSearchParam(searchParam);
  };

  const fetchingBookingListHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.bookingStatus = bookingStatus;
      sendData.pageSize = 10;
      sendData.page = page - 1;
      sendData.subscriptionType = subscriptionType;
      const { data } = await getSubscriptionListApi(sendData);
      if (data?.code === 200) {
        setBookingList(data?.data);
      } else {
        toastMessage(data?.message, "error", "BookingServicesList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingBookingListHandler();
  }, [searchParam]);

  return (
    <div className="card_section webinar">
      <div className="sideBar">
        <ul>
          {filteredBooking &&
            filteredBooking.length &&
            filteredBooking.map((data) => (
              <li
                key={data?.value}
                className={`${bookingStatus === data?.value ? "active" : ""}`}
                onClick={() => bookingStatusHandler(data?.value)}
              >
                {data?.name}
              </li>
            ))}
        </ul>
      </div>
      {
        <SubscriptionListUpcoming
          bookingStatus={bookingStatus}
          setBookingList={setBookingList}
          bookingList={bookingList}
          fetchingBookingListHandler={fetchingBookingListHandler}
          subscriptionType={subscriptionType}
        />
      }
    </div>
  );
};

export default SubscriptionList;
