import React from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.scss";
import { BrowserRouter, HashRouter, Outlet } from "react-router-dom";
import Routing from "routing";
import ScrollToTop from "routing/ScrollTop";
import { Provider } from "react-redux";
import appStore from "store/appStore";
import { AxiosInterceptor } from "config/axiosInstance";
import UserProvider from "common/UserProvider";
import { HelmetProvider } from "react-helmet-async";
import AddTags from "config/AddTags";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        {/* <AddTags /> */}
        {/* <HashRouter> */}
        <BrowserRouter basename="/">
          <ScrollToTop />

          <Provider store={appStore}>
            <UserProvider>
              <AxiosInterceptor>
                <Routing />
              </AxiosInterceptor>
            </UserProvider>
          </Provider>
        {/* </HashRouter> */}
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
