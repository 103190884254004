import { banner1, imagePlaceholder, serviceImg1 } from "assets/images";
import { Button } from "components/form";
import React, { useEffect, useState } from "react";
import { LuIndianRupee } from "react-icons/lu";
import { RiEdit2Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "../ManageServices.scss";
import {
  deleteServicesApi,
  getServicesDetailApi,
} from "services/expertServices/manage";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { setServiceDetailInfo } from "store/slices/servicesDetailSlice";
import Placeholder from "components/placeholder/Placeholder";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import DeleteServices from "../DeleteServices";
import { convertTo12HourFormat } from "utils/dateFormat";
// import { weekdays } from "moment-timezone";

const ConsultationDetail = () => {
  const [loader, onShow, onHide] = FullPageLoader();
  const dispatch = useDispatch();
  const [consultantAvailability, setConsultantAvailability] = useState([]);
  const [serviceDetail, setServiceDetail] = useState({});
  const { serviceType, serviceId } = useParams();
  const [testimonial, setTestimonial] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const navigate = useNavigate();

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setServiceDetail(data?.data);
        dispatch(setServiceDetailInfo({ serviceDetailInfo: data?.data }));
        setConsultantAvailability(
          data?.data?.consultationAvailability.map((item) => {
            if (item.range && item.range.length > 0) {
              return {
                ...item,
                name: weekDays[item?.day],
              };
            }
            return item;
          }) || []
        );
        setTestimonial(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // -----------Delete Service------------
  const deleteServiceHandler = async () => {
    try {
      setDeleteLoader(true);
      const { data } = await deleteServicesApi({ _id: serviceId });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "DeleteService");
        navigate(`/dashboard/manage-services?serviceType=${serviceType}`);
      } else {
        toastMessage(data?.message, "error", "DeleteService");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  // console.log("consultantAvailability", consultantAvailability);

  return (
    <>
      {loader}
      <div className="consultation_details has_banner">
        <img src={banner1} alt="" className="banner_img" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>1:1 Consultations</h3>
          </div>

          <div className="page_banner spacing">
            <div className="banner_content">
              <img src={serviceDetail?.image || imagePlaceholder} alt="" />
              <div className="content">
                <h5>{serviceDetail?.title || "---"} </h5>
                <p className="duration">
                  Duration{" "}
                  <strong>({serviceDetail?.duration || "---"} mins)</strong>
                </p>
                <p className="price">
                  {/* <del>
                    <LuIndianRupee /> {serviceDetail?.pricing?.amount || "---"}
                  </del> */}
                  {serviceDetail?.pricing?.showSlashedAmount && (
                    <del>
                      <LuIndianRupee />{" "}
                      {serviceDetail?.pricing?.slashAmount || (
                        <Skeleton width={50} />
                      )}
                    </del>
                  )}
                  <span>
                    <LuIndianRupee />{" "}
                    {
                    // serviceDetail?.pricing?.showSlashedAmount
                    //   ? Number(
                    //       Number(serviceDetail?.pricing?.slashAmount)
                    //       -
                    //         Number(serviceDetail?.pricing?.slashAmount)
                    //     )
                    //   : 
                      Number(serviceDetail?.pricing?.amount)}
                  </span>
                </p>
              </div>
            </div>
            <div className="button_wrap button_right">
              <div className="button_wrap button_right">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <HiDotsVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={"span"}>
                      <Link
                        to={`/dashboard/manage-services/consultation/edit-schedule/${serviceId}`}
                      >
                        <RiEdit2Line /> Edit Schedule
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as={"span"}>
                      <Link
                        to={`/dashboard/manage-services/consultation/detail/edit/${serviceType}/${serviceId}`}
                      >
                        <RiEdit2Line /> Edit Service
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={"span"}
                      onClick={() => setDeleteModal(true)}
                    >
                      <HiOutlineTrash />
                      Delete Service
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* <Link
                to={`/dashboard/manage-services/consultation/edit-schedule/${serviceId}`}
                className={" button has_icon light dashboard_button"}
              >
                <RiEdit2Line /> Edit schedule
              </Link>
              <Link
                to={`/dashboard/manage-services/consultation/detail/edit/${serviceType}/${serviceId}`}
                className={" button has_icon dashboard_button"}
              >
                <RiEdit2Line /> Edit Service
              </Link> */}
            </div>
          </div>
          <div className="page_content spacing">
            <div className="highlight table_radius">
              <div class="_sub_title">
                <h4>Weekly hours</h4>
              </div>
              <ul className="content">
                <p className="text-center">
                  {serviceDetail?.isScheduled === false && "No Services Found"}
                </p>
                {consultantAvailability &&
                  consultantAvailability.length > 0 &&
                  consultantAvailability.filter((data) =>data?.status=="true").map((data,index)=> {
                    return (
                      <li key={index}>
                        <span className="day">{data?.name}</span>
                        <p className="schedule">
                          {data?.range &&
                            data.range.length > 0 &&
                            data.range.map((rangeData) => {
                              return (
                                <React.Fragment key={rangeData._id}>
                                  <span>
                                    {convertTo12HourFormat(
                                      rangeData?.startTime
                                    ) || "---"}
                                  </span>{" "}
                                  -{" "}
                                  <span>
                                    {convertTo12HourFormat(
                                      rangeData?.endTime
                                    ) || "---"}
                                  </span>
                                </React.Fragment>
                              );
                            })}
                        </p>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="overView table_radius">
              <div className="_sub_title">
                <h4>Overview</h4>
              </div>
              <div className="content">
                {/* <h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat
                </h4> */}
                {serviceDetail?.description ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: serviceDetail.description,
                    }}
                  ></p>
                ) : (
                  <p>---</p>
                )}
                {/* <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                  nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur?
                </p> */}
              </div>
            </div>
          </div>
          {serviceDetail?.video && (
            <div className="introduction_url">
              <b>Introduction Video Link</b>
              {/* <p>https://youtu.be/YIC_Ml_g_jc?si=7DmqyVOMgnpN9JvN</p> */}
              <p>{serviceDetail?.video || "---"}</p>
            </div>
          )}

          {testimonial && testimonial?.length > 0 && (
            <div className="table_radius">
              <div className="_sub_title">
                <h4>Testimonial</h4>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {/* {testimonial &&
        testimonial.map((item) => {
          return (
            <TestimonialCard
              key={item.id}
              title={item.title}
              date={item.date}
              description={item.description}
            />
          );
        })} */}
                  {testimonial && testimonial?.length > 0 ? (
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          // key={item.id}
                          // title={item.title}
                          // date={item.date}
                          // description={item.description}
                          bestTestimonials={testimonial}
                          setBestTestimonials={setTestimonial}
                          display={false}
                        />
                      );
                    })
                  ) : (
                    <Placeholder
                      text={"No testimonials found for this services"}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Delete Component */}
          {deleteModal && (
            <DeleteServices
              loading={deleteLoader}
              deleteServiceHandler={deleteServiceHandler}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultationDetail;
