import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../../config/axiosInstance";

// Get Services API
export const getExpertDetailApi = async (data) => {
  const endPoint =
    `user/explore/expert-details` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get Expert Services API
export const getExpertServicesApi = async (data) => {
  const endPoint =
    `user/explore/expert-services/${data?.id}` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

export const getSelectedExpertTestimonialsApi = async (data,id) => {
  const endPoint =
    `user/explore/expert-testimonials/${id}` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Fetching Only Services That Are Available API
export const getAvailaleServicesApi = async (id) => {
  const endPoint =
    `user/explore/expert-available-services/${id}` 
  return await axios.get(endPoint, setJwtToken());
};
