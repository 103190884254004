import {
  PriorityDMsIcon2,
  crownIcon2,
  on2onIcon2,
  webinarIcon,
} from "assets/icons";
import ServicesCard from "components/cards/servicesCard/ServicesCard";
import { Button } from "components/form";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getServicesApi } from "services/expertServices/manage";
import { serviceCardData } from "staticData";
import { charLimit } from "utils/mediaDataSender";
import { toastMessage } from "utils/toastMessage";

const CreatePackage = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [packageList, setPackageList] = useState([]);
  const [showSimmer,setShowShimmer]=useState(false);
  const [total, setTotal] = useState(null);
  const [loader, onShow, onHide] = FullPageLoader();
  let activePage = +searchParam.get("page") || 0;
  const [addServiceModal, setAddServiceModal] = useState(false);
  const navigate = useNavigate();

  const fetchingServicesData = async () => {
    try {
      setShowShimmer(true);
      const sendData = {
        page: `${activePage}`,
        pageSize: "10",
        serviceType: "package",
      };

      setPackageList([]);
      const { data } = await getServicesApi(sendData);
      if (data?.code === 200) {
        setPackageList(data?.data || []);
        setTotal(data?.result?.total || 0);
      } else {
        setPackageList([]);
        setTotal(0);
        toastMessage(data.message, "error", "offersList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    setShowShimmer(false);
    }
  };

  useEffect(() => {
    fetchingServicesData();
  }, []);

  return (
    <>
      <div className="digital_contents">
        <div className="dashboard_title">
          <h3>Package</h3>
        </div>
        <div className="dashboard_content_inner">
          <div className="tab_section flex pt-0">
            <h5>Package</h5>
            <div className="button_section">


     


              <Link
                to="/dashboard/package/add"
                className="button"
              >
                <IoMdAdd /> Add Services
              </Link>
            </div>
          </div>

          <div className="card_wrapper">
            {packageList && packageList.length>0 ?
              packageList.map((item) => {
                return (
                  <>
                    <ServicesCard
                      key={item?._id}
                      serviceId={item?._id}
                      services={item?.services}
                      url={`/dashboard/package/detail/${item.serviceType}/${item?._id}`}
                      isScheduled={item?.isScheduled}
                      image={item?.image}
                      duration={item?.duration}
                      title={item?.title}
                      isHidden={item?.isHidden}
                      description={charLimit(item?.description, 75)}
                      pricing={item?.pricing}
                      serviceType={"package"}
                      webinarAvailability={item?.webinarAvailability}
                      fetchingServicesData={fetchingServicesData}
                    />
                  </>
                );
              }) :  showSimmer ? (
                Array.from({ length: 6 }).map((_, i) => (
                  <ServicesCard key={i} showSimmer={showSimmer} />
                ))
              ) : 
                <Placeholder text={"Package Not Available"} />
                }
          </div>
        </div>
      </div>

      <CustomModal
        show={addServiceModal}
        handleClose={() => setAddServiceModal(false)}
        className={"addServiceModal"}
      >
        <h3>Select Service</h3>
        <p>Please select the category in which you like to add this service</p>
        <ul>
          <li className="active">
            <img src={on2onIcon2} alt="" />
            <span>1 : 1 Consultation</span>
          </li>
          <li>
            <img src={webinarIcon} alt="" />
            <span>Webinar</span>
          </li>
          {/* <li>
            <img src={PriorityDMsIcon2} alt="" />
            <span>Priority DMs</span>
          </li>
          <li>
            <img src={crownIcon2} alt="" />
            <span>Subscription</span>
          </li> */}
        </ul>
        <div className="button_wrap button_center">
          <Link to="/dashboard/package/edit" className="button w-100">
            Next
          </Link>
        </div>
      </CustomModal>
    </>
  );
};

export default CreatePackage;
