import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { LuFilter, LuReply } from "react-icons/lu";
import {
  Button,
  DatePicker,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { Dropdown, DropdownItem } from "react-bootstrap";
import { pdfIcon } from "assets/icons";
import CustomModal from "components/modals/CustomModal";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { getPriorityDmsApi, sendReplyApi } from "services/priorityDms";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { useSearchParams } from "react-router-dom";
import { dateFormat, dateFormatYear } from "utils/dateFormat";
import { regex } from "utils/formValidator";
import { getLocalStorage, setLocalStorage } from "config/config";
import UseDebounce from "common/UseDebounce";
import { profileImg } from "assets/images";
import "../Bookings.scss";
import WebinarListing from "./section/WebinarListing";
import { getwebinarListApi } from "services/userServices/servicesListing";
import { getOneToOneApi } from "services/oneToOne";
import { getWebinarApi } from "services/webinar";
import DateFilter from "expertDashboard/dashboard/priorityDMS/DateFilter";
import CommonPagination from "common/CommonPagination";
const BookingWebinar = () => {
  const [tabButton, setTabButton] = useState("pending");
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  let fromDate = searchParam.get("fromDate") || "";
  let toDate = searchParam.get("toDate") || "";
  const [filterData, setFilterData] = useState({});
  let bookingStatus = searchParam.get("bookingStatus") || "upcoming";
  const [ReplyModal, setReplyModal] = useState(false);
  const [attached, setAttached] = useState(false);
  const [total, setTotal] = useState(null);
  const [sendReplyData, setSendReplyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [webinarList, setWebinarList] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [error, setError] = useState("");
  const { pdfFileRegex } = regex;
  const [search, setSearch] = useState(
    getLocalStorage("webinarList", "search") || ""
  );

  const debouncedValue = UseDebounce(search, 300);

  const fetchingWebinarList = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.page = page - 1;
      sendData.pageSize = 10;
      if (debouncedValue) {
        sendData.search = debouncedValue;
        sendData.pageSize = 10;
        sendData.page=0
      }
      if (bookingStatus) {
        sendData.bookingStatus = bookingStatus;
      }
      if (fromDate && toDate) {
        sendData.fromDate = dateFormat(fromDate);
        sendData.toDate = dateFormat(toDate);
      }
      const { data } = await getWebinarApi(sendData);
      if (data?.code === 200) {
        // setSendReplyData(
        //   data?.data?.map((data) => {
        //     if (
        //       data?.expertMessage &&
        //       data?.expertMessage.isEdited.toString() === "false"
        //     ) {
        //       return data;
        //     }
        //   })
        // );
        // if (
        //   data?.data?.expertMessage &&
        //   (data?.data?.expertMessage?.isEdited).toString() === "false"
        // ) {
        //   setSendReplyData(data?.data?.expertMessage);
        // }
        setWebinarList(data?.data);
        setTotal(data?.totalItems || null);
      } else {
        toastMessage(data?.message, "error", "webinarList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  const bookingStatusHandler = (data) => {
    searchParam.set("bookingStatus", data);
    setSearchParam(searchParam);
  };

  // const imageHandler = async (e) => {
  //   e.preventDefault();
  //   if (e.target.files.length > 0) {
  //     if (pdfFileRegex.test(e.target.files[0].name)) {
  //       setSendReplyData({ ...sendReplyData, attachment: e.target.files[0] });
  //     } else {
  //       e.target.value = "";
  //       setSendReplyData({ ...sendReplyData, attachment: "" });
  //       toastMessage("Invalid Format", "error", "imageFormat");
  //     }
  //   } else {
  //     e.target.value = "";
  //     setSendReplyData({ ...sendReplyData, attachment: "" });
  //   }
  // };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  // const validation = () => {
  //   let status = true;
  //   if (!sendReplyData?.text) {
  //     setError("Message is mandatory");
  //     status = false;
  //   }
  //   return validation;
  // };

  // const sendReplyHandler = async () => {
  //   if (validation()) {
  //     try {
  //       setLoading(true);
  //       const formData = new FormData();
  //       if (sendReplyData?.attachment && sendReplyData?.attachment?.name) {
  //         formData.append("attachment", sendReplyData?.attachment);
  //       }

  //       formData.append("text", sendReplyData?.text);
  //       formData.append("_id", sendReplyData?._id);
  //       if (sendReplyData?.textedOn) {
  //         formData.append("isEdited", true);
  //       }
  //       const { data } = await sendReplyApi(formData);
  //       if (data?.code === 200) {
  //         toastMessage(data?.message, "success", "sendReply");
  //         fetchingWebinarList();
  //         setSendReplyData({});
  //         setReplyModal(false);
  //         setError("");
  //       } else {
  //         toastMessage(data?.message, "error", "sendReply");
  //       }
  //     } catch (e) {
  //       console.log(e.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  useEffect(() => {
    fetchingWebinarList();
  }, [searchParam, debouncedValue]);

  useEffect(() => {
    setLocalStorage("webinarList", "search", search);
  }, [search]);

  return (
    <>
      <div className="prioritydms bookings">
        <div className="dashboard_title">
          <h3>Bookings</h3>
        </div>
        <div className="dashboard_content_inner">
          <div className="tab_section flex">
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => bookingStatusHandler("upcoming")}
                  className={`tab_button ${
                    bookingStatus === "upcoming" ? "active" : ""
                  }`}
                >
                  Upcoming
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => bookingStatusHandler("completed")}
                  className={`tab_button ${
                    bookingStatus === "completed" ? "active" : ""
                  }`}
                >
                  Completed
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => bookingStatusHandler("cancelled")}
                  className={`tab_button ${
                    bookingStatus === "cancelled" ? "active" : ""
                  }`}
                >
                  Cancelled
                </button>
              </li>
            </ul>

            <div className="button_section">
              <div className="searchBox">
                <input
                  type="search"
                  className="input"
                  placeholder="Search By User Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch />
              </div>
              <DateFilter
                fromDate={fromDate}
                toDate={toDate}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
              />
            </div>
          </div>

          <div className="booking_content consultation">
            <h3 className="sub_title">Webinars</h3>

            <WebinarListing
              setWebinarList={setWebinarList}
              bookingStatus={bookingStatus}
              webinarList={webinarList}
              total={total}
              handlePageChange={handlePageChange}
              fetchingWebinarList={fetchingWebinarList}
            />
                  <CommonPagination
              handlePageChange={handlePageChange}
              total={total}
              activePage={page}
            />
          </div>
        </div>
      </div>

      {/* <CustomModal
        show={ReplyModal}
        handleClose={() => {
          setReplyModal(false);
          setSendReplyData({});
          setError("");
        }}
      >
        <div className="PriorityDMS_reply">
          <h3 className="title">{`Reply to ${sendReplyData?.firstName} ${" "} ${
            sendReplyData?.lastName
          }`}</h3>
          <p>Please enter your registered email address to continue</p>

          <div className="form_field">
            <label className="label">
              Enter Message{" "}
              <p className="file_upload">
                {sendReplyData?.attachment ||
                sendReplyData?.attachment?.name ? (
                  <>
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="text-decoration-underline">File name</span>
                    <button
                      type="button"
                      className="cancel_button"
                      onClick={() => {
                        setAttached(true);
                        setSendReplyData({ ...sendReplyData, attachment: "" });
                      }}
                    >
                      <IoClose />
                    </button>
                  </>
                ) : (
                  <label htmlFor="uploadFile">
                    <input
                      type="file"
                      id="uploadFile"
                      onChange={imageHandler}
                    />
                    <GrAttachment />
                    <span className="text-decoration-underline">
                      Upload Document
                    </span>
                  </label>
                )}
              </p>
            </label>
            <TextArea
              placeholder="Enter Message"
              value={sendReplyData?.text}
              onChange={(e) =>
                setSendReplyData({ ...sendReplyData, text: e.target.value })
              }
              error={error}
            />
          </div>
          <LoadingButton
            className="w-100"
            loading={loading}
            onClick={sendReplyHandler}
          >
            Submit
          </LoadingButton>
        </div>
      </CustomModal> */}
    </>
  );
};

export default BookingWebinar;
