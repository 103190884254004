import { configureStore } from "@reduxjs/toolkit";
import { loginReducer, stepsReducer } from "./loginInfoSlice";
import configSlice from "./slices/configSlice";
import serviceDetailSlice from "./slices/servicesDetailSlice";
import pixelInfoSlice from "./slices/pixelSlice";

const appStore = configureStore({
  reducer: {
    login: loginReducer,
    steps: stepsReducer,
    config: configSlice,
    serviceDetails: serviceDetailSlice,
    pixelInfo: pixelInfoSlice,
  },
});

export default appStore;
