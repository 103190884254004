import { page404 } from "assets/images";
import { Button } from "components/form";
import React from "react";
import "./page404.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authRouteHandler } from "utils/common";

const Page404 = () => {
  const {personalInfo } = useSelector(
    (state) => state.config
  );

  // console.log(personalInfo,"PersonalInfo");
  const navigate = useNavigate();



  const navigateHanlder = () => {
    if(!personalInfo?.token || !(personalInfo?.currentType).toString()==="0"){
      navigate("/")
    }else{
      authRouteHandler({
        currentType:personalInfo?.currentType,
        navigate,
        personalInfo: personalInfo,
      });
    }

  };

  return (
    <>
      <div className="page_404">
        <div className="container">
          <div className="image_wrap">
            <img src={page404} alt="" />
          </div>
          <div className="content">
            <h3>Page not found</h3>
            <p>Oops! The page you are looking for does not exist</p>
            <div className="button_wrap button_center">
              <Button onClick={navigateHanlder} type="button">
                Back to home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
