import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
import { serviceCardData, testimonial } from "staticData";
import CustomModal from "components/modals/CustomModal";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "components/cards/servicesCard/ServicesCard.scss";
import { LiaRupeeSignSolid, LiaTimesSolid } from "react-icons/lia";
import { getPackageServicesApi } from "services/packages";
import { toastMessage } from "utils/toastMessage";
import Placeholder from "components/placeholder/Placeholder";
import { LuIndianRupee } from "react-icons/lu";
import GetServices from "./GetServices";
import {
  addServicesApi,
  getServicesDetailApi,
  updateServicesApi,
} from "services/expertServices/manage";
import { useNavigate, useParams } from "react-router-dom";
import { allowNumber, regex } from "utils/formValidator";
import JoditEditor from "jodit-react";
import commonMessages, { slashedValidation } from "utils/commonMessages";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { getTestimonials } from "services/master";
import GetTestimonials from "../manageServices/GetTestimonials";
import { sendArrayDataId } from "utils/mediaDataSender";
import CoverImageCropper from "../manageServices/CoverImageCropper";

const EditPackage = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [pricing, setPricing] = useState({});
  const [serviceActivePage, setServiceActivePage] = useState(1);
  const [totalService, setTotalService] = useState(null);
  const [inputField, setInputField] = useState({});
  const [loader, onShow, onHide] = FullPageLoader();
  const [serviceDetail, setServiceDetail] = useState({});
  const { serviceType, serviceId } = useParams();
  const [testimonial, setTestimonial] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [packageServicesList, setPackageServicesList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [total, setTotal] = useState(null);
  const [active, setActive] = useState("yes");
  const [modal, setModal] = useState(false);
  const [selectServiceModal, setSelectServiceModal] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const { IMAGE_FORMAT } = commonMessages;
  const { imageFileRegex, url } = regex;

  // Fetching Package Service Detail API
  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setInputField(data?.data);
        setSelectedServices(
          (data?.data?.services || []).reduce((acc, service) => {
            acc.push({
              ...service.service,
              provided: service.provided,
            });
            return acc;
          }, [])
        );

        setPricing(
          {
            ...data?.data?.pricing,
            showSlashedAmount: data?.data?.pricing?.showSlashedAmount
              ? "yes"
              : "no",
          } || {}
        );
        setImage(data?.data?.image);
        // setTestimonial(data?.data?.testimonials || []);
        setBestTestimonials(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  const fetchingPackageServicesList = async () => {
    try {
      setPackageServicesList([]);
      const sendData = {
        page: serviceActivePage - 1,
        pageSize: 10,
      };
      const { data } = await getPackageServicesApi(sendData);
      if (data?.code === 200) {
        setPackageServicesList(
          data?.data.map((item) => ({ ...item, provided: 1 }))
        );
        setTotalService(data?.totalItems);
      } else {
        setPackageServicesList([]);
        toastMessage(data?.message, "error", "setPackageServicesList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // For Implementing Text Editor
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    setActive(event);
  };
  //  Handle Pagination for services
  const handleServicePagination = (event) => {
    setServiceActivePage(event);
  };

  // Fetching Testimonials
  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: "package",
        page: activePage - 1,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
        setTotal(data?.totalItems);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(file);
    e.target.value = null;
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };

  // Increment Services Quantity Handler
  const incrementQuantityHandler = (serviceId, qty, index) => {
    const tempData = [...selectedServices];
    tempData[index].provided = qty + 1;
    setSelectedServices(tempData);
  };

  // Decrement Services Quantity Handler
  const decrementQuantityHandler = (serviceId, qty, index) => {
    const tempData = [...selectedServices];
    if (qty > 0) {
      tempData[index].provided = qty - 1;
      setSelectedServices(tempData);
    }
  };

  //Input Field Handler
  const inputFieldHandler = (e) => {
    const { name, value } = e.target;

    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    } else if (name === "showSlashedAmount") {
      setInputField({ ...inputField, [name]: value, slashAmount: "" });
    } else if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else {
      setInputField({ ...inputField, [name]: value });
    }
  };

  // Image Handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  // Pricing Handler
  const pricingHandler = (e) => {
    const { name, value } = e.target;
    if (name === "showSlashedAmount") {
      setPricing({ ...pricing, [name]: value, slashAmount: "" });
    } else setPricing({ ...pricing, [name]: allowNumber(value) });
  };

  // Validation
  const validation = () => {
    let status = true;
    let errors = {};
    const emptyDescriptionRegex = /^<p><br><\/p>$/;
    if (
      !inputField.description ||
      emptyDescriptionRegex.test(inputField.description)
    ) {
      errors["description"] = `Description is mandatory`;
      status = false;
    }
    if (!inputField?.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    // if (!inputField?.video) {
    //   errors["video"] = "Video Url is mandatory";
    //   status = false;
    // }
    if (inputField?.video && !inputField?.video.match(url)) {
      errors["video"] = "Invalid Url";
      errors["videoErr"] = true;
      status = false;
    }
    // if (!image) {
    //   errors["image"] = "Cover image is mandatory";
    //   status = false;
    // }
    if (!pricing?.amount) {
      errors["amount"] = "Amount is mandatory";
      status = false;
    }
    if (!pricing?.showSlashedAmount) {
      errors["showSlashedAmount"] = "Show slash pricing?";
      status = false;
    }
    if (pricing?.showSlashedAmount == "yes" && !pricing?.slashAmount) {
      errors["slashAmount"] = "Enter Slash amount";
      status = false;
    }
    if (status && !selectedServices.length) {
      toastMessage(
        "Please add services in packages",
        "error",
        "EditPackagesServices"
      );
      status = false;
    }

    let { error, slashstatus } = slashedValidation(
      pricing?.showSlashedAmount,
      pricing?.amount,
      pricing?.slashAmount
    );
    if (Object.keys(error).length > 0) {
      Object.assign(errors, error);
      status = slashstatus;
    }
    setError(errors);
    return status;
  };

  // Add Package Service
  const updateServiceHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        if (image && image?.name) {
          formData.append("image", image);
        }
        if (serviceId) {
          formData.append("_id", serviceId);
        }
        formData.append(
          "pricing",
          JSON.stringify({
            ...pricing,
            showSlashedAmount:
              pricing?.showSlashedAmount === "yes" ? true : false,
          })
        );

        formData.append("description", inputField?.description);
        if (inputField?.video) {
          formData.append("video", inputField?.video);
        } else {
          formData.append("video", "");
        }
        formData.append("title", inputField?.title);
        if (bestTestimonials && bestTestimonials.length) {
          const selectedTestimonials = sendArrayDataId(bestTestimonials);
          formData.append("testimonials", JSON.stringify(selectedTestimonials));
        } else {
          formData.append("testimonials", `[]`);
        }
        formData.append("serviceType", "package");
        formData.append(
          "services",
          JSON.stringify(
            selectedServices.map((data) => ({
              serviceType: data?.serviceType,
              service: data?._id,
              provided: data?.provided,
            }))
          )
        );

        const { data } = await updateServicesApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "addWebinar");
          setInputField("");
          navigate(`/dashboard/package`);
          setError({});
        } else {
          toastMessage(data?.message, "error", "addWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchingPackageServicesList();
  }, [serviceActivePage]);

  useEffect(() => {
    if (showTestimonialModal) {
      fetchingTestimonialHandler();
    }
  }, [showTestimonialModal, activePage]);

  // console.log(selectedServices, "SelectedServices");

  return (
    <>
      <div className="edit_digital_content">
        <div className="dashboard_title">
          <h3>Create Package</h3>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
              </div>
              <div className="content_right">
                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="fileUpload">
                    <input
                      type="file"
                      name="image"
                      id="fileUpload"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="fileUpload">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>

                {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}
   <CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />

              </div>
            </form>
            <div className="form_field">
              <Input
                label={"Overview Video Link"}
                name="video"
                value={inputField?.video || ""}
                placeholder={"Enter url"}
                onChange={inputFieldHandler}
                error={error?.video}
                extraError={error?.videoErr}
              />
            </div>
            <div className="pricing">
              <h5>Pricing</h5>
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      value={pricing?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="yes"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "yes" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="yes"
                        value="yes"
                        checked={pricing?.showSlashedAmount == "yes"}
                        onChange={pricingHandler}
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      htmlFor="no"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "no" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="no"
                        value="no"
                        checked={pricing?.showSlashedAmount == "no"}
                        onChange={pricingHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={pricing?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      placeholder="Enter Amount"
                      disabled={pricing?.showSlashedAmount == "no"}
                      name="slashAmount"
                      value={pricing?.slashAmount || ""}
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                    {!pricing?.slashAmount ? (
                      <span>
                        {pricing?.amount
                          ? Number(pricing?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {pricing?.slashAmount
                            ? Number(pricing?.slashAmount)
                            : 0}
                        </del>
                        <span>
                          {pricing?.amount
                            ? // Number(pricing?.amount) -
                              Number(pricing?.amount || 0).toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="chapter_list spacing">
              <ul>
                <li>
                  <p>
                    <b>Add Services</b>
                    <span>
                      Select the best testimonials that you want to display for
                      this service
                    </span>
                  </p>

                  <Button
                    className={"dashboard_button"}
                    onClick={() => setSelectServiceModal(true)}
                  >
                    Select Services
                  </Button>
                </li>
              </ul>
            </div>

            <div className="chapter_list spacing">
              <h5>Add Services</h5>
              <ul>
                {selectedServices && selectedServices.length > 0 ? (
                  selectedServices.map((data, index) => {
                    return (
                      <li>
                        <p>
                          <b>
                            {/* Chapters 1 :  */}
                            {data?.title || "---"}
                          </b>
                          <p className="m-0 d-flex">
                            {data?.pricing?.showSlashedAmount && (
                              <del>
                                <LuIndianRupee /> {data?.pricing?.slashAmount}
                              </del>
                            )}

                            <b className="ms-2">
                              ₹{" "}
                              {
                                // data?.pricing?.showSlashedAmount
                                //   ? Number(
                                //       Number(data?.pricing?.amount) -
                                //         Number(data?.pricing?.slashAmount)
                                //     )
                                //   :
                                Number(data?.pricing?.amount)
                              }
                            </b>
                          </p>
                        </p>
                        <div className="action">
                          <div className="quantity_button">
                            <span
                              className="decrement"
                              onClick={() =>
                                decrementQuantityHandler(
                                  data?._id,
                                  data?.provided,
                                  index
                                )
                              }
                            >
                              <FaMinus />
                            </span>
                            <input
                              type="number"
                              value={data?.provided || "---"}
                            />
                            <span
                              className="increment"
                              onClick={() =>
                                incrementQuantityHandler(
                                  data?._id,
                                  data?.provided,
                                  index
                                )
                              }
                            >
                              <FaPlus />
                            </span>
                          </div>

                          <button
                            type="button"
                            onClick={() =>
                              setSelectedServices((prev) =>
                                prev
                                  .map((service) =>
                                    service?._id === data?._id
                                      ? { ...service, provided: 1 }
                                      : service
                                  )
                                  .filter(
                                    (service) => service?._id !== data?._id
                                  )
                              )
                            }
                          >
                            <LiaTimesSolid />
                          </button>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <Placeholder text={"No services is selected "} />
                )}
              </ul>
            </div>

            <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {/* {testimonial &&
                    testimonial.map((item) => {
                      return (
                        <TestimonialCard
                          key={item.id}
                          title={item.title}
                          date={item.date}
                          description={item.description}
                          deleteButton={true}
                        />
                      );
                    })} */}
                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                      display={true}
                    />
                  ) : (
                    <Placeholder text={"No Testimonials Found"} />
                  )}
                </div>
              </div>
            </div>
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={updateServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>

      {/* Get Services */}
      <GetServices
        packageServicesList={packageServicesList}
        setPackageServicesList={setPackageServicesList}
        selectServiceModal={selectServiceModal}
        setSelectServiceModal={setSelectServiceModal}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        total={totalService}
        activePage={serviceActivePage}
        handlePageChange={handleServicePagination}
      />

      {showTestimonialModal && (
        <GetTestimonials
          testimonial={testimonials}
          setTestimonial={setTestimonials}
          showTestimonialModal={showTestimonialModal}
          setShowTestimonilModal={setShowTestimonilModal}
          bestTestimonials={bestTestimonials}
          setBestTestimonials={setBestTestimonials}
          total={total}
          activePage={active}
          handlePageChange={handlePageChange}
        />
      )}
      {/* 
      <CustomModal
        className={"xl select_testimonials"}
        show={modal}
        handleClose={() => setModal(false)}
      >
        <h3>Display Testimonials for this service</h3>
        <p>
          Select the best testimonials that you want to display for this service
        </p>

        <div className="card_wrap">
          {testimonial &&
            testimonial.map((item) => {
              return (
                <>
                  <div className="inner_testimonial_card" key={item.id}>
                    <label className="item" htmlFor={item.id}>
                      <input type="checkbox" className="check" id={item.id} />
                      <div className="head">
                        <h5>{item.title}</h5>
                        <p>{item.date}</p>
                      </div>
                      <p>{item.description}</p>
                    </label>
                  </div>
                </>
              );
            })}
        </div>
        <div className="button_wrap button_right mt-3">
          <LoadingButton onClick={updateServiceHandler}>
            Select Testimonials
          </LoadingButton>
        </div>
      </CustomModal> */}

      {/* Select Services */}
      {/* <CustomModal
        className={"xl select_testimonials"}
        show={selectServiceModal}
        handleClose={() => setSelectServiceModal(false)}
      >
        <h3>Display Testimonials for this service</h3>
        <p>
          Select the best testimonials that you want to display for this service
        </p>

        <div className="card_wrap">
          {packageServicesList && packageServicesList.length > 0 ? (
            packageServicesList.map((item) => {
              return (
                <>
                  <div className="inner_testimonial_card service" key={item.id}>
                    <label className="item" htmlFor={item.id}>
                      <input type="checkbox" className="check" id={item.id} />
                      <h5>{item.title}</h5>
                      <p className="price">
                        {item?.pricing?.showSlashedAmount && (
                          <del>
                            <LuIndianRupee /> {item?.pricing?.amount}
                          </del>
                        )}

                        <span>
                          <LuIndianRupee />
                          {item?.pricing?.showSlashedAmount
                            ? Number(
                                Number(item?.pricing?.amount) -
                                  Number(item?.pricing?.slashAmount)
                              )
                            : Number(item?.pricing?.amount)}
                        </span>
                      </p>
                      <div className="bottom_text">
                        <span>(1 to 1 Consultation)</span>
                      </div>
                    </label>
                  </div>
                </>
              );
            })
          ) : (
            <Placeholder text={"No services found to select"} />
          )}
        </div>
        <div className="button_wrap button_right mt-3">
          <LoadingButton>Select Services</LoadingButton>
        </div>
      </CustomModal> */}
    </>
  );
};

export default EditPackage;
