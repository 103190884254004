import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { LuFilter, LuReply } from "react-icons/lu";
import {
  Button,
  DatePicker,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { Dropdown, DropdownItem } from "react-bootstrap";
import { pdfIcon } from "assets/icons";
import CustomModal from "components/modals/CustomModal";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { getPriorityDmsApi, sendReplyApi } from "services/priorityDms";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { useSearchParams } from "react-router-dom";
import { dateFormat, dateFormatYear } from "utils/dateFormat";
import { regex } from "utils/formValidator";
import { getLocalStorage, setLocalStorage } from "config/config";
import UseDebounce from "common/UseDebounce";
import { profileImg } from "assets/images";
import "../Bookings.scss";
// import UpcomingConsultation from "./section/UpcomingConsultation";
// import { getsubscriptionListApi } from "services/userServices/servicesListing";
import { getOneToOneApi } from "services/oneToOne";
import { getSubscriptionListApi } from "services/subscription";
import DateFilter from "expertDashboard/dashboard/priorityDMS/DateFilter";
import UpcomingSubscription from "./section/UpcomingSubscription";

const BookingSubscription = () => {
  const [tabButton, setTabButton] = useState("pending");
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  let fromDate = searchParam.get("fromDate") || "";
  let toDate = searchParam.get("toDate") || "";
  let bookingStatus = searchParam.get("bookingStatus") || "ongoing";
  let subscriptionType = searchParam.get("subscriptionType") || "oneTime";
  let recurringServiceType =
    searchParam.get("recurringServiceType") || "private";
  const [ReplyModal, setReplyModal] = useState(false);
  const [attached, setAttached] = useState(false);
  const [total, setTotal] = useState(null);
  const [sendReplyData, setSendReplyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [error, setError] = useState("");
  const { pdfFileRegex } = regex;
  const [search, setSearch] = useState(
    getLocalStorage("subscriptionList", "search") || ""
  );

  const debouncedValue = UseDebounce(search, 300);
  // console.log('bookingStatus', bookingStatus)
  const booking = [
    { name: "Ongoing", value: "ongoing" },
    { name: "Upcoming", value: "upcoming" },
    { name: "Completed", value: "completed" },
    { name: "Cancelled", value: "cancelled" },
  ];

  let filteredBooking = booking;

  // Adjust booking list based on subscriptionType
  if (subscriptionType === "oneTime") {
    // Remove "Cancelled" status for one-time subscriptions
    filteredBooking = booking.filter((item) => item.value !== "cancelled");
  } else if (subscriptionType === "recurring") {
    // Remove "Completed" and "Upcoming" statuses for recurring subscriptions
    filteredBooking = booking.filter(
      (item) => item.value !== "completed" && item.value !== "upcoming"
    );
  }

  const fetchingSubscriptionList = async () => {
    try {
      onShow();
      const sendData = {};
      if (debouncedValue) {
        sendData.search = debouncedValue;
        sendData.pageSize = 10;
      }
      sendData.page = page - 1;
      if (bookingStatus) {
        sendData.bookingStatus = bookingStatus;
      }
      if (subscriptionType) {
        sendData.subscriptionType = subscriptionType;
      }
      if (fromDate && toDate) {
        sendData.fromDate = dateFormat(fromDate);
        sendData.toDate = dateFormat(toDate);
      }
      if (subscriptionType === "recurring") {
        sendData.recurringServiceType = recurringServiceType;
      }
      const { data } = await getSubscriptionListApi(sendData);
      if (data?.code === 200) {
        setSendReplyData(
          data?.data?.map((data) => {
            if (
              data?.expertMessage &&
              data?.expertMessage.isEdited.toString() === "false"
            ) {
              return data;
            }
          })
        );
        if (
          data?.data?.expertMessage &&
          (data?.data?.expertMessage?.isEdited).toString() === "false"
        ) {
          setSendReplyData(data?.data?.expertMessage);
        }
        setSubscriptionList(data?.data);
        setTotal(data?.totalItems || null);
      } else {
        toastMessage(data?.message, "error", "subscriptionList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  const bookingStatusHandler = (data) => {
    searchParam.set("bookingStatus", data);
    setSearchParam(searchParam);
  };

  // Subscription Type Handler
  const subscriptionTypeHandler = (data) => {
    if (data === "oneTime") {
      searchParam.set("bookingStatus", "ongoing");
      searchParam.set("subscriptionType", data);
      searchParam.delete("recurringServiceType");
      setSearchParam(searchParam);
    } else if (data === "recurring") {
      searchParam.set("bookingStatus", "ongoing");
      searchParam.set("subscriptionType", data);
      setSearchParam(searchParam);
    } else {
      searchParam.set("subscriptionType", data);
      setSearchParam(searchParam);
    }
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setSendReplyData({ ...sendReplyData, attachment: e.target.files[0] });
      } else {
        e.target.value = "";
        setSendReplyData({ ...sendReplyData, attachment: "" });
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setSendReplyData({ ...sendReplyData, attachment: "" });
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  const validation = () => {
    let status = true;
    if (!sendReplyData?.text) {
      setError("Message is mandatory");
      status = false;
    }
    return status;
  };

  const sendReplyHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        if (sendReplyData?.attachment && sendReplyData?.attachment?.name) {
          formData.append("attachment", sendReplyData?.attachment);
        }

        formData.append("text", sendReplyData?.text);
        formData.append("_id", sendReplyData?._id);
        if (sendReplyData?.textedOn) {
          formData.append("isEdited", true);
        }
        const { data } = await sendReplyApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "sendReply");
          fetchingSubscriptionList();
          setSendReplyData({});
          setReplyModal(false);
          setError("");
        } else {
          toastMessage(data?.message, "error", "sendReply");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const recurringServiceTypeHandler = (type) => {
    if (subscriptionType === "recurring") {
      searchParam.set("recurringServiceType", type);
      setSearchParam(searchParam);
    }
  };

  // useEffect(() => {
  //   fetchingSubscriptionList();
  // }, [searchParam]);

  useEffect(() => {
    // if (Number(page) === 1) {
    //   searchParam.delete("page");
    //   setSearchParam(searchParam);
    // }
    fetchingSubscriptionList();
  }, [searchParam, debouncedValue]);

  useEffect(() => {
    setLocalStorage("subscriptionList", "search", search);
  }, [search]);

  // console.log(bookingStatus, "Send Reply Data");

  return (
    <>
      <div className="prioritydms bookings">
        <div className="dashboard_title">
          <h3>Trainings</h3>
        </div>
        <div className="dashboard_content_inner">
          <div className="tab_section flex">
            <ul>
              {filteredBooking &&
                filteredBooking.length > 0 &&
                filteredBooking.map((data) => (
                  <li key={data?.value}>
                    <button
                      type="button"
                      onClick={() => bookingStatusHandler(data?.value)}
                      className={`tab_button ${
                        bookingStatus === data?.value ? "active" : ""
                      }`}
                    >
                      {data?.name}
                    </button>
                  </li>
                ))}
            </ul>

            <div className="button_section">
              <div className="searchBox">
                <input
                  type="search"
                  className="input"
                  placeholder="Search By User Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch />
              </div>
              <DateFilter
                fromDate={fromDate}
                toDate={toDate}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
              />
            </div>
          </div>
          <div className="tab_section flex">
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => subscriptionTypeHandler("oneTime")}
                  className={`tab_button ${
                    subscriptionType === "oneTime" ? "active" : ""
                  }`}
                >
                  Batch Trainings
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => subscriptionTypeHandler("recurring")}
                  className={`tab_button ${
                    subscriptionType === "recurring" ? "active" : ""
                  }`}
                >
                  Recurring Trainings
                </button>
              </li>
            </ul>

            {subscriptionType === "recurring" && (
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => recurringServiceTypeHandler("private")}
                    className={`tab_button ${
                      recurringServiceType === "private" ? "active" : ""
                    }`}
                  >
                    Personal Trainings
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => recurringServiceTypeHandler("public")}
                    className={`tab_button ${
                      recurringServiceType === "public" ? "active" : ""
                    }`}
                  >
                    Group Trainings
                  </button>
                </li>
              </ul>
            )}
          </div>

          <div className="booking_content consultation">
            <h3 className="sub_title">Trainings</h3>

            <UpcomingSubscription
              setSubscriptionList={setSubscriptionList}
              subscriptionList={subscriptionList}
              total={total}
              handlePageChange={handlePageChange}
              bookingStatus={bookingStatus}
              fetchingSubscriptionList={fetchingSubscriptionList}
              subscriptionType={subscriptionType}
            />
          </div>
        </div>
      </div>

      {/* <CustomModal
        show={ReplyModal}
        handleClose={() => {
          setReplyModal(false);
          setSendReplyData({});
          setError("");
        }}
      >
        <div className="PriorityDMS_reply">
          <h3 className="title">{`Reply to ${sendReplyData?.firstName} ${" "} ${
            sendReplyData?.lastName
          }`}</h3>
       

          <div className="form_field">
            <label className="label">
              Enter Message{" "}
              <p className="file_upload">
                {sendReplyData?.attachment ||
                sendReplyData?.attachment?.name ? (
                  <>
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="text-decoration-underline">File name</span>
                    <button
                      type="button"
                      className="cancel_button"
                      onClick={() => {
                        setAttached(true);
                        setSendReplyData({ ...sendReplyData, attachment: "" });
                      }}
                    >
                      <IoClose />
                    </button>
                  </>
                ) : (
                  <label htmlFor="uploadFile">
                    <input
                      type="file"
                      id="uploadFile"
                      onChange={imageHandler}
                    />
                    <GrAttachment />
                    <span className="text-decoration-underline">
                      Upload Document
                    </span>
                  </label>
                )}
              </p>
            </label>
            <TextArea
              placeholder="Enter Message"
              value={sendReplyData?.text}
              onChange={(e) =>
                setSendReplyData({ ...sendReplyData, text: e.target.value })
              }
              error={error}
            />
          </div>
          <LoadingButton
            className="w-100"
            loading={loading}
            onClick={sendReplyHandler}
          >
            Submit
          </LoadingButton>
        </div>
      </CustomModal> */}
    </>
  );
};

export default BookingSubscription;
