import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Services.scss";
import {
  banner1,
  expert1Img,
  imagePlaceholder,
  service1,
  serviceImg1,
} from "assets/images";
// import VideoPlayer from "components/video-player/VideoPlayer";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import { testimonial } from "staticData";
import { Button, Input, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import {
  bookOneToOneApi,
  bookPriorityDmAPi,
  getExpertServiceDetailApi,
} from "services/userServices/servicesDetail&Booking";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { useNavigate, useParams } from "react-router-dom";
import VideoPlayer from "common/YouTubeVideoPlayer";
import { pdfIcon } from "assets/icons";
import JoditEditor from "jodit-react";
import { getFileNameSuffix, regex } from "utils/formValidator";
import { textPriorityDmApi } from "services/userServices/startServices";
import { Accordion } from "react-bootstrap";
import { FaPhotoVideo } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { handleMediaDownload } from "utils/mediaDataSender";
import HowTo from "expertDashboard/dashboard/manageServices/HowTo";
import "../digital-content/PackagesList.scss";
import Placeholder from "components/placeholder/Placeholder";
import { useSelector } from "react-redux";
import AuthLogin from "auth/login/Login";
import SignUp from "auth/signUp/SignUp";
import ConfirmPayModal from "components/modals/ConfirmPayModal";
import SuccessPayment from "components/SuccessfulPaymentModal/SuccessPayment";
import { BsFillPlayCircleFill } from "react-icons/bs";

const UserDigitalContent = () => {
  const { expertId, serviceId } = useParams();
  const { personalInfo } = useSelector((state) => state.config);
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const { pdfFileRegex } = regex;
  const [loading, setLoading] = useState(false);
  const [payoutModal, setPayoutModal] = useState(false);
  const [sendDmModal, setSendDmModal] = useState(false);
  const [image, setImage] = useState("");
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [expertServicesDetail, setExpertServicesDetail] = useState({});
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [loader, onShow, onHide] = FullPageLoader();
  const [inputField, setInputField] = useState({});
  const [error, setError] = useState("");
  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [digitalContentVideo, setDigitalContentVideo] = useState("");
  const navigate = useNavigate();

  const fetchingExpertServicesDetail = async () => {
    try {
      onShow();
      const { data } = await getExpertServiceDetailApi(expertId, serviceId);
      if (data?.code === 200) {
        setExpertServicesDetail(data?.data);
        setBestTestimonials(data?.data?.testimonials);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  // Add Banner image handler
  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setImage("");
    }
  };

  // Send Priority DM
  const sendPriorityDmHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (image && image?.name) {
        formData.append("attachment", image);
      }
      formData.append("_id", expertServicesDetail?.alreadyBooked?._id);
      formData.append("text", inputField?.description);
      const { data } = await textPriorityDmApi(formData);
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "bookPriorityDm");
        setSendDmModal(false);
        fetchingExpertServicesDetail();
      } else {
        toastMessage(data?.message, "error", "addWebinar");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Book Priority Dm Handler
  const bookDigitalContentHandler = async () => {
    if (personalInfo?.token) {
      try {
        setLoading(true);
        const { data } = await bookOneToOneApi({
          _id: serviceId,
          // bookedFor: mergeTimeDate(bookingDate, slotTime?.startTime),
          amount: expertServicesDetail?.pricing?.amount,
          totalAmount: expertServicesDetail?.pricing?.totalAmount,
          transactionFeePercentage:
            expertServicesDetail?.pricing?.transactionFeePercentage,
          // slot: slotTime,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "bookPriorityDm");
          setPayoutModal(false);
          setPaymentStatusModal(true);
          fetchingExpertServicesDetail();
          // navigate(`/expert-details/${expertId}?serviceType=digital`);
          navigate(`/${expertId}?serviceType=digital`);
        } else {
          toastMessage(data?.message, "error", "addWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    } else {
      setLoginModal(true);
      setPayoutModal(false);
    }
  };

  useEffect(() => {
    fetchingExpertServicesDetail();
  }, []);

  return (
    <>
      {loader}
      <div className="services_page priorityDM">
        <div className="container">
          <img src={banner1} alt="" className="services_banner" />

          <div className="service_content_wrap">
            <div className="service_banner">
              <div className="banner_content">
                <img src={expertServicesDetail?.image || service1} alt="" />
                <div className="content">
                  {/* <h4>Pulled Hamstring causes, Treatment and recovery </h4> */}
                  <h4>{expertServicesDetail?.title || "---"}</h4>

                  <p className="duration">
                    {expertServicesDetail?.digitalContentType === "course" && (
                      <>
                        <span>Number Of Chapters</span>
                        <b>{`${
                          expertServicesDetail?.noOfChapters || "---"
                        }`}</b>{" "}
                      </>
                    )}
                  </p>
                  <p className="price">
                    {expertServicesDetail?.pricing?.showSlashedAmount ? (
                      <>
                        <del>
                          ₹ {Number(expertServicesDetail?.pricing?.slashAmount)}
                        </del>
                        <b>
                          ₹{" "}
                          {
                            Number(expertServicesDetail?.pricing?.amount)
                            //  -
                            //   Number(expertServicesDetail?.pricing?.slashAmount)
                          }
                        </b>
                      </>
                    ) : (
                      <>₹ {Number(expertServicesDetail?.pricing?.amount)}</>
                    )}
                  </p>
                </div>
              </div>

              {/* This Button Show Only To User */}

              {(personalInfo?.currentType === 1 || !personalInfo?.token) && (
                <div className="banner_tag">
                  <span className="name_tag">
                    {expertServicesDetail?.digitalContentType === "product"
                      ? "Digital Product"
                      : "Digital Course"}
                  </span>
                  {expertServicesDetail?.alreadyBooked?._id ? (
                    // <Button
                    //   className={"dashboard_button"}
                    //   onClick={() => setSendDmModal(true)}
                    // >
                    //   Start Course
                    // </Button>
                    <p className="bookedTag">Already Purchased</p>
                  ) : (
                    <Button
                      className={"dashboard_button"}
                      onClick={() => setPayoutModal(true)}
                    >
                      Purchase
                    </Button>
                  )}
                </div>
              )}
            </div>
            {expertServicesDetail?.description && (
              <div className="service_content">
                <div className="content_left">
                  <h5 className="_title">Overview</h5>
                  <div className="content">
                    {expertServicesDetail?.description ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: expertServicesDetail.description,
                        }}
                      ></p>
                    ) : (
                      <p>---</p>
                    )}{" "}
                  </div>
                </div>
              </div>
            )}

            {expertServicesDetail?.digitalContentType === "course" ? (
              <div className="chapter_list">
                {expertServicesDetail?.chapters &&
                expertServicesDetail?.chapters.length
                  ? expertServicesDetail?.chapters.map((data, index) => (
                      <Accordion defaultActiveKey={0}>
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header>
                            Chapter {index + 1} : {data?.name}{" "}
                            <span className="topic">
                              Total Topic {data?.topics?.length}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            {data?.topics &&
                              data?.topics.map((topic, index) => (
                                <ul>
                                  <li>
                                    <p>
                                      <FaPhotoVideo />
                                      <b>{topic?.name}</b>
                                    </p>{" "}
                                    {topic?.uploadType === "video" ? (
                                      <button
                                        onClick={() => {
                                          setDigitalContentVideo(topic?.url);
                                          setPlayVideoModal(true);
                                        }}
                                        type="button"
                                        className="digital_access_button"
                                      >
                                        <BsFillPlayCircleFill /> Play Video
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          handleMediaDownload(topic?.url)
                                        }
                                        type="button"
                                        className="digital_access_button"
                                      >
                                        <IoDocumentText />
                                        Download document
                                      </button>
                                    )}
                                  </li>
                                </ul>
                              ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))
                  : ""}
              </div>
            ) : (
              <ul className="digital_content_access">
                <li>
                  <b>Access your digital product</b>
                  {expertServicesDetail?.document?.uploadType === "video" ? (
                    <button
                      onClick={() => {
                        setDigitalContentVideo(
                          expertServicesDetail?.document?.url
                        );
                        setPlayVideoModal(true);
                      }}
                      type="button"
                    >
                      <BsFillPlayCircleFill />
                      Play Video
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        handleMediaDownload(expertServicesDetail?.document?.url)
                      }
                    >
                      {/* {getFileNameSuffix(
                        expertServicesDetail?.document?.url,
                        20
                      )} */}
                      <IoDocumentText />
                      Download document
                    </button>
                  )}
                </li>
              </ul>
            )}

            {expertServicesDetail?.video && (
              <VideoPlayer src={expertServicesDetail?.video || ""} />
            )}

            {bestTestimonials && bestTestimonials.length > 0 && (
              <div className="content_box">
                <h5 className="_title">Testimonials</h5>

                <div className="content">
                  <div className="card_wrap">
                    {bestTestimonials && bestTestimonials.length ? (
                      bestTestimonials.map((item) => {
                        return (
                          <TestimonialCard
                            bestTestimonials={bestTestimonials}
                            setBestTestimonials={setBestTestimonials}
                            display={false}
                          />
                        );
                      })
                    ) : (
                      <Placeholder text="No testimonial available for this service" />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Play Video Modal */}
      {playVideoModal && (
        <HowTo
          url={digitalContentVideo}
          viewInfoModal={playVideoModal}
          setViewInfoModal={setPlayVideoModal}
        />
      )}

      {/* Common Payout Component MOdal */}
      <ConfirmPayModal
        show={payoutModal}
        handleClose={() => setPayoutModal(false)}
        modalTitle={"Confirm and pay"}
        // serviceTag={"Digital Contents"}
        serviceTag={
          expertServicesDetail?.digitalContentType === "course"
            ? "Digital Course"
            : "Digital Product"
        }
        serviceTittle={expertServicesDetail?.title || "---"}
        price={expertServicesDetail?.pricing?.totalAmount || "---"}
        buttonText={"Process to Pay"}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        authModal={authModal}
        setAuthModal={setAuthModal}
        loading={loading}
        serviceId={serviceId}
        expertId={expertId}
        expertServicesDetail={expertServicesDetail}
        token={personalInfo?.token}
        payoutModal={payoutModal}
        setPayoutModal={setPayoutModal}
        setPaymentStatusModal={setPaymentStatusModal}
      />

      <SuccessPayment
        paymentStatusModal={paymentStatusModal}
        setPaymentStatusModal={setPaymentStatusModal}
        expertId={expertId}
        serviceType="digital"
      />

      <CustomModal show={sendDmModal} handleClose={() => setSendDmModal(false)}>
        <h3>Priority DM</h3>
        <p>{JSON.parse(localStorage.getItem("fitzure_info"))?.email}</p>

        <div className="_card has-border attachment">
          {image && image?.name && (
            <>
              {" "}
              <img src={pdfIcon} alt="" />
              <span>attachment.pdf</span>
            </>
          )}
        </div>

        <div className="_card has-border">
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p> */}
          <div className="form_field">
            <Input
              label={"Attachment"}
              type="file"
              // name="title"
              // value={inputField?.title || ""}
              placeholder={"Enter Title"}
              onChange={imageHandler}
              maxlength="100"
              error={error?.title}
            />
          </div>

          <div className="form_field">
            <JoditEditor
              ref={editor}
              value={inputField?.description}
              tabIndex={1}
              onBlur={(value) =>
                setInputField({ ...inputField, description: value })
              }
              config={config}
            />
            <p className="invalid-feedback d-block">{error?.description}</p>
          </div>
        </div>
        <div className="button_wrap">
          <LoadingButton className="w-100" onClick={sendPriorityDmHandler}>
            Submit
          </LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default UserDigitalContent;
