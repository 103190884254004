import React from "react";
import CustomModal from "./CustomModal";
import { LoadingButton, RatingStar, TextArea } from "components/form";
import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa";

const RatingModal = ({
  show,
  handleClose,
  onChangeHandler,
  ratingField,
  setRatingField,
  loading,
  action,
  error,
}) => {
  // console.log(ratingField,"ratingField");
  return (
    <>
      <CustomModal show={show} handleClose={handleClose}>
        <div className="ratingModal">
          <h3>Write a Review</h3>
          <p>Please enter your review</p>
          <form>
            {/* <RatingStar /> */}
            <div className="rating">
              {/* <span className="_rating">{rating}</span> */}
              <Rating
                className="rating_star"
                onClick={(value) => {
                  setRatingField({ ...ratingField, rating: value });
                }}
                initialRating={ratingField?.rating}
                emptySymbol={<FaRegStar />}
                fullSymbol={<FaStar />}
                // {...rest}
              />
              {error && (
                <span className="invalid-feedback d-block">{error}</span>
              )}
              {/* <span className="user">{user}</span> */}
            </div>
            <div className="form_field">
              <TextArea
                label="Enter Message"
                placeholder="Enter Message"
                onChange={onChangeHandler}
                name="message"
                value={ratingField?.message}
              />
            </div>
          </form>
          <div className="button_wrap button_center">
            <LoadingButton loading={loading} onClick={action}>
              Submit
            </LoadingButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default RatingModal;
