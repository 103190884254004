import React, { useEffect, useState } from "react";
import "./PriorityDMS.scss";
import { IoIosSearch } from "react-icons/io";
import { LuFilter, LuReply } from "react-icons/lu";
import {
  Button,
  DatePicker,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { Dropdown, DropdownItem } from "react-bootstrap";
import { pdfIcon } from "assets/icons";
import CustomModal from "components/modals/CustomModal";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { getPriorityDmsApi, sendReplyApi } from "services/priorityDms";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { useSearchParams } from "react-router-dom";
import { dateFormat, dateFormatYear } from "utils/dateFormat";
import { charLimit, handleMediaDownload } from "utils/mediaDataSender";
import { regex } from "utils/formValidator";
import { getLocalStorage, setLocalStorage } from "config/config";
import UseDebounce from "common/UseDebounce";
import DescriptionModal from "common/DescriptionModal";
import DateFilter from "./DateFilter";
import CommonPagination from "common/CommonPagination";

const PriorityDMS = () => {
  const [tabButton, setTabButton] = useState("pending");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [description, setDescription] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  let fromDate = searchParam.get("fromDate") || "";
  let toDate = searchParam.get("toDate") || "";
  let isReplied = searchParam.get("isReplied") || "false";
  const [ReplyModal, setReplyModal] = useState(false);
  const [attached, setAttached] = useState(false);
  const [total, setTotal] = useState(null);
  const [sendReplyData, setSendReplyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [priorityList, setPriorityList] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [error, setError] = useState("");
  const { pdfFileRegex } = regex;
  const [search, setSearch] = useState(
    getLocalStorage("priorityList", "search") || ""
  );

  const debouncedValue = UseDebounce(search, 300);

  const fetchingPriorityDmsHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.page = page - 1;
      sendData.pageSize = 10;
      if (debouncedValue) {
        sendData.search = debouncedValue;
        sendData.page = 0;
      }
      if (isReplied) {
        sendData.isReplied = isReplied;
      }
      if (fromDate && toDate) {
        sendData.fromDate = dateFormat(fromDate);
        sendData.toDate = dateFormat(toDate);
      }
      const { data } = await getPriorityDmsApi(sendData);
      if (data?.code === 200) {
        setSendReplyData(
          data?.data?.map((data) => {
            if (
              data?.expertMessage &&
              data?.expertMessage.isEdited.toString() === "false"
            ) {
              return data;
            }
          })
        );
        // if (
        //   data?.data?.expertMessage &&
        //   (data?.data?.expertMessage?.isEdited).toString() === "false"
        // ) {
        //   setSendReplyData(data?.data?.expertMessage);
        // }
        setPriorityList(data?.data);
        setTotal(data?.totalItems || null);
      } else {
        toastMessage(data?.message, "error", "priorityList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  const isRepliedHandler = (data) => {
    searchParam.set("isReplied", data);
    setSearchParam(searchParam);
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setSendReplyData({ ...sendReplyData, attachment: e.target.files[0] });
      } else {
        e.target.value = "";
        setSendReplyData({ ...sendReplyData, attachment: "" });
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setSendReplyData({ ...sendReplyData, attachment: "" });
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    if (Number(event) === 1) {
      searchParam.delete("page");
    } else {
      searchParam.set("page", event);
    }
    setSearchParam(searchParam);
  };

  const validation = () => {
    let status = true;
    if (!sendReplyData?.text) {
      setError("Message is mandatory");
      status = false;
    }
    return status;
  };

  const sendReplyHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        if (sendReplyData?.attachment && sendReplyData?.attachment?.name) {
          formData.append("attachment", sendReplyData?.attachment);
        }

        formData.append("text", sendReplyData?.text);
        formData.append("_id", sendReplyData?._id);
        if (sendReplyData?.textedOn) {
          formData.append("isEdited", true);
        }
        if (!sendReplyData?.attachment) {
          formData.append("deleteAttachment", sendReplyData?.deleteAttachment);
        }
        const { data } = await sendReplyApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "sendReply");
          fetchingPriorityDmsHandler();
          setSendReplyData({});
          setReplyModal(false);
          setError("");
        } else {
          toastMessage(data?.message, "error", "sendReply");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchingPriorityDmsHandler();
  }, [searchParam, debouncedValue]);

  useEffect(() => {
    setLocalStorage("priorityList", "search", search);
  }, [search]);

  return (
    <>
      <div className="prioritydms">
        <div className="dashboard_title">
          <h3>Priority DM</h3>
        </div>
        <div className="dashboard_content_inner">
          <div className="tab_section flex">
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => isRepliedHandler("false")}
                  className={`tab_button ${
                    isReplied === "false" ? "active" : ""
                  }`}
                >
                  Pending
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => isRepliedHandler("true")}
                  className={`tab_button ${
                    isReplied === "true" ? "active" : ""
                  }`}
                >
                  Replied
                </button>
              </li>
            </ul>

            <div className="button_section">
              <div className="searchBox">
                <input
                  type="search"
                  className="input"
                  placeholder="Search By Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IoIosSearch />
              </div>
              <DateFilter
                fromDate={fromDate}
                toDate={toDate}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
              />
            </div>
          </div>
          <div className="table_wrap">
            <h3 className="table_title">Priority DM Replied</h3>
            <div className="table_inner">
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Services</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Attachment</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {priorityList && priorityList.length ? (
                    priorityList.map((data) => {
                      return (
                        <tr>
                          <td className="nowrap">{`${
                            (data?.user && data?.user?.firstName) || "---"
                          } ${"  "}${
                            (data?.user && data?.user?.lastName) || "---"
                          }`}</td>
                          <td>
                            {(data?.service && data?.service?.title) || "---"}
                          </td>
                          <td>{dateFormat(data?.createdAt) || "---"}</td>
                          <td>
                            {data?.userMessage?.text.length > 50 ? (
                              <>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: charLimit(
                                      data?.userMessage?.text,
                                      50
                                    ),
                                  }}
                                ></p>
                                <span
                                  onClick={() => {
                                    setDescriptionModal(true);
                                    setDescription(
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data?.userMessage?.text,
                                        }}
                                      ></p>
                                    );
                                  }}
                                >
                                  Read More
                                </span>
                              </>
                            ) : (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data?.userMessage?.text,
                                }}
                              ></p>
                            )}
                          </td>
                          <td
                            onClick={() =>
                              data?.userMessage?.attachment &&
                              handleMediaDownload(
                                data?.userMessage &&
                                  data?.userMessage?.attachment
                              )
                            }
                          >
                            {data?.userMessage?.attachment ? (
                              <>
                                <img src={pdfIcon} alt="" className="iconImg" />
                                <span
                                  className="text-decoration-underline"
                                  role="button"
                                >
                                  attachment.pdf
                                </span>
                              </>
                            ) : (
                              "---"
                            )}
                          </td>
                          <td>
                            <Button
                              className={"has_icon"}
                              isDisabled={
                                data?.expertMessage?.isEdited.toString() ===
                                "true"
                              }
                              onClick={() => {
                                setReplyModal(true);
                                setSendReplyData({
                                  ...sendReplyData,
                                  _id: data?._id,
                                  lastName: data?.user?.lastName,
                                  firstName: data?.user?.firstName,
                                  attachment: data?.expertMessage?.attachment,
                                  deleteAttachment:
                                    data?.expertMessage?.attachment || "",
                                  text: data?.expertMessage?.text,
                                  isEdited: (data?.expertMessage?.isEdited).toString(),
                                  textedOn: data?.expertMessage?.textedOn,
                                });
                              }}
                            >
                              <LuReply /> Reply
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={6}>{`${
                        isReplied
                          ? "All Priority Dm Replied"
                          : "No Priority DM Pending"
                      }`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <CommonPagination
            handlePageChange={handlePageChange}
            total={total}
            activePage={page}
          />
        </div>
      </div>

      <CustomModal
        show={ReplyModal}
        handleClose={() => {
          setReplyModal(false);
          setSendReplyData({});
          setError("");
        }}
      >
        <div className="PriorityDMS_reply">
          <h3 className="title">{`Reply to ${sendReplyData?.firstName} ${" "} ${
            sendReplyData?.lastName
          }`}</h3>
          {/* <p>Please enter your registered email address to continue</p> */}

          <div className="form_field">
            <label className="label">
              Enter Message{" "}
              <p className="file_upload">
                {sendReplyData?.attachment ||
                sendReplyData?.attachment?.name ? (
                  <>
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="text-decoration-underline">File name</span>
                    <button
                      type="button"
                      className="cancel_button"
                      onClick={() => {
                        setAttached(true);
                        setSendReplyData({ ...sendReplyData, attachment: "" });
                      }}
                    >
                      <IoClose />
                    </button>
                  </>
                ) : (
                  <label htmlFor="uploadFile">
                    <input
                      type="file"
                      id="uploadFile"
                      onChange={imageHandler}
                    />
                    <GrAttachment />
                    <span className="text-decoration-underline">
                      Upload Document
                    </span>
                  </label>
                )}
              </p>
            </label>
            <TextArea
              placeholder="Enter Message"
              value={sendReplyData?.text}
              onChange={(e) =>
                setSendReplyData({ ...sendReplyData, text: e.target.value })
              }
              error={error}
            />
          </div>
          <LoadingButton
            className="w-100"
            loading={loading}
            onClick={sendReplyHandler}
          >
            Submit
          </LoadingButton>
        </div>
      </CustomModal>

      {/* Description Modal */}

      {descriptionModal && (
        <DescriptionModal
          show={descriptionModal}
          handleClose={() => {
            setDescriptionModal(false);
          }}
          description={description}
          title={"Description"}
        />
      )}
    </>
  );
};

export default PriorityDMS;
