import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { myProfile } from "services/details";
import { useDispatch } from "react-redux";
import { setConfig, setPersonalInfo, setSteps } from "store/slices/configSlice";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";

const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("fitzure_info");
  const [isActivate, setIsActivate] = useState(false);

  const fetchDetail = async () => {
    try {
      const profile = await myProfile();

      if (profile && profile?.data?.code === 200) {
        const parseToken = { ...JSON.parse(token), ...profile?.data?.data };
        localStorage.setItem("fitzure_info", JSON.stringify(parseToken));
        dispatch(setPersonalInfo({ personalInfo: parseToken }));
        dispatch(setConfig({ userType: parseToken?.accountType }));
        dispatch(setSteps({ steps: parseToken?.moduleSteps || 0 }));
      } else {
        navigate("/");
      }
      setIsActivate(true);
    } catch (error) {
      if (error?.response?.status === 403) {
        localStorage.removeItem("fitzure_info");
        localStorage.removeItem("fitzure_web");
        localStorage.removeItem("expertProfileStatus");
        toastMessage(error?.response?.data, "error", "removeFitzureData");
        navigate("/");
      }
      setIsActivate(true);
    }
  };

  useEffect(() => {
    if (!token) {
      setIsActivate(true);
      return;
    }

    fetchDetail();
  }, []);

  return (
    <div>
      {isActivate ? children : <FullPageLoader />}
      {/* {loader} */}
    </div>
  );
};

export default UserProvider;
