import { FaFilePdf } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { TbFileTypeDocx } from "react-icons/tb";

export default function createMediaData(formData, sendData) {
  for (const key in sendData) {
    formData.append(key, sendData[key]);
  }
}

export const sendArrayDataId = (data = []) => {
  return data.reduce((acc, d) => {
    acc.push({ _id: d._id });
    return acc;
  }, []);
};

// export function charLimit(data, limit) {
//   return data?.length > limit ? data.substring(0, limit + 1) + "..." : data;
// }

// Function to convert HTML to plain text
const stripHtmlAndStyles = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

// Function to apply character limit without counting HTML tags or styles
export const charLimit = (html, limit) => {
  const plainText = stripHtmlAndStyles(html); // Remove HTML tags and styles
  return plainText?.length > limit
    ? plainText.substring(0, limit + 1) + "..." || "---"
    : plainText;
};

const previewDocIcon = (type) => {
  const docLogo = {
    pdf: <FaFilePdf className="pdf_icon" />,
    doc: <IoDocumentText />,
    docx: <TbFileTypeDocx />,
  }[type] || <FaFilePdf />;

  return docLogo;
};

// Get file extension
export const getFileExtension = (fileName) => {
  if (fileName) {
    return previewDocIcon(fileName.split(".").at(-1)) || ""; // Using .at(-1) to get the last element (file extension)
  } else return null;
};

export const handleMediaDownload = (url) => {
  window.open(url);
};

export const showImageHandler = (src) => {
  if (src?.name) {
    const extension = src?.name.split(".").at(-1) || "";
    if (extension === "png" || extension === "jpg" || extension === "jpeg") {
      return <img src={URL.createObjectURL(src)} />;
    } else {
      return getFileExtension(extension);
    }
  } else {
    const extension = src.split(".").at(-1) || "";
    if (extension === "png" || extension === "jpg" || extension === "jpeg") {
      return <img src={src} />;
    } else {
      return getFileExtension(src);
    }
  }
};
