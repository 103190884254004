import { logoImg } from "assets/images";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import ProfileNav from "expertDashboard/expertProfileSetup/ProfileNav";
import HomePage from "pages/homePage/HomePage";
import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "common/Sidenav";
import AboutUs from "pages/aboutUs/AboutUs";
import ExploreExperts from "pages/exploreExperts/ExploreExperts";
import ContactUs from "pages/contactUs/ContactUs";
import ExpertDetail from "pages/exploreExperts/expertDetail/expertsDetail";
import DashboardHeader from "expertDashboard/dashboard/dashboardHeader/DashboardHeader";
import ExpertSideBar from "expertDashboard/dashboard/expertSidebar/ExpertSideBar";
import { toastMessage } from "utils/toastMessage";

import EditUserProfile from "userDashboard/UserDetails";

import { commonRoutes } from "common/CommonSidenav";
import { setConfig, setPersonalInfo, setSteps } from "store/slices/configSlice";
import Consultation from "pages/services/Consultation";
import Webinars from "pages/services/Webinars";
import PriorityDMService from "pages/services/PriorityDM";
import PriorityDM from "pages/priorityDM/PriorityDM";
import AddConsultations from "expertDashboard/dashboard/manageServices/consultation/AddConsultations";
import MyBookings from "pages/bookings/MyBookings";
import AddWebinars from "expertDashboard/dashboard/manageServices/webinars/AddWebinars";
import AddSubscriptions from "expertDashboard/dashboard/manageServices/subscription/AddSubscriptions";
import AddPriorityDms from "expertDashboard/dashboard/manageServices/priorityDMS/AddPriorityDms";
import PackagesList from "pages/packagesList/PackagesList";
import PackageDetail from "pages/packagesList/PackageDetail";
import TransactionHistory from "pages/TransactionHistory/TransactionHistory";
import PrivacyPolicy from "pages/privacy-policy/PrivacyPolicy";
import TermsCondition from "pages/terms-condition/TermsCondition";
import FAQs from "pages/FAQ/FAQs";
import DigitalContentList from "pages/digital-content/DigitalContentList";
import DigitalContentDetail from "pages/digital-content/DigitalContentDetail";
import { IoCloseSharp } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import AddRecurringSubscription from "expertDashboard/dashboard/manageServices/subscription/AddRecurringSubscription";
import UserDigitalContent from "pages/services/UserDigitalContent";
import UserSubscription from "pages/services/UserSubscription";
import Page404 from "pages/404/Page404";
import PriorityDMDetail from "pages/priorityDM/PriorityDMDetail";
import ConsultationDetail from "pages/bookings/consultation/ConsultationDetail";
import WebinarDetail from "pages/bookings/webinar/WebinarDetail";
import SubscriptionDetail from "pages/bookings/subscription/SubscriptionDetail";
import PathNavigator from "common/PathNavigator";
import PackageConsultantDetail from "pages/services/PackageConsultantDetail";
import { userRoutes } from "common/UserRoutes";
import { authRouteHandler } from "utils/common";
import PublishExpertDetail from "expertDashboard/dashboard/PublishExpertDetail";
import routesConfig from "./ExpertDetailRoute";

const ExpertDashboard = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  return (
    <>
      <div
        className={`dashboard_wrap expert_dashboard ${
          toggleSidebar ? "has_sidenav" : ""
        }`}
      >
        <div className="sidebar_wrap">
          <div className="logo_wrap">
            <button
              type="button"
              className="closeNav"
              onClick={() => setToggleSidebar(false)}
            >
              <IoCloseSharp />
            </button>
            <img src={logoImg} alt="fitzure" />
          </div>
          <ExpertSideBar hideNav={() => setToggleSidebar(false)} />
        </div>
        <div className="dashboard_page">
          <div className="dashboard_header">
            <DashboardHeader onClick={() => setToggleSidebar(true)} />
          </div>
          <div className="dashboard_content">
            <Outlet />
          </div>
        </div>
        <div className="overlay" onClick={() => setToggleSidebar(false)}></div>
      </div>
    </>
  );
};


const SuccessPage=()=>{
  return (
    <>
    <h1> Subscription Payment Successfull</h1>
    </>
  )
}

const ExpertProtectedRoute = () => {
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem("fitzure_info"));
  if (!userInfo?.token) {
    return (
      <>
        <Navigate to="/" replace />
        {toastMessage("Login Please", "error", "tokenMissing")}
      </>
    );
  } else if (location.pathname === "/dashboard") {
    return <Page404 />;
  } else if (location.pathname === "/") {
    return <Page404 />;
  } else {
    return <Outlet />;
  }
};

const Routing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userType, steps, personalInfo } = useSelector(
    (state) => state.config
  );

  const ExpertProfileSetup = () => {
    const [sideNav, setSideNav] = useState(false);
    return (
      <>
        <div className={`dashboard_wrap _profile ${sideNav ? "active" : ""}`}>
          <div className="sidebar_wrap">
            <div className="logo_wrap">
              <button
                type="button"
                className="closeNav"
                onClick={() => setSideNav(false)}
              >
                <IoCloseSharp />
              </button>
              <img src={logoImg} alt="fitzure" />
            </div>
            <span className="sub_title">Account Details</span>
            <ProfileNav onClick={() => setSideNav(false)} />
          </div>
          <div className="dashboard_page">
            <div className="dashboard_header">
              <button
                type="button"
                className="toggle_button"
                onClick={() => setSideNav(true)}
              >
                <FaBars />
              </button>
              <h3>Profile</h3>
            </div>
            <div className="dashboard_content">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  };

  //   const ExpertDashboard = () => {
  //     // const [toggleSidebar, setToggleSidebar] = useState(false);

  //     return (
  //       <>
  //         <div
  //           className={`dashboard_wrap expert_dashboard ${
  //             toggleSidebar ? "has_sidenav" : ""
  //           }`}
  //         >
  //           <div className="sidebar_wrap">
  //             <div className="logo_wrap">
  //               <button
  //                 type="button"
  //                 className="closeNav"
  //                 onClick={() => setToggleSidebar(false)}
  //               >
  //                 <IoCloseSharp />
  //               </button>
  //               <img src={logoImg} alt="fitzure" />
  //             </div>
  //             <ExpertSideBar hideNav={() => setToggleSidebar(false)} />
  //           </div>
  //           <div className="dashboard_page">
  //             <div className="dashboard_header">
  //               <DashboardHeader onClick={() => setToggleSidebar(true)} />
  //             </div>
  //             <div className="dashboard_content">
  //               <Outlet />
  //             </div>
  //           </div>
  //           <div
  //             className="overlay"
  //             onClick={() => setToggleSidebar(false)}
  //           ></div>
  //         </div>
  //       </>
  //     );
  //   };

  const CommonHeader = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };

  const ProtectedRoute = ({ children }) => {
    const userInfo = JSON.parse(localStorage.getItem("fitzure_info"));
    if (!userInfo?.token) {
      return (
        <>
          <Navigate to="/" replace />
          {toastMessage("session Expire Login again", "error", "tokenMissing")}
        </>
      );
    } else if (location.pathname === "/profile-setup") {
      return <Page404 />;
    } else {
      return children;
    }
  };

  const UserProtectedRoute = ({ children }) => {
    const userInfo = JSON.parse(localStorage.getItem("fitzure_info"));
    if (userInfo?.moduleSteps === 0 && userInfo?.currentType === 1) {
      return (
        <>
          <Navigate to="/profile-setup/personal-info" replace />
        </>
      );
    } else if (
      userInfo?.currentType === 0 &&
      userInfo?.moduleSteps === 4 &&
      location.pathname === "/"
    ) {
      return <Navigate to={"/dashboard/home"} />;
    } else {
      return children;
    }
  };

  return (
    <>
    
      <Routes>
        <Route
          path="/expert_publish_detail/:slug"
          element={<PublishExpertDetail />}
        />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/success_payment" element={<SuccessPage />}/>

        {/* User Dashboard */}
        <Route
          path="/"
          element={
            <UserProtectedRoute>
              <CommonHeader />
            </UserProtectedRoute>
          }
        >
          {/* User Route Mapping */}
          {userRoutes(
            personalInfo?.currentType,
            personalInfo?.moduleSteps,
            personalInfo?.token
          ) &&
            userRoutes(
              personalInfo?.currentType,
              personalInfo?.moduleSteps,
              personalInfo?.token
            ).length &&
            userRoutes(
              personalInfo?.currentType,
              personalInfo?.moduleSteps,
              personalInfo?.token
            ).map((data) => {
              return (
                <Route
                  key={data?.path}
                  path={data?.path}
                  element={data?.element}
                />
              );
            })}
        </Route>

        {/* Expert Dashboard */}
        <Route element={<ExpertProtectedRoute />}>
          <Route path="/dashboard" element={<ExpertDashboard />}>
            {commonRoutes(
              personalInfo?.currentType,
              personalInfo?.moduleSteps
            ) &&
              commonRoutes(personalInfo?.currentType, personalInfo?.moduleSteps)
                .length &&
              commonRoutes(
                personalInfo?.currentType,
                personalInfo?.moduleSteps
              ).map((data) => {
                return (
                  <Route
                    path={data?.path}
                    element={<ProtectedRoute>{data?.element}</ProtectedRoute>}
                  />
                );
              })}
          </Route>
        </Route>

        {/*Profile setup */}
        <Route
          path="/profile-setup"
          element={
            <ProtectedRoute>
              <ExpertProfileSetup />
            </ProtectedRoute>
          }
        >
          {routes({ userType, steps }).map((data, index) => (
            <Route
              key={index}
              path={data?.path}
              element={<ProtectedRoute>{data?.element}</ProtectedRoute>}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
