
import {
    FaInstagram,
    FaLinkedinIn,
    FaTelegramPlane,
    FaYoutube,
  } from "react-icons/fa";
  import { FaFacebookF, FaXTwitter } from "react-icons/fa6";
export const socialIcons = {
    facebook: <FaFacebookF />,
    instagram: <FaInstagram />,
    telegram: <FaTelegramPlane />,
    twitter: <FaXTwitter />,
    youtube: <FaYoutube />,
  };