import { isDisabled } from "@testing-library/user-event/dist/utils";
import { pdfIcon } from "assets/icons";
import { profileImg } from "assets/images";
import { Button, LoadingButton, TextArea } from "components/form";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import ConsultationList from "pages/bookings/consultation/ConsultationList";
import React, { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import {
  addNoteApi,
  cancelOneToOne,
  startOneToOneApi,
} from "services/oneToOne";
import { compareDates, dateFormat, timeFormat } from "utils/dateFormat";
import { regex } from "utils/formValidator";
import { charLimit } from "utils/mediaDataSender";
import { toastMessage } from "utils/toastMessage";

const UpcomingConsultation = ({
  setConsultantList,
  consultantList,
  total,
  handlePageChange,
  bookingStatus,
  fetchingConsultantList,
}) => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [addNoteData, setAddNoteData] = useState({});
  const [startConsultantLoading, setStartConsultantLoading] = useState(false);
  const [cancelOneToOneModal, setCancelOneToOneModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [attached, setAttached] = useState(false);
  const [error, setError] = useState("");
  const { pdfFileRegex } = regex;

  // Cancel oneToOne Handler
  const cancelConsultationHandler = async () => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await cancelOneToOne({
          _id: id,
          serviceType: "oneToOne",
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "cancelConsultation");
          fetchingConsultantList();
          setId("");
          setCancelOneToOneModal(false);
        } else {
          toastMessage(data?.message, "error", "cancelConsultation");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const noteValidation = () => {
    let status = true;
    if (!addNoteData?.text) {
      setError("Please add note to the user");
      status = false;
    }
    return status;
  };

  // Note oneToOne Handler
  const NoteConsultationHandler = async () => {
    if (id && noteValidation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("text", addNoteData.text);
        formData.append("_id", id);
        if (addNoteData?.attachment?.name) {
          formData.append("attachment", addNoteData.attachment);
        }

        const { data } = await addNoteApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "noteAdd");
          fetchingConsultantList();
          setAddNoteData("");
          setError("");
          setId("");
          setNoteModal(false);
        } else {
          toastMessage(data?.message, "error", "noteAdd");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Start Consultation Handler
  const startConsultationHandler = async (_id) => {
    if (_id) {
      try {
        const { data } = await startOneToOneApi({
          _id: _id,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "startConsultation");
          fetchingConsultantList();
        } else {
          toastMessage(data?.message, "error", "startConsultation");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setStartConsultantLoading(false);
      }
    }
  };

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (pdfFileRegex.test(e.target.files[0].name)) {
        setAddNoteData({ ...addNoteData, attachment: e.target.files[0] });
      } else {
        e.target.value = "";
        setAddNoteData({ ...addNoteData, attachment: "" });
        toastMessage("Invalid Format", "error", "imageFormat");
      }
    } else {
      e.target.value = "";
      setAddNoteData({ ...addNoteData, attachment: "" });
    }
  };

  return (
    <>
      <div className="card_wrap">
        {consultantList && consultantList.length ? (
          consultantList.map((data) => {
            return (
              <>
                <div className="consultation_card">
                  <div className="item">
                    <div className="head">
                      <h4>{data?.service?.title || "---"}</h4>
                      <span><strong>Booking ID: </strong>{`${data?.bookingId || "---"}`}</span>
                    </div>
                    <div className="content_wrap">
                      <div className="content">
                        {/* <img src={data?.user?.image || ""} alt="" /> */}
                        {data?.service?.image && (
                          <img src={data?.user?.image || ""} alt="" />
                        )}

                        <p>
                          <b>{`${
                            (data?.user && data?.user?.firstName) || "---"
                          }${" "}${data?.user && data?.user?.lastName}`}</b>
                          <span>
                            Booking Date :{" "}
                            {dateFormat(data?.bookedFor) || "---"}
                          </span>
                        </p>
                      </div>
                      <p className="time">
                        <span>
                          Start Time : {timeFormat(data?.bookedFor) || "---"}
                        </span>
                        <span>Duration : {data?.slot?.duration} min</span>
                      </p>

                      <div className="button_wrap">
                        {bookingStatus === "upcoming" ? (
                          compareDates(
                            data?.bookedFor,
                            data?.slot?.duration,
                            null
                          ) === "Start Consultation" ? (
                            <Button
                              className={"w-100"}
                              onClick={() => {
                                startConsultationHandler(data?._id);
                                
                                // Open the expert meeting link if it exists
                                if (data?.expertMeetingLink) {
                                    window.open(data.expertMeetingLink, "_blank");
                                }
                            }}
                            
                            >
                              Start Consultation
                            </Button>
                          ) : compareDates(data?.bookedFor, null, 1) ===
                            "Cancel Consultation" ? (
                            <Button
                              className={"w-100 light red"}
                              onClick={() => {
                                setCancelOneToOneModal(true);
                                setId(data?._id);
                              }}
                            >
                              Cancel Consultation
                            </Button>
                          ) : (
                            <Button isDisabled={true} className={"w-100"}>
                              Scheduled
                            </Button>
                          )
                        ) : bookingStatus === "completed" ? (
                          <Button
                            className={"w-100"}
                            isDisabled={data?.note?.noteAddedOn}
                            onClick={() => {
                              setNoteModal(true);
                              setId(data?._id);
                              setAddNoteData({
                                ...addNoteData,
                                firstName: data?.user?.firstName,
                                lastName: data?.user?.lastName,
                                // noteAddedOn:data?.note?.noteAddedOn || ""
                              });
                            }}
                          >
                            Add Note
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <Placeholder text={"No Services Available of this expert"} />
        )}
      </div>

      {/* Cancel One TO One Modal */}
      <CustomModal
        show={cancelOneToOneModal}
        handleClose={() => {
          setCancelOneToOneModal(false);
        }}
        className={"logoutModal"}
      >
        <h3>Cancel Service</h3>
        <p>Are you sure you want to cancel this service?</p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setCancelOneToOneModal(false);
            }}
          >
            No
          </Button>
          <Button onClick={cancelConsultationHandler}>Yes</Button>
        </div>
      </CustomModal>

      {/* Add Note Modal */}
      <CustomModal
        show={noteModal}
        handleClose={() => {
          setNoteModal(false);
          setId();
          setError("");
          setAddNoteData("");
        }}
      >
        <div className="PriorityDMS_reply">
          <h3 className="title">{`Reply to ${addNoteData?.firstName} ${" "} ${
            addNoteData?.lastName
          }`}</h3>
          {/* <p>Please enter your registered email address to continue</p> */}

          <div className="form_field">
            <label className="label">
              Enter Message{" "}
              <p className="file_upload">
                {addNoteData?.attachment?.name ? (
                  <>
                    <img src={pdfIcon} alt="" />{" "}
                    <span className="text-decoration-underline">
                      {(addNoteData?.attachment &&
                        charLimit(addNoteData?.attachment?.name, 10)) ||
                        "File Uploaded"}
                    </span>
                    <button
                      type="button"
                      className="cancel_button"
                      onClick={() => {
                        setAttached(true);
                        setAddNoteData({ ...addNoteData, attachment: "" });
                      }}
                    >
                      <IoClose />
                    </button>
                  </>
                ) : (
                  <label htmlFor="uploadFile">
                    <input
                      type="file"
                      id="uploadFile"
                      onChange={imageHandler}
                    />
                    <GrAttachment />
                    <span className="text-decoration-underline">
                      Upload Document
                    </span>
                  </label>
                )}
              </p>
            </label>
            <TextArea
              placeholder="Enter Message"
              value={addNoteData?.text}
              onChange={(e) =>
                setAddNoteData({ ...addNoteData, text: e.target.value })
              }
              error={error}
            />
          </div>
          <LoadingButton
            className="w-100"
            // isDisabled={addNoteData?.noteAddedOn}
            loading={loading}
            onClick={NoteConsultationHandler}
          >
            Submit
          </LoadingButton>
        </div>
      </CustomModal>
    </>
  );
};

export default UpcomingConsultation;
