import React, { useEffect } from "react";
import {Adsense} from '@ctrl/react-adsense';

const GoogleAds = () => {
//   useEffect(() => {
//     try {
//       (window.adsbygoogle = window.adsbygoogle || []).push({});
//     } catch (e) {
//       console.error("Error loading ads", e);
//     }
//   }, []);

  return (
<Adsense
  client="ca-pub-7640562161899788"
  slot="7259870550"
  style={{ display: 'block' }}
  layout="in-article"
  format="fluid"
/>
  );
};

export default GoogleAds;
