import {
  banner1,
  imagePlaceholder,
  profileImg,
  serviceImg1,
} from "assets/images";
import { Button } from "components/form";
import React, { useEffect, useState } from "react";
import { LuIndianRupee } from "react-icons/lu";
import { RiEdit2Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "../ManageServices.scss";
import {
  deleteServicesApi,
  getServicesDetailApi,
} from "services/expertServices/manage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import { dateFormat, timeFormat_24 } from "utils/dateFormat";
import Skeleton from "react-loading-skeleton";
import Placeholder from "components/placeholder/Placeholder";
import { HiDotsVertical } from "react-icons/hi";
import { Dropdown } from "react-bootstrap";
import { HiOutlineTrash } from "react-icons/hi2";
import DeleteServices from "../DeleteServices";

const WebinarsDetails = () => {
  const [loader, onShow, onHide] = FullPageLoader();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [serviceDetail, setServiceDetail] = useState({});
  const { serviceType, serviceId } = useParams();
  const [testimonial, setTestimonial] = useState([]);
  const navigate = useNavigate();

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setServiceDetail(data?.data);
        setTestimonial(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // -----------Delete Service------------
  const deleteServiceHandler = async () => {
    try {
      setDeleteLoader(true);
      const { data } = await deleteServicesApi({ _id: serviceId });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "DeleteService");
        navigate(`/dashboard/manage-services?serviceType=${serviceType}`);
      } else {
        toastMessage(data?.message, "error", "DeleteService");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  return (
    <>
      {loader}
      <div className="consultation_details has_banner">
        <img src={banner1} alt="" className="banner_img" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>Webinars</h3>
          </div>

          <div className="page_banner spacing">
            <div className="banner_content">
              <img src={serviceDetail?.image || imagePlaceholder} alt="" />
              <div className="content">
                {/* <h5>Pulled Hamstring causes, Treatment and recovery </h5> */}
                <h5>{serviceDetail?.title} </h5>

                <p className="duration">
                  Duration <strong>{serviceDetail?.duration}</strong> Start Date
                  & Time{" "}
                  <strong>
                    ({dateFormat(serviceDetail?.webinarAvailability)},
                    {timeFormat_24(serviceDetail?.webinarAvailability)} )
                  </strong>
                </p>
                <p className="price">
                  {/* <del>
                    <LuIndianRupee /> {serviceDetail?.pricing?.amount}
                  </del> */}
                  {serviceDetail?.pricing?.showSlashedAmount && (
                    <del>
                      <LuIndianRupee />{" "}
                      {Number(serviceDetail?.pricing?.slashAmount) || (
                        <Skeleton width={50} />
                      )}
                    </del>
                  )}
                  <span>
                    <LuIndianRupee />{" "}
                    {
                    // serviceDetail?.pricing?.showSlashedAmount
                    //   ? Number(
                    //       Number(serviceDetail?.pricing?.amount)
                    //        -
                    //         Number(serviceDetail?.pricing?.slashAmount)
                    //     )
                    //   :
                       Number(serviceDetail?.pricing?.amount)
                      }
                  </span>
                </p>
              </div>
            </div>
            <div className="button_wrap button_right">
              {/* <Link
                to={`/dashboard/manage-services/webinar/detail/edit/${serviceType}/${serviceId}`}
                className={" button has_icon dashboard_button"}
              >
                <RiEdit2Line /> Edit Service
              </Link> */}

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <HiDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={"span"}>
                    <Link
                      to={`/dashboard/manage-services/webinar/detail/edit/${serviceType}/${serviceId}`}
                    >
                      <RiEdit2Line /> Edit Service
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={"span"}
                    onClick={() => setDeleteModal(true)}
                  >
                    <HiOutlineTrash />
                    Delete Service
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="page_content spacing">
            <div className="overView table_radius">
              <div className="_sub_title">
                <h4>Overview</h4>
              </div>
              <div className="content">
                {/* <h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat
                </h4> */}
                {/* <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                  nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur?
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                  nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur?
                </p> */}

                {serviceDetail?.description ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: serviceDetail.description,
                    }}
                  ></p>
                ) : (
                  <p>---</p>
                )}
              </div>
            </div>

            <div className="highlight table_radius">
              <div class="_sub_title">
                <h4>
                  {serviceDetail?.serviceBookingsCount} People join this
                  webinars
                </h4>
              </div>
              <ul className="content _profiles">
                {serviceDetail?.serviceBookings &&
                serviceDetail?.serviceBookings.length > 0
                  ? serviceDetail?.serviceBookings.map((data) => (
                      <li key={data?._id}>
                        <img
                          src={
                            (data?.user?.image && data?.user?.image) ||
                            profileImg
                          }
                          alt=""
                        />
                        <p>
                          <b>{`${
                            (data?.user?.firstName && data?.user?.firstName) ||
                            "---"
                          } ${" "} ${
                            (data?.user?.lastName && data?.user?.lastName) ||
                            "---"
                          }`}</b>
                          {/* <span>Booking ID : {data?._id}</span> */}
                        </p>
                      </li>
                    ))
                  : "No Booking User Found"}
                {/* <li>
                  <img src={profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li>
                <li>
                  <img src={profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li>
                <li>
                  <img src={profileImg} alt="" />
                  <p>
                    <b>Jane Cooper</b>
                    <span>Booking ID : #123DBS4282</span>
                  </p>
                </li> */}
              </ul>
            </div>
          </div>
          {serviceDetail?.video && (
            <div className="introduction_url">
              <b>Introduction Video Link</b>
              {/* <p>https://youtu.be/YIC_Ml_g_jc?si=7DmqyVOMgnpN9JvN</p> */}
              <p>{serviceDetail?.video || "---"}</p>
            </div>
          )}
          {testimonial && testimonial.length > 0 && (
            <div className="table_radius">
              <div className="_sub_title">
                <h4>Testimonial</h4>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial && testimonial.length > 0 ? (
                    <TestimonialCard
                      bestTestimonials={testimonial}
                      setBestTestimonials={setTestimonial}
                      display={false}
                    />
                  ) : (
                    <Placeholder text="No Testimonials for this webinar services" />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Delete Component */}
          {deleteModal && (
            <DeleteServices
              loading={deleteLoader}
              deleteServiceHandler={deleteServiceHandler}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WebinarsDetails;
