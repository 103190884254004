import { Button, LoadingButton, SwitchButton } from "components/form";
import React, { useState } from "react";
import { LuIndianRupee } from "react-icons/lu";
import "./ServicesCard.scss";
import { Link, useNavigate } from "react-router-dom";
import { charLimit } from "utils/mediaDataSender";
import { dateFormatWithTime } from "utils/dateFormat";
import CustomModal from "components/modals/CustomModal";
import { toastMessage } from "utils/toastMessage";
import { updateServicesApi } from "services/expertServices/manage";
import { Shimmer } from "common/Simmer";
import { imagePlaceholder } from "assets/images";
const ServicesCard = ({
  duration,
  showSimmer,
  serviceId,
  image,
  title,
  description,
  pricing,
  // discountPrice,
  isScheduled,
  webinarAvailability,
  url,
  serviceType,
  bookedFor,
  promisedResponseTime,
  services,
  isHidden,
  fetchingServicesData,
  ...rest
}) => {
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigateHandler = () => {
    navigate("/dashboard/services-schedule");
  };

  const updateServiceStatusHandler = async () => {
    if (serviceData?._id) {
      try {
        setLoading(true);

        const { data } = await updateServicesApi(serviceData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "updateStatus");
          fetchingServicesData();
          setServiceData("");
          setStatusModal(false);
        } else {
          toastMessage(data?.message, "error", "updateStatus");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderServiceTypeContent = () => {
    switch (serviceType) {
      case "oneToOne":
        return (
          !isScheduled && (
            <Button onClick={navigateHandler}>Schedule Now</Button>
          )
        );
      case "webinar":
        return (
          <>
            <p className="_schedule">
              <span>Start Date & Time</span>{" "}
              <b>{dateFormatWithTime(webinarAvailability)}</b>
            </p>
          </>
        );
      case "prioritydm":
        return (
          <>
            <p className="_schedule">Response Time {promisedResponseTime}</p>
          </>
        );
      case "package":
        return (
          <>
            {/* <p className="_schedule">Response Time {promisedResponseTime}</p> */}
          </>
        );
      default:
        return null;
    }
  };

  return (
    // <>
    //   {showSimmer ? (
    //     <Shimmer
    //       showShimmer={showSimmer}
    //       height={150}
    //       width={200}
    //       type="thumbnail"
    //       className="m-0"
    //     />
    //   ) : (
    //     <div className="serviceCard">
    //       <div className="item">
    //         <Link to={url}></Link>
    //         {showSimmer ? (
    //           <Shimmer
    //             showShimmer={showSimmer}
    //             height={150}
    //             width={200}
    //             type="thumbnail"
    //             className="m-0"
    //           />
    //         ) : (
    //           <div className="image_wrap">
    //             <img src={image} alt="" />
    //             {serviceType === "package"
    //               ? // Render content for package here
    //                 services && services.length
    //               : duration && <span className="duration">{duration}</span>}
    //           </div>
    //         )}

    //         <div className="card_content">
    //           {showSimmer ? (
    //             <Shimmer showShimmer={showSimmer} type="badge" />
    //           ) : (
    //             <>
    //               {" "}
    //               <SwitchButton
    //                 status={!isHidden}
    //                 onClick={() => {
    //                   setServiceData({
    //                     ...serviceData,
    //                     _id: serviceId,
    //                     serviceType: serviceType,
    //                     isHidden: !isHidden,
    //                   });
    //                   setStatusModal(true);
    //                 }}
    //               />
    //             </>
    //           )}

    //           {showSimmer ? (
    //             <Shimmer
    //               showShimmer={showSimmer}
    //               height={10}
    //               width={250}
    //               type="title"
    //             />
    //           ) : (
    //             <h4>{title && charLimit(title, 29)}</h4>
    //           )}

    //           {showSimmer ? (
    //             <Shimmer
    //               showShimmer={showSimmer}
    //               height={150}
    //               width={325}
    //               type="text"
    //             />
    //           ) : (
    //             <>
    //               {description ? (
    //                 <p
    //                   dangerouslySetInnerHTML={{
    //                     __html: charLimit(description, 80),
    //                   }}
    //                 ></p>
    //               ) : (
    //                 <p>---</p>
    //               )}
    //             </>
    //           )}

    //           <div className="button_wrap">
    //             {showSimmer ? (
    //               <Shimmer showShimmer={showSimmer} type="button" />
    //             ) : (
    //               <p className="price">
    //                 {pricing?.showSlashedAmount ? (
    //                   <>
    //                     <del>
    //                       <LuIndianRupee />
    //                       {pricing?.slashAmount}
    //                     </del>
    //                     {/* )} */}

    //                     <span>
    //                       <LuIndianRupee />
    //                       {Number(
    //                         pricing?.amount
    //                         // - Number(pricing?.slashAmount)
    //                       )}
    //                     </span>
    //                   </>
    //                 ) : (
    //                   <span>
    //                     <LuIndianRupee />
    //                     {Number(
    //                       pricing?.amount
    //                       // - Number(pricing?.slashAmount)
    //                     )}
    //                   </span>
    //                 )}
    //               </p>
    //             )}
    //             {/* {serviceType === "oneToOne" ? (
    //           !isScheduled && <Button>Schedule Now</Button>
    //         ) : serviceType === "webinar" ? (
    //           <p>Start Date & Time</p>
    //         ) : // <h3>{}</h3>
    
    //         serviceType === "prioritydm" ? (
    //           <>
    //             <p>Response</p>
    //             <p>{promisedResponseTime}</p>
    //           </>
    //         ) : (
    //           ""
    //         )} */}
    //             {showSimmer ? (
    //               <Shimmer showShimmer={showSimmer} type="badge" />
    //             ) : (
    //               renderServiceTypeContent()
    //             )}
    //           </div>
    //         </div>
    //       </div>

    //       <CustomModal
    //         show={statusModal}
    //         handleClose={() => {
    //           setStatusModal(false);
    //           setServiceData("");
    //         }}
    //         className={"logoutModal"}
    //       >
    //         <h3>Hide Services</h3>
    //         <p>
    //           Are you sure you want to {isHidden ? "unhide" : "hide"} this
    //           service from your users?
    //         </p>

    //         <div className="button_wrap button_center">
    //           <Button
    //             className={"light"}
    //             onClick={() => {
    //               setServiceData("");
    //               setStatusModal(false);
    //             }}
    //           >
    //             Cancel
    //           </Button>
    //           <LoadingButton
    //             loading={loading}
    //             onClick={updateServiceStatusHandler}
    //           >
    //             Yes
    //           </LoadingButton>
    //         </div>
    //       </CustomModal>
    //     </div>
    //   )}
    // </>





<>
  {showSimmer ? (
        <div className="serviceCard">
        <div className="item">
    <Shimmer
      showShimmer={showSimmer}
      type="thumbnail"
      className="m-0"
    />
    </div>
    </div>
  ) : (
    <div className="serviceCard">
      <div className="item">
        <Link to={url}></Link>

        <div className="image_wrap">
          <img src={image || imagePlaceholder} alt="" />
          {serviceType === "package"
            ? services && services.length
            : duration && <span className="duration">{`${duration} Min`}</span>}
        </div>

        <div className="card_content">
          <SwitchButton
            status={!isHidden}
            onClick={() => {
              setServiceData({
                ...serviceData,
                _id: serviceId,
                serviceType: serviceType,
                isHidden: !isHidden,
              });
              setStatusModal(true);
            }}
          />

          <h4>{title && charLimit(title, 29)}</h4>

          {description ? (
            <p
              dangerouslySetInnerHTML={{
                __html: charLimit(description, 80),
              }}
            ></p>
          ) : (
            <p>---</p>
          )}

          <div className="button_wrap">
            <p className="price">
              {pricing?.showSlashedAmount ? (
                <>
                  <del>
                    <LuIndianRupee />
                    {pricing?.slashAmount}
                  </del>

                  <span>
                    <LuIndianRupee />
                    {Number(pricing?.amount)}
                  </span>
                </>
              ) : (
                <span>
                  <LuIndianRupee />
                  {Number(pricing?.amount)}
                </span>
              )}
            </p>

            {renderServiceTypeContent()}
          </div>
        </div>
      </div>

      <CustomModal
        show={statusModal}
        handleClose={() => {
          setStatusModal(false);
          setServiceData("");
        }}
        className={"logoutModal"}
      >
        <h3>Hide Services</h3>
        <p>
          Are you sure you want to {isHidden ? "unhide" : "hide"} this service
          from your users?
        </p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setServiceData("");
              setStatusModal(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={updateServiceStatusHandler}
          >
            Yes
          </LoadingButton>
        </div>
      </CustomModal>
    </div>
  )}
</>


  );
};

export default ServicesCard;
