import AccountDetails from "expertDashboard/expertProfileSetup/AccountDetail";
import AdditionalDetails from "expertDashboard/expertProfileSetup/AdditionalDetail";
import PersonaDetail from "expertDashboard/expertProfileSetup/PersonalDetail";
// import UpdatedPersonalDetail from "expertDashboard/expertProfileSetup/UpdatedPersonalDetail";
import ExpertPersonalDetails from "expertDashboard/expertProfileSetup/PersonalInfo";
import React, { useState } from "react";
import "../DashboardHome.scss";
const EditExpertProfile = () => {
  const [tabButton, setTabButton] = useState("personal");
  return (
    <>
      <div className="editProfile">
        <div className="dashboard_title">
          <h4>Edit Profile</h4>
        </div>
        <div className="dashboard_content_inner">
          <div className="tab_section">
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => setTabButton("personal")}
                  className={`tab_button ${
                    tabButton === "personal" ? "active" : ""
                  }`}
                >
                  Personal Information{" "}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTabButton("ProfessionalDetails")}
                  className={`tab_button ${
                    tabButton === "ProfessionalDetails" ? "active" : ""
                  }`}
                >
                  Professional Details
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTabButton("bankAccount")}
                  className={`tab_button ${
                    tabButton === "bankAccount" ? "active" : ""
                  }`}
                >
                  Bank Account
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTabButton("additional")}
                  className={`tab_button ${
                    tabButton === "additional" ? "active" : ""
                  }`}
                >
                  Additional Details
                </button>
              </li>
            </ul>
          </div>

          <div className="tab_content">
            {tabButton === "personal" && <ExpertPersonalDetails signUpForm={false} />}
            {tabButton === "ProfessionalDetails" && <PersonaDetail signUpForm={false} />}
            {/* {tabButton === "ProfessionalDetails" && <UpdatedPersonalDetail />} */}

            {tabButton === "bankAccount" && <AccountDetails signUpForm={false} />}
            {tabButton === "additional" && <AdditionalDetails signUpForm={false} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditExpertProfile;
