import { instance as axios, setJwtToken } from "../../config/axiosInstance";

// ---------------------Country List API ----------------------------------
export const countryListApi = async () => {
  return await axios.get("masterdata/countries");
};

// ---------------------State List API ----------------------------------
export const stateListApi = async (id) => {
  return await axios.get(`masterdata/states/${id}`);
};

// ---------------------City List API ----------------------------------
export const cityListApi = async (id) => {
  return await axios.get(`masterdata/cities/${id}`);
};

// ---------------------Expertise List--------------------------------
export const expertiseListApi = async () => {
  return await axios.get(`masterdata/expertise?search=${""}`);
};

// ---------------------Expert Listing--------------------------------
export const expertListApi = async (data) => {
  let endPoint;
  if (data) {
    endPoint =
      `user/explore/experts` +
      "?" +
      new URLSearchParams({ ...data }).toString();
  } else {
    endPoint = `user/explore/experts`;
  }
  return await axios.get(endPoint, setJwtToken() ? setJwtToken() : "");
};

// ---------------------SKills Listing--------------------------------
export const skillsListApi = async () => {
  return await axios.get("masterdata/skills");
};

// Get Testimonials API
export const getTestimonials = async (data) => {
  const endPoint =
    `expert/service/testimonials` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get Expert Details By Slug
export const getExpertDetailBySlug = async (data) => {
  const endPoint =
    `expert/details-by-slug/${data}` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// Get FAQ list
export const getFaqList = async (data) => {
  const endPoint =
    `masterdata/cms/FAQs` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint);
};

// Submit Query
export const submitQueryApi = async (data) => {
  const endPoint = `masterdata/send-query`;
  return await axios.post(endPoint, data, setJwtToken());
};

// Get Terms Data
export const getTermsApi = async (data) => {
  const endPoint =
    `masterdata/cms/get-terms-policy` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};


// Get Testimonials
export const getTestimonialsApi = async (data) => {
  const endPoint =
    `masterdata/testimonials` 
  return await axios.get(endPoint, setJwtToken());
};
