import AddService from "components/cards/servicesCard/AddService";
// import "../ManageServices.scss";
import "../../dashboard/manageServices/ManageServices.scss";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  TextArea,
} from "components/form";
import { IoCloudUploadOutline } from "react-icons/io5";
// import "../../../../components/cards/servicesCard/ServicesCard.scss";
import "../../../components/cards/servicesCard/ServicesCard.scss";
import { FaTimes } from "react-icons/fa";
import { BsCurrencyRupee } from "react-icons/bs";
// import { testimonial } from "staticData";
// import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
// import CustomModal from "components/modals/CustomModal";
// import { getTestimonials } from "services/master";
// import commonMessages from "utils/commonMessages";
// import { allowNumber, regex } from "utils/formValidator";
// import { toastMessage } from "utils/toastMessage";
import { useNavigate, useParams } from "react-router-dom";
// import { addOneToOneApi } from "services/expertServices/manage/consultations";
// import { addServicesApi } from "services/expertServices/manage";
// import GetTestimonials from "../GetTestimonials";
import { sendArrayDataId } from "utils/mediaDataSender";
import JoditEditor from "jodit-react";
import { allowNumber, getFileExtension, regex } from "utils/formValidator";
import commonMessages, { slashedValidation } from "utils/commonMessages";
import { toastMessage } from "utils/toastMessage";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import Placeholder from "components/placeholder/Placeholder";
import {
  addServicesApi,
  getServicesDetailApi,
  updateServicesApi,
} from "services/expertServices/manage";
import { getTestimonials } from "services/master";
import GetTestimonials from "../manageServices/GetTestimonials";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import CoverImageCropper from "../manageServices/CoverImageCropper";
// import Placeholder from "components/placeholder/Placeholder";

const EditDigitalCourse = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const { serviceType, serviceId, digitalType } = useParams();
  const { IMAGE_FORMAT } = commonMessages;
  const [image, setImage] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [document, setDocument] = useState("");
  const { imageFileRegex, url, documentFileRegex } = regex;
  const [inputField, setInputField] = useState({ serviceType: serviceType });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [bestTestimonials, setBestTestimonials] = useState([]);
  const [showTestimonialModal, setShowTestimonilModal] = useState(false);
  const navigate = useNavigate();
  const [loader, onShow, onHide] = FullPageLoader();
  const [serviceDetail, setServiceDetail] = useState({});
  const [testimonial, setTestimonial] = useState([]);
  const [pricing, setPricing] = useState({});

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setBestTestimonials(data?.data?.testimonials || []);
        setInputField({
          ...data?.data,
          uploadType: data?.data?.document?.uploadType,
        });
        setImage(data?.data?.image);
        if (data?.data?.document?.uploadType === "file") {
          setFileUpload(data?.data?.document?.url || "");
        } else {
          setInputField({
            ...data?.data,
            uploadType: data?.data?.document?.uploadType,
            url: data?.data?.document?.url,
          });
        }
        setPricing(
          {
            ...data?.data?.pricing,
            showSlashedAmount: data?.data?.pricing?.showSlashedAmount
              ? "yes"
              : "no",
          } || {}
        );
        setBestTestimonials(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  const duration = ["15", "30", "45", "60", "75", "90", "105", "120"];

  const imageHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (imageFileRegex.test(e.target.files[0].name)) {
        setImage(e.target.files[0]);
      } else {
        e.target.value = "";
        setImage("");
        toastMessage(IMAGE_FORMAT, "error", "imageFormat");
      }
    }
  };

  const fileUploadHandler = async (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      if (documentFileRegex.test(e.target.files[0].name)) {
        setFileUpload(e.target.files[0]);
      } else {
        e.target.value = "";
        setFileUpload("");
        toastMessage("Please upload pdf , txt , doc", "error", "fileFormat");
      }
    }
  };

  const deleteImg = (e) => {
    e.target.value = null;
    setImagePreview(null);
  };

  const deleteFileUpload = (e) => {
    e.target.value = null;
    setFileUpload(null);
  };

  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholer: "",
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
      controls: {
        font: {
          command: "fontname",
          list: {
            "'Open Sans',sans-serif": "Open Sans",
            "Helvetica,sans-serif": "Helvetica",
            "Arial,Helvetica,sans-serif": "Arial",
            "Georgia,serif": "Georgia",
            "Impact,Charcoal,sans-serif": "Impact",
            "Tahoma,Geneva,sans-serif": "Tahoma",
            "'Times New Roman',Times,serif": "Times New Roman",
            "Verdana,Geneva,sans-serif": "Verdana",
            "Consolas,monaco,monospace": "Consolas",
          },
        },
      },
    }),
    []
  );

  const fetchingTestimonialHandler = async () => {
    try {
      setTestimonials([]);
      const sendData = {
        serviceType: serviceType,
        page: 0,
        pageSize: 10,
      };
      const { data } = await getTestimonials(sendData);
      if (data?.code === 200) {
        setTestimonials(data?.data);
      } else {
        setTestimonials([]);
        toastMessage(data?.message, "error", "GetTestimonials");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;

    if (name === "video") {
      setInputField({ ...inputField, [name]: value });
    } else if (name === "showSlashedAmount") {
      setInputField({ ...inputField, [name]: value, slashAmount: "" });
    } else if (name === "amount" || name === "slashAmount") {
      setInputField({ ...inputField, [name]: allowNumber(value) });
    } else {
      setInputField({ ...inputField, [name]: value });
    }
  };

  // Pricing Handler
  const pricingHandler = (e) => {
    const { name, value } = e.target;
    if (name === "showSlashedAmount") {
      setPricing({ ...pricing, [name]: value, slashAmount: "" });
    } else setPricing({ ...pricing, [name]: allowNumber(value) });
  };

  const validation = () => {
    let status = true;
    let errors = {};
    const emptyDescriptionRegex = /^<p><br><\/p>$/;
    if (
      !inputField.description ||
      emptyDescriptionRegex.test(inputField.description)
    ) {
      errors["description"] = `Description is mandatory`;
      status = false;
    }
    if (!inputField?.title) {
      errors["title"] = "Title is mandatory";
      status = false;
    }
    // if (!inputField?.duration) {
    //   errors["duration"] = "Select duration";
    //   status = false;
    // }
    if (!inputField?.uploadType) {
      errors["uploadType"] = "Select Upload Type";
      status = false;
    }
    if (inputField?.uploadType === "link" && !inputField?.url) {
      errors["url"] = "Please provide URL";
      status = false;
    }
    if (inputField?.uploadType === "file" && !fileUpload) {
      errors["document"] = "Please upload file";
      status = false;
    }
    // if (!inputField?.video) {
    //   errors["video"] = "Video Url is mandatory";
    //   status = false;
    // }
    if (inputField?.video && !inputField?.video.match(url)) {
      errors["video"] = "Invalid Url";
      errors["videoErr"] = true;
      status = false;
    }
    if (!image) {
      errors["image"] = "Cover image is mandatory";
      status = false;
    }
    if (!pricing?.amount) {
      errors["amount"] = "Amount is mandatory";
      status = false;
    }
    if (!pricing?.showSlashedAmount) {
      errors["showSlashedAmount"] = "Show slash pricing?";
      status = false;
    }
    if (pricing?.showSlashedAmount == "yes" && !pricing?.slashAmount) {
      errors["slashAmount"] = "Enter Slash amount";
      status = false;
    }
    let { error, slashstatus } = slashedValidation(
      pricing?.showSlashedAmount,
      pricing?.amount,
      pricing?.slashAmount
    );
    if (Object.keys(error).length > 0) {
      Object.assign(errors, error);
      status = slashstatus;
    }

    setError(errors);
    return status;
  };

  const addServiceHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const formData = new FormData();
        if (image && image?.name) {
          formData.append("image", image);
        }
        if (serviceId) {
          formData.append("_id", serviceId);
        }
        formData.append("pricing", JSON.stringify(pricing));

        formData.append("description", inputField?.description);
        formData.append("video", inputField?.video);
        // formData.append("duration", inputField?.duration);
        formData.append("title", inputField?.title);
        formData.append("serviceType", "digital");
        formData.append("digitalContentType", "product");
        if (bestTestimonials && bestTestimonials.length) {
          const selectedTestimonials = sendArrayDataId(bestTestimonials);
          formData.append("testimonials", JSON.stringify(selectedTestimonials));
        } else {
          formData.append("testimonials", `[]`);
        }
        // formData.append("uploadType", inputField?.uploadType);
        if (
          inputField?.uploadType === "file" &&
          fileUpload &&
          fileUpload?.name
        ) {
          formData.append("document", fileUpload);
          formData.append("uploadType", inputField?.uploadType);
        }
        if (inputField?.uploadType === "video") {
          formData.append("documentVideoLink", inputField?.url);
          formData.append("uploadType", inputField?.uploadType);
        }

        const { data } = await updateServicesApi(formData);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "addOneToOne");
          navigate("/dashboard/digital-contents");
          setError({});
        } else {
          toastMessage(data?.message, "error", "addOneToOne");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (showTestimonialModal) {
      fetchingTestimonialHandler();
    }
  }, [showTestimonialModal]);

  return (
    <>
      <div className="edit_service">
        <div className="dashboard_title">
          <h3>Edit Digital Product</h3>
        </div>

        <div className="dashboard_content_inner">
          <div className="add_services_from">
            <form>
              <div className="content_left">
                <div className="form_field">
                  <Input
                    label={"Title"}
                    name="title"
                    value={inputField?.title || ""}
                    placeholder={"Enter Title"}
                    onChange={inputFieldHandler}
                    maxlength="100"
                    error={error?.title}
                  />
                </div>
                <div className="form_field">
                <label className="label">Description</label>
                  <JoditEditor
                    ref={editor}
                    value={inputField?.description}
                    tabIndex={1}
                    onBlur={(value) =>
                      setInputField({ ...inputField, description: value })
                    }
                    config={config}
                  />
                  <p className="invalid-feedback d-block">
                    {error?.description}
                  </p>
                </div>
                <div className="form_field">
                  <Input
                    label={"Overview Video Link"}
                    name="video"
                    value={inputField?.video || ""}
                    placeholder={"Enter url"}
                    onChange={inputFieldHandler}
                    error={error?.video}
                    extraError={error?.videoErr}
                  />
                </div>
              </div>
              <div className="content_right">
                {/* <div className="form_field">
                  <Select
                    label={"Duration (mins)"}
                    name="duration"
                    value={inputField?.duration || ""}
                    onChange={inputFieldHandler}
                    error={error?.duration}
                  >
                    <option value="">Select Duration</option>
                    {duration &&
                      duration.length &&
                      duration.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                  </Select>
                </div> */}

                {/* <div className="form_field position-relative">
                  <label className="label d-block">Upload Cover Image</label>
                  {imagePreview && (
                    <span className="delete_button" onClick={deleteImg}>
                      <FaTimes />
                    </span>
                  )}
                  <label className="imagePreview" htmlFor="coverImage">
                    <input
                      type="file"
                      name="image"
                      id="coverImage"
                      onChange={imageHandler}
                      error={error?.image}
                    />
                    {image ? (
                      <>
                        <img
                          src={
                            image && image?.name
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="content">
                        <IoCloudUploadOutline />
                        <label className="button" htmlFor="coverImage">
                          Browse
                        </label>
                        <span>drop a file here</span>
                        <p>*File supported .png, .jpg, .webp</p>
                      </div>
                    )}
                  </label>
                </div>

                {error.image && (
                  <span className="invalid-feedback d-block">
                    {error?.image}
                  </span>
                )} */}

<CoverImageCropper
                  imageSelect={(img) => {
                    setImage(img);
                  }}
                  prevImage={image}
                  error={error?.image}
                />
              </div>
            </form>
            <div className="pricing">
              <h5>Pricing</h5>
              <div className="pricing_content">
                <div className="form_field">
                  <label className="label">Enter Amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      name="amount"
                      value={pricing?.amount || ""}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.amount}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Show Slash Pricing</label>
                  <div className="radio_button_wrap">
                    <label
                      htmlFor="yes"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "yes" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="yes"
                        value="yes"
                        checked={pricing?.showSlashedAmount == "yes"}
                        onChange={pricingHandler}
                      />
                      <span>Yes</span>
                    </label>
                    <label
                      htmlFor="no"
                      className={`radio_button input ${
                        pricing?.showSlashedAmount == "no" ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="showSlashedAmount"
                        id="no"
                        value="no"
                        checked={pricing?.showSlashedAmount == "no"}
                        onChange={pricingHandler}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>

                <div className="form_field">
                  <label className="label">Actual amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      disabled
                      name="amount"
                      value={pricing?.amount || 0}
                      placeholder="Enter Amount"
                      onChange={pricingHandler}
                      maxlength="6"
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Slashed amount</label>
                  <div className="input_wrap">
                    <Input
                      type={"text"}
                      placeholder="Enter Amount"
                      disabled={pricing?.showSlashedAmount == "no"}
                      name="slashAmount"
                      value={pricing?.slashAmount || ""}
                      onChange={pricingHandler}
                      maxlength="6"
                      error={error?.slashAmount}
                      extraError={error?.slashAmountErr}
                    />
                    <span className="icon">
                      <BsCurrencyRupee />
                    </span>
                  </div>
                </div>
                <div className="form_field">
                  <label className="label">Display Price</label>
                  <div className="display_price input">
                    {!pricing?.showSlashedAmount ? (
                      <span>
                        {pricing?.amount
                          ? Number(pricing?.amount).toFixed(2)
                          : 0}
                      </span>
                    ) : (
                      <>
                        {" "}
                        <del>
                          <BsCurrencyRupee />{" "}
                          {pricing?.slashAmount
                            ? Number(pricing?.slashAmount)
                            : 0}
                        </del>
                        <span>
                          {pricing?.amount
                            ? Number(pricing?.amount)
                                // -
                                // Number(pricing?.slashAmount || 0)
                                .toFixed(2)
                            : 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="pricing">
              <h5>Upload Document</h5>
              <form>
                <div className="content_left">
                  <div className="form_field">
                    <Select
                      label={"Upload Option"}
                      name="uploadType"
                      value={inputField?.uploadType || ""}
                      onChange={inputFieldHandler}
                      error={error?.uploadType}
                    >
                      <option value="">Select Option</option>
                      <option value="file">File</option>
                      <option value="video">URL</option>
                    </Select>
                  </div>
                </div>
                <div className="content_right">
                  {inputField?.uploadType === "file" ? (
                    <>
                      <div className="form_field position-relative">
                        <label className="label d-block">
                          Upload File
                        </label>
                        {imagePreview && (
                          <span
                            className="delete_button"
                            onClick={deleteFileUpload}
                          >
                            <FaTimes />
                          </span>
                        )}
                        <label
                          className="imagePreview fileUpload"
                          htmlFor="fileUpload"
                        >
                          <input
                            type="file"
                            name="image"
                            id="fileUpload"
                            onChange={fileUploadHandler}
                          />
                          {fileUpload ? (
                            <>
                              {getFileExtension(
                                fileUpload.name ? fileUpload?.name : fileUpload
                              )}
                            </>
                          ) : (
                            <div className="content">
                              <IoCloudUploadOutline />
                              <label className="button" htmlFor="fileUpload">
                                Browse
                              </label>
                              <span>drop a file here</span>
                              <p>*File supported .pdf & .word</p>
                            </div>
                          )}
                        </label>
                      </div>

                      {error.document && (
                        <span className="invalid-feedback d-block">
                          {error?.document}
                        </span>
                      )}
                    </>
                  ) : (
                    <div className="form_field">
                      <Input
                        label={"Enter URL"}
                        name="url"
                        value={inputField?.url || ""}
                        onChange={inputFieldHandler}
                        error={error?.url}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className="table_radius spacing">
              <div class="_sub_title">
                <h4>
                  Display Testimonials for this service
                  <small>
                    Select the best testimonials that you want to display for
                    this service
                  </small>
                </h4>
                <Button
                  className={"dashboard_button"}
                  onClick={() => setShowTestimonilModal(true)}
                >
                  Select Testimonials
                </Button>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {bestTestimonials && bestTestimonials.length ? (
                    <TestimonialCard
                      bestTestimonials={bestTestimonials}
                      setBestTestimonials={setBestTestimonials}
                    />
                  ) : (
                    <Placeholder text={"No Testimonial Found"} />
                  )}
                </div>
              </div>
            </div>
            <div className="button_wrap button_right">
              <LoadingButton loading={loading} onClick={addServiceHandler}>
                Save
              </LoadingButton>
            </div>
          </div>

          <GetTestimonials
            testimonial={testimonials}
            setTestimonial={setTestimonials}
            showTestimonialModal={showTestimonialModal}
            setShowTestimonilModal={setShowTestimonilModal}
            bestTestimonials={bestTestimonials}
            setBestTestimonials={setBestTestimonials}
          />
        </div>
      </div>
    </>
  );
};

export default EditDigitalCourse;
