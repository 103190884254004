import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../../config/axiosInstance";

// Get Services API
export const getServicesApi = async (data) => {
  const endPoint =
    `expert/service/list` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// ------------------ Add Services ----------------------------------
export const addServicesApi = async (data) => {
  return await axios.post("expert/service/add", data, setMultiPartHeader());
};

// ------------------ Details Service ----------------------------------
export const getServicesDetailApi = async (data) => {
  return await axios.get(`expert/service/details/${data}`, setJwtToken());
};

// ------------------ Update Services ----------------------------------
export const updateServicesApi = async (data) => {
  return await axios.put("expert/service/update", data, setMultiPartHeader());
};

//----------------------Delete Services------------------------
export const deleteServicesApi = async (data) => {
  return await axios.patch(
    "expert/service/delete-service",
    data,
    setJwtToken()
  );
};

// -----------------How To Video-------------------------------
export const getHowToApi = async (data) => {
  return await axios.get(`expert/service/how-to/${data}`, setJwtToken());
};

// -----------------Hide Services-------------------------------
export const hideServicesApi = async (data) => {
  return await axios.get(`expert/service/how-to/${data}`, setJwtToken());
};

// Update Digital Product Services
export const updateDigitalProductApi = async (data) => {
  return await axios.put(
    `expert/service/update-chapter`,
    data,
    setMultiPartHeader()
  );
};
