import VideoPlayer from "common/YouTubeVideoPlayer";
import { Button, LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HowTo = ({ viewInfoModal, setViewInfoModal, url }) => {
  const { personalInfo } = useSelector((state) => state.config);
  return (
    <CustomModal
      show={viewInfoModal}
      handleClose={() => {
        setViewInfoModal(false);
      }}
      className={"md"}
    >
      <VideoPlayer src={url || ""} />

      <div className="button_wrap button_center">
        <button
          className="button whatsAppButton"
          onClick={() => {
            window.open(
              `https://wa.me/${personalInfo?.countryCode}${personalInfo?.phone}`,
              "_blank"
            );
          }}
        >
          
            <FaWhatsapp />
            Chat on Whatsapp
        </button>

        <Link to="/dashboard/expert/faqs" className="button">
          <div className="whatsapp-icon">
            <i className="fab fa-whatsapp"></i> Contact Support
          </div>
        </Link>
      </div>
    </CustomModal>
  );
};

export default HowTo;
