import { banner2, imagePlaceholder, serviceImg1 } from "assets/images";
import React, { useEffect, useState } from "react";
import { LuIndianRupee } from "react-icons/lu";
import { RiEdit2Line } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { testimonial } from "staticData";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "../manageServices/ManageServices.scss";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import {
  deleteServicesApi,
  getServicesDetailApi,
} from "services/expertServices/manage";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import DeleteServices from "../manageServices/DeleteServices";
import Placeholder from "components/placeholder/Placeholder";
import { IoDocumentText } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { handleMediaDownload } from "utils/mediaDataSender";
const ViewDigitalCourse = () => {
  const [loader, onShow, onHide] = FullPageLoader();
  const [serviceDetail, setServiceDetail] = useState({});
  const { serviceType, serviceId, digitalType } = useParams();
  const [testimonial, setTestimonial] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const navigate = useNavigate();

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setServiceDetail(data?.data);
        setTestimonial(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // -----------Delete Service------------
  const deleteServiceHandler = async () => {
    try {
      setDeleteLoader(true);
      const { data } = await deleteServicesApi({ _id: serviceId });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "DeleteService");
        navigate(`/dashboard/digital-contents`);
      } else {
        toastMessage(data?.message, "error", "DeleteService");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);
  return (
    <>
      {loader}
      <div className="consultation_details has_banner _priority_dm">
        <img src={banner2} alt="" className="banner_img" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>Digital Product</h3>
          </div>
          <div className="page_banner spacing">
            <div className="banner_content">
              <img src={serviceDetail?.image || imagePlaceholder} alt="" />
              <div className="content">
                <h5>{serviceDetail?.title || "---"} </h5>
                <p className="duration">
                  {/* No. Of Chapters <strong>{serviceDetail?.noOfChapters}</strong> */}
                </p>
                <p className="price">
                  {serviceDetail?.pricing?.showSlashedAmount && (
                    <del>
                      <LuIndianRupee />{" "}
                      {Number(serviceDetail?.pricing?.slashAmount) || "---"}
                    </del>
                  )}
                  <span>
                    <LuIndianRupee />{" "}
                    {
                      // serviceDetail?.pricing?.showSlashedAmount
                      //   ? Number(
                      //       Number(serviceDetail?.pricing?.slashAmount)
                      //        -
                      //         Number(serviceDetail?.pricing?.slashAmount)
                      //     )
                      //   :
                      Number(serviceDetail?.pricing?.amount)
                    }
                  </span>
                </p>
              </div>
            </div>
            <div className="button_wrap button_right">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <HiDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={"span"}>
                    <Link
                      to={`/dashboard/digital-contents/edit/digitalProduct/${serviceType}/${serviceId}/${digitalType}`}
                      // to={`/dashboard/digital-contents/edit/${serviceType}/${serviceId}/${digitalType}`}
                    >
                      <RiEdit2Line /> Edit Service
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={"span"}
                    onClick={() => setDeleteModal(true)}
                  >
                    <HiOutlineTrash />
                    Delete Service
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="page_content spacing">
            <div className="overView table_radius">
              <div className="_sub_title">
                <h4>Overview</h4>
              </div>
              <div className="content">
                {serviceDetail?.description ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: serviceDetail.description,
                    }}
                  ></p>
                ) : (
                  <p>---</p>
                )}
              </div>
            </div>
          </div>
          <div className="chapters_link spacing">
            <h5>Chapters List</h5>
            <ul className="download_document">
              {serviceDetail?.document?.uploadType === "video" && (
                <li>
                  {" "}
                  <b>{(serviceDetail?.document?.url)}</b>{" "}
                </li>
              )}

              {serviceDetail?.document?.uploadType === "file" && (
                <li>
                  <p>
                    <IoDocumentText />
                    {serviceDetail?.document?.url && (
                      <b>{(serviceDetail?.document?.url).slice(-20)}</b>
                    )}
                  </p>{" "}
                  <button
                    className="active"
                    type="button"
                    onClick={() =>
                      handleMediaDownload(serviceDetail?.document?.url)
                    }
                  >
                    <MdOutlineFileDownload />
                    Download
                  </button>
                </li>
              )}
            </ul>
          </div>
          {serviceDetail?.video && (
            <div className="introduction_url">
              <b>Introduction Video Link</b>
              <p>{serviceDetail?.video || "---"}</p>
            </div>
          )}

          {testimonial && testimonial.length > 0 && (
            <div className="table_radius">
              <div className="_sub_title">
                <h4>Testimonial</h4>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial && testimonial.length > 0 ? (
                    <TestimonialCard
                      bestTestimonials={testimonial}
                      setBestTestimonials={setTestimonial}
                      display={false}
                    />
                  ) : (
                    <Placeholder text="No Testimonials for this  service" />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Delete Component */}
          {deleteModal && (
            <DeleteServices
              loading={deleteLoader}
              deleteServiceHandler={deleteServiceHandler}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ViewDigitalCourse;
