import React, { useEffect, useState } from "react";
import "./Header.scss";
import { logoImg } from "assets/images";
import { Link, NavLink, matchPath, useLocation } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";
import CustomModal from "components/modals/CustomModal";
import AuthLogin from "auth/login/Login";

import SignUp from "auth/signUp/SignUp";
import Avatar from "./Avatar";
import ExpertAvatar from "./ExpertAvatar";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

const ROUTE_ARR = {
  "/explore-experts": ["expertList"],
  "/dashboard/expert/bookings/consultation": ["consultationList"],
};

const Header = () => {
  const { personalInfo } = useSelector((state) => state.config);
  const [navigateStatus, setNavigateStatus] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [authModal, setAuthModal] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    Object.entries(ROUTE_ARR).forEach(([key, val]) => {
      const match = matchPath(
        {
          path: `${key}/*`,
          exact: false,
          strict: false,
        },
        location.pathname
      );
      if (match) {
        try {
          let tempVar = {};
          let localData = JSON.parse(localStorage.getItem("fitzure_web"));
          val.forEach((item) => {
            tempVar = { ...tempVar, [item]: localData[item] };
          });
          localStorage.setItem("fitzure_web", JSON.stringify(tempVar));
        } catch (err) {
          // localStorage.removeItem("admin")
        }
      }
    });
  }, [location]);

  const fitzureInfo = JSON.parse(localStorage.getItem("fitzure_info"));

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  useEffect(() => {
    document.body.classList.toggle("mobile_open", mobileOpen);
  }, [mobileOpen]);

  return (
    <>
      <header className={`header ${scroll ? "scrolledHeader" : ""}`}>

        <div className="container">
          <div className="header_content">
            <div className="logo">
              <Link to="/">
                <img src={logoImg} alt="" />
              </Link>
            </div>
            <nav className="header_menu">
              {personalInfo?.currentType !== 0 && (
                <ul onClick={() => setMobileOpen(false)}>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/aboutus">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/explore-experts">Explore Experts</NavLink>
                  </li>
                  <li>
                    <NavLink to="/faq">FAQ</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contactus">Contact Us</NavLink>
                  </li>
                </ul>
              )}
            </nav>

            <div className="button_wrap">
              {personalInfo?.currentType == 1 ? (
                <Avatar
                  fitzureInfo={fitzureInfo}
                  setNavigateStatus={setNavigateStatus}
                  navigateStatus={navigateStatus}
                />
              ) : personalInfo?.currentType == 0 ? (
                <ExpertAvatar
                  setNavigateStatus={setNavigateStatus}
                  navigateStatus={navigateStatus}
                />
              ) : (
                <button className="button" onClick={() => setLoginModal(true)}>
                  Get Started
                </button>
              )}

              {/* Menu button */}
              <button
                className="menu_button"
                onClick={() => setMobileOpen(!mobileOpen)}
              >
                {mobileOpen ? <IoCloseSharp /> : <RiMenu3Fill />}
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="overlay" onClick={() => setMobileOpen(false)}></div>
      {/* Login Modal */}

      <CustomModal show={loginModal} handleClose={() => setLoginModal(false)}>
        {authModal ? (
          <AuthLogin
            moveSignUp={() => setAuthModal(false)}
            setLoginModal={setLoginModal}
            showSignUp={true}
          />
        ) : (
          <SignUp
            moveLogin={() => setAuthModal(true)}
            setLoginModal={setLoginModal}
            showSignUp={true}
          />
        )}
      </CustomModal>
    </>
  );
};

export default Header;
