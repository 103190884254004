import { regex } from './formValidator'

const { extractYoutubeLink, extractVimeoLink } = regex

export const extractVideoId = (url) => {
  let match = url.match(extractYoutubeLink)
  if (match) return { id: match[1], type: 'youtube' }

  match = url.match(extractVimeoLink)
  if (match) return { id: match[1], type: 'vimeo' }

  return null
}
