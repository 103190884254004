// import { banner1, profileImg, serviceImg1, banner2 } from "assets/images";
// import { Button } from "components/form";
// import React, { useEffect, useState } from "react";
// import { LuIndianRupee } from "react-icons/lu";
// import { RiEdit2Line } from "react-icons/ri";
// import { Link, useParams } from "react-router-dom";
// import { testimonial } from "staticData";
// import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
// import "../ManageServices.scss";
// import { getServicesDetailApi } from "services/expertServices/manage";
// import FullPageLoader from "components/fullPageLoader/FullPageLoader";
// import { toastMessage } from "utils/toastMessage";
// import { dateFormat, timeFormat_24 } from "utils/dateFormat";

// const PriorityDmsDetails = () => {
//   const [loader, onShow, onHide] = FullPageLoader();
//   const [serviceDetail, setServiceDetail] = useState({});
//   const { serviceType, serviceId } = useParams();
//   const [testimonial, setTestimonial] = useState([]);

//   const fetchingServiceDetailHandler = async () => {
//     try {
//       onShow();
//       const { data } = await getServicesDetailApi(serviceId);
//       if (data?.code === 200) {
//         setServiceDetail(data?.data);
//         setTestimonial(data?.data?.testimonials || []);
//       } else {
//         toastMessage(data?.message, "error", "ServiceDetail");
//       }
//     } catch (e) {
//       console.log(e.message || "something went wrong");
//     } finally {
//       onHide();
//     }
//   };

//   useEffect(() => {
//     fetchingServiceDetailHandler();
//   }, []);
//   return (
//     <>
//       <div className="consultation_details has_banner _priority_dm">
//         <img src={banner2} alt="" className="banner_img" />
//         <div className="dashboard_content_inner">
//           <div className="dashboard_title">
//             <h3>Priority DMs</h3>
//           </div>

//           <div className="page_banner spacing">
//             <div className="banner_content">
//               <img src={serviceDetail?.image || serviceImg1} alt="" />
//               <div className="content">
//                 <h5> {serviceDetail?.title}</h5>
//                 <p className="duration">
//                   Promised response{" "}
//                   <strong>{serviceDetail?.responseTime}</strong>
//                 </p>
//                 <p className="price">
//                   <del>
//                     <LuIndianRupee /> {serviceDetail?.pricing?.amount}
//                   </del>
//                   <span>
//                     <LuIndianRupee />
//                     {serviceDetail?.pricing?.showSlashedAmount
//                       ? Number(
//                           Number(serviceDetail?.pricing?.amount) -
//                             Number(serviceDetail?.pricing?.slashAmount)
//                         )
//                       : Number(serviceDetail?.amount)}
//                   </span>
//                 </p>
//               </div>
//             </div>
//             <div className="button_wrap button_right">
//               <Link
//                 to={`/dashboard/manage-services/prioritydms/detail/edit/${serviceType}/${serviceId}`}
//                 className={" button has_icon dashboard_button"}
//               >
//                 <RiEdit2Line /> Edit Service
//               </Link>
//             </div>
//           </div>
//           <div className="page_content spacing">
//             <div className="overView table_radius">
//               <div className="_sub_title">
//                 <h4>Overview</h4>
//               </div>
//               <div className="content">
//                 {serviceDetail?.description ? (
//                   <p
//                     dangerouslySetInnerHTML={{
//                       __html: serviceDetail.description,
//                     }}
//                   ></p>
//                 ) : (
//                   <p>---</p>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="introduction_url">
//             <b>Introduction Video Link</b>
//             <p>{serviceDetail?.video}</p>
//           </div>
//           <div className="table_radius">
//             <div className="_sub_title">
//               <h4>Testimonial</h4>
//             </div>
//             <div className="content">
//               <div className="card_wrap">
//                 {testimonial &&
//                   testimonial.map((item) => {
//                     return (
//                       <TestimonialCard
//                         // key={item.id}
//                         // title={item.title}
//                         // date={item.date}
//                         // description={item.description}
//                         bestTestimonials={testimonial}
//                         setBestTestimonials={setTestimonial}
//                         display={false}
//                       />
//                     );
//                   })}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PriorityDmsDetails;

import React, { useEffect, useState } from "react";
import { banner2, serviceImg1 } from "assets/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LuIndianRupee } from "react-icons/lu";
import { RiEdit2Line } from "react-icons/ri";
import TestimonialCard from "components/cards/testimonialCard/TestimonialCard";
import "../ManageServices.scss";
import {
  deleteServicesApi,
  getServicesDetailApi,
} from "services/expertServices/manage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { toastMessage } from "utils/toastMessage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Placeholder from "components/placeholder/Placeholder";
import { Dropdown } from "react-bootstrap";
import { HiOutlineTrash } from "react-icons/hi2";
import { HiDotsVertical } from "react-icons/hi";
import DeleteServices from "../DeleteServices";

const PriorityDmsDetails = () => {
  const [loader, onShow, onHide] = FullPageLoader();
  const [serviceDetail, setServiceDetail] = useState({});
  const { serviceType, serviceId } = useParams();
  const [testimonial, setTestimonial] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const navigate = useNavigate();

  const fetchingServiceDetailHandler = async () => {
    try {
      onShow();
      const { data } = await getServicesDetailApi(serviceId);
      if (data?.code === 200) {
        setServiceDetail(data?.data);
        setTestimonial(data?.data?.testimonials || []);
      } else {
        toastMessage(data?.message, "error", "ServiceDetail");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  // -----------Delete Service------------
  const deleteServiceHandler = async () => {
    try {
      setDeleteLoader(true);
      const { data } = await deleteServicesApi({ _id: serviceId });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "DeleteService");
        navigate(`/dashboard/manage-services?serviceType=${serviceType}`);
      } else {
        toastMessage(data?.message, "error", "DeleteService");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    fetchingServiceDetailHandler();
  }, []);

  return (
    <>
      <div className="consultation_details has_banner _priority_dm">
        <img src={banner2} alt="" className="banner_img" />
        <div className="dashboard_content_inner">
          <div className="dashboard_title">
            <h3>Priority DM</h3>
          </div>

          <div className="page_banner spacing">
            <div className="banner_content">
              {serviceDetail?.image ? (
                <img src={serviceDetail.image} alt="" />
              ) : (
                <Skeleton height={200} width={200} />
              )}
              <div className="content">
                <h5>{serviceDetail?.title || <Skeleton width={150} />}</h5>
                <p className="duration">
                  Promised response{" "}
                  <strong>
                    {serviceDetail?.responseTime || <Skeleton width={50} />}
                  </strong>
                </p>
                <p className="price">
                  {serviceDetail?.pricing?.showSlashedAmount && (
                    <del>
                      <LuIndianRupee />{" "}
                      {serviceDetail?.pricing?.slashAmount || (
                        <Skeleton width={50} />
                      )}
                    </del>
                  )}

                  <span>
                    <LuIndianRupee />
                    {
                    // serviceDetail?.pricing?.showSlashedAmount &&
                      // ? Number(
                      //     Number(serviceDetail?.pricing?.slashAmount)
                      //     //  -
                      //     //   Number(serviceDetail?.pricing?.slashAmount)
                      //   )
                      // : 
                      Number(serviceDetail?.pricing?.amount) || (
                          <Skeleton width={50} />
                        )
                        }
                  </span>
                </p>
              </div>
            </div>
            <div className="button_wrap button_right">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <HiDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={"span"}>
                    <Link
                      to={`/dashboard/manage-services/prioritydms/detail/edit/${serviceType}/${serviceId}`}
                    >
                      <RiEdit2Line /> Edit Service
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={"span"}
                    onClick={() => setDeleteModal(true)}
                  >
                    <HiOutlineTrash />
                    Delete Service
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="page_content spacing">
            <div className="overView table_radius">
              <div className="_sub_title">
                <h4>Overview</h4>
              </div>
              <div className="content">
                {serviceDetail?.description ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: serviceDetail.description,
                    }}
                  ></p>
                ) : (
                  <p>
                    <Skeleton count={3} />
                  </p>
                )}
              </div>
            </div>
          </div>
          {serviceDetail?.video && (
            <div className="introduction_url">
              <b>Introduction Video Link</b>
              <p>{serviceDetail?.video || "---"}</p>
            </div>
          )}

          {testimonial && testimonial.length > 0 && (
            <div className="table_radius">
              <div className="_sub_title">
                <h4>Testimonial</h4>
              </div>
              <div className="content">
                <div className="card_wrap">
                  {testimonial && testimonial.length > 0 ? (
                    testimonial.map((item, index) => {
                      return (
                        <TestimonialCard
                          key={index}
                          // title={item.title}
                          // date={item.date}
                          // description={item.description}
                          bestTestimonials={testimonial}
                          setBestTestimonials={setTestimonial}
                          display={false}
                        />
                      );
                    })
                  ) : (
                    <Placeholder
                      text={"No testimonials found for this service"}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Delete Component */}
          {deleteModal && (
            <DeleteServices
              loading={deleteLoader}
              deleteServiceHandler={deleteServiceHandler}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PriorityDmsDetails;
