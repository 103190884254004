import { FaFilePdf } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { TbFileTypeDocx } from "react-icons/tb";

export const regex = {
  email: /^(?=[a-zA-Z0-9@._%+-]{6,254}$)(?=[a-zA-Z0-9._%+-]{1,64}@)(?:(?!.*@.*@)[a-zA-Z0-9._%+-]+)@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
  url: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/,
  name: /^(?=.{3,})[a-zA-Z]+([ '-][a-zA-Z]+)*$/,
  imageFileRegex: /\.(jpg|jpeg|png|bmp|webp)$/i,
  ifscRegex: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  accountNumberRegex: /^\d{6,12}$/,
  documentFileRegex: /\.(pdf|doc|docx|txt)$/i,
  fileRegex:/\.(pdf|doc|docx|txt|jpg|jpeg|png|bmp|webp)$/i,
  pdfFileRegex: /\.(pdf)$/i,
  excelFileRegex: /\.(xlsx|xls)$/i,

  audioFileRegex: /\.(mp3|wav|flac|m4a|aac|ogg)$/i,
  videoFileRegex: /\.(mp4|avi|mov|mkv|wmv|flv|webm)$/i,
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/,
  youtubeLinkRegex: /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\w+\/|(?:v|e(?:mbed)?)\/|\w+\/)?|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  extractYoutubeLink: /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  extractVimeoLink: /vimeo\.com\/(?:.*?\/)?(?:video\/)?([0-9]+)/,
  panRegex: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
};

export function optimizeFunction(value) {
  return value.replace(/(^\s+)|([^A-Za-z\s])|(\s+)/g, " ").trimStart();
}

export function allowNumber(value) {
  return value.replace(/[^\d]/g, "").trimStart();
}

export const findSerialNumber = (index = 0, activePage = 1, limit = 10) => {
  return (activePage - 1) * limit + index + 1;
};

export const replaceLabelWithName = (obj) => {
  const { label, value, ...rest } = obj;
  return { name: label, id: value, ...rest };
};

export const replaceNameWithLabel = (obj) => {
  const { name, id, ...rest } = obj;
  return { label: name, value: id, ...rest };
};

export const replaceNameWithLabelSkill = (obj) => {
  const { name, _id, ...rest } = obj;
  return { label: name, value: _id, ...rest };
};

// Creating Random Number
export function createArrayUpTo(num) {
  return Array.from({ length: num + 1 }, (_, i) => i + 1);
}

// Creating Array From To
export function createArrayFromTo(start, end) {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

const previewDocIcon = (type) => {
  const docLogo = {
    pdf: <FaFilePdf />,
    doc: <IoDocumentText />,
    docx: <TbFileTypeDocx />,
  }[type] || <FaFilePdf />;

  return docLogo;
};

// Get File Extension
export const getFileExtension = (fileName) => {
  if (fileName) {
    return (
      <div className="file_preview">
        {previewDocIcon(fileName.split(".").at(-1)) || ""}
      </div>
    ); // Using .at(-1) to get the last element (file extension)
  } else return null;
};

// Get file name from last character
export const getFileNameSuffix = (fileName, length) => {
  // Get the last 'length' characters of the file name
  if (fileName) {
    return fileName.slice(-length);
  }
};

// End Date DropDown Function
// export function generateTimeIntervals(startTime, endTime, intervalHours) {
//   if (typeof intervalHours !== 'number' || intervalHours <= 0 || intervalHours > 24) {
//       return []
//   }

//   // Validate and parse start and end times
//   function parseTime(time) {
//       const [hours, minutes] = time.split(':').map(Number);
//       if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
//           return { hours:"", minutes:"" }
//       }
//       return { hours, minutes };
//   }

//   const start = parseTime(startTime);
//   const end = parseTime(endTime);

//   // Convert times to minutes since start of the day
//   function timeToMinutes({ hours, minutes }) {
//       return hours * 60 + minutes;
//   }

//   const startMinutes = timeToMinutes(start);
//   const endMinutes = timeToMinutes(end);

//   if (endMinutes <= startMinutes) {
//       return []
//   }

//   const intervalMinutes = intervalHours * 60;
//   const timePoints = [];

//   for (let current = startMinutes; current <= endMinutes; current += intervalMinutes) {
//       const hours = Math.floor(current / 60);
//       const minutes = current % 60;
//       const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
//       timePoints.push(timeString);
//   }

//   return timePoints;
// }

export function generateTimeIntervals(data) {
  const { type } = data;

  if (type === "startTime") {
    const { startTime, duration, endTime:endTimeData } = data;

    const prevStartTime=endTimeData?.endTime
    const intervalHours = Number(duration) / 60;
    if (
      typeof intervalHours !== "number" ||
      intervalHours <= 0 ||
      intervalHours > 24
    ) {
      return [];
    }

    // Validate and parse start and end times
    function parseTime(time) {
      const [hours, minutes] = time.split(":").map(Number);
      if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
        return { hours: "", minutes: "" };
      }
      return { hours, minutes };
    }

    const start = parseTime(prevStartTime ? convertTo24Hour(prevStartTime) : "00:00");
    const end = parseTime("23:59");

    // Convert times to minutes since start of the day
    function timeToMinutes({ hours, minutes }) {
      return hours * 60 + minutes;
    }

    const startMinutes = timeToMinutes(start);
    const endMinutes = timeToMinutes(end);

    if (endMinutes <= startMinutes) {
      return [];
    }

    const intervalMinutes = intervalHours * 60;
    const timePoints = [];

    function convertTo12HourFormat(hours, minutes) {
      const suffix = hours >= 12 ? "PM" : "AM";
      const adjustedHours = hours % 12 || 12; // Convert 0 -> 12 for 12-hour format
      return `${String(adjustedHours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")} ${suffix}`;
    }

    for (
      let current = startMinutes;
      current <= endMinutes;
      current += intervalMinutes
    ) {
      const hours = Math.floor(current / 60);
      const minutes = current % 60;
      const timeString = convertTo12HourFormat(hours, minutes);
      timePoints.push(timeString);
    }

    return timePoints;
  }

  if (type === "endTime") {
    const { startTime, duration } = data;
    const intervalHours = Number(duration) / 60;



    if (
      typeof intervalHours !== "number" ||
      intervalHours <= 0 ||
      intervalHours > 24
    ) {
      return [];
    }

    // Validate and parse start and end times
    function parseTime(time) {
      const [hours, minutes] = time.split(":").map(Number);
      if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
        return { hours: "", minutes: "" };
      }
      return { hours, minutes };
    }

    const start = parseTime(startTime?convertTo24Hour(startTime)  : "00:00");
    const end = parseTime('23:59');

    // Convert times to minutes since start of the day
    function timeToMinutes({ hours, minutes }) {
      return (hours * 60 + minutes);
    }

    const startMinutes = timeToMinutes(start);
    const endMinutes = timeToMinutes(end);

    if (endMinutes <= startMinutes) {
      return [];
    }

    const intervalMinutes = intervalHours * 60;
    const timePoints = [];

    function convertTo12HourFormat(hours, minutes) {
      const suffix = hours >= 12 ? "PM" : "AM";
      const adjustedHours = hours % 12 || 12; // Convert 0 -> 12 for 12-hour format
      return `${String(adjustedHours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")} ${suffix}`;
    }



    for (
      let current = (startMinutes+intervalMinutes);
      current <= endMinutes;
      current += intervalMinutes
    ) {
      const hours = Math.floor(current / 60);
      const minutes = current % 60;
      const timeString = convertTo12HourFormat(hours, minutes);
      timePoints.push(timeString);
    }

    return timePoints;
  }
}


export function convertTo24Hour(time12h) {
  const [time, modifier] = time12h?.split(' ');  // Split the time and AM/PM

  let [hours, minutes] = time.split(':');       // Split hours and minutes

  
  // Convert to 24-hour format
  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12;
  } else if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  return `${hours}:${minutes}`;
}