import React, { useEffect, useState } from "react";
import WebinarsListCompleted from "./WebinarsListCompleted";
import WebinarsListCancelled from "./WebinarsListCancelled";
import WebinarsListUpcoming from "./WebinarsListUpcoming";
import { useSearchParams } from "react-router-dom";
import { toastMessage } from "utils/toastMessage";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import { getWebinarListApi } from "services/userServices/servicesListing";

const WebinarsList = ({ serviceType }) => {
  // console.log(serviceType,"ServiceType");
  const [loader, onShow, onHide] = FullPageLoader();
  const [bookingList, setBookingList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  let page = +searchParam.get("page") || 1;
  let type = searchParam.get("type") || serviceType;
  let bookingStatus = searchParam.get("bookingStatus") || "upcoming";

  const booking = [
    {
      name: "Upcoming",
      value: "upcoming",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Cancelled",
      value: "cancelled",
    },
  ];

  const bookingStatusHandler = (service) => {
    searchParam.set("bookingStatus", service);
    setSearchParam(searchParam);
  };

  const fetchingBookingListHandler = async () => {
    try {
      onShow();
      const sendData = {};
      sendData.bookingStatus = bookingStatus;
      sendData.pageSize = 10;
      sendData.page = page - 1;
      const { data } = await getWebinarListApi(sendData);
      if (data?.code === 200) {
        setBookingList(data?.data);
      } else {
        toastMessage(data?.message, "error", "BookingServicesList");
      }
    } catch (e) {
      console.log(e.message || "something went wrong");
    } finally {
      onHide();
    }
  };

  useEffect(() => {
    // if (page === 0) {
    //   searchParam.delete("page");
    //   setSearchParam(searchParam);
    // }
    fetchingBookingListHandler();
  }, [searchParam]);

  // console.log(booking, "Booking");

  return (
    <div className="card_section webinar">
      <div className="sideBar">
        <ul>
          {booking &&
            booking.length &&
            booking.map((data) => (
              <li
                key={data?.value}
                className={`${bookingStatus === data?.value ? "active" : ""}`}
                onClick={() => bookingStatusHandler(data?.value)}
              >
                {data?.name}
              </li>
            ))}
        </ul>
      </div>
      {
        // bookingStatus === "upcoming" &&
        <WebinarsListUpcoming
          bookingStatus={bookingStatus}
          setBookingList={setBookingList}
          bookingList={bookingList}
          fetchingBookingListHandler={fetchingBookingListHandler}
        />
      }
      {/* {bookingStatus === "completed" && <WebinarsListCompleted />} */}
      {/* {bookingStatus === "cancelled" && <WebinarsListCancelled />} */}
    </div>
  );
};

export default WebinarsList;
