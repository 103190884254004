import { logOutIcon } from "assets/icons";
import { sidebar } from "common/Sidenav";
import CustomModal from "components/modals/CustomModal";
import LogoutModal from "components/modals/LogoutModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { setPersonalInfo } from "store/slices/configSlice";

const ProfileNav = ({onClick}) => {
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const dispatch=useDispatch();

  // const steps = useSelector(
  //   (store) => store.steps.stepsInfo
  // );

  // Getting User Type
  const { userType, steps } = useSelector((state) => state.config);


  // console.log(userType, "userType", steps, "Steps");

  // let fitzureInfo = (localStorage.getItem("expertProfileStatus"));
  // let moduleSteps = fitzureInfo ? Number(fitzureInfo) : 0;

  // useEffect(()=>{
  //   let fitzureInfo = (localStorage.getItem("expertProfileStatus"));
  //   let moduleSteps = fitzureInfo ? Number(fitzureInfo) : 0;
  //    const nextStep = sidebar({userType}).find(({ id }) => id === moduleSteps);
  //    navigate(nextStep?.path ?? "/profile-setup/personal-info");
  // },[steps])

  const logoutHandler = () => {
    localStorage.removeItem("fitzure_info");
    localStorage.removeItem("expertProfileStatus");
    dispatch(setPersonalInfo({ personalInfo: "" }));
    navigate("/");
  };
  return (
    <>
      <nav className="side_nav">
        <ul onClick={onClick}>
          {sidebar({ userType, steps }) &&
          sidebar({ userType, steps }).length > 0
            ? sidebar({ userType, steps }).map((data) => {
                return (
                  <li key={data.id}>
                    {data.id > steps ? (
                      <div className="nav-link disabled">
                        <img src={data.icon} alt={data.name} />
                        <span>{data.name}</span>
                      </div>
                    ) : (
                      <NavLink to={data.path} className="nav-link">
                        <img src={data.icon} alt={data.name} />
                        <span>{data.name}</span>
                      </NavLink>
                    )}
                  </li>
                );
              })
            : null}
        </ul>
      </nav>
      <nav className="side_nav">
        <ul>
          <li>
            <button
              type="button"
              className="nav-link _logout"
              onClick={() => {
                setLogoutModal(true);
              }}
            >
              <img src={logOutIcon} alt="" />
              <span className="text-center">Logout</span>
            </button>
          </li>
        </ul>
      </nav>
      <LogoutModal
        show={logoutModal}
        handleClose={()=>setLogoutModal(false)}
        action={logoutHandler}
      />
    </>
  );
};

export default ProfileNav;
