import { expertPlaceholderImg, profileImg, service1 } from "assets/images";
import { Button } from "components/form";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import ConsultationList from "pages/bookings/consultation/ConsultationList";
import React, { useState } from "react";
import { cancelOneToOne } from "services/oneToOne";
import { startWebinarApi } from "services/userServices/startServices";
import {
  compareDates,
  dateFormat,
  dateFormatWithTime,
  timeFormat,
} from "utils/dateFormat";
import { charLimit } from "utils/mediaDataSender";
import { toastMessage } from "utils/toastMessage";

const WebinarListing = ({
  webinarList,
  fetchingWebinarList,
  bookingStatus,
}) => {
  const [webinarModal, setWebinarModal] = useState(false);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startConsultantLoading, setStartConsultantLoading] = useState(false);

  // Start Consultation Handler
  const startWebinarHandler = async (_id) => {
    if (_id) {
      try {
        const { data } = await startWebinarApi({
          _id: _id,
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "startWebinar");
          fetchingWebinarList();
        } else {
          toastMessage(data?.message, "error", "startWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setStartConsultantLoading(false);
      }
    }
  };

  // Cancel oneToOne Handler
  const cancelWebinarHandler = async () => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await cancelOneToOne({
          _id: id,
          serviceType: "webinar",
        });
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "Webinar");
          fetchingWebinarList();
          setId("");
          setWebinarModal(false);
        } else {
          toastMessage(data?.message, "error", "Webinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="webinar">
        {webinarList && webinarList.length ? (
          webinarList.map((data) => {
            return (
              <>
                <div className="item">
                  <div className="_card">
                      {data?.service?.image && (
                    <div className="image_wrap">
                        <img src={data?.service?.image || ""} alt="" />

                      <span className="tag">{data?.service?.duration} min</span>
                    </div>
                      )}
                    <div className="content">
                      {/* <p className="joining_members">
                        {" "}
                        <div className="_avatar">
                          <img src={profileImg} alt="" />
                          <img src={profileImg} alt="" />
                          <img src={profileImg} alt="" />
                          <img src={profileImg} alt="" />
                        </div>{" "}
                        {data?.totalBooking} People join this webinars
                      </p> */}
                      <p className="joining_members">
                        <div>{`${data?.user?.firstName && (data?.user?.firstName || "---" )}${" "}${data?.user?.lastName && (data?.user?.lastName || "---" )} `}</div>
                      </p>
                      <p className="timing">
                        Start Date & Time :{" "}
                        {dateFormatWithTime(
                          data?.service?.webinarAvailability
                        ) || "---"}
                      </p>
                      <h4>{data?.service?.title || "---"} </h4>
                      {/* <p>{data?.service?.description}</p> */}
                      {data?.service?.description.length > 50 ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: charLimit(data?.service?.description, 50),
                          }}
                        ></p>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data?.service?.description,
                          }}
                        ></p>
                      )}

                      <div className="button_wrap">
                        {bookingStatus === "upcoming" ? (
                          compareDates(
                            data?.service?.webinarAvailability,
                            data?.service?.duration,
                            null
                          ) === "Start Consultation" ? (
                            <Button
                              className={"w-100"}
                              onClick={() => {
                                startWebinarHandler(data?._id);

                                // Open the expert meeting link if it exists
                                if (data?.expertMeetingLink) {
                                  window.open(data.expertMeetingLink, "_blank");
                                }
                              }}
                            >
                              Start Webinar
                            </Button>
                          ) : compareDates(
                              data?.service?.webinarAvailability,
                              null,
                              1
                            ) === "Cancel Consultation" ? (
                            <Button
                              className={"w-100 light red"}
                              onClick={() => {
                                setWebinarModal(true);
                                setId(data?._id);
                              }}
                            >
                              Cancel Webinar
                            </Button>
                          ) : (
                            <Button isDisabled={true} className={"w-100"}>
                              Webinar Start Soon
                            </Button>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <Placeholder
            text={"Webinars not available"}
            image={expertPlaceholderImg}
          />
        )}
      </div>

      {/* Cancel One TO One Modal */}
      <CustomModal
        show={webinarModal}
        handleClose={() => {
          setWebinarModal(false);
        }}
        className={"logoutModal"}
      >
        <h3>Cancel Service</h3>
        <p>Are you sure you want to cancel this service?</p>

        <div className="button_wrap button_center">
          <Button
            className={"light"}
            onClick={() => {
              setWebinarModal(false);
            }}
          >
            No
          </Button>
          <Button onClick={cancelWebinarHandler}>Yes</Button>
        </div>
      </CustomModal>
    </>
  );
};

export default WebinarListing;
