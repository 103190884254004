import CommonPagination from "common/CommonPagination";
import { LoadingButton } from "components/form";
import CustomModal from "components/modals/CustomModal";
import Placeholder from "components/placeholder/Placeholder";
import React, { Fragment } from "react";
import Pagination from "react-js-pagination";
import { dateFormat } from "utils/dateFormat";

const GetTestimonials = ({
  showTestimonialModal,
  setShowTestimonilModal,
  testimonial,
  setTestimonial,
  bestTestimonials,
  setBestTestimonials,
  total,
  activePage,
  handlePageChange,
}) => {
  const bestTestimonialsHandler = (data) => {
    const objectIndex = bestTestimonials.findIndex(
      (testimonial) => testimonial?._id === data?._id
    );
    const tempTestimonial = [...bestTestimonials];

    if (objectIndex !== -1) {
      tempTestimonial.splice(objectIndex, 1);
    } else {
      tempTestimonial.push(data);
    }

    setBestTestimonials(tempTestimonial);
  };

  return (
    <CustomModal
      className={"xl select_testimonials"}
      show={showTestimonialModal}
      handleClose={() => setShowTestimonilModal(false)}
    >
      <h3>Display Testimonials for this service</h3>
      <p>
        Select the best testimonials that you want to display for this service
      </p>

      <div className="card_wrap">
        {testimonial && testimonial.length>0 ?
          testimonial.map((item) => {
            return (
              <Fragment key={item?.id}>
                <div className="inner_testimonial_card" key={item._id}>
                  <label className="item" htmlFor={item._id}>
                    <input
                      type="checkbox"
                      className="check"
                      checked={bestTestimonials.find(
                        (test) => test._id === item._id
                      )}
                      onChange={() => bestTestimonialsHandler(item)}
                      id={item._id}
                    />
                    <div className="head">
                      <h5>{`${item?.user?.firstName || "---"}${" "}${
                        item?.user?.lastName || "---"
                      }`}</h5>
                      <p>{dateFormat(item?.createdAt) || "---"}</p>
                    </div>
                    <p>{item?.message}</p>
                  </label>
                </div>
              </Fragment>
            );
          }):<Placeholder text={"No testimonial available to select "}/>}
      </div>

      <CommonPagination
        handlePageChange={handlePageChange}
        activePage={activePage}
        total={total}
      />

      <div className="button_wrap button_right mt-3">
        <LoadingButton onClick={() => setShowTestimonilModal(false)}>
          Select Testimonials
        </LoadingButton>
      </div>
    </CustomModal>
  );
};

export default GetTestimonials;
