import { profileImg } from "assets/images";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { HiOutlineBell } from "react-icons/hi2";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  PriorityDMsIcon,
  PrivacyPolicyIcon,
  bookingsIcon,
  contentIcon,
  faqIcon,
  logOutIcon,
  packageIcon,
  profilePlaceholder,
  profileSIcon,
  termsIcon,
  transactionIcon,
} from "assets/icons";
import { switchAccountApi } from "services/auth";
import { toastMessage } from "utils/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setPersonalInfo, setSteps } from "store/slices/configSlice";
import CustomModal from "components/modals/CustomModal";
import { Button } from "components/form";
import { sidebar } from "common/Sidenav";
import LogoutModal from "components/modals/LogoutModal";
import { authRouteHandler } from "utils/common";
import {
  getNotificationApi,
  markAllAsReadApi,
  markAsReadApi,
  newNotificationAlert,
} from "services/notification";
import { dateFormatWithTime } from "utils/dateFormat";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";
import Placeholder from "components/placeholder/Placeholder";
import {
  getNotificationUserApi,
  markAllAsReadUserApi,
  markAsReadUserApi,
  newNotificationUserAlert,
} from "services/userServices/notification";

const Avatar = ({ fitzureInfo }) => {
  const { personalInfo } = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const [loader, onShow, onHide] = FullPageLoader();
  const [pathNavigate, setPathNavigate] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState(null);

  const switchAccountHandler = async () => {
    try {
      const { data } = await switchAccountApi({ currentType: 0 });
      if (data?.code === 200) {
        toastMessage(data?.message, "success", "switchAccount");
        const currentTypeData = { ...personalInfo, currentType: 0 };
        dispatch(setPersonalInfo({ personalInfo: currentTypeData }));
        authRouteHandler({
          currentType: currentTypeData?.currentType,
          navigate,
          personalInfo: currentTypeData,
        });
        dispatch(
          setConfig({
            userType: data?.data?.accountType,
          })
        );
        dispatch(
          setSteps({
            steps: fitzureInfo.moduleSteps,
          })
        );
        localStorage.setItem("fitzure_info", JSON.stringify(currentTypeData));
        // navigate({
        //   pathname: "/navigation",
        //   search: `?${new URLSearchParams({
        //     currentType: "0",
        //   }).toString()}`,
        // });

        // const navigateTo = getNavigationPath(
        //   Number(data?.data?.accountType),
        //   Number(fitzureInfo.moduleSteps)
        // );
        // navigate(navigateTo);

        // const nextStep = sidebar({
        //   userType: data?.data?.accountType,
        //   steps: fitzureInfo.moduleSteps,
        // }).find(({ id }) => id === fitzureInfo.moduleSteps);
      } else {
        toastMessage(data?.message, "error", "switchAccount");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Get Notification Handler
  const getNotificationHandler = async () => {
    try {
      onShow();
      const { data } = await getNotificationUserApi();
      if (data?.code === 200) {
        setNotificationList(data?.data);
      } else {
        toastMessage(data?.message, "error", "notificationList");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

  // Mark All As Read Handler
  const getMarkAsAllReadHandler = async (e) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const { data } = await markAllAsReadUserApi();
      if (data?.code === 200) {
        getNotificationHandler();
        newNotificationAlertHandler();
      } else {
        toastMessage(data?.message, "error", "getMarkAsAllReadHandler");
        getNotificationHandler();
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // Mark All As Read Handler
  const markAsReadHandler = async (e, _id) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const { data } = await markAsReadUserApi({ _id: _id });
      if (data?.code === 200) {
        setNotificationList((prev) =>
          prev.map((data) => {
            if (data?._id === _id) {
              return {
                ...data,
                isRead: !data?.isRead,
              };
            } else {
              return data;
            }
          })
        );
        setNotificationNumber((prev) => prev - 1);
      } else {
        toastMessage(data?.message, "error", "markAsReadHandler");
        getNotificationHandler();
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  // New Notification Alert
  const newNotificationAlertHandler = async () => {
    try {
      const { data } = await newNotificationUserAlert();
      if (data?.code === 200) {
        setNotificationNumber(data?.data);
      } else {
        toastMessage(data?.message, "error", "newNotificationAlertHandler");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getNotificationHandler();
    newNotificationAlertHandler();
  }, [location.pathname]);

  const userLogoutHandler = () => {
    // console.log("I m working");
    localStorage.removeItem("fitzure_info");
    dispatch(
      setPersonalInfo({
        personalInfo: "",
      })
    );
    navigate("/");
    setLogoutModal(false);
  };

  return (
    <>
      <div className="avatar_content">
        <div className="notification_dropdown">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <HiOutlineBell className="icon" />
              {notificationNumber > 0 ? <span className="blinker"></span> : ""}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <h5>
                Notifications
                <button onClick={getMarkAsAllReadHandler}>
                  {notificationList &&
                    notificationList.length > 0 &&
                    notificationList.filter((data) => !data?.isRead).length >
                      0 &&
                    "Mark all as read"}
                </button>
              </h5>
              <ul>
                {notificationList && notificationList.length > 0 ? (
                  notificationList.map((data) => (
                    <li
                      key={data?._id}
                      className={`${data?.isRead ? "" : "read"}`}
                      onClick={(e) => {
                        markAsReadHandler(e, data?._id);
                        e.stopPropagation();
                      }}
                    >
                      <b>{data?.title || "---"}</b>
                      <p>{data?.message || "----"}</p>
                      <span className="time">
                        {dateFormatWithTime(data?.createdAt)}
                      </span>
                      {data?.isRead ? (
                        ""
                      ) : (
                        <span className="time">Mark As Read</span>
                      )}
                    </li>
                  ))
                ) : (
                  <Placeholder text={"No Notification Available"} />
                )}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="profile_dropdown">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <img src={fitzureInfo?.image || profilePlaceholder} alt="name" />{" "}
              <span>{`${fitzureInfo?.firstName || ""} ${
                fitzureInfo?.lastName || ""
              } `}</span>{" "}
              <IoIosArrowDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={"span"}>
                <div className="image_wrap">
                  <img
                    src={fitzureInfo?.image || profilePlaceholder}
                    alt="name"
                  />
                  <div className="content">
                    <span>{`${fitzureInfo?.firstName || ""} ${
                      fitzureInfo?.lastName || ""
                    } `}</span>
                    <small title={fitzureInfo?.email}>
                      {fitzureInfo?.email.slice(0, 20) || ""}
                    </small>

                    <Link to="/user-detail-edit" className="button">
                      Edit Profile
                    </Link>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={"span"}>
                <Link to="#" onClick={switchAccountHandler}>
                  <img src={profileSIcon} alt="" className="item" />
                  Switch to Expert Account
                </Link>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <NavLink to="/priorityDM">
                  <img src={PriorityDMsIcon} alt="" className="item" />
                  Priority DM
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <NavLink to="/my-bookings">
                  <img src={bookingsIcon} alt="" className="item" />
                  My Bookings
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <NavLink to="digital-content-list">
                  <img src={contentIcon} alt="" className="item" />
                  Digital Contents
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <NavLink to="/packages-list">
                  <img src={packageIcon} alt="" className="item" />
                  Package
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item as={"span"}>
                <NavLink to="/transaction-history">
                  <img src={transactionIcon} alt="" className="item" />
                  Transaction History
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as={"span"}>
                <Link
                  to="#"
                  onClick={(e) => {
                    setLogoutModal(true);
                    e.preventDefault();
                  }}
                  className="_logout"
                >
                  <img src={logOutIcon} alt="" className="item" />
                  Logout
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* <CustomModal
        show={logoutModal}
        handleClose={() => {
          setLogoutModal(false);
        }}
      >
        <h3>Logout</h3>
        <div className="form_field">
          <p>Are you sure you want to logout?</p>
        </div>
        <div className="button_wrap button_center">
          <Button onClick={userLogoutHandler}>Logout</Button>
        </div>
      </CustomModal> */}

      <LogoutModal
        show={logoutModal}
        handleClose={() => setLogoutModal(false)}
        action={userLogoutHandler}
      />
    </>
  );
};

export default Avatar;
