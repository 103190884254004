import AccountDetails from "expertDashboard/expertProfileSetup/AccountDetail";
import {
  bankIcon,
  classIcon,
  professionalIcon,
  userIcon,
} from "../assets/icons";
import PersonaDetail from "expertDashboard/expertProfileSetup/PersonalDetail";
// import UpdatedPersonalDetail from "expertDashboard/expertProfileSetup/UpdatedPersonalDetail";
import AdditionalDetails from "expertDashboard/expertProfileSetup/AdditionalDetail";
import ExpertPersonalDetails from "expertDashboard/expertProfileSetup/PersonalInfo";

const accountType = Number(
  JSON.parse(localStorage.getItem("fitzure_info"))?.accountType
);
// console.log(typeof accountType,"accountTYpe");

export const sidebar = ({ userType = null, steps = 0 }) => {
  const allSidebar = [
    {
      id: 0,
      enabled:
        (userType === 1 && steps !== 1) ||
        (userType === 0 && steps !== 4) ||
        (userType === 2 && steps !== 4),

      name: "Personal Information",
      path: "/profile-setup/personal-info",
      icon: userIcon,
      isChild: null,
    },
    {
      id: 1,
      enabled: userType !== 1,

      // enabled: true,
      name: "Professional details",
      path: "/profile-setup/personal-details",
      icon: professionalIcon,
      isChild: null,
    },
    {
      id: 2,
      // enabled: true,
      enabled: userType !== 1,
      name: "Bank Account Details",
      path: "/profile-setup/account-details",
      icon: bankIcon,
      isChild: null,
    },
    {
      id: 3,
      // enabled: true,
      enabled: userType !== 1,
      name: "Additional Details",
      path: "/profile-setup/additional-details",
      icon: classIcon,
      isChild: null,
    },
  ].filter((data) => data.enabled);
  return allSidebar;
};

export const routes = ({ userType = null, steps = Number(0) }) => {
  const allRoutes = [
    {
      id: 0,
      // enabled: true,
      enabled:
        (userType === 1 && steps !== 1) ||
        (userType === 0 && steps !== 4) ||
        (userType === 2 && steps !== 4),
      element: <ExpertPersonalDetails />,
      path: "/profile-setup/personal-info",
      active: true,
    },
    {
      id: 1,
      // enabled: true,
      enabled: userType !== 1 && (steps>0 && steps !== 4),
      element: <PersonaDetail />,
      // element:<UpdatedPersonalDetail/>,
      path: "/profile-setup/personal-details",
      active: true,
    },
    {
      id: 2,
      // enabled: true,
      enabled: userType !== 1 && (steps>1 && steps !== 4),
      element: <AccountDetails />,
      path: "/profile-setup/account-details",
      active: true,
    },
    {
      id: 3,
      // enabled: true,
      enabled: userType !== 1 && (steps>2 && steps !== 4),
      element: <AdditionalDetails />,
      path: "/profile-setup/additional-details",
      active: true,
    },
  ].filter((data) => data.enabled);
  return allRoutes;
};
