import React from "react";
import ExpertAvatar from "components/header/ExpertAvatar";
import { FaBars } from "react-icons/fa";

const DashboardHeader = ({onClick}) => {
  return (
    <>
      <button type="button" className="toggle_button" onClick={onClick}>
        <FaBars />
      </button>
      <ExpertAvatar />
    </>
  );
};

export default DashboardHeader;
