import {
  expertPlaceholderImg2,
  imagePlaceholder,
  packagesNot,
  profileImg,
  serviceImg1,
} from "assets/images";
import { Button } from "components/form";
import React, { useEffect, useState } from "react";
import "./PackagesList.scss";
import RatingModal from "components/modals/RatingModal";
import { Link } from "react-router-dom";
import { getUserPackageList } from "services/packages";
import { toastMessage } from "utils/toastMessage";
import { rateWebinarApi } from "services/userServices/startServices";
import Placeholder from "components/placeholder/Placeholder";

const PackagesList = () => {
  const [ratingModal, setRatingModal] = useState(false);
  const [packageList, setPackageList] = useState([]);
  const [packageActivePage, setPackageActivePage] = useState(1);
  const [totalPackage, setTotalPackage] = useState("");
  const [ratingField, setRatingField] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // fetch user package list
  const fetchingPackageServicesList = async () => {
    try {
      setPackageList([]);
      const sendData = {
        page: packageActivePage - 1,
        pageSize: 10,
      };
      const { data } = await getUserPackageList(sendData);
      if (data?.code === 200) {
        // setPackageList(data?.data.map((item) => ({ ...item, provided: 1 })))
        setPackageList(data.data);
        setTotalPackage(data?.totalItems);
      } else {
        setPackageList([]);
        toastMessage(data?.message, "error", "setPackageList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // validation handler

  const validation = () => {
    let status = true;
    if (!ratingField?.rating) {
      setError("Please provide rating for this service");
      status = false;
    }
    return status;
  };

  // Rating handler
  const rateServiceHandler = (_id) => {
    setRatingField({ ...ratingField, _id: _id, serviceType: "package" });
    setRatingModal(true);
  };

  // OnChange Handler

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setRatingField({ ...ratingField, [name]: value });
  };

  // Submit Review Handler

  const submitReviewHandler = async () => {
    if (validation()) {
      try {
        setLoading(true);
        const { data } = await rateWebinarApi(ratingField);
        if (data?.code === 200) {
          toastMessage(data?.message, "success", "rateWebinar");
          setRatingField({});
          setRatingModal(false);
          fetchingPackageServicesList();
        } else {
          toastMessage(data?.message, "error", "rateWebinar");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchingPackageServicesList();
  }, []);

  return (
    <>
      <div className="exploreExperts packagesList">
        <div className="container">
          <div className="page_title text-center">
            <span>Package</span>
            <h3>Package List</h3>
            <p>
              Lorem Ipsum&nbsp;is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's
            </p>
          </div>
          <div className="packages_listing_wrap">
            {packageList && packageList.length > 0 ? (
              packageList.map((currentPackage) => {
                return (
                  <div className="item">
                    <div className="package_card">
                      <Link
                        to={`/packages-list/detail/${currentPackage?.expert?._id}/${currentPackage?.service._id}`}
                      ></Link>
                      <div className="image_wrap">
                        <img
                          src={
                            currentPackage?.service?.image || imagePlaceholder
                          }
                          alt=""
                        />
                        <span className="tag">
                          {currentPackage?.packageBookingServices.length} Services
                        </span>
                      </div>
                      <div className="content">
                        <div className="author">
                          <img src={profileImg} alt="" />
                          <span>
                            {`Created by ${`${
                              currentPackage?.expert?.firstName || ""
                            } ${currentPackage?.expert?.lastName || ""}`}`}
                          </span>
                        </div>

                        <h3>{currentPackage?.service?.title}</h3>
                        <p>
                          {currentPackage?.service?.description ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: currentPackage?.service?.description,
                              }}
                            ></p>
                          ) : (
                            <p>---</p>
                          )}
                        </p>
                        <div className="price">
                          {/* {expertServicesDetail?.pricing?.showSlashedAmount ? (
                            <>
                              <del>
                                ₹{' '}
                                {Number(expertServicesDetail?.pricing?.amount)}
                              </del>
                              <b>
                                ₹{' '}
                                {Number(expertServicesDetail?.pricing?.amount) -
                                  Number(
                                    expertServicesDetail?.pricing?.slashAmount
                                  )}
                              </b>
                            </>
                          ) : (
                            <>
                              ₹ {Number(expertServicesDetail?.pricing?.amount)}
                            </>
                          )} */}
                        </div>
                        <div className="button_wrap button_center">
                          <Button
                            isDisabled={currentPackage?.isRated}
                            className={"light w-100"}
                            onClick={() =>
                              rateServiceHandler(currentPackage?._id)
                            }
                          >
                            Rate Package
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Placeholder
                image={expertPlaceholderImg2}
                text={"Package are not available"}
              />
            )}

            {/* <div className="item">
              <div className="package_card">
                <Link to="/packages-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button
                      className={'light w-100'}
                      onClick={() => setRatingModal(true)}
                    >
                      Rate Package
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="package_card">
                <Link to="/packages-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button className={'light w-100'}>Rate Package</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="package_card">
                <Link to="/packages-list/detail"></Link>
                <div className="image_wrap">
                  <img src={serviceImg1} alt="" />
                  <span className="tag">2 Services</span>
                </div>
                <div className="content">
                  <div className="author">
                    <img src={profileImg} alt="" />
                    <span>Created by Nikhil</span>
                  </div>

                  <h3>Pulled Hamstring causes, Treatment and recovery </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the.
                  </p>
                  <div className="price">
                    <del>₹600</del>
                    <b>₹500</b>
                  </div>
                  <div className="button_wrap button_center">
                    <Button className={'light w-100'}>Rate Package</Button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {ratingModal && (
        <RatingModal
          show={ratingModal}
          handleClose={() => {
            setRatingModal(false);
            setRatingField({});
            setError("");
          }}
          ratingField={ratingField}
          setRatingField={setRatingField}
          onChangeHandler={onChangeHandler}
          loading={loading}
          action={submitReviewHandler}
          error={error}
        />
      )}
    </>
  );
};

export default PackagesList;
