import React, { memo, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import DateFilter from "../priorityDMS/DateFilter";
import { Pie } from "react-chartjs-2";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { dashboardFilter, dateFormat } from "utils/dateFormat";
import { Button } from "components/form";
import ReactDatePicker from "react-datepicker";
import Placeholder from "components/placeholder/Placeholder";
import { pieChartDataApi } from "services/dashboard";
import { toastMessage } from "utils/toastMessage";
import { useSearchParams } from "react-router-dom";
import FullPageLoader from "components/fullPageLoader/FullPageLoader";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const EarningPieChart = ({
}) => {
const [pieChartData,setPieChartData]=useState([])
const [searchParam,setSearchParam]=useSearchParams();
let fromDate = searchParam.get("fromDate") || "";
let toDate = searchParam.get("toDate") || "";
const [filterDashboardDate, setFilterDashboardDate] = useState({
  fromDate: "",
  toDate: "",
});
const [selectedFilter, setSelectedFilter] = useState("Dropdown end");
const [loader, onShow, onHide] = FullPageLoader();

const servicesData = [
  { name: "Trainings", value: "subscription" },
  { name: "Package", value: "package" },
  { name: "1:1 Consultations", value: "oneToOne" },
  { name: "Priority DM", value: "prioritydm" },
  { name: "Webinars", value: "webinar" },
  { name: "Digital Contents", value: "digital" },
];



  // Getting data for pie chart
  const fetchingPieChartData = async () => {
    try {
      let sendData = {};
      if (fromDate && toDate) {
        sendData = Object.assign(sendData, {
          fromDate: dateFormat(fromDate),
          toDate: dateFormat(toDate),
        });
      }

      onShow();
      const { data } = await pieChartDataApi(sendData);
      if (data?.code === 200) {
        setPieChartData(
          data?.data && data.data.length > 0
            ? data.data.map((d) => {
                // Find matching service by name
                const matchingService = servicesData.find(
                  (service) => service?.value === d?._id
                );

                // If matching service is found, add the name to the object d
                if (matchingService) {
                  return {
                    ...d,
                    name: matchingService.name,
                  };
                }

                // If no match is found, return the original object d
                return d;
              })
            : []
        );
      } else {
        toastMessage(data?.message, "error", "fetchingPieChartData");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      onHide();
    }
  };

useEffect(()=>{
  fetchingPieChartData();
},[fromDate,toDate])


  const data = {
    labels:
    pieChartData &&
    pieChartData.length > 0 &&
    pieChartData.map((data) => data?.name || []),
    datasets: [
      {
        data:
          pieChartData &&
          pieChartData.length > 0 &&
          pieChartData.map((data) => data?.percentage || []), // percentage values from your data
        backgroundColor: [
          "rgba(0, 160, 207, 1)",
          "rgba(255, 49, 105, 1)",
          "rgba(255, 165, 35, 1)",
          "rgba(250, 185, 35, 1)",
          "rgba(140, 185, 35, 1)",
        ],
        borderColor: [
          "rgba(0, 160, 207, 1)",
          "rgba(255, 49, 105, 1)",
          "rgba(255, 165, 35, 1)",
          "rgba(250, 185, 35, 1)",
          "rgba(140, 185, 35, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const percentage = dataset.data[tooltipItem.dataIndex];
            return `${tooltipItem?.label}: ${percentage.toFixed(2)}%`;
          },
        },
      },
    },
  };

  return (
    <>
      <div className="filters earnings">
        <b>Earning by Category</b>

        <div className="button_wrap">
          <DropdownButton
            align="start"
            title={`${dateFormat(fromDate) || "Select From Date"} - ${
              dateFormat(toDate) || "Select To Date"
            }`}
            id="dropdown-autoclose-outside"
            autoClose="outside"
          >
            <div
              className={`dropdown_content ${
                selectedFilter === "Custom Range" ? "has_date" : ""
              }`}
            >
              <div className="inner_menu">
                {dashboardFilter() &&
                  dashboardFilter().length > 0 &&
                  dashboardFilter().map((data, index) => {
                    if (dashboardFilter().length - 1 !== index) {
                      return (
                        <Dropdown.Item
                          as={"span"}
                          key={index}
                          eventKey={index}
                          onClick={() => {
                            setSelectedFilter(
                              `${dateFormat(data?.fromDate)} - ${dateFormat(
                                data?.endDate
                              )}`
                            );
                            searchParam.set("fromDate", data?.fromDate);
                            searchParam.set("toDate", data?.endDate);
                            setSearchParam(searchParam);
                          }}
                        >
                          {data?.name}
                        </Dropdown.Item>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            as={"span"}
                            eventKey={index}
                            onClick={() => {
                              setSelectedFilter(data?.name);
                            }}
                          >
                            {data?.name}
                          </Dropdown.Item>
                        </React.Fragment>
                      );
                    }
                  })}
              </div>

              {selectedFilter === "Custom Range" && (
                <div className="custom_date_range">
                  <ReactDatePicker
                    selected={filterDashboardDate?.fromDate}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      setFilterDashboardDate({
                        ...filterDashboardDate,
                        fromDate: start,
                        toDate: end,
                      });
                    }}
                    startDate={filterDashboardDate?.fromDate}
                    endDate={filterDashboardDate?.toDate}
                    selectsRange
                    monthsShown={2}
                    inline
                  />

                  <div className="button_wrap button_right">
                    <button
                      type="button"
                      className="button"
                      onClick={() => {
                        searchParam.set(
                          "fromDate",
                          filterDashboardDate?.fromDate
                        );
                        searchParam.set("toDate", filterDashboardDate?.toDate);
                        setSearchParam(searchParam);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
          </DropdownButton>

          <Button
            className={""}
            onClick={() => {
              searchParam.delete("fromDate");
              searchParam.delete("toDate");
              setSearchParam(searchParam);
            }}
          >
            Clear Filter
          </Button>

          <Button
            className={""}
            onClick={() => {
              searchParam.set("fromDate", filterDashboardDate?.fromDate);
              searchParam.set("toDate", filterDashboardDate?.toDate);
              setSearchParam(searchParam);
            }}
          >
            Save Filter
          </Button>
        </div>
      </div>
      <div className="chart_wrap">
        {pieChartData && pieChartData.length > 0 ? (
          <Pie data={data} options={options} />
        ) : (
          <Placeholder text={"No Data Found For This Time Interval"} />
        )}
      </div>
    </>
  );
};

export default memo(EarningPieChart);
