import React, { useEffect, useState } from "react";
import "./ExploreExperts.scss";
import { Button } from "components/form";
import { IoIosSearch } from "react-icons/io";
import ExpertCard from "components/expertsCard";
import { experts_card } from "staticData";
import { expertListApi, expertiseListApi } from "services/master";
import { toastMessage } from "utils/toastMessage";
import { expertPlaceholderImg, profileImg } from "assets/images";
import { getLocalStorage, setLocalStorage } from "config/config";
import UseDebounce from "common/UseDebounce";
import { useSearchParams } from "react-router-dom";
import Placeholder from "components/placeholder/Placeholder";
import { getSelectedExpertTestimonialsApi } from "services/userServices/expertDetails";

const ExploreExperts = () => {
  const [expertList, setExpertList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [total, setTotal] = useState(null);
  const [pageSize, setPageSize] = useState(+searchParam.get("pageSize") || 10);
  const [expertise, setExpertise] = useState([]);
  let expertised = searchParam.get("expertise") || "";
  const [search, setSearch] = useState(
    getLocalStorage("expertsList", "search") || ""
  );
//   const debounceValue = UseDebounce(search || "", 300);

  const fetchExpertListing = async () => {
    const reqData = {};
    reqData.search = search;
    reqData.pageSize = pageSize;
    reqData.page = 0;
    if (expertised) {
      reqData.expertise = expertised;
    }
  
    try {
      const { data } = await expertListApi(reqData);
      if (data?.code === 200) {
        setExpertList(data?.data || []);
        setTotal(data?.totalItems);
      } else {
        toastMessage(data?.message, "error", "expertList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //Fetching Expertise List i,e:- calisthenics, lifting
  const fetchExpertiseListing = async () => {
    try {
      const { data } = await expertiseListApi();
      if (data?.code === 200) {
        setExpertise(data?.data || []);
      } else {
        toastMessage(data?.message, "error", "expertList");
      }
    } catch (e) {
      console.log(e.message);
    }
  };



  useEffect(() => {
    fetchExpertiseListing();
  }, []);

  useEffect(() => {
    fetchExpertListing();
  }, [searchParam, search]);

  const handlePageSize = () => {
    if (pageSize < total) {
      const newPageSize = Math.min(pageSize + 5, total);
      setPageSize(newPageSize);
      searchParam.set("pageSize", newPageSize);
      setSearchParam(searchParam);
    }
  };

  const expertiseHandler = (name) => {
    searchParam.set("expertise", name);
    setSearchParam(searchParam);
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setLocalStorage("exploreExperts", "search", search);
  }, [search]);

//   console.log(expertList,"ExpertList");

  return (
    <>
      <section className="exploreExperts">
        <div className="container">
          <div className="page_title text-center">
            <span>Explore Expertise</span>
            <h3>For people making Impact</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p>
          </div>

          <div className="search_bar">
            <input
              type="search"
              className="input"
              placeholder="Search for experts..."
              value={search}
              onChange={(e) => searchHandler(e)}
            />
            <Button className="has_icon">
              <IoIosSearch /> Search
            </Button>
          </div>

          {/*  */}
          <div className="servicesList">
            <ul>
              <li
                className={`${!expertised || expertised == "" ? "active" : ""}`}
                onClick={() => expertiseHandler("")}
              >
                All Experts
              </li>
              {expertise &&
                expertise.length > 0 &&
                expertise.map((data) => {
                  return (
                    <li
                      onClick={() => expertiseHandler(data?._id)}
                      className={`${expertised === data?._id ? "active" : ""}`}
                    >
                      {data?.name}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="experts_card_wrap">
            {expertList && expertList.length > 0 ? (
              expertList.map((item) => {
                return (
                  
                  <ExpertCard
                    // key={item?._id}
                    profileImage={item.image || profileImg}
                    expertName={item.firstName}
                    serviceName={item.expertise[0]}
                    rating={item?.rating?.avg}
                    users={item?.rating?.total || 20}
                    url={`/${item?.slug}`}
                  />
                );
              })
            ) : (
              <Placeholder
                image={expertPlaceholderImg}
                text={"No Expert Available in this category"}
              />
            )}

            {expertList.length > 0 && (
              <div className="button_wrap button_center">
                {pageSize < total && (
                  <Button onClick={handlePageSize}>Load More</Button>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreExperts;
