import React from "react";
import "./Aboutus.scss";
import { aboutUsBannerImg, bannerBg, personalADImg, webinarImg } from "assets/images";

const AboutUs = () => {
  return (
    <>
      <section className="aboutUs home_page">
        <div className="home_banner">
          <div className="container">
            <img src={aboutUsBannerImg} alt="" className="bgImage" />
            <div className="content">
              <span className="tag">
                About Us
              </span>
              <h1>Empower Your Fitness Journey: Achieve, Transform, Thrive</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
          </div>
        </div>

        <section className="expert_webinars spacing">
          <div className="container">
            <div className="content">
              <div className="page_title">
                <span className="_tag">Advance Features</span>
                <h3>Master Your Skills with Expert Webinars</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </p>
                <ul>
                  <li>Lorem Ipsum is simply dummy text of </li>
                  <li>the printing and typesetting industry. </li>
                  <li>Lorem Ipsum has been industry's</li>
                </ul>
              </div>
            </div>
            <div className="image_wrap">
              <img src={webinarImg} alt="" />
            </div>
          </div>
        </section>

        <section className="expert_webinars odd spacing">
          <div className="container">
            <div className="content">
              <div className="page_title">
                <span className="_tag">Advance Features</span>
                <h3>Get a personal advice on 1-to-1 video call with Sarah</h3>
                <p>
                  Tone your body and improve your health without starving
                  yourself or cutting out carbs Lorem Ipsum is simply dummy text
                  of the printing and the printing.
                </p>
                <ul>
                  <li>Perfect for begineers </li>
                  <li>Great for adults </li>
                  <li>For both men and women</li>
                </ul>
              </div>
            </div>
            <div className="image_wrap">
              <img src={personalADImg} alt="" />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default AboutUs;
